import React, { useState, useContext, Fragment } from "react"
import { RootContext } from '../../RootContext'
import { fetchWithTokenPegasoTools } from "components/Fetch/tools-api-fetch"

//core components
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

// pegaso views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import Shape from "views/Shape/Shape"


//import CSS
import { makeStyles } from "@material-ui/core/styles"
import { useJobStyles, useJobStylesText } from "styles/job/jobCss"

import OmaUtils from "components/Utils/OmaUtils"
import JobUtils from "components/Utils/JobUtils"

function EdgingGridContainer(props) {

  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    apiUrlPegasoTools,
    setAccess_token_pegaso_tools } = useContext(RootContext)
  const { creation, onHandleClose, shape, shapes, setShapes } = props
  //Valori dei dati
  const [shapeData, setShapeData] = useState(creation ?
    {
      shapeId: CalculateId(),
      description: "",
      active: true
    } :
    {
      shapeId: shape.shapeId,
      description: shape.description,
      active: shape.active
    })
  const [currentStandardShape, setCurrentStandardShape] = useState(shape.shape !== undefined ? shape.shape.standard : "")
  const [currentOriginalShape, setCurrentOriginalShape] = useState(shape.shape !== undefined ? shape.shape.original : "")
  const [currentEdgingData, setCurrentEdgingData] = useState(shape.shape !== undefined ? shape.shape.original : "")
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)


  const classes = useJobStyles()
  const classesText = useJobStylesText() // TEXT

  /**********************************************
    *  USE EFFECT
    **********************************************/



  /**************************************************************************
   *                    FUNZIONI
   *****************************************************************************/

  function CalculateId() {
    var maxVal = (Math.max(...shapes.map(item => item.shapeId)))
    return maxVal === Number.NEGATIVE_INFINITY ? 1 : maxVal + 1
  }

  /*************************************************************************
                     FUNZIONE SCROLL (BARRA IN ALTO FISSATA)
    **************************************************************************/
  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  /*************************************************************************
                      HANDLER CHANGER
   **************************************************************************/



  //Cambia field frame data
  // const handleChangeFrameData = (name) => (event) => {
  //   setShapeData({ ...shapeData, [name]: event.target.value })
  // }

  //Salvataggio Shape
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/loadedShape`
    fetchWithToken(
      url,
      {
        method: creation ? "POST" : "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          shapeId: shapeData.shapeId.toString(),
          description: shapeData.description,
          active: shapeData.active,
          shape: {
            original: currentOriginalShape,
            standard: currentStandardShape,
            edgingData: currentEdgingData,
          }
        }),
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      }
    )
      .then((response) => response.body)
      .then((data) => {
        setIsSaving(false)
        setDialogMsg("Shape salvata")
        setOpenDialog(true)
        let newElem = {
          shapeId: shapeData.shapeId,
          description: shapeData.description,
          active: shapeData.active,
          shape: {
            original: currentOriginalShape,
            standard: currentStandardShape,
          }
        }
        let newFrameRows = creation
          ? shapes.concat(newElem)
          : shapes.map((elem) => {
            if (elem.shapeId === shapeData.shapeId) {
              return newElem
            } else {
              return elem
            }
          })
        setShapes(newFrameRows)
      })
      .catch((err) => {
        const { body } = err
        if (body) {
          const { code } = body
          if (code === "E3") {
            setDialogMsg("10343")
          }
        }
        else {
          setDialogMsg("10344")
        }
        setIsSaving(false)
        setOpenDialog(true)
        ErrorUtils.errorLog("frameBrandPutError", err)
      })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    onHandleClose()
  }

  //Cambia field (checkbox)
  const handleChangeCheckedActive = (name) => (event) => {
    setShapeData({ ...shapeData, [name]: event.target.checked })
  }


  //Aggiorna gli edging data
  function handleUpdateEdgingData(
    standardShape,
    edgingData,
    originalShape,
    deviceUploader = false
  ) {
    let HBOX = OmaUtils.ExtractOmaValueFromString(edgingData, "HBOX", false)
    let VBOX = OmaUtils.ExtractOmaValueFromString(edgingData, "VBOX", false)
    let result = "";
    if (HBOX.trim() === "" || VBOX.trim() === "") {
      var shapeConverted = JobUtils.GetStandardShape({ shape: { standard: standardShape } })
      fetchWithTokenPegasoTools(`${apiUrlPegasoTools}/Shape/calcluate-vbox-hbox?side=R`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ shapeIn: shapeConverted }),
        apiUrl_pegaso_tools: apiUrlPegasoTools,
        access_token_pegaso_tools: access_token_pegaso_tools,
        refresh_token_pegaso_tools: refresh_token_pegaso_tools,
        setAccess_token_pegaso_tools: setAccess_token_pegaso_tools
      })
        .then(response => {
          const edgingDataFiltered = response.body.edgingData
            .split("\r\n")
            .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))
          edgingDataFiltered.forEach((row) => {
            const omaLabel = OmaUtils.ExtractOmaLabelFromRow(row)
            const omaValue = OmaUtils.ExtractOmaValueFromRow(row)
            result += omaLabel + "=" + omaValue + ";" + omaValue + "\r\n"
          })
          setCurrentEdgingData(result)
        })
        .catch(err => {
          ErrorUtils.errorLog(t("48"), err.message)
        })
    }
    else {
      result = "HBOX=" + HBOX + "\r\nVBOX=" + VBOX
      setCurrentEdgingData(result)
    }
    setCurrentOriginalShape(originalShape)
    setCurrentStandardShape(standardShape)
  }

  return (
    <div className={classes.jobRoot}>
      <Card>
        <CardBody>
          <Toolbar id="back-to-top-anchor" />

          {/* X CHIUSURA IN ALTO */}
          <ElevationScroll {...props}>
            <AppBar
              className={classes.appbar}
              position="sticky"
            >
              <Box display="flex" flexDirection="row-reverse" p={1}>
                <Box p={1}>
                  <IconButton
                    aria-label={t("10238")}
                    variant="outlined"
                    onClick={onHandleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </AppBar>
          </ElevationScroll>

          <Grid container spacing={2}>
            {/*GRID CONTENENTE I DATI DELLA MONTATURA*/}
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10221").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="frameBrand"
                    className={classesText.textField}
                    disabled
                    label={t("10434")}
                    value={shapeData.shapeId}
                    contentEditable={false}
                  />
                </Grid>

                {/* <Grid item xs={3}>
                  <TextField
                    id="modelName"
                    className={classesText.textField}
                    label={t("10334")}
                    value={shapeData.description}
                    onChange={handleChangeFrameData("description")}
                  />
                </Grid> */}

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shapeData.active}
                        onChange={handleChangeCheckedActive("active")}
                        name="active"
                      />
                    }
                    label={t("10134")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*GRID CONTENENTE IL DISEGNO E LA LETTURA DA TRACER*/}
          <Grid className={classes.jobGridStandardShape} item xs={12}>
            <Shape
              creation={creation}
              edgingData={currentEdgingData}
              editMode={false}
              frameMode={true}
              jobId={shapeData.shapeId}
              jobSoftware={""}
              handleUpdateEdgingData={handleUpdateEdgingData}
              scaleX={4}
              scaleY={4}
              shape={currentStandardShape ? currentStandardShape : ""}
            />
          </Grid>

          {/*BOTTONE SALVA*/}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.gridToolbar}
          >
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSave}
              >
                {t("10114")}
              </Button>
            </Box>
          </Box>
        </CardBody>
      </Card>


      {/*DIALOG SALVATAGGIO DATI*/}
      <Dialog
        open={isSaving || openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        {openDialog ? (
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogContent>
              <DialogContent>
                <CircularProgress size={42} style={{ color: "primary" }} />
              </DialogContent>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </div>
  )
}


// Griglia interna alla finestra del Shape
export default function LoadedShape(props) {
  const { creation, shape } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (shape) {
    return <EdgingGridContainer {...props} />
  } else if (!shape && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
