/***************************************************************************
                   FUNZIONE PER CONTROLLO VALORI NEI CAMPI
 ***************************************************************************/
import JobUtils from "components/Utils/JobUtils"

export default class JobControlUtils {
  static controlDiameter(valDiametro, t) {
    if (!valDiametro) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (valDiametro.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (valDiametro < 1) {
        return {
          error: true,
          msgError: t("3")
        }
      } else if (valDiametro > 99) {
        return {
          error: true,
          msgError: t("21")
        }
      } else if (!JobUtils.isNumeric(valDiametro)) {
        return {
          error: true,
          msgError: t("4")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlSphere(valSfero, t) {
    if (valSfero.trim() !== "") {
      valSfero = valSfero.replace(",", ".")
      if (!JobUtils.isNumeric(valSfero)) {
        return {
          error: true,
          msgError: t("6")
        }
      } else if (!JobUtils.zeroTwentyFive(valSfero)) {
        return {
          error: true,
          msgError: t("7")
        }
      } else if (valSfero > 30 || valSfero < -30) {
        return {
          error: true,
          msgError: t("8")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlCylinder(valcilindro, t) {
    if (valcilindro.trim() !== "") {
      valcilindro = valcilindro.replace(",", ".")
      if (!JobUtils.isNumeric(valcilindro)) {
        return {
          error: true,
          msgError: t("9")
        }
      } else if (!JobUtils.zeroTwentyFive(valcilindro)) {
        return {
          error: true,
          msgError: t("10")
        }
      } else if (valcilindro >= 10 || valcilindro <= -10) {
        return {
          error: true,
          msgError: t("11")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlAxis(valAsse, t) {
    if (valAsse) {
      if (valAsse.trim() !== "") {
        if (!JobUtils.isNumeric(valAsse)) {
          return {
            error: true,
            msgError: t("12")
          }
        } else if (valAsse < 0) {
          return {
            error: true,
            msgError: t("13")
          }
        } else if (valAsse > 360) {
          return {
            error: true,
            msgError: t("14")
          }
        } else if (!JobUtils.numberIsIntger(valAsse)) {
          return {
            error: true,
            msgError: t("15")
          }
        }
      }
      return {
        error: false,
        msgError: ""
      }
    } else {
      return {
        error: false,
        msgError: ""
      }
    }
  }

  static controlEdgingField(val, min, max, t) {
    if (val) {
      if (val.trim() !== "") {
        val = val.replace(",", ".")
        if (!JobUtils.isNumeric(val)) {
          return {
            error: true,
            msgError: t("16")
          }
        } else if (val < min) {
          if (min === 0) {
            return {
              error: true,
              msgError: t("17")
            }
          } else {
            return {
              error: true,
              msgError: `${t("18")} ${min}`
            }
          }
        } else if (val > max) {
          return {
            error: true,
            msgError: `${t("19")} ${max}`
          }
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlAddition(val, t) {
    return JobControlUtils.controlEdgingField(val, 0, 9.99, t)
  }

  static controlPd(val, t) {
    return JobControlUtils.controlEdgingField(val, 10, 45, t)
  }

  static controlEdgingHeight(val, t) {
    return JobControlUtils.controlEdgingField(val, 5, 40, t)
  }

  static controlBox(val, t) {
    return JobControlUtils.controlEdgingField(val, 15, 85, t)
  }

  static controlDbl(val, t) {
    return JobControlUtils.controlEdgingField(val, 4, 30, t)
  }

  static controlOffset(valOffset, t) {
    valOffset = valOffset + ""
    if (valOffset.trim() !== "") {
      valOffset = valOffset.replace(",", ".")
      if (!JobUtils.isNumeric(valOffset)) {
        return {
          error: true,
          msgError: t("16")
        }
      } else if (valOffset > 10 || valOffset < -10) {
        return {
          error: true,
          msgError: t("20")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlBevm(valBevp, valBevr, t) {
    if (valBevp && valBevr !== "") {
      if (valBevp.trim() !== "" && valBevp !== "?") {
        if (valBevr === "(mm)") {
          return JobControlUtils.controlEdgingField(valBevp, 0, 5, t)
        } else if (!JobUtils.isNumeric(valBevp)) {
          return {
            error: true,
            msgError: t("16")
          }
        } else if (valBevp < 0) {
          return {
            error: true,
            msgError: t("17")
          }
        } else if (valBevp > 99) {
          return {
            error: true,
            msgError: t("19") + " 99"
          }
        } else if (!JobUtils.numberIsIntger(valBevp)) {
          return {
            error: true,
            msgError: t("25")
          }
        }
      }
      return {
        error: false,
        msgError: ""
      }
    } else {
      return {
        error: false,
        msgError: ""
      }
    }
  }

  static controlTickestEdge(valEdge, t) {
    return JobControlUtils.controlEdgingField(valEdge, 1, 20, t)
  }

  static controlTickestCenter(valEdge, t) {
    return JobControlUtils.controlEdgingField(valEdge, 0, 20, t)
  }

  static controlGwidth(valGwidth, t) {
    return JobControlUtils.controlEdgingField(valGwidth, 0, 10, t)
  }

  static controlPinb(valPinb, t) {
    valPinb = valPinb + ""
    if (valPinb.trim() !== "") {
      valPinb = valPinb.replace(",", ".")
      if (!JobUtils.isNumeric(valPinb)) {
        return {
          error: true,
          msgError: t("16")
        }
      } else if (valPinb < 0) {
        return {
          error: true,
          msgError: t("13")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlGeneric(val,t){
    if (!val) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (val.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (val < 0) {
        return {
          error: true,
          msgError: t("18") + " 0"
        }
      } else if (val > 99) {
        return {
          error: true,
          msgError: t("19") + " 100"
        }
      } else if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("16")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlFcrv(val,t){
    if (!val) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (val.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (val < 0) {
        return {
          error: true,
          msgError: t("18") + " 0"
        }
      } else if (val > 12) {
        return {
          error: true,
          msgError: t("19") + " 12"
        }
      } else if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("16")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlZtilt(val,t){
    if (!val) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (val.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (val < 0) {
        return {
          error: true,
          msgError: t("18") + " 0"
        }
      } else if (val > 40) {
        return {
          error: true,
          msgError: t("19") + " 40"
        }
      } else if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("16")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlPanto(val,t){
    if (!val) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (val.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (val < 0) {
        return {
          error: true,
          msgError: t("18") + " 0"
        }
      } else if (val > 25) {
        return {
          error: true,
          msgError: t("19") + " 25"
        }
      } else if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("16")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }

  static controlCornealApex(val,t){
    if (!val) {
      return {
        error: false,
        msgError: ""
      }
    }
    if (val.trim() === "") {
      return {
        error: false,
        msgError: ""
      }
    } else {
      if (val < 6) {
        return {
          error: true,
          msgError: t("18") + " 6"
        }
      } else if (val > 30) {
        return {
          error: true,
          msgError: t("19") + " 30"
        }
      } else if (!JobUtils.isNumeric(val)) {
        return {
          error: true,
          msgError: t("16")
        }
      }
    }
    return {
      error: false,
      msgError: ""
    }
  }
}
