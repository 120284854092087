import merge from "lodash/merge"

// fetchJSON is bundled wrapper around fetch which simplifies working
// with JSON API:
//   * Automatically adds Content-Type: application/json to request headers
//   * Parses response as JSON when Content-Type: application/json header is
//     present in response headers
//   * Converts non-ok responses to errors
import { configureRefreshFetch, fetchJSON } from "refresh-fetch"

var local = {
  apiUrl_pegaso_tools: null,
  access_token_pegaso_tools: null,
  refresh_token_pegaso_tools: null,
  setAccess_token_pegaso_tools: null,
  streaming: false,
}

// Provide your favorite token saving -- to cookies, local storage, ...
function retrieveToken() {
  var result = local.access_token_pegaso_tools
  return result
}
const saveToken = (newToken) => {
  local.access_token_pegaso_tools = newToken
  sessionStorage.setItem("access_token_pegaso_tools", newToken)
  if (local.setAccess_token_pegaso_tools !== null) local.setAccess_token_pegaso_tools(newToken)
}
const clearToken = () => {
  local.access_token_pegaso_tools = null
  //if(local.setAccess_token_pegaso_tools !== null) local.setAccess_token_pegaso_tools(null) // F5 -> Si blocca tutto se scommentato
}

// Add token to the request headers
const fetchJSONWithToken = (url, options = {}) => {
  if (local.apiUrl_pegaso_tools === null) local.apiUrl_pegaso_tools = options.apiUrl_pegaso_tools
  if (local.access_token_pegaso_tools === null) local.access_token_pegaso_tools = options.access_token_pegaso_tools
  if (local.refresh_token_pegaso_tools === null) local.refresh_token_pegaso_tools = options.refresh_token_pegaso_tools
  if (local.setAccess_token_pegaso_tools === null)
    local.setAccess_token_pegaso_tools = options.setAccess_token_pegaso_tools
  if (local.streaming === null) local.streaming = options.streaming

  const token = retrieveToken()
  let optionsWithToken = options
  if (token != null) {
    if(token.trim() !== ""){
      optionsWithToken = merge({}, options, {
        headers: {
          Authorization: `Bearer ${retrieveToken()}`,
        },
      })
    }
  }

  if (local.streaming !== null) {
    if (options.streaming) {
      // Read in streaming
      return fetch(url, optionsWithToken)
    }
  }
  // Read normal json
  return fetchJSON(url, optionsWithToken)
}

// Add token to the request headers
const fetchJSONWithNoToken = (url, options = {}) => {
  return fetchJSON(url, options)
}

// Decide whether this error returned from API means that we want
// to try refreshing the token. error.response contains the fetch Response
// object, error.body contains the parsed JSON response body
const shouldRefreshToken = (error) => {
  return error.response
    ? (error.response.status === 401) ||
    (error.response.status === 500)
    : false
}

// Do the actual token refreshing and update the saved token
// IN AMBIENTE LOCALHOST NELLA VARIABILE LOCAL VENGONO PERSI I DATI QUANDO SI SALVA LA PAGINA
export const refreshToken = () => {
  let refreshToken = local.refresh_token_pegaso_tools

  if(refreshToken != null){
    if(refreshToken.trim() !==""){
      return fetchJSONWithNoToken(
        `${local.apiUrl_pegaso_tools}/Account/refresh-token`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
          body: JSON.stringify({ refreshToken: refreshToken }),
        }
      )
        .then((response) => {
          var newAccessToken = response["body"]["accessToken"]
          saveToken(newAccessToken)
          return newAccessToken
        })
        .catch((error) => {
          clearToken()
          throw error
        })
      }else{
        clearToken()
        throw new Error("token_expired")
      }
    }else{
      clearToken()
      throw new Error("token_expired")
    }
}

export const fetchWithTokenPegasoTools = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken,
  fetch: fetchJSONWithToken,
})
