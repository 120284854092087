import React, { useContext, useEffect, useCallback, useState } from "react"
import { Switch, Redirect } from "react-router-dom"
import { PrivateRoute } from "../index"
import { useTranslation } from "react-i18next"

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Favicon from "react-favicon"
import Footer from "components/Footer/Footer.js"
import Navbar from "components/Navbars/Navbar.js"
import Sidebar from "components/Sidebar/Sidebar.js"
import { RootContext } from "../RootContext"
import { CircularIndeterminate } from "views/Loading"

// Pegaso components
import ErrorUtils from "components/Tools/ErrorUtils"

import routes from "routes.js"
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js"
import bgImage from "assets/img/sidebar-2.jpg"

let ps

function switchRoutes(t, getIsDemo, catalogueScope) {
  return (
    <Switch>
      {routes.map((prop, key) => {
        prop.name = t(prop.name)
        if (prop.layout === "/admin") {
          if (prop.path === "/catalogue") {
            if (catalogueScope) {
              return (
                <PrivateRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              )
            } else {
              return null
            }
          } else {
            return (
              <PrivateRoute
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            )
          }
        }
        return null
      })}
      <Redirect
        from="/admin"
        to={`/admin/jobs`}
      />
    </Switch>
  )
}
const useStyles = makeStyles(styles)

export default function Admin({ ...rest }) {
  const { t, apiUrl, companyId, publicUrl } = useContext(RootContext)
  const { getIsDemo, companyName, catalogueScope, getLanguage } = useContext(RootContext)
  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()
  // states and functions
  const [image] = React.useState(bgImage)
  const [color] = React.useState("blue")
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [logoIco, setLogoIco] = useState()
  const [companyFooter, setCompanyFooter] = useState()
  const { i18n } = useTranslation()
  const userLanguage = getLanguage()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps"
  }
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  const fetchCompanyLogo = useCallback(
    (format, setStateImage) => {
      fetch(`${apiUrl}/companies/${companyId}/image?format=${format}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          let result
          if (format === "ico") {
            result = blob.size === 0 ? ({ src: publicUrl + '/resources/images/connect.ico' }) : ({ src: URL.createObjectURL(blob) })
          }
          else{
            result = blob.size === 0 ? ({ src: publicUrl + '/resources/images/logoConnect.png' }) : ({ src: URL.createObjectURL(blob) })
          }
          setStateImage(result)
        })
        .catch((err) => {
          ErrorUtils.errorLog("Login", err.message)
        })
    },
    [apiUrl, companyId, publicUrl]
  )

  const fetchCompanyFooter = useCallback(() => {
    fetch(`${apiUrl}/companies/${companyId}/footer`, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(result => setCompanyFooter(result))
      .catch((err) => {
        //setloginError({ message: "inv_logo" })
        ErrorUtils.errorLog("Login", err.message)
      })
  },
    [apiUrl, companyId,]
  )

  // Imposto lingua del utente
  useEffect(() => {
    i18n.changeLanguage(userLanguage)
  }, [i18n, userLanguage])

  // Se manca logoIco lo riprendo dal server
  useEffect(() => {
    if (!logoIco && !companyFooter) {
      fetchCompanyLogo("ico", setLogoIco)
      fetchCompanyFooter()
    }
  }, [companyFooter, logoIco, fetchCompanyFooter, fetchCompanyLogo, setLogoIco])

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (logoIco) {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        })
        document.body.style.overflow = "hidden"
      }
      window.addEventListener("resize", resizeFunction)
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy()
        }
        window.removeEventListener("resize", resizeFunction)
      }
    }
  }, [logoIco, mainPanel])

  // const newRoute = routes.filter(((prop, key) => {
  //   return true
  // })
  return logoIco ? (
    <div className={classes.wrapper}>
      <Favicon url={`${logoIco.src}`} />
      <Sidebar
        routes={routes}
        logoText={companyName}
        logo={logoIco.src}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        companyFooter={companyFooter}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {switchRoutes(t, getIsDemo, catalogueScope)}
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            {switchRoutes(t, getIsDemo, catalogueScope)}
          </div>
        )}
        {getRoute() && companyFooter ? <Footer companyFooter={companyFooter} /> : null}
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "scale(4, 4)",
      }}
    >
      <CircularIndeterminate className={classes.progress} />
    </div>
  )
}
