import React, { useState, useEffect, useContext, useCallback } from 'react';
import { RootContext } from 'RootContext';

// Core
import Paper from '@material-ui/core/Paper';

// Pegaso components
import EdgingStatisticToolBar from 'views/Statistics/EdgingStatisticToolbar'
import DatesUtils from 'components/Tools/DatesUtils'
import ErrorUtils from 'components/Tools/ErrorUtils'
import { fetchWithToken } from 'components/Fetch/api-fetch'

// Css
import { useStatisticFetchStyles } from 'styles/statistics/statisticFetchCss'

export default function EdgingStatisticFetch(props) {
    const { apiUrl, companyId, getLab, access_token, refresh_token, setAccess_token } = useContext(RootContext)
    const { labId } = getLab()
    const classes = useStatisticFetchStyles();
    const { allBrandsDevices, setJobsChartData, setStatFetched, setUpdateAgo } = props
    const [fetching, setFetching] = useState({ "cancel": false, "isFetching": false, "error": false })
    const [filter, setFilter] = useState({
        "fromDate": DatesUtils.getDaysAgoDate('-', 15),
        "toDate": DatesUtils.getCurrentDate('-'),
        "optician": "",
        "resultType": "days",
        "brand": "",
        "model": "",
        loading: true
    })
    const maxDays = 30;
    const [models, setModels] = useState([])
    const urlToFetch = `${apiUrl}/companies/${companyId}/labs/${labId}/edgingstatistic?`

    /*
        FETCH
    */
    //Fetch -  Elenco devices
    const fetchGetEdgingStatistic = useCallback(() => {
        const urlToFetchNew = urlToFetch +
            `fromDate=${filter.fromDate}&toDate=${filter.toDate}` +
            `&resultType=${filter.resultType}` +
            `&optician=${filter.optician}` +
            `&model=${filter.model}`
        fetchWithToken(urlToFetchNew, {
            method: 'GET',
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then(response => {
                return response.body // data
            })
            .then(data => {
                const newLineChartData = {
                    series: [[]],
                    labels: []
                }
                Object.entries(data).slice(0, maxDays).map(([key, value]) => {
                    newLineChartData.series[0].push(value)
                    newLineChartData.labels.push(DatesUtils.formatDateToString(key, true, false))
                    return value
                }
                );
                setJobsChartData(newLineChartData)
                setStatFetched(true)
                setUpdateAgo(Date.now())
            })
            .catch(err => {
                ErrorUtils.errorLog('Fetch Statistic Error', err)
            })
    }, [access_token, apiUrl, companyId, filter, refresh_token, setAccess_token, setJobsChartData, setStatFetched, setUpdateAgo, urlToFetch])

    const handleFetchStatistic = useCallback(() => {
        if (fetching.isFetching === false) {
            setFetching({ ...fetching, isFetching: true })
            fetchGetEdgingStatistic()
        }
    }, [fetching, fetchGetEdgingStatistic])

    /*
        BUTTONS
    */
    // Click when change data con Filter
    function handleCancelFetch() {
        setStatFetched(false)
        setFetching({ ...fetching, cancel: true })
    }

    // Click when change data con Filter
    function handleChangeFilter(filterField, targetValue) {
        setFilter({ ...filter, [filterField]: targetValue, loading: false });
    }

    /*
        USE EFFECT
    */
    // Calcolo Stasticia
    useEffect(() => {
        if (filter) {
            if (fetching.cancel) {
                setFetching({ ...fetching, cancel: false, isFetching: false })
            }
            handleFetchStatistic()
        }
    }, [fetching, filter, handleFetchStatistic])

    // Cambio Brand
    useEffect(() => {
        if (allBrandsDevices.length > 0) {
            if (filter.brand !== "") {
                allBrandsDevices.map((elem) => {
                    if (parseInt(elem.brandId) === parseInt(filter.brand)) {
                        let brandModels = elem.models.sort(function (a, b) {
                            return parseInt(a.modelId) - parseInt(b.modelId);
                        });
                        setModels(brandModels)
                    }
                    return elem
                })
            } else {
                setModels([])
            }
        }
    }, [allBrandsDevices, filter.brand])

    return (
        <div>
            <Paper className={classes.paper}>
                <EdgingStatisticToolBar
                    allBrandsDevices={allBrandsDevices}
                    filter={filter}
                    handleCancelFetch={handleCancelFetch}
                    handleChangeFilter={handleChangeFilter}
                    maxDays={maxDays}
                    models={models}
                />
            </Paper>
        </div>
    )
}