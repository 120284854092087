/*eslint-disable*/
import React, { useContext } from 'react'
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { RootContext } from '../../RootContext'

const useStyles = makeStyles(styles);


export default function Footer(props) {
  const classes = useStyles();
  const { t, companyName, publicUrl } = useContext(RootContext)
  const { companyFooter } = props
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/* <div className={classes.left}> */}
        {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={classes.block}>
                {t('home')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={classes.block}>
                {t('company')}
              </a>
            </ListItem>
          </List>
        </div> */}
        <p className={classes.left}>
          <span>
            &copy; Copyright {1900 + new Date().getYear()} Pegaso Informatica Venezia P.Iva 03508320276 &nbsp;
          </span>
        </p>
        <p className={classes.right}>
          <span>
          {companyName} - Tel. {companyFooter.telephoneNumber === "" ? " (+39) 041 3094100" : companyFooter.telephoneNumber} &nbsp;
            <a href={companyFooter.site === "" ? 'http://www.pegasovenezia.com' : companyFooter.site}>{companyFooter.site === "" ? "www.pegasovenezia.com" : companyFooter.site}</a> &nbsp;
            Email.<a href={companyFooter.email === "" ? 'mailto:info@pegasovenezia.com' : 'mailto:' + companyFooter.email}> {companyFooter.email === "" ? "info@pegasovenezia.com" : companyFooter.email} </a> &nbsp;
            - <a href={publicUrl + '/resources/SupremoPegaso.exe'} target="_blank" download='SupremoPegaso.exe' >{t("10225")}</a>
          </span>
        </p>
      </div>
    </footer >
  );
}
