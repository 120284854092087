import React from "react"
import { Line, Arc } from "react-konva"

export default function Slot(props) {
  const { colorSlot, origin, slot, side, startLens, scaleX, scaleY } = props
  let xDrawEnd, xStartValue, xEndValue
  let line1XStart, line1YStart
  let line2XStart, line2YStart
  let arc1XStart, arc1YStart
  let arc2XStart, arc2YStart
  let pointsLine = []
  let degreesRotation

  //calcolo dati retta
  let distanceX, distanceY, lengthLine
  distanceX = slot.xEnd - slot.xStart
  distanceY = slot.yEnd - slot.yStart
  lengthLine = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2))

  if (side === "right") {
    //LINEA 1 
    line1XStart = startLens + (((slot.xStart + (distanceY / lengthLine) * (slot.diam / 2)) + distanceX) * scaleX)
    line1YStart = origin.y - (((slot.yStart - (distanceX / lengthLine) * (slot.diam / 2)) + distanceY) * scaleY)

    //LINEA 2
    line2XStart = startLens + (((slot.xStart - (distanceY / lengthLine) * (slot.diam / 2)) + distanceX) * scaleX)
    line2YStart = origin.y - (((slot.yStart + (distanceX / lengthLine) * (slot.diam / 2)) + distanceY) * scaleY)

    //Valori disegno retta
    xStartValue = startLens + slot.xEnd * scaleX
    xEndValue = startLens + slot.xStart * scaleX
    xDrawEnd = xStartValue - xEndValue
    pointsLine = [0, 0, -xDrawEnd, distanceY * scaleY]

    //ARCO 1 
    arc1XStart = startLens + slot.xStart * scaleX
    arc1YStart = origin.y - slot.yStart * scaleY

    //ARCO 2
    arc2XStart = startLens + slot.xEnd * scaleX
    arc2YStart = origin.y - (slot.yEnd) * scaleY

    //Rotazione dell'arco
    degreesRotation = Math.atan2(arc2YStart - arc1YStart, arc2XStart - arc1XStart) * 180 / Math.PI;

  }
  else {

    //LINEA 1 
    line1XStart = startLens - (((slot.xStart + (distanceY / lengthLine) * (slot.diam / 2)) + distanceX) * scaleX)
    line1YStart = origin.y - (((slot.yStart - (distanceX / lengthLine) * (slot.diam / 2)) + distanceY) * scaleY)

    //LINEA 2
    line2XStart = startLens - (((slot.xStart - (distanceY / lengthLine) * (slot.diam / 2)) + distanceX) * scaleX)
    line2YStart = origin.y - (((slot.yStart + (distanceX / lengthLine) * (slot.diam / 2)) + distanceY) * scaleY)

    //Valori disegno retta
    xStartValue = startLens + slot.xStart * scaleX
    xEndValue = startLens + slot.xEnd * scaleX
    xDrawEnd = xStartValue - xEndValue
    pointsLine = [0, 0, -xDrawEnd, distanceY * scaleY]

    //ARCO 1 
    arc1XStart = startLens - slot.xEnd * scaleX
    arc1YStart = origin.y - slot.yEnd * scaleY
    arc2XStart = startLens - slot.xStart * scaleX
    arc2YStart = origin.y - (slot.yStart) * scaleY

    //Rotazione dell'arco
    degreesRotation = Math.atan2(arc2YStart - arc1YStart, arc2XStart - arc1XStart) * 180 / Math.PI;
  }


  return (
    <React.Fragment>
      <Arc
        x={arc1XStart}
        y={arc1YStart}
        innerRadius={(slot.diam * scaleX) / 2}
        outerRadius={(slot.diam * scaleX) / 2}
        angle={180}
        stroke={colorSlot}
        strokeWidth={1}
        rotation={degreesRotation + 90}
      />
      <Line
        x={line1XStart}
        y={line1YStart}
        points={pointsLine}
        stroke={colorSlot}
        strokeWidth={1}

      />
      <Line
        x={line2XStart}
        y={line2YStart}
        points={pointsLine}
        stroke={colorSlot}
        strokeWidth={1}
      />
      <Arc
        x={arc2XStart}
        y={arc2YStart}
        innerRadius={(slot.diam * scaleX) / 2}
        outerRadius={(slot.diam * scaleX) / 2}
        angle={180}
        stroke={colorSlot}
        strokeWidth={1}
        rotation={degreesRotation - 90}
      />
    </React.Fragment>

  )
}