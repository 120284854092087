import { makeStyles } from '@material-ui/styles'
import theme from '../theme'

const useDeviceEditScreenStyles = makeStyles({
  gridDeviceToolbar: {
    bgcolor: "background.paper",
  },
  gridDeviceValues: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridDeviceCurrentValues: {
    width: "16.6%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
})

/*---------  TEXT FIELDS  ---------*/
const useDeviceStylesText = makeStyles({
 textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none'
  },
  menuItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none',
    width: 200
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});


export { useDeviceEditScreenStyles, useDeviceStylesText }
