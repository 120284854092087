import { makeStyles } from '@material-ui/core/styles';
import {
  primaryColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const useJobsTableStyles = makeStyles({
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...primaryColor,
    "&, &$tableCell": {
      fontSize: "1.1rem",
      lineHeight: "1.42857143",
      padding: "8px 1px",
      verticalAlign: "middle",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "1px 1px",
    verticalAlign: "middle",
    fontSize: "1.1rem",
  },
  tableCellError: {
    ...defaultFont,
    color:"red",
    lineHeight: "1.42857143",
    padding: "1px 1px",
    verticalAlign: "middle",
    fontSize: "1.1rem",
  }, 
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export { useJobsTableStyles }
