import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ErrorUtils from "components/Tools/ErrorUtils"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import { fetchWithToken } from "../../components/Fetch/api-fetch"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"

//Pegaso components
import FrameSuppliers from "views/FramesManagement/FrameSuppliers"
import FrameBrands from "views/FramesManagement/FrameBrands"
import FramesList from "views/FramesManagement/FramesList"

export default function Frames() {
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token } = useContext(RootContext)
  const [endLoadingFrames, setEndLoadingFrames] = useState(false)
  const [endLoadingFrameBrands, setEndLoadingFrameBrands] = useState(false)
  const [endLoadingFrameSuppliers, setEndLoadingFrameSuppliers] = useState(false)
  const [frames, setFrames] = useState([])
  const [frameBrands, setFrameBrands] = useState([])
  const [frameBrandsFilterOption, setFrameBrandsFilterOption] = useState([])
  const [frameSuppliers, setFrameSuppliers] = useState([])
  const [frameSuppliersFilterOption, setFrameSuppliersFilterOption] = useState([])
  const [isFetchingFrames, setIsFetchingFrames] = useState(true)
  const [isFetchingFrameBrands, setIsFetchingFrameBrands] = useState(true)
  const [isFetchingFrameSuppliers, setIsFetchingFrameSuppliers] = useState(true)
  const [openPanelSupplier, setOpenPanelSupplier] = useState(false)
  const [openPanelBrand, setOpenPanelBrand] = useState(false)
  const [openPanelFrame, setOpenPanelFrame] = useState(false)
  /*
    HANDLER
  */


  //Chiude o apre il pannello contenente i supplier
  const changeOpenPanelFrameSupplier = (event,) => {
    var value = !openPanelSupplier
    setOpenPanelSupplier(value)
  }

  //Chiude o apre il pannello contenente i brand
  const changeOpenPanelFrameBrand = (event,) => {
    var value = !openPanelBrand
    setOpenPanelBrand(value)
  }

  //Chiude o apre il pannello contenente i brand
  const changeOpenPanelFrame = (event,) => {
    var value = !openPanelFrame
    setOpenPanelFrame(value)

  }

  /**
   *
   * FETCH
   *
   */


  //Fetch dei frame suppliers
  const fetchFrameSuppliers = useCallback(() => {
    if (isFetchingFrameSuppliers === true) {
      setIsFetchingFrameSuppliers(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameSupplier`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameSuppliers } = body
          var newFrameSuppliers = frameSuppliers.map((option => {
            option.formattedActive = option.active ? t("10147") : t("10079")
            return option
          }))
          var optionFrameSupplier = frameSuppliers.map(option => {
            return {
              value: option.frameSupplierId,
              text: option.frameSupplierId + " - " + option.frameSupplierName,
            }
          })
          optionFrameSupplier.unshift({
            value: 0,
            text: ""
          })
          setFrameSuppliers(newFrameSuppliers)
          setFrameSuppliersFilterOption(optionFrameSupplier)
          setEndLoadingFrameSuppliers(true)
        })
        .catch(err => {
          setEndLoadingFrameSuppliers(true)

          ErrorUtils.errorLog("Fetch frame suppliers Error.", err.message)
        })
    }

  }, [access_token, apiUrl, companyId, isFetchingFrameSuppliers, refresh_token, setAccess_token, setIsFetchingFrameSuppliers,t])

  //Fetch dei frame brand
  const fetchFrameBrands = useCallback(() => {
    if (isFetchingFrameBrands === true) {
      setIsFetchingFrameBrands(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameBrand`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameBrands } = body
          var formattedBrands = frameBrands.map((brand) => {
            const { frameSupplier } = brand
            if (frameSupplier) {
              brand.formattedSupplier = frameSupplier.frameSupplierId + " - " + frameSupplier.frameSupplierName
              brand.frameSupplierId = frameSupplier.frameSupplierId
            }
            brand.formattedActive = brand.active ? t("10147") : t("10079")
            return brand
          })
          setFrameBrands(formattedBrands)
          var optionFrameBrands = frameBrands.map(option => {
            return {
              value: option.frameBrandId,
              text: option.frameBrandId + " - " + option.frameBrandName
            }
          })
          optionFrameBrands.unshift({
            value: 0,
            text: ""
          })
          setFrameBrandsFilterOption(optionFrameBrands)
          setEndLoadingFrameBrands(true)
        })
        .catch(err => {
          setEndLoadingFrameBrands(true)
          ErrorUtils.errorLog("Fetching frame brands Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingFrameBrands, refresh_token, setAccess_token,t])

  //Fetch dei frame brand
  const fetchFrame = useCallback(() => {
    if (isFetchingFrames === true) {
      setIsFetchingFrames(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frames } = body
          setFrames(frames)
          setEndLoadingFrames(true)
        })
        .catch(err => {
          setEndLoadingFrames(true)
          ErrorUtils.errorLog("RequiredField Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingFrames, refresh_token, setAccess_token,])

  /**
   * 
   *USE EFFECT
   */
  useEffect(() => {
    if (isFetchingFrameSuppliers || isFetchingFrameBrands) {
      fetchFrameSuppliers()
      fetchFrameBrands()
    }
    if (isFetchingFrames) {
      fetchFrame()
    }
  }, [isFetchingFrames, isFetchingFrameSuppliers, isFetchingFrameBrands, fetchFrameSuppliers, fetchFrameBrands, fetchFrame])



  return (
    <div>

      {/*PANNELLO DEI SUPPLIER*/}
      <Accordion expanded={openPanelSupplier} onChange={changeOpenPanelFrameSupplier} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10378").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FrameSuppliers
                  endLoadingFrameSuppliers={endLoadingFrameSuppliers}
                  tableLine={frameSuppliers}
                  setIsFetching={setIsFetchingFrameSuppliers}
                  setEndLoadingFrameSuppliers={setEndLoadingFrameSuppliers}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/*PANNELLO DEI BRAND*/}
      <Accordion expanded={openPanelBrand} onChange={changeOpenPanelFrameBrand} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10377").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FrameBrands
                  frameSuppliers={frameSuppliers}
                  endLoadingFrameBrands={endLoadingFrameBrands}
                  tableLine={frameBrands}
                  setIsFetching={setIsFetchingFrameBrands}
                  setEndLoadingFrameBrands={setEndLoadingFrameBrands}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/*PANNELLO DELLE MONTATURE*/}
      <Accordion expanded={openPanelFrame} onChange={changeOpenPanelFrame} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10376").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FramesList
                  framesRows={frames}
                  frameSuppliers={frameSuppliers}
                  frameSuppliersFilterOption={frameSuppliersFilterOption}
                  frameBrandsFilterOption={frameBrandsFilterOption}
                  frameBrands={frameBrands}
                  endLoadingFrames={endLoadingFrames}
                  setFramesRows={setFrames}
                  setIsFetchingFrames={setIsFetchingFrames}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

    </div>
  )
}
