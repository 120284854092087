import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme'

const useStatisticFetchStyles = makeStyles({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
});

export {useStatisticFetchStyles}
