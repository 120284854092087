//Pagina delle settings device + taratura
import React, { useContext } from "react"
import { RootContext } from 'RootContext'

import DeviceSettings from "views/Settings/DeviceSettings"
import CalibrationSettings from "views/Settings/CalibrationSettings"
import OrderSettings from "views/Settings/OrderSettings"



export default function Settings() {

    const { getAutoSetupTracer } = useContext(RootContext)
    var autoSetupTracer = getAutoSetupTracer()

    return (
        <div>
            {autoSetupTracer ? <DeviceSettings /> : null}
            <CalibrationSettings />
            <OrderSettings/>
        </div>
    )
}