//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"

//import CSS
import { useDeviceEditScreenStyles } from "styles/device/deviceCss.js"


//Componenti Pegaso
import SerialDevice from "components/DeviceOption/SerialDevice"
import DownloadOption from "components/DeviceOption/DownloadOption"
import OptionDevice from "components/DeviceOption/OptionDevice"

export default function Edger(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    connectionType,
    deviceProtocol,
    isCreationModel,
    selectedDeviceData,
    selectedDeviceJSON,
    setChangeDevice,
    setSelectedDeviceJSON,
  } = props

  //Valori modificabili
  const [downloadLabel, setDownloadLabel] = useState(deviceProtocol.options.download.omaLabels)
  const [downloadOption, setDownloadOption] = useState(deviceProtocol.options.download)
  const [optionEdger, setOptionEdger] = useState({
    idProtocol: deviceProtocol.id,
    jobWithoutBarcode: deviceProtocol.options.jobWithoutBarcode,
    offsetMetal: deviceProtocol.options.offsetMetal,
    offsetOther: deviceProtocol.options.offsetOther,
    offsetPlastic: deviceProtocol.options.offsetPlastic,
    nack: deviceProtocol.options.nack,
    singleSide: deviceProtocol.options.singleSide,
    tracerId: deviceProtocol.options.tracerId,
    withoutBarcode: deviceProtocol.options.withoutBarcode,
  })
  const [openPanelOption, setOpenPanelOption] = useState(false)
  const [openPanelSerial, setOpenPanelSerial] = useState(false)
  const [serialOption, setSerialOption] = useState({
    discardNull: selectedDeviceData.serial.discardNull,
    dtr: selectedDeviceData.serial.dtr,
    handshake: selectedDeviceData.serial.handshake,
    readBufferSize: selectedDeviceData.serial.readBufferSize,
    readTimeout: selectedDeviceData.serial.readTimeout,
    rts: selectedDeviceData.serial.rts,
    timeout: selectedDeviceData.serial.timeout,
    writeBufferSize: selectedDeviceData.serial.writeBufferSize,
    writeTimeout: selectedDeviceData.serial.writeTimeout
  })

  //Classes utilizzate per CSS
  const classes = useDeviceEditScreenStyles()

  //SAVE MODIFICHE 
  const handleEditOption = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      protocol: {
        id: optionEdger.idProtocol,
        options: {
          download: downloadOption,
          jobWithoutBarcode: optionEdger.jobWithoutBarcode,
          nack: optionEdger.nack,
          offsetMetal: optionEdger.offsetMetal,
          offsetOther: optionEdger.offsetOther,
          offsetPlastic: optionEdger.offsetPlastic,
          singleSide: optionEdger.singleSide,
          tracerId: optionEdger.tracerId,
          //upload: uploadOption,
          withoutBarcode: optionEdger.withoutBarcode,
        }
      }
    })
    setOpenPanelOption(false)
  }

  //Salva le modifiche efettuate alla porta seriale
  const handleEditSerial = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        ...selectedDeviceJSON.serial,
        timeout: serialOption.timeout,
        handshake: serialOption.handshake,
        readBufferSize: serialOption.readBufferSize,
        writeBufferSize: serialOption.writeBufferSize,
        readTimeout: serialOption.readTimeout,
        writeTimeout: serialOption.writeTimeout,
        rts: serialOption.rts,
        dtr: serialOption.dtr,
        discardNull: serialOption.discardNull,
      }
    })
    setOpenPanelSerial(false)
  }

  //Chiude il pannello contenten i dati
  const changeOpenPanelOption = (event,) => {
    var value = !openPanelOption
    setOpenPanelOption(value)
  }

  //Chiude il pannello contenten i dati della porta seriale
  const changeOpenPanelSerial = (event) => {
    var value = !openPanelSerial
    setOpenPanelSerial(value)
  }

  return (
    <div>
      <Accordion expanded={openPanelOption} onChange={changeOpenPanelOption}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("10267")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>

            <OptionDevice
              changeDevice={changeDevice}
              optionDevice={optionEdger}
              setChangeDevice={setChangeDevice}
              setOpenPanelOption={setOpenPanelOption}
              setOptionDevice={setOptionEdger}
            />

            <Typography className={classes.heading}>{t("10268")}</Typography>
            <DownloadOption
              changeDevice={changeDevice}
              downloadLabel={downloadLabel}
              downloadOption={downloadOption}
              setChangeDevice={setChangeDevice}
              setDownloadLabel={setDownloadLabel}
              setDownloadOption={setDownloadOption}
              setOpenPanelOption={setOpenPanelOption}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              className={classes.gridDeviceToolbar}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEditOption}
                >
                  {t("10270")}
                </Button>
              </Box>
            </Box>
          </Card>
        </AccordionDetails>
      </Accordion>
      {
        connectionType === "serial" || isCreationModel === true ? (
          <Accordion expanded={openPanelSerial} onChange={changeOpenPanelSerial}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{t("10271")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <SerialDevice
                  changeDevice={changeDevice}
                  selectedDeviceData={selectedDeviceData}
                  serialOption={serialOption}
                  setChangeDevice={setChangeDevice}
                  setSerialOption={setSerialOption}
                  setOpenPanelSerial={setOpenPanelSerial}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  p={1}
                  className={classes.gridDeviceToolbar}
                >
                  <Box p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleEditSerial}
                    >
                      {t("10270")}
                    </Button>
                  </Box>
                </Box>
              </Card>

            </AccordionDetails>
          </Accordion>
        ) : (
            null
          )
      }

    </div>
  )
}