import { makeStyles } from "@material-ui/styles"

const useScrollStyles = makeStyles({
  appbar: {
    display: "flex",
    marginLeft: "auto", 
    background: "transparent", 
    boxShadow: "none",
    width: "50px", 
  },
})

export { useScrollStyles }
