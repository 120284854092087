import React, { useState, useContext, } from "react"
import { RootContext } from "../../RootContext"

//core components
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// OpticianUsers Table -> HEAD
export default function FrameListTableHead(props) {
    const {isFullTable} = props
    const { t, } = useContext(RootContext)

    const headCells = [

        { id: "frameId", numeric: false, disablePadding: false, label: t("10333"), },
        { id: "frameSupplier", numeric: false, disablePadding: false, label: t("10378") },
        { id: "frameBrand", numeric: false, disablePadding: false, label: t("10377") },
        { id: "modelName", numeric: false, disablePadding: false, label: t("10334"), },
        { id: "color", numeric: false, disablePadding: false, label: t("10335"), },
        { id: "caliber", numeric: false, disablePadding: false, label: t("10336") },
        { id: "active", numeric: false, disablePadding: false, label: t("10134") },
    ]
    var [orderBy, setOrderBy] = useState("frameId")
    const [order, setOrder] = useState("asc")

    // Click sort by coloumn
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {isFullTable ? null : (<TableCell key={"collapse"}/>)}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label.toUpperCase()}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

}