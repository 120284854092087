import { makeStyles } from '@material-ui/styles'

import {
    defaultFont,
} from "assets/jss/material-dashboard-react.js";

const useCardStyle = makeStyles({
    cardTitleWhite: {
        ...defaultFont,
        color: "#FFFFFF",
        marginTop: "auto",
        marginBottom: "0px",
    },
    cardMarginBottom: {
        marginBottom: '4%'
    },
    cardHeaderStyle: {
        height: 'auto',
        marginBottom: "0px"
    }
})

const useDropzoneAreaStyle = makeStyles({
    myDropZone: {
        width: 'auto',
        height: 'auto',
        textAlign: 'center',
        background: 'white',
    },
    previewChip: {
        minWidth: "auto",
        maxWidth: "auto",
    },
    dropZoneText: {
        fontSize: '20px'
    }
})

const useGridStyle = makeStyles({
    cardMarginBottom: {
        marginBottom: '5px'
    }
})

export { useCardStyle, useDropzoneAreaStyle, useGridStyle }