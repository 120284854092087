import React, {useState } from "react"

//Core components
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"


export default function CustomFilterComponent(props) {
  const { columnOrder, field, lookupArray, onFilterChanged, classes } = props
  const [selectedVal, setSelectedVal] = useState(0)

  function handleChange(e) {
    const val = e.target.value
    setSelectedVal(val)
    onFilterChanged(columnOrder, val)
  }

  return (
    <FormControl>
      <Select
        key={field + "_select"}
        value={selectedVal}
        onChange={handleChange}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        <MenuItem key={field + "_menuitem_blank"} value={0}></MenuItem>
        {lookupArray.map((elem) => {
          return (
            <MenuItem
              key={field + "_menuitem_" + elem}
              value={elem}
              selected={selectedVal === elem}
            >
              {elem}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}