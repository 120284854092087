import React, { useState, useEffect, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

// core components
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import MaterialTable, { MTableAction, MTableEditField } from "@material-table/core"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import _ from "lodash"
import { titleCase } from "title-case"

// pegaso views/components
import CustomPagination from "components/CustomMaterialTable/CustomPagination"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"

export default function MaterialTableDemo() {
  const {
    companyId,
    t,
    apiUrl,
    getLab,
    access_token,
    refresh_token,
    setAccess_token,
    catalogueScope,
  } = useContext(RootContext)
  const { labId } = getLab()
  const [fetch, setFetch] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [duplicateError, setDuplicateError] = useState(false)
  const [open, setOpen] = useState(false)
  const [requiredErrorId, setRequiredErrorId] = useState(false)
  const [requiredErrorName, setRequiredErrorName] = useState(false)

  const defaultAddress = {
    street: "",
    number: "",
    zipCode: "",
    city: "",
    province: "",
    state: "",
  }
  const [currentAddress, setCurrentAddress] = useState(defaultAddress)
  const [currentPrices, setCurrentPrices] = useState({})
  const [countOpticians, setCountOpticians] = useState(0)
  const classes = useJobsStyles()

  let initColumns = [
    {
      title: t("10046").toUpperCase(),
      field: "opticianId",
      editable: "onAdd",
      headerStyle: { width: 25, maxWidth: 25 },
      cellStyle: { width: 25, maxWidth: 25 },
      editComponent: (props) => (
        <MTableEditField
          {...props}
          error={props.value === "" || !props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e)
            setRequiredErrorId(e === "")
          }}
        />
      ),
    },
    {
      title: t("10077").toUpperCase(),
      field: "name",
      editComponent: (props) => (
        <MTableEditField
          {...props}
          error={props.value === "" || !props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e)
            setRequiredErrorName(e === "")
          }}
        />
      ),
    },
    { title: t("10195").toUpperCase(), field: "addressFormatted" },
    { title: t("10193").toUpperCase(), field: "vatNumber" },
    { title: t("10098").toUpperCase(), field: "phone" },
    { title: t("10073").toUpperCase(), field: "email" },
  ]

  if (catalogueScope) {
    initColumns.splice(2, 0, { title: t("10192").toUpperCase(), field: "pricesFormatted" })
  }

  const [state, setState] = useState({ columns: initColumns, data: [] })

  function formatPrices(prices) {
    return prices.join(",")
  }

  const formatAddress = useCallback(
    (address) => {
      let { street, number, city, province, zipCode, state } = address
      street = street ? `${t("10132")} ${street}` : ""
      number = number ? number : ""
      city = city ? city : ""
      province = `(${province})` ? province : ""
      zipCode = zipCode ? zipCode : ""
      state = state ? state : ""
      const comma = street || number ? "," : ""
      return `${street} ${number}${comma} ${city} ${province} ${zipCode} ${state} `
    },
    [t]
  )

  const handleGetOptician = useCallback(() => {
    setIsFetching(true)
    fetchWithToken(`${apiUrl}/companies/${companyId}/labs/${labId}/opticians`, {
      method: "GET",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => response.body)
      .then((data) => {
        setState((prevState) => {
          return {
            ...prevState,
            data: data.map((optician) => {
              const { address } = optician
              if (address) {
                optician.addressFormatted = formatAddress(address)
              }
              if (catalogueScope) {
                if (optician.prices) {
                  optician.pricesFormatted = formatPrices(optician.prices)
                }
              }

              return optician
            }),
          }
        })

        setCurrentAddress({
          street: "",
          number: "",
          zipCode: "",
          city: "",
          province: "",
          state: "",
        })
        if (catalogueScope) {
          setCurrentPrices({})
        }
        setCountOpticians(data.length)
        setFetch(false)
        setIsFetching(false)
      })
      .catch((err) => {
        setFetch(false)
        setIsFetching(false)
        ErrorUtils.errorLog("Fetch Opticians Error", err)
      })
  }, [
    companyId,
    setIsFetching,
    setFetch,
    access_token,
    apiUrl,
    catalogueScope,
    formatAddress,
    labId,
    refresh_token,
    setAccess_token,
  ])

  useEffect(() => {
    if (fetch && !isSaving && !isFetching) {
      handleGetOptician()
    }
  }, [fetch, isSaving, isFetching, handleGetOptician])

  const handlePutOrPost = (newOptician, method, resolve, reject) => {
    setIsSaving(true)
    const address = { ...currentAddress }
    if (address["last"] || address["last"] === "") {
      delete address["last"]
    }
    if (
      newOptician["addressFormatted"] ||
      newOptician["addressFormatted"] === ""
    ) {
      delete newOptician["addressFormatted"]
    }
    newOptician.lab = labId
    const bodyToSend = { ...newOptician, address }

    if (catalogueScope) {
      Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate))
      const filtered = Object.filter(
        catalogueScope.companySettings.values,
        ([k, v]) => v["active"]
      )
      let newPrices = _.mapValues(filtered, () => false)
      if (newOptician.prices) {
        newOptician.prices.forEach((element) => {
          newPrices[element] = true
        })
      }

      // eslint-disable-next-line
      for (let key in currentPrices) {
        newPrices[key] = currentPrices[key]
      }

      const prices = []
      // eslint-disable-next-line
      for (let key in newPrices) {
        if (key) {
          if (newPrices[key]) prices.push(parseInt(key, 10))
        }
      }

      bodyToSend["prices"] = prices
    }

    if (bodyToSend["pricesFormatted"] || bodyToSend["pricesFormatted"] === "") {
      delete bodyToSend["pricesFormatted"]
    }

    fetchWithToken(
      `${apiUrl}/companies/${companyId}/opticians/${newOptician.opticianId}`,
      {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyToSend),
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      }
    )
      .then((response) => response.body)
      .then((data) => {
        resolve()
        setIsSaving(false)
        setFetch(true)
      })
      .catch((err) => {
        reject()
        const { body } = err
        if (body) {
          const { code } = body
          if (code === "E3") {
            setDuplicateError(true)
          }
        }
        setOpen(true)
        setIsSaving(false)
        setFetch(false)
        ErrorUtils.errorLog("opticianPutError", err)
      })
  }

  function handleDelete(opticianId, resolve, reject) {
    setIsSaving(true)
    fetchWithToken(`${apiUrl}/companies/${companyId}/opticians/${opticianId}`, {
      method: "DELETE",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => response.body)
      .then((data) => {
        resolve()
        setIsSaving(false)
        setFetch(true)
      })
      .catch((err) => {
        reject()
        setOpen(true)
        setIsSaving(false)
        setFetch(false)
        ErrorUtils.errorLog("Delete Opticians Error", err)
      })
    setFetch(true)
  }

  const handleClose = () => {
    setCurrentAddress(defaultAddress)
    setCurrentPrices({})
    setOpen(false)
    setDuplicateError(false)
    setRequiredErrorId(false)
    setRequiredErrorName(false)
  }

  const handleChangeAddress = (name) => (event) => {
    setCurrentAddress((currentAddress) => {
      return {
        ...currentAddress,
        [name]: event.target.value,
        last: name,
      }
    })
  }

  function handleOnChangePrice(event) {
    setCurrentPrices((currentPrices) => {
      return {
        ...currentPrices,
        [event.target.value]: event.target.checked,
      }
    })
  }

  //Controlla quali righe sono editabili
  function searchEditableDeletable(row) {
    var res = true;
    if (row.opticianId.indexOf("LAB") >= 0) {
      res = false;
    }
    if (row.opticianId.indexOf("OPT") >= 0) {
      res = false;
    }
    return res
  }

  function PricesCheckGroup({ value, handleOnChangePrice }) {
    Object.filter = (obj, predicate) =>
      Object.fromEntries(Object.entries(obj).filter(predicate))
    const filtered = Object.filter(
      catalogueScope.companySettings.values,
      ([k, v]) => v["active"]
    )
    let prices = _.mapValues(filtered, () => false)
    if (typeof value === "string") {
      value.split(",").forEach((element) => {
        prices[element] = true
      })

      // eslint-disable-next-line
      for (const key in currentPrices) {
        prices[key] = currentPrices[key]
      }
    }

    function FormControlLabelPrices({ price }) {
      return (
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              color="primary"
              value={price}
              checked={prices[price]}
              onChange={handleOnChangePrice}
            />
          }
          label={price}
          labelPlacement="end"
        />
      )
    }

    return (
      <div>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {Object.keys(prices)
              .filter((el) => el !== "")
              .map((price) => (
                <FormControlLabelPrices key={price} price={price} />
              ))}
          </FormGroup>
        </FormControl>
      </div>
    )
  }

  const EditField = ({ label, onChange, value, field, last }) => (
    <FormControl margin="normal" fullWidth>
      <TextField
        id={field}
        label={label}
        value={value}
        InputProps={{ style: { fontSize: 13 } }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        type="text"
        onChange={onChange}
        autoFocus={last ? last === field : false}
      />
    </FormControl>
  )

  function AddressGroup() {
    const {
      last,
      street,
      number,
      zipCode,
      city,
      province,
      state,
    } = currentAddress
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EditField
            field="street"
            label={t("10132")}
            onChange={handleChangeAddress("street")}
            value={street}
            last={last}
          />
        </Grid>
        <Grid item xs={3}>
          <EditField
            field="number"
            label={t("10133")}
            onChange={handleChangeAddress("number")}
            value={number}
            last={last}
          />
        </Grid>
        <Grid item xs={3}>
          <EditField
            field="zipCode"
            label={t("10148")}
            onChange={handleChangeAddress("zipCode")}
            value={zipCode}
            last={last}
          />
        </Grid>
        <Grid item xs={4}>
          <EditField
            field="city"
            label={t("10012")}
            onChange={handleChangeAddress("city")}
            value={city}
            last={last}
          />
        </Grid>
        <Grid item xs={4}>
          <EditField
            field="province"
            label={t("10107")}
            onChange={handleChangeAddress("province")}
            value={province}
            last={last}
          />
        </Grid>
        <Grid item xs={4}>
          <EditField
            field="state"
            label={t("10129")}
            onChange={handleChangeAddress("state")}
            value={state}
            last={last}
          />
        </Grid>
      </Grid>
    )
  }



  return (
    <div>
      <Card>
        <CardBody>
          <div className={classes.tableWrapper}>
            <Paper className={classes.paper}>
              <MaterialTable
                isLoading={isSaving || fetch}
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                title={t("10052")}
                columns={state.columns}
                data={state.data}
                options={{
                  actionsColumnIndex: 0,
                  pageSize: 10,
                  pageSizeOptions: [5, 10, 15],
                  draggable: false,
                  showFirstLastPageButtons: false,
                }}
                components={{
                  EditField: (props) => {
                    const { columnDef, rowData } = props
                    const { field } = columnDef
                    if (field === "addressFormatted") {
                      let { address } = rowData
                      if (!address) {
                        address = defaultAddress
                      }
                      if (address && !currentAddress.last) {
                        setCurrentAddress({ ...address, last: "init" })
                      }
                      return <AddressGroup />
                    } else if (field === "pricesFormatted") {
                      const { value } = props
                      return (
                        <PricesCheckGroup
                          value={value ? value : ""}
                          handleOnChangePrice={handleOnChangePrice}
                        />
                      )
                    } else {
                      return <MTableEditField {...props} />
                    }
                  },
                  Action: (props) => {
                    const { action } = props
                    if (action) {
                      if (props.action.tooltip === t("10161")) {
                        const newProps = {
                          ...props,
                          ...action,
                        }
                        const disabled = requiredErrorId || requiredErrorName
                        action["disabled"] = disabled
                        return <MTableAction {...newProps} />
                      } else if (props.action.tooltip === t("10010")) {
                        const f = action.onClick
                        action["onClick"] = () => {
                          handleClose()
                          f()
                        }
                        const newProps = {
                          ...props,
                          ...action,
                        }
                        return <MTableAction {...newProps} />
                      }
                    }
                    return <MTableAction {...props} />
                  },
                  Pagination: (props) => {
                    const { rowsPerPage, rowsPerPageOptions } = props
                    return <CustomPagination
                      {...props}
                      count={countOpticians}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                    />
                  }
                }}
                localization={{
                  toolbar: {
                    searchTooltip: titleCase(t("10119")),
                    searchPlaceholder: titleCase(t("10119")),
                  },
                  header: { actions: "" },
                  body: {
                    emptyDataSourceMessage: t("10156"),
                    addTooltip: t("10157"),
                    deleteTooltip: t("10158"),
                    editTooltip: t("10159"),
                    editRow: {
                      deleteText: t("10162"),
                      cancelTooltip: t("10010"),
                      saveTooltip: t("10161"),
                    },
                  },
                }}
                editable={{
                  isEditable: (rowData) =>
                    searchEditableDeletable(rowData), // solo righe no LAB possono essere modificate
                  isDeletable: (rowData) =>
                    searchEditableDeletable(rowData), // solo righe no LAB possono essere modificate
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      handlePutOrPost(newData, "POST", resolve, reject)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      handlePutOrPost(newData, "PUT", resolve, reject)
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      handleDelete(oldData.opticianId, resolve, reject)
                    }),
                }}
              />
            </Paper>
          </div>
        </CardBody>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("28")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {duplicateError ? t("26") : t("27")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {" "}
            Ok{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
