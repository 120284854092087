import { createTheme } from '@material-ui/core/styles'
import { indigo, pink } from "@material-ui/core/colors";

export default createTheme({
  palette: {
    secondary: {
      main: pink[600]
    },
    primary: {
      main: indigo[700]
    }
  },
  typography: {
    fontFamily: ['"Roboto"', "Helvetica", "Arial", "sans-serif"].join(",")
  }
});