import React, { useContext } from 'react'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { RootContext } from 'RootContext';
import VisibilityIcon from '@material-ui/icons/Visibility';



// Button che apre la finestra con i dati del Job
function ButtonDialogJob(props) {
  const { handleClickOpen, year, job } = props
  const { t, isOpticianUser } = useContext(RootContext)

  return (
    <div>
      <Tooltip title={isOpticianUser() ? t("10153") : t("10154")}>
        <IconButton aria-label={t("10154")} variant="outlined" onClick={() => { handleClickOpen(year, job) }}>

          {isOpticianUser() ? <VisibilityIcon /> : <EditIcon />}
        </IconButton>
      </Tooltip >
    </div >
  );
}

function JobButton(props) {
  return (
    <div id="contenitore">
      <ButtonDialogJob  {...props} />
    </div>
  );
}

export default JobButton;

//import FileCopyIcon from '@material-ui/icons/FileCopy';

