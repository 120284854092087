/*eslint-disable*/
import React, { useContext } from 'react'
import { RootContext } from 'RootContext'
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { titleCase } from "title-case"

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

function ListNav({ prop, activePro, classes, listItemClasses, whiteFontClasses, rtlActive, t }) {
  return <NavLink
    to={prop.layout + prop.path}
    className={activePro + classes.item}
    activeClassName="active"
  >
    <ListItem button className={classes.itemLink + listItemClasses}>
      {typeof prop.icon === "string" ? (
        <Icon
          className={classNames(classes.itemIcon, whiteFontClasses, {
            [classes.itemIconRTL]: rtlActive
          })}
        >
          {prop.icon}
        </Icon>
      ) : (
        <prop.icon
          className={classNames(classes.itemIcon, whiteFontClasses, {
            [classes.itemIconRTL]: rtlActive
          })}
        />
      )}
      <ListItemText
        primary={rtlActive ? prop.rtlName : titleCase(t(prop.description))}
        className={classNames(classes.itemText, whiteFontClasses, {
          [classes.itemTextRTL]: rtlActive
        })}
        disableTypography={true}
      />
    </ListItem>
  </NavLink>
}

export default function Sidebar(props) {
  const classes = useStyles();
  const { t } = useContext(RootContext)
  const { getIsDemo, getOptician, catalogueScope, getFrameManagementActive, getAutocadConverterFromOMAtoDXFActive, getPrecalibrationActive } = useContext(RootContext)

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, companyFooter, logo, image, logoText, routes } = props;
  const site = companyFooter?.site || 'http://www.pegasovenezia.com';

  const frameManagementActiveScope = getFrameManagementActive()
  const autocadConverterFromOMAtoDXFScope = getAutocadConverterFromOMAtoDXFActive()
  var links = (
    <List className={classes.list} key="SideBarList" >
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;

        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });

        const path = prop.path
        const isOnlyForDemo = path === '/dashboard' || path === '/user'
        let isOnlyForLab = path === '/user' || path === '/statistic' || path === '/opticians' || path === '/requiredfields'

        if (catalogueScope) {
          isOnlyForLab = isOnlyForLab || path === '/catalogue'
        }

        if (frameManagementActiveScope) {
          isOnlyForLab = isOnlyForLab || path === '/frames'
        }

        if (autocadConverterFromOMAtoDXFScope) {
          isOnlyForLab = isOnlyForLab || path === '/autocadConverter'
        }

        if (getPrecalibrationActive()) {
          isOnlyForLab = isOnlyForLab || path === '/loadedShapes'
        }

        const Nav = <ListNav key={key} prop={prop} activePro={activePro} rtlActive={props.rtlActive}
          classes={classes} listItemClasses={listItemClasses} whiteFontClasses={whiteFontClasses} t={t} />;

        if (isOnlyForDemo && isOnlyForLab) { //solo per demo e lab
          if (getIsDemo() && !getOptician()) return (Nav)
        } else if (isOnlyForDemo) { //solo per demo e tutti
          if (getIsDemo()) return (Nav)
        }
        else if (isOnlyForLab) { //solo lab  
          if (!getOptician()) return (Nav)
        } else if (path === "/catalogue" && !catalogueScope) { //solo lab          
          return (null)
        } else if (path === "/frames" && !frameManagementActiveScope) { //solo lab          
          return (null)
        } else if (path === "/autocadConverter" && !autocadConverterFromOMAtoDXFScope) { //solo lab          
          return (null)
        }else if (path === "/loadedShapes" && !getPrecalibrationActive()) { //solo lab          
          return (null)
        }
        else { // tutti         
          return (Nav)
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href={site !== "" ? site : 'http://www.pegasovenezia.com'}
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.container}>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>




          <Typography  >{logoText}</Typography>
        </div>

      </a>

    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {<AdminNavbarLinks history={props.history} />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
