import React, { useEffect, useState, useContext, Fragment } from "react"
import { RootContext } from "../../RootContext"

// Core components
import _ from "lodash"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import DeleteIcon from "@material-ui/icons/Delete"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Divider from "@material-ui/core/Divider"
import ErrorUtils from "components/Tools/ErrorUtils"
import FileSaver from "file-saver"
import GetAppIcon from '@material-ui/icons/GetApp'
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import LinearProgress from "@material-ui/core/LinearProgress"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import Switch from "@material-ui/core/Switch"
import Tooltip from "@material-ui/core/Tooltip"
import { Buffer } from 'buffer';
import { CircularIndeterminate } from "../Loading"
import { CircularProgress } from "@material-ui/core"
import { DropzoneDialog } from "material-ui-dropzone"
import { fetchWithToken } from "components/Fetch/api-fetch"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"

export default function Catalogue() {
  const {
    companyId,
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token
  } = useContext(RootContext)
  const [openUpload, setOpenUpload] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [saving, setSaving] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [uploading, setUploding] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const [levels, setLevels] = useState({})
  const [details, setDetails] = useState({})
  const [values, setValues] = useState({})
  const [lensFamilyExternalCode, setLensFamilyExternalCode] = useState({})
  const [personalizedLens, setPersonalizedLens] = useState({})
  const [coatingExternalCode, setCoatingExternalCode] = useState({})
  const [companySettings, setCompanySettings] = useState({})
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)

  const classes = useJobsStyles()

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  function handleCloseDialog() {
    setOpenDialog(false)
    setEditMode(false)
    setRefresh(true)
  }

  function handleCloseErrorDialog() {
    setOpenErrorDialog(false)
  }


  useEffect(() => {
    fetchWithToken(`${apiUrl}/companies/${companyId}/catalogue`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        const { catalogueScope } = body
        setCompanySettings(catalogueScope.companySettings)
      })
      .catch(err => {
        ErrorUtils.errorLog(t("33"), err.message)
      })
  }, [
    access_token,
    apiUrl,
    companyId,
    refresh_token,
    setAccess_token,
    setCompanySettings,
    uploading,
    refresh,
    t
  ])

  useEffect(() => {
    if (!isEmpty(companySettings)) {
      const transformProduct = (obj, key) =>
        _.transform(obj, function (r, v, k) {
          r[k.substring(1, 2)] = {
            description: v,
            visible: companySettings[key][k.substring(1, 2)]["visible"]
          }
        })

      const transformProduct2 = (obj, key) =>
        _.transform(obj, function (r, v, k) {
          r["description"] = v
          r["visible"] = companySettings[key]["visible"]
        })

      fetchWithToken(`${apiUrl}/companies/${companyId}/products?header=true`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { header } = body
          Object.filter = (obj, predicate) =>
            Object.fromEntries(Object.entries(obj).filter(predicate))
          const filtered = Object.filter(header, ([k, v]) => v !== "")
          const levelsToSet = transformProduct(
            Object.filter(
              filtered,
              ([k, v]) =>
                k.includes("l") &&
                k !== "lensFamilyExternalCode" &&
                k !== "coatingExternalCode" &&
                k !== "isPersonalized"
            ),
            "levels"
          )

          const detailsToSet = transformProduct(
            Object.filter(
              filtered,
              ([k, v]) =>
                k.includes("d") &&
                k !== "id" &&
                k !== "lensFamilyExternalCode" &&
                k !== "coatingExternalCode" &&
                k !== "isPersonalized"
            ),
            "details"
          )

          const valueToSet = transformProduct(
            Object.filter(
              filtered,
              ([k, v]) =>
                k.includes("v") &&
                k !== "lensFamilyExternalCode" &&
                k !== "coatingExternalCode" &&
                k !== "isPersonalized"
            ),
            "values"
          )

          const lensFamilyExternalCodeToSet = transformProduct2(
            Object.filter(filtered, ([k, v]) => k === "lensFamilyExternalCode"),
            "lensFamilyExternalCode"
          )

          const coatingExternalCodeToSet = transformProduct2(
            Object.filter(filtered, ([k, v]) => k === "coatingExternalCode"),
            "coatingExternalCode"
          )

          const personalizedCodeToSet = transformProduct2(
            Object.filter(filtered, ([k, v]) => k === "isPersonalized"),
            "isPersonalized"
          )

          setLevels(levelsToSet)
          setDetails(detailsToSet)
          setValues(valueToSet)
          setLensFamilyExternalCode(lensFamilyExternalCodeToSet)
          setCoatingExternalCode(coatingExternalCodeToSet)
          setPersonalizedLens(personalizedCodeToSet)
          setRefresh(false)
          setFetching(false)
        })
        .catch(err => {
          setFetching(false)
          ErrorUtils.errorLog(t("34"), err.message)
        })
    }
  }, [
    access_token,
    apiUrl,
    companyId,
    companySettings,
    refresh_token,
    setAccess_token,
    t
  ])

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true)
  }

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false)
  }

  function handleDelete() {
    setLevels({})
    setDetails({})
    setValues({})
    setLensFamilyExternalCode({})
    setCoatingExternalCode({})
    setCompanySettings({})
    fetchWithToken(`${apiUrl}/companies/${companyId}/catalogue`, {
      method: "DELETE",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        handleCloseConfirmDelete()
      })
      .catch(err => {
        ErrorUtils.errorLog(t("33"), err.message)
      })
  }

  function handleDownload() {
    const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
    const fileName = 'CATALOGUE'
    const fileExtension = '.xlsx'
    setDownloading(true)
    fetchWithToken(`${apiUrl}/companies/${companyId}/catalogue/export`, {
      method: "GET",
      headers: {
        "Content-disposition": `attachment`,
        "Content-Type": fileType,
      },
      responseType: 'arraybuffer',
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((body) => {
        // DOWNLOAD EXCEL 
        setDownloading(false)
        const data = Buffer.from(body.excel, 'base64')
        const blob = new Blob([data], {
          type: fileType,
        });
        FileSaver.saveAs(blob, fileName + fileExtension);
      })
      .catch(err => {
        setDownloading(false)
        ErrorUtils.errorLog(t("48"), err.message)
      })
  }

  function handleSave(files) {
    setUploding(true)
    setOpenUpload(false)
    setOpenErrorDialog(false)
    const formData = new FormData()
    formData.append("file", files[0])
    var request = new XMLHttpRequest()
    request.open("POST", `${apiUrl}/companies/${companyId}/catalogue`)
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.onreadystatechange = function (aEvt) {
      if (request.readyState === 4) {
        if(request.status === 200){
          //OK
        }else{
          // ERRORE
          setOpenErrorDialog(true)
        }
      }
    }
    request.send(formData)
    request.addEventListener(
      "loadend",
      () => {
        setUploding(false)
        setFetching(true)
      },
      false
    )
  }

  const handleChangeSwitch = (key, subKeyToParse) => event => {
    const updateState = prevState => {
      const result = {
        ...prevState,
        [subKeyToParse]: {
          ...prevState[subKeyToParse],
          visible: event.target.checked
        }
      }
      return result
    }

    if (key === "levels") {
      setLevels(updateState)
    } else if (key === "values") {
      setValues(updateState)
    } else if (key === "details") {
      setDetails(updateState)
    }
  }

  const handleChangeSwitch2 = (key) => event => {
    const updateState = prevState => {
      const result = {
        ...prevState,
        visible: event.target.checked
      }
      return result
    }
    if (key === "lensFamilyExternalCode") {
      setLensFamilyExternalCode(updateState)
    } else if (key === "coatingExternalCode") {
      setCoatingExternalCode(updateState)
    } else if (key === "personalizedLens"){
      setPersonalizedLens(updateState)
    }
  }

  const fromStateToCompanySettings = (fieldState, key) => {
    const field = companySettings[key]
    return _.transform(field, function (r, v, k) {
      if (fieldState[k] !== undefined) {
        r[k] = {
          ...field[k],
          visible: fieldState[k]["visible"]
        }
      } else {
        r[k] = {
          ...field[k]
        }
      }
    })
  }

  const fromStateToCompanySetting = (fieldState, key) => {
    const field = companySettings[key]
    return _.transform(field, function (r, v, k) {
      if (fieldState["visible"] !== undefined) {
        r["active"] = field["active"]
        r["visible"] = fieldState["visible"]
      } else {
        r["active"] = field["active"]
        r["visible"] = field["visible"]
      }
    })
  }

  function handlePatch() {
    setSaving(true)
    setOpenDialog(true)

    const newSettings = {
      ...companySettings,
      levels: fromStateToCompanySettings(levels, "levels"),
      details: fromStateToCompanySettings(details, "details"),
      values: fromStateToCompanySettings(values, "values"),
      lensFamilyExternalCode: fromStateToCompanySetting(lensFamilyExternalCode, "lensFamilyExternalCode"),
      coatingExternalCode: fromStateToCompanySetting(coatingExternalCode, "coatingExternalCode"),
      isPersonalized: fromStateToCompanySetting(personalizedLens, "isPersonalized"),
      // "requiredFields": checked
    }

    fetchWithToken(`${apiUrl}/companies/${companyId}/catalogue`, {
      method: "PATCH",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ companySettings: newSettings }),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        setSaving(false)
      })
      .catch(err => {
        ErrorUtils.errorLog(t("33"), err.message)
      })
  }

  function ListSwitch({ keyDescription, itemDict, onChange }) {
    const price = k => (keyDescription === "values" ? ` (L.${k})` : "")
    return (
      <List
        subheader={<ListSubheader>{ }</ListSubheader>}>
        {Object.keys(itemDict).map((k, i) => (
          <ListItem key={"ListItem" + keyDescription + "-" + k} >
            <Switch
              disabled={!editMode}
              edge="start"
              onChange={onChange(keyDescription, k)}
              checked={itemDict[k]["visible"]}
            />
            <ListItemText primary={itemDict[k]["description"] + price(k)} />
          </ListItem>
        ))}
      </List>
    )
  }

  function SingleSwitch({ keyDescription, itemDict, onChange }) {
    return (
      <ListItem key={"ListItem" + keyDescription} >
        <Switch
          disabled={!editMode}
          edge="start"
          onChange={onChange(keyDescription)}
          checked={itemDict["visible"]}
        />
        <ListItemText primary={itemDict["description"]} />
      </ListItem>
    )
  }

  const { currentCatalogue } = companySettings

  return (
    <div>
      <div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{t("10197").toUpperCase()}</h4>
          </CardHeader>

          <CardBody>
            {fetching ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <CircularIndeterminate />
              </Grid>
            ) : (
              <div>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  alignItems="center">
                  <Grid item xs={12}>
                    {t("10207").toUpperCase()}
                  </Grid>
                  <Grid item xs={5}>
                    <Tooltip title={t("10158")}>
                      <IconButton
                        aria-label={t("10158")}
                        disabled={!currentCatalogue}
                        color="default"
                        onClick={handleOpenConfirmDelete}>
                        {" "}
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    {(currentCatalogue
                      ? `CAT. ${currentCatalogue}`
                      : t("45")) + " "}
                    <Tooltip title={t("10410")}>
                      <IconButton
                        aria-label={t("10410")}
                        disabled={!currentCatalogue}
                        variant="outlined"
                        color="default"
                        onClick={handleDownload}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {uploading || downloading ? (
                    <Grid item xs={5}>
                      <LinearProgress />{" "}
                    </Grid>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenUpload(true)}>
                    {" "}
                    {t("10198")}
                  </Button>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                {editMode && currentCatalogue ? (
                  <Box display="flex" justifyContent="flex-end">
                    <Box p={1}>
                      {" "}
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setRefresh(true)
                          setEditMode(false)
                        }}>
                        {" "}
                        {t("10010")}
                      </Button>{" "}
                    </Box>
                    <Box p={1}>
                      {" "}
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          handlePatch()
                        }}>
                        {" "}
                        {t("10114")}
                      </Button>{" "}
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="flex-end">
                    {currentCatalogue ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setEditMode(true)}>
                        {" "}
                        {t("10214")}
                      </Button>
                    ) : null}
                  </Box>
                )}

                {//{
                  // saving ||
                  fetching ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start">
                      <CircularIndeterminate />
                    </Grid>
                  ) : (
                    <div>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <Grid item xs={12}>
                          {currentCatalogue ? t("10379").toUpperCase() : null}
                        </Grid>
                        {isEmpty(levels) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <ListSwitch
                              keyDescription="levels"
                              onChange={handleChangeSwitch}
                              itemDict={levels}
                            />{" "}
                          </Grid>
                        )}
                        {isEmpty(details) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <ListSwitch
                              keyDescription="details"
                              onChange={handleChangeSwitch}
                              itemDict={details}
                            />{" "}
                          </Grid>
                        )}
                        {isEmpty(values) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <ListSwitch
                              keyDescription="values"
                              onChange={handleChangeSwitch}
                              itemDict={values}
                            />{" "}
                          </Grid>
                        )}
                        {isEmpty(lensFamilyExternalCode) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <SingleSwitch
                              keyDescription="lensFamilyExternalCode"
                              onChange={handleChangeSwitch2}
                              itemDict={lensFamilyExternalCode}
                            />{" "}
                          </Grid>
                        )}
                        {isEmpty(coatingExternalCode) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <SingleSwitch
                              keyDescription="coatingExternalCode"
                              onChange={handleChangeSwitch2}
                              itemDict={coatingExternalCode}
                            />{" "}
                          </Grid>
                        )}
                         {isEmpty(personalizedLens) ? null : (
                          <Grid item xs={4}>
                            {" "}
                            <SingleSwitch
                              keyDescription="personalizedLens"
                              onChange={handleChangeSwitch2}
                              itemDict={personalizedLens}
                            />{" "}
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                {/* } */}
              </div>
            )}
          </CardBody>
        </Card>
        <DropzoneDialog
          open={openUpload}
          onSave={handleSave}
          onChange={() => { }}
          filesLimit={1}
          acceptedFiles={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ]}
          showPreviews={true}
          useChipsForPreview={true}
          showFileNamesInPreview={true}
          maxFileSize={1e8}
          onClose={() => setOpenUpload(false)}
          dropzoneText={t("10209")}
          cancelButtonText={t("10010")}
          submitButtonText={t("10205")}
        />
      </div>

      {/* ELIMINA CATALOGO */}
      <Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
        <DialogTitle id="alert-dialog-slide-title">
          {t("10208")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("10206")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            {t("10010")}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {t("10205")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* AGGIORNA CATALOGO */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        {saving ? <Fragment>
          <DialogContent>
            <CircularProgress size={42} style={{ color: "primary" }} />
          </DialogContent>
        </Fragment> :
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("10200")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>Ok</Button>
            </DialogActions>
          </Fragment>
        }
      </Dialog>

      {/* ERRORE CATALOGO */}
      <Dialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
        {saving ? <Fragment>
          <DialogContent>
            <CircularProgress size={42} style={{ color: "primary" }} />
          </DialogContent>
        </Fragment> :
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("49")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErrorDialog} color="primary" autoFocus>Ok</Button>
            </DialogActions>
          </Fragment>
        }
      </Dialog>
    </div>
  )
}
