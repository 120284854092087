import React, { useEffect, useState, useCallback, useContext } from "react"

// Core components
import CircularProgress from "@material-ui/core/CircularProgress"
import { RootContext } from 'RootContext';
import $ from 'jquery';
import { titleCase } from "title-case"

// Pegaso components
import ErrorUtils from "components/Tools/ErrorUtils"
import TracerUtils from "components/Utils/TracerUtils"
import useIsMounted from "components/Utils/UseIsMounted"

export default function TracerLoading(props) {
    const { getServicePort } = useContext(RootContext)
    const {
        JSON_tracerJson,
        JSON_serviceMessages,
        setServiceError,
        setServiceResponse,
        tracerEventRequest
    } = props



    const tracerJson = JSON.parse(JSON_tracerJson)
    const messages = JSON.parse(JSON_serviceMessages)
    const UrlTcpTracerNew = "http://127.0.0.1:"
    const PortaTcpTracerNew = getServicePort()
    const IncrementoSecondiCiclo = 1000;
    const MaxCicli = 60 * 3 * IncrementoSecondiCiclo;

    const [output, SetOutput] = useState(null)
    const [secondiReali, setSecondiReali] = useState(0);
    const [driverParms, setDriverParms] = useState(null)
    const [currentEvent, setCurrentEvent] = useState(null)
    const [doEvent1, setDoEvent1] = useState(false)
    const [doEvent2, setDoEvent2] = useState(false)
    const isMounted = useIsMounted()
    /*
        USE CALLBACK
    */
    const ErroreServizio = useCallback((bottone, xhr, textStatus, error) => {
        // error[0] = Nessun Tracer Locale specificato
        // error[1] = PegasoDriver - Dispositivo non raggiungibile (servizio...)
        // error[2] = TimeOut - Nessuna forma ricevuta

        // Log Generico
        ErrorUtils.errorLog("TracerLoading", 'Service Error ' + bottone + ': xhr=' + xhr.statusText + ' textStatus=' + textStatus + ' error=' + error)
        var ErrorMess = '';
        var Sts = xhr.statusText;

        // Errore Servizio: Servizio Non Attivo
        if (Sts === 'error' && textStatus === 'error' && (error === '' || error === null)) {
            ErrorMess = messages.error[1];
        } else if (Sts !== '' || Sts !== null) {
            // Errore Servizio: Generico (se 'In Ricezione' non fare alert)
            //if (Sts.toString().substr(6,2) !== '31') {
            ErrorMess = Sts.toString();
            //}
        }

        // Se c� messaggio di errore f� alert e chiude finestra
        if (ErrorMess !== '') {
            setServiceError(ErrorMess)
        }
    }, [messages, setServiceError])

    const StatusServizio = useCallback((sts) => {
        switch (sts) {
            case 2:
                SetOutput(messages.status[2]) // Disconnecting
                break;
            case 3:
                SetOutput(messages.status[0]) // Connecting
                break;
            case 4:
                SetOutput(messages.status[1]) // Waiting for data
                break;
            case 5:
                break;
            case 6:
                SetOutput(messages.status[1]) // Waiting for data
                break;
            case 7:
                SetOutput(messages.status[0]) // Connecting
                break;
            case 8:
                break;
            default:
                break;
        }
    }, [messages, SetOutput])

    /*
        MANUAL REQUEST
    */
    // POST disconnectAndRespond - Status 2
    const disconnectAndRespond = useCallback(() => {
        StatusServizio(2);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                console.log("disconnectAndRespond -> SUCCESS");

                setServiceResponse(response)
            },
            error: function (xhr, textStatus, error) {
                console.log("disconnectAndRespond -> ERROR");
                ErroreServizio('DISCONNECT', xhr, textStatus, error)
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, setServiceResponse, ErroreServizio])


    // POST disconnectAndEnd  - Fine lettura dati
    const disconnectAndEnd = useCallback(() => {
        StatusServizio(2);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                console.log("disconnectAndEnd -> SUCCESS");
            },
            error: function (xhr, textStatus, error) {
                console.log("disconnectAndEnd -> ERROR");
                ErroreServizio('DISCONNECT', xhr, textStatus, error)
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, ErroreServizio])

    // POST connectAndRespond - Status 3
    const connectAndRespond = useCallback(() => {
        StatusServizio(3);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/connect`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                console.log("connectAndRespond -> SUCCESS");
                setServiceResponse(response)
            },
            error: function (xhr, textStatus, error) {
                console.log("connectAndRespond -> ERROR");
                ErroreServizio('CONNECT', xhr, textStatus, error)
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, setServiceResponse, ErroreServizio])

    // POST receiveAndRespond - Status 4
    const receiveAndRespond = useCallback(() => {
        StatusServizio(4);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/receive`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                console.log("receiveAndRespond -> SUCCESS");
                setDoEvent1(true)
                setCurrentEvent("DISCONNECT")
                setServiceResponse(response)
            },
            error: function (xhr, textStatus, error) {
                console.log("receiveAndRespond -> ERROR");
                ErroreServizio('RECEIVE (Wait)', xhr, textStatus, error)
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, setServiceResponse, ErroreServizio])

    /*
        AUTOMATIC REQUEST
    */
    // POST receiveWaitModeAndRespond - Status 5
    const receiveWaitModeAndRespond = useCallback((seconds, handleSetSeconds) => {
        StatusServizio(5);
        if (isMounted()) {
            $.post({
                traditional: true,
                url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/receive`,
                contentType: 'application/json',
                crossDomain: true,
                data: JSON.stringify({
                    tracer: driverParms.DescrizioneTracer,
                    codTracer: driverParms.Tracer,
                    comName: driverParms.PortaTracer,
                    tracerSetting: driverParms.TracerSettingJAVA,
                    token1: driverParms.Token1
                }),
                dataType: 'json',
                success: function (response) {
                    if (isMounted()) {
                        console.log("receiveAndRespond -> SUCCESS");
                        disconnectAndEnd()
                        setServiceResponse(response)
                    }
                },
                error: function (xhr, textStatus, error) {
                    if (isMounted()) {
                        handleSetSeconds(seconds + IncrementoSecondiCiclo)
                    }
                }
            });
        }
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, setServiceResponse, IncrementoSecondiCiclo, isMounted, disconnectAndEnd])

    // CicloReceiveWaitMode - Status 6
    const CicloReceiveWaitMode = useCallback(() => {
        StatusServizio(6);
        setSecondiReali(IncrementoSecondiCiclo)
    }, [StatusServizio, setSecondiReali, IncrementoSecondiCiclo])

    // POST connectAndReceive - Status 7
    const connectAndReceive = useCallback(() => {
        StatusServizio(7);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/connect`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                console.log("connectAndRespond -> SUCCESS");
                CicloReceiveWaitMode();
            },
            error: function (xhr, textStatus, error) {
                console.log("connectAndRespond -> ERROR");
                ErroreServizio('CONNECT', xhr, textStatus, error)
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, CicloReceiveWaitMode, ErroreServizio])

    // CicloConnect_Receive 
    const CicloConnect_Receive = useCallback(() => {
        setTimeout(connectAndReceive(), 300);
    }, [connectAndReceive])

    // POST allAndRespond - Status 8
    const allAndRespond = useCallback(() => {
        StatusServizio(8);
        $.post({
            traditional: true,
            url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect`,
            contentType: 'application/json',
            crossDomain: true,
            data: JSON.stringify({
                tracer: driverParms.DescrizioneTracer,
                codTracer: driverParms.Tracer,
                comName: driverParms.PortaTracer,
                tracerSetting: driverParms.TracerSettingJAVA,
                token1: driverParms.Token1
            }),
            dataType: 'json',
            success: function (response) {
                CicloConnect_Receive();
            },
            error: function (xhr, textStatus, error) {
                CicloConnect_Receive();
            }
        });
    }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, CicloConnect_Receive])

    /*
        USE EFFECT
    */
    useEffect(() => {
        if (tracerJson) {
            if (!driverParms) {
                setDriverParms(
                    {
                        Token1: tracerJson.token1,
                        Tracer: tracerJson.codTracer,
                        PortaTracer: tracerJson.comName,
                        TracerSettingJAVA: tracerJson.tracerSetting,
                        DescrizioneTracer: tracerJson.tracer
                    })
            }
        }
    }, [tracerJson, driverParms])

    useEffect(() => {
        if (tracerEventRequest) {
            setCurrentEvent(tracerEventRequest)
            setDoEvent1(true)
        }
    }, [tracerEventRequest])

    useEffect(() => {
        if (doEvent1) {
            switch (currentEvent) {
                case "ALL":
                    allAndRespond()
                    setDoEvent2(true)
                    break;
                case "DISCONNECT":
                    disconnectAndRespond();
                    setDoEvent2(true)
                    break;
                case "CONNECT":
                    connectAndRespond()
                    setDoEvent2(true)
                    break;
                case "RECEIVE":
                    receiveAndRespond()
                    setDoEvent2(true)
                    break;
                default:
                    break;
            }
            setDoEvent1(false)
        }
    }, [allAndRespond, connectAndRespond, disconnectAndRespond, receiveAndRespond, currentEvent, doEvent1, IncrementoSecondiCiclo])

    useEffect(() => {
        if (driverParms) {
            if (driverParms.Tracer) {
                if (doEvent2) {
                    if (currentEvent === "ALL") {
                        if (secondiReali > 0) {
                            if (secondiReali < MaxCicli) {
                                TracerUtils.sleep(IncrementoSecondiCiclo).then(() => {
                                    receiveWaitModeAndRespond(secondiReali, setSecondiReali);
                                });
                            } else {
                                setServiceError(messages.error[2])
                            }
                        }
                    }
                }
            }
        }
    }, [driverParms, doEvent2, secondiReali, MaxCicli, receiveWaitModeAndRespond, IncrementoSecondiCiclo, currentEvent, setServiceError, messages])

    return (<div>
        {output ? titleCase(output) : null}
        <br />
        <CircularProgress />
    </div>)
}