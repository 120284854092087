import React, { useState, useContext, useEffect, Fragment } from "react"
import { RootContext } from '../../RootContext'

//core components
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

// pegaso views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import JobUtils from "components/Utils/JobUtils"
import JobsUtils from "components/Utils/JobUtils"
import OmaUtils from "components/Utils/OmaUtils"
import Shape from "views/Shape/Shape"


//import CSS
import { makeStyles } from "@material-ui/core/styles"
import { useJobStyles, useJobStylesText } from "styles/job/jobCss"

import _, { toInteger } from "lodash"


// Crea lo stato iniziale della schermata
function createDefaultState(frameData) {
  const edgingData = JobUtils.GetEdgingData(frameData)
  const arrayValue = edgingData.split("\r\n")
  var omaLabel = ""
  var omaValue = ""
  var dxSx = []
  var i

  const left = {
    side: "L",
    ltyp: "",
    dia: "",
    sph: "",
    ipd: "",
    cyl: "",
    ocht: "",
    ax: "",
    hbox: "",
    add: "",
    vbox: "",
    lmattype: "",
    _lcoat: "",
    thkp: "",
    cthick: "",
    fcrv: "",
    frnt: "",
    ztilt: "",
    lind: "",
    _seght: "",
  }

  const right = {
    side: "R",
    ltyp: "",
    dia: "",
    sph: "",
    ipd: "",
    cyl: "",
    ocht: "",
    ax: "",
    hbox: "",
    add: "",
    vbox: "",
    lmattype: "",
    _lcoat: "",
    thkp: "",
    cthick: "",
    fcrv: "",
    frnt: "",
    ztilt: "",
    lind: "",
    _seght: "",
  }

  const edging = {
    etyp: "",
    bevp: "",
    bevm: "",
    polish: "",
    ftyp: "",
    dbl: "",
    offset: "",
    drillingPoints: [],
    drillePoints: [],
    gwidth: "",
    gdepth: "",
    fpinb: "",
    pinb: "",
  }

  for (i = 0; i < arrayValue.length; i++) {
    omaLabel = arrayValue[i].substring(0, arrayValue[i].indexOf("=", 0))
    omaValue = arrayValue[i].substring(arrayValue[i].indexOf("=", 0) + 1)
    switch (omaLabel) {
      // Valori singoli
      case "DBL":
        edging.dbl = omaValue
        break
      case "ETYP":
        edging.etyp = omaValue
        break
      case "BEVP":
        edging.bevp = omaValue
        break
      case "BEVM":
        edging.bevm = omaValue
        break
      case "POLISH":
        edging.polish = omaValue
        break
      case "FTYP":
        edging.ftyp = omaValue
        break
      case "DRILL":
        edging.drillingPoints.push(omaValue)
        break
      case "DRILLE":
        edging.drillePoints.push(omaValue)
        break
      // Valori DX;SX
      case "LTYP":
        dxSx = omaValue.split(";")
        right.ltyp = dxSx[0]
        left.ltyp = dxSx[1]
        break
      case "IPD":
        dxSx = omaValue.split(";")
        right.ipd = JobUtils.formatGeneric(dxSx[0])
        left.ipd = JobUtils.formatGeneric(dxSx[1])
        break
      case "OCHT":
        dxSx = omaValue.split(";")
        right.ocht = JobUtils.formatGeneric(dxSx[0])
        left.ocht = JobUtils.formatGeneric(dxSx[1])
        break
      case "HBOX":
        dxSx = omaValue.split(";")
        right.hbox = JobUtils.formatGeneric(dxSx[0])
        left.hbox = JobUtils.formatGeneric(dxSx[1])
        break
      case "VBOX":
        dxSx = omaValue.split(";")
        right.vbox = JobUtils.formatGeneric(dxSx[0])
        left.vbox = JobUtils.formatGeneric(dxSx[1])
        break
      case "LMATTYPE":
        dxSx = omaValue.split(";")
        right.lmattype = dxSx[0]
        left.lmattype = dxSx[1]
        break
      case "_LCOAT":
        dxSx = omaValue.split(";")
        right._lcoat = dxSx[0]
        left._lcoat = dxSx[1]
        break
      case "THKP":
        dxSx = omaValue.split(";")
        right.thkp = dxSx[0]
        left.thkp = dxSx[1]
        break
      case "CTHICK":
        dxSx = omaValue.split(";")
        right.cthick = dxSx[0]
        left.cthick = dxSx[1]
        break
      case "FPINB":
        dxSx = omaValue.split(";")
        edging.fpinb = JobUtils.formatGeneric(dxSx[0])
        break
      case "PINB":
        dxSx = omaValue.split(";")
        edging.pinb = JobUtils.formatGeneric(dxSx[0])
        break
      case "GWIDTH":
        dxSx = omaValue.split(";")
        edging.gwidth = JobUtils.formatGeneric(dxSx[0])
        break
      case "GDEPTH":
        dxSx = omaValue.split(";")
        edging.gdepth = JobUtils.formatGeneric(dxSx[0])
        break
      case "FCRV":
        dxSx = omaValue.split(";")
        right.fcrv = dxSx[0]
        left.fcrv = dxSx[1]
        break
      case "FRNT":
        dxSx = omaValue.split(";")
        right.frnt = dxSx[0]
        left.frnt = dxSx[1]
        break
      case "ZTILT":
        dxSx = omaValue.split(";")
        right.ztilt = dxSx[0]
        left.ztilt = dxSx[1]
        break
      case "LIND":
        dxSx = omaValue.split(";")
        right.lind = dxSx[0]
        left.lind = dxSx[1]
        break
      case "_SEGHT":
        dxSx = omaValue.split(";")
        right._seght = JobUtils.formatGeneric(dxSx[0])
        left._seght = JobUtils.formatGeneric(dxSx[1])
        break
      default:
        break
    }
  }

  const status = {
    rightValues: right,
    leftValues: left,
    edgingValues: edging,
  }


  return status
}


function EdgingGridContainer(props) {

  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, } = useContext(RootContext)
  const { creation, frame, frameBrands, frameSuppliers, framesRows, onHandleClose, setFramesRows } = props

  //Valori dei dati
  const [edgingData, setEdgingData] = useState(JobUtils.GetEdgingData(frame))
  const [frameData, setFrameData] = useState(creation ?
    {
      frameId: "",
      modelName: "",
      color: "",
      caliber: "",
      active: true
    } :
    {
      frameId: frame.frameId,
      modelName: frame.modelName,
      color: frame.color,
      caliber: frame.caliber,
      active: frame.active
    })
  const { rightValues, leftValues, edgingValues, } = createDefaultState(frame)
  const [currentEdgingValues, setCurrentEdgingValues] = useState(edgingValues)
  const [currentRightValues, setCurrentRightValues] = useState(rightValues)
  const [currentLeftValues, setCurrentLeftValues] = useState(leftValues)
  const [currentStandardShape, setCurrentStandardShape] = useState(frame.shape !== undefined ? frame.shape.standard : "")
  const [currentOriginalShape, setCurrentOriginalShape] = useState(frame.shape !== undefined ? frame.shape.original : "")
  var edgeTypeOption = JobsUtils.edgingTypeValues()
  const [filterFrameBrands, setFilterFrameBrands] = useState(frameBrands)
  const [frameBrandId, setFrameBrandId] = useState(creation ? 0 : frame.frameBrand.frameBrandId)
  const [frameSupplierId, setFrameSupplierId] = useState(creation ? 0 : frame.frameBrand.frameSupplier.frameSupplierId)
  var frameTypeOption = JobsUtils.frameTypeValues()
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)


  const classes = useJobStyles()
  const classesText = useJobStylesText() // TEXT

  /**********************************************
    *  USE EFFECT
    **********************************************/

  useEffect(() => {
    const createEdgingData = (r, l, e) => {
      const result = JobUtils.createSingleOmaEdgingData(e)
        .concat(JobUtils.createRightLeftOmaEdgingData(r, l))
        .join("\r\n")
      return result
    }
    setEdgingData(
      createEdgingData(
        currentRightValues,
        currentLeftValues,
        currentEdgingValues
      )
    )
  }, [currentRightValues, currentLeftValues, currentEdgingValues])


  /**************************************************************************
   *                    FUNZIONI
   *****************************************************************************/
  function disableSave() {
    let result =
      frameSupplierId === 0 ||
      frameBrandId === 0 ||
      frameData.frameId === "" ||
      frameData.modelName === ""
    return result
  }

  //Crea la riga da aggiungere nella tabella
  function CreateBrandSupplierDataNewElem() {
    var result = {}
    var brand = frameBrands.filter(option => option.frameBrandId === frameBrandId)[0]
    var supplier = frameSuppliers.filter(option => option.frameSupplierId === frameSupplierId)[0]
    result = {
      frameBrandId: brand.frameBrandId,
      frameBrandName: brand.frameBrandName,
      frameSupplier: {
        frameSupplierId: supplier.frameSupplierId,
        frameSupplierName: supplier.frameSupplierName
      }
    }
    return result
  }

  /*************************************************************************
                     FUNZIONE SCROLL (BARRA IN ALTO FISSATA)
    **************************************************************************/
  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  /*************************************************************************
                      HANDLER CHANGER
   **************************************************************************/

  //Cambia field frame supplier
  const handleChangeFrameSupplier = (event) => {
    var filterBrands = frameBrands
    setFrameSupplierId(event.target.value)
    filterBrands = filterBrands.filter(option => option.frameSupplier.frameSupplierId === event.target.value)
    setFilterFrameBrands(filterBrands)
  }

  //Cambia field frame brand
  const handleChangeFrameBrand = (event) => {
    setFrameBrandId(event.target.value)
  }

  //Cambia field frame data
  const handleChangeFrameData = (name) => (event) => {
    setFrameData({ ...frameData, [name]: event.target.value })
  }

  //Cambia field edging vallue
  const handleChangeEdgingValue = (name) => (event) => {
    setCurrentEdgingValues({ ...currentEdgingValues, [name]: event.target.value })
  }

  //Salvataggio Frame
  const handleSave = (event) => {
    setIsSaving(true)

    var newLine = "\r\n"
    var newOmaValues // Array con i nuovi valori di montaggio (originali + nuovi da GUI)
    var guiOmaValues // Array con i valori della GUI
    var originalOmaValues = edgingData.split(newLine) // Array con i valori presi da Job

    // Creazione array con i dati della GUI
    guiOmaValues = JobUtils.createRightLeftOmaEdgingData(
      currentRightValues,
      currentLeftValues
    )
    guiOmaValues = guiOmaValues.concat(
      JobUtils.createSingleOmaEdgingData(currentEdgingValues)
    )

    // Creo la nuva stringa con i dati montaggio
    newOmaValues = OmaUtils.SearchAndReplaceOmaData(
      originalOmaValues,
      guiOmaValues
    ).join(newLine)

    const url = `${apiUrl}/companies/${companyId}/frame`
    fetchWithToken(
      url,
      {
        method: creation ? "POST" : "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          frameId: frameData.frameId,
          frameBrandId: frameBrandId,
          modelName: frameData.modelName,
          color: frameData.color,
          caliber: toInteger(frameData.caliber),
          active: frameData.active,
          shape: {
            original: currentOriginalShape,
            standard: currentStandardShape,
            edgingData: newOmaValues,
            offset: 0
          }
        }),
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      }
    )
      .then((response) => response.body)
      .then((data) => {
        setIsSaving(false)
        setDialogMsg("Frame salvata")
        setOpenDialog(true)
        let frameBrand = CreateBrandSupplierDataNewElem()
        let newElem = {
          frameId: frameData.frameId,
          frameBrandId: frameBrandId,
          modelName: frameData.modelName,
          color: frameData.color,
          caliber: frameData.caliber,
          dbl: currentEdgingValues.dbl,
          frameBrand: frameBrand,
          active: frameData.active
        }
        let newFrameRows = creation
          ? framesRows.concat(newElem)
          : framesRows.map((elem) => {
            if (elem.frameId === frameData.frameId) {
              return newElem
            } else {
              return elem
            }
          })
        setFramesRows(newFrameRows)
      })
      .catch((err) => {
        const { body } = err
        if (body) {
          const { code } = body
          if (code === "E3") {
            setDialogMsg("10343")
          }
        }
        else {
          setDialogMsg("10344")
        }
        setIsSaving(false)
        setOpenDialog(true)
        ErrorUtils.errorLog("frameBrandPutError", err)
      })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    onHandleClose()
  }

  //Aggiorna gli edging data
  function handleUpdateEdgingData(
    standardShape,
    edgingData,
    originalShape,
    deviceUploader = false
  ) {
    const {
      rightValues,
      leftValues,
      edgingValues,
    } = JobUtils.createDefaultState({ shape: { edgingData } })
    const rightValuesFromTracer = _.pickBy(rightValues, function (value, key) {
      return value !== "" && !currentRightValues[key]
    })

    const leftValuesFromTracer = _.pickBy(leftValues, function (value, key) {
      return value !== "" && !currentLeftValues[key]
    })

    const edgingValuesFromTracer = _.pickBy(edgingValues, function (value, key) {
      return value !== "" && !currentEdgingValues[key]
    })
    // 2020/06/30 - Aggiungo manualmente drillingPoints (xk è array)
    edgingValuesFromTracer.drillingPoints = edgingValues.drillingPoints
    edgingValuesFromTracer.drillePoints = edgingValues.drillePoints

    //2020/10/05 - Aggiorno i dati di dima orizzontale - dima verticale - ponte
    //Lente destra
    rightValuesFromTracer.hbox = rightValues.hbox
    rightValuesFromTracer.vbox = rightValues.vbox
    //Lente sinistra
    leftValuesFromTracer.hbox = leftValues.hbox
    leftValuesFromTracer.vbox = leftValues.vbox
    //Ponte
    edgingValuesFromTracer.dbl = edgingValues.dbl

    let tmpRightValues = { ...currentRightValues }
    _.forEach(rightValuesFromTracer, (v, k) => {
      tmpRightValues[k] = v
    })

    let tmpLeftValues = { ...currentLeftValues }
    _.forEach(leftValuesFromTracer, (v, k) => {
      tmpLeftValues[k] = v
    })

    let tmpEdgingValues = { ...currentEdgingValues }
    _.forEach(edgingValuesFromTracer, (v, k) => {
      tmpEdgingValues[k] = v
    })
    setCurrentRightValues(tmpRightValues)
    setCurrentLeftValues(tmpLeftValues)
    setCurrentEdgingValues(tmpEdgingValues)
    setCurrentOriginalShape(originalShape)
    setCurrentStandardShape(standardShape)
  }

  //Cambia field (checkbox)
  const handleChangeCheckedActive = (name) => (event) => {
    setFrameData({ ...frameData, [name]: event.target.checked })
  }

  return (
    <div className={classes.jobRoot}>
      <Card>
        <CardBody>
          <Toolbar id="back-to-top-anchor" />

          {/* X CHIUSURA IN ALTO */}
          <ElevationScroll {...props}>
            <AppBar
              className={classes.appbar}
              position="sticky"
            >
              <Box display="flex" flexDirection="row-reverse" p={1}>
                <Box p={1}>
                  <IconButton
                    aria-label={t("10238")}
                    variant="outlined"
                    onClick={onHandleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </AppBar>
          </ElevationScroll>

          <Grid container spacing={2}>
            {/*GRID CONTENENTE I DATI DELLA MONTATURA*/}
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10331").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="frameSupplier"
                    label={t("10330")}
                    value={frameSupplierId}
                    onChange={handleChangeFrameSupplier}
                    helperText={t("10106")}
                  >
                    <MenuItem key={0} value={0} />
                    {frameSuppliers.map((option) => (

                      <MenuItem key={option.frameSupplierId} value={option.frameSupplierId} name={option.frameSupplierName}>
                        {option.frameSupplierId + " - " + option.frameSupplierName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="frameBrand"
                    label={t("10332")}
                    value={frameBrandId}
                    onChange={handleChangeFrameBrand}
                    helperText={t("10106")}
                    disabled={frameSupplierId === 0}
                  >
                    <MenuItem key={0} value={0} />
                    {filterFrameBrands.map((option) => (

                      <MenuItem key={option.frameBrandId} value={option.frameBrandId} name={option.frameBrandName}>
                        {option.frameBrandId + " - " + option.frameBrandName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="frameBrand"
                    className={classesText.textField}
                    label={t("10333")}
                    value={frameData.frameId}
                    onChange={handleChangeFrameData("frameId")}
                    helperText={t("10106")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="modelName"
                    className={classesText.textField}
                    label={t("10334")}
                    value={frameData.modelName}
                    onChange={handleChangeFrameData("modelName")}
                    helperText={t("10106")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="color"
                    className={classesText.textField}
                    label={t("10335")}
                    value={frameData.color}
                    onChange={handleChangeFrameData("color")}
                    helperText={t("10335")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="dbl"
                    className={classesText.textField}
                    label={t("10025")}
                    value={currentEdgingValues.dbl}
                    onChange={handleChangeEdgingValue("dbl")}
                    helperText={t("10025")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="caliber"
                    className={classesText.textField}
                    label={t("10336")}
                    value={frameData.caliber}
                    onChange={handleChangeFrameData("caliber")}
                    helperText={t("10336")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="etyp"
                    label={t("10035")}
                    value={currentEdgingValues.etyp}
                    onChange={handleChangeEdgingValue("etyp")}
                    helperText={t("10124")}
                  >
                    {edgeTypeOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="ftyp"
                    label={t("10040")}
                    value={currentEdgingValues.ftyp}
                    onChange={handleChangeEdgingValue("ftyp")}
                    helperText={t("10125")}
                  >
                    {frameTypeOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={frameData.active}
                        onChange={handleChangeCheckedActive("active")}
                        name="active"
                      />
                    }
                    label={t("10134")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*GRID CONTENENTE IL DISEGNO E LA LETTURA DA TRACER*/}
          <Grid className={classes.jobGridStandardShape} item xs={12}>
            <Shape
              creation={creation}
              edgingData={edgingData}
              editMode={false}
              frameMode={true}
              isGlasant={currentEdgingValues["ftyp"] === "3"}
              jobId={frameData.frameId}
              jobSoftware={""}
              handleUpdateEdgingData={handleUpdateEdgingData}
              // handleDrillChange={handleDrillChange}
              offset={currentEdgingValues.offset}
              scaleX={4}
              scaleY={4}
              shape={currentStandardShape ? currentStandardShape : ""}
            />
          </Grid>

          {/*BOTTONE SALVA*/}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.gridToolbar}
          >
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
                disabled={disableSave()}
                onClick={handleSave}
              >
                {t("10114")}
              </Button>
            </Box>
          </Box>
        </CardBody>
      </Card>


      {/*DIALOG SALVATAGGIO DATI*/}
      <Dialog
        open={isSaving || openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        {openDialog ? (
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogContent>
              <DialogContent>
                <CircularProgress size={42} style={{ color: "primary" }} />
              </DialogContent>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </div>
  )
}


// Griglia interna alla finestra del Frame
export default function Frame(props) {
  const { frame, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (frame) {
    return <EdgingGridContainer {...props} />
  } else if (!frame && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
