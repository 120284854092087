import OmaUtils from "./OmaUtils"

export default class ShapeUtils {
  static GetDrillingPoints(edgingData) {
    let drillingPoints = []
    let drillSplit
    let drill = {
      id: 0,
      x: 0,
      y: 0,
      diam: 0,
      side: "",
    }
    if (edgingData) {
      const edgingDataFiltered = edgingData
        .split("\r\n")
        .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))

      // Get DBL,HBOX,VBOX for left position
      edgingDataFiltered.forEach((row) => {
        const omaLabel = OmaUtils.ExtractOmaLabelFromRow(row)
        const omaValue = OmaUtils.ExtractOmaValueFromRow(row)
        switch (omaLabel) {
          case "DRILL":
            drillSplit = omaValue.split(";")
            if (drillSplit.length > 0) {
              if (!isNaN(parseFloat(drillSplit[4])) && !isNaN(parseFloat(drillSplit[5]))) {
                let x1, x2, y1, y2, side
                side = drillSplit[0]
                x1 = side === "L" ? -parseFloat(drillSplit[1]) : parseFloat(drillSplit[1])
                y1 = parseFloat(drillSplit[2])
                x2 = side === "L" ? -parseFloat(drillSplit[4]) : parseFloat(drillSplit[4])
                y2 = parseFloat(drillSplit[5])
                drill = {
                  id: drillingPoints.length + 1,
                  xStart: Math.abs(x1) < Math.abs(x2) ? x1 : x2,
                  yStart: Math.abs(x1) < Math.abs(x2) ? y1 : y2,
                  diam: parseFloat(drillSplit[3]),
                  xEnd: Math.abs(x1) < Math.abs(x2) ? x2 : x1,
                  yEnd: Math.abs(x1) < Math.abs(x2) ? y2 : y1,
                  isSlot: true,
                  side: side,
                }
              }
              else {
                let side = drillSplit[0]
                drill = {
                  id: drillingPoints.length + 1,
                  xStart: side === "L" ? -parseFloat(drillSplit[1]) : parseFloat(drillSplit[1]),
                  yStart: parseFloat(drillSplit[2]),
                  diam: parseFloat(drillSplit[3]),
                  isSlot: false,
                  side: side,
                }
              }
              drillingPoints.push(drill)
            }
            break
          default:
            break
        }
      })
    }
    return drillingPoints
  }

  static extractShapeFromOMA(shape, edgingData, scaleX, scaleY, offset, calibration) {
    const shapeJson = {
      right: {
        points: [""],
        numberPoints: "",
        hbox: "",
        vbox: "",
        ocht: "",
        ipd: "",
      },
      left: {
        points: [""],
        numberPoints: "",
        hbox: "",
        vbox: "",
        ocht: "",
        ipd: "",
      },
      dbl: "",
      drillingPoints: []
    }

    if (edgingData) {
      const edgingDataFiltered = edgingData
        .split("\r\n")
        .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))

      // Get DBL,HBOX,VBOX for left position
      edgingDataFiltered.forEach((row) => {
        const omaLabel = OmaUtils.ExtractOmaLabelFromRow(row)
        const omaValue = OmaUtils.ExtractOmaValueFromRow(row)
        switch (omaLabel) {
          case "DBL":
            shapeJson.dbl = parseFloat(omaValue)
            break
          case "HBOX":
            shapeJson.right.hbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.hbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          case "VBOX":
            shapeJson.right.vbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.vbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          case "OCHT":
            shapeJson.right.ocht = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.ocht = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          case "IPD":
            shapeJson.right.ipd = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.ipd = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          default:
            break
        }
      })

      // Get DRILL (Drilling Points)
      shapeJson.drillingPoints = ShapeUtils.GetDrillingPoints(edgingData)
    }

    // Shape
    if (shape) {
      const shapeFiltered = shape
        .split("\r\n")
        .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))

      // Get Radius
      const radius = this.ExtractRadiusFromRows(shapeFiltered)

      // Convert radius to points
      shapeJson.right.points = this.radiusToPoints(
        radius.right.radius,
        scaleX,
        scaleY,
        radius.right.numberRadius,
        offset,
        calibration
      )
      shapeJson.left.points = this.radiusToPoints(
        radius.left.radius,
        scaleX,
        scaleY,
        radius.left.numberRadius,
        offset,
        calibration
      )
    }

    return shapeJson
  }

  static ExtractRadiusFromRows(rowsFiltered) {
    let currentSide = ""
    const radiusResult = {
      right: {
        radius: [],
        numberRadius: 0,
      },
      left: {
        radius: [],
        numberRadius: 0,
      },
    }

    if (rowsFiltered) {
      rowsFiltered.forEach((row) => {
        if (currentSide === "") {
          const side = row.split(";")[3]
          if (row.includes("TRCFMT=") && (side === "R" || side === "L")) {
            if (side === "R") {
              radiusResult.right.numberRadius = row.split(";")[1]
            } else {
              radiusResult.left.numberRadius = row.split(";")[1]
            }
            currentSide = side
          }
        } else if (currentSide === "R") {
          const side = row.split(";")[3]
          if (row.includes("TRCFMT=") && side === "L") {
            currentSide = side
            radiusResult.left.numberRadius = row.split(";")[1]
          } else {
            if (row[0] === "R") {
              const radius = row.replace("R=", "").split(";")
              radius.forEach((r) => radiusResult.right.radius.push(r))
            }
          }
        } else if (currentSide === "L") {
          const side = row.split(";")[3]
          if (row.includes("TRCFMT=") && side === "R") {
            currentSide = side
            radiusResult.right.numberRadius = row.split(";")[1]
          } else {
            if (row[0] === "R") {
              const radius = row.replace("R=", "").split(";")
              radius.forEach((r) => radiusResult.left.radius.push(r))
            }
          }
        }
      })
    }

    return radiusResult
  }

  static radiusToPoints(radius, scaleX, scaleY, numberRadius, offset, calibration) {
    const stepRadiant = (2 * Math.PI) / numberRadius
    let currentRadiant = 0
    let points = []
    let newCalibration = { dpiX: 4 * calibration.dpiX, dpiY: 4 * calibration.dpiY, }
    radius.forEach((element) => {
      element = !offset
        ? parseFloat(element)
        : parseFloat(element) + parseFloat(offset) * 100
      points.push((((element * Math.cos(currentRadiant)) / newCalibration.dpiX) * scaleX))
      points.push((-(element * Math.sin(currentRadiant)) / newCalibration.dpiY) * scaleY)
      currentRadiant = currentRadiant + stepRadiant
    })
    return points
  }

  static radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  static pointsToRadius(points, scaleX, scaleY, numberRadius, calibration) {
    let radius = []
    let coorX, coorX_Calibrated
    let coorY, coorY_Calibrated
    let newCalibration = { dpiX: 4 * calibration.dpiX, dpiY: 4 * calibration.dpiY, }

    let Angolo_old
    let arrayAngoli_old = []
    let Raggio_new
    let Angolo_new
    let arrayRaggio_new = []
    let arrayAngoli_new = []
    let risoluzione = numberRadius / 2
    let puntiPerQuadrante = risoluzione / 4

    for (let k = 0; k < risoluzione; k++) {
      coorX = points[k * 2]
      coorY = points[(k * 2) + 1]
      coorX_Calibrated = (coorX / scaleX) * newCalibration.dpiX
      coorY_Calibrated = (coorY / scaleY) * newCalibration.dpiY

      Angolo_old = k * 360 / risoluzione;		// Angolo raggio originale
      arrayAngoli_old.push(Angolo_old)
      Raggio_new = parseInt(Math.sqrt(Math.pow(coorX_Calibrated, 2) + Math.pow(coorY_Calibrated, 2)));	// RAGGIO nuovo
      Angolo_new = Math.abs(this.radians_to_degrees(Math.atan(coorY_Calibrated / coorX_Calibrated)));	  // ANGOLO del nuovo raggio

      // La funzione atan() restituisce valori compresi tra - 90° e + 90° quindi bisogna sommare la differenza per ogni quadrante
      switch (true) {
        case (k > puntiPerQuadrante && k <= puntiPerQuadrante * 2):
          // QUADRANTE 2  
          Angolo_new = 90 + (90 - Angolo_new);
          break;
        case (k > puntiPerQuadrante * 2 && k <= puntiPerQuadrante * 3):
          // QUADRANTE 3
          Angolo_new = Angolo_new + 180;
          break;
        case (k > puntiPerQuadrante * 3 && k <= puntiPerQuadrante * 4):
          // QUADRANTE 4
          Angolo_new = 270 + (90 - Angolo_new);
          break;
        default:
          // QUADRANTE 1
          break;
      }

      // Alla fine del ciclo ho due array contenenti i nuovi raggi e e i corrispondenti raggi
      arrayRaggio_new.push(Raggio_new);
      arrayAngoli_new.push(parseFloat(parseFloat(Angolo_new).toFixed(2)));
    }

    // In questo ciclo confronto i vecchi angoli con i nuovi angoli e seleziono il Raggio con angolo più vicino al vecchio angolo
    // $valDiPartenza a ogni ciclo prende il valore della posizione dell' angolo scelto per ridurre i tempi di ricerca, evitando di analizzare ogni volta tutto l'array, facendo partire la ricerca
    // dall' elemento scelto nel ciclo precedente
    let valDiPartenza = 0
    let indice = 0
    let angolo_equidistante
    let differenza_1
    let differenza_2
    for (let z = 0; z < risoluzione; z++) {
      angolo_equidistante = arrayAngoli_old[z];
      for (let x = valDiPartenza; x < risoluzione; x++) {
        if ((angolo_equidistante - arrayAngoli_new[x]) < 0) {
          indice = x;
          break;
        }
      }
      differenza_1 = Math.abs(angolo_equidistante - arrayAngoli_new[indice - 1]);
      differenza_2 = Math.abs(angolo_equidistante - arrayAngoli_new[indice]);
      if (differenza_1 <= differenza_2) {
        radius.push(parseInt(arrayRaggio_new[indice - 1]))
        valDiPartenza = indice - 1;
      } else {
        radius.push(parseInt(arrayRaggio_new[indice]))
        valDiPartenza = indice;
      }
    }

    return radius
  }


  static async extractRadiusFromOma(shape, edgingData, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t,) {
    const shapeJson = {
      right: {
        points: [""],
        numberPoints: "",
        hbox: "",
        vbox: "",
      },
      left: {
        points: [""],
        numberPoints: "",
        hbox: "",
        vbox: "",
      },
      dbl: "",
      drillingPoints: []
    }

    if (edgingData) {
      const edgingDataFiltered = edgingData
        .split("\r\n")
        .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))

      // Get DBL,HBOX,VBOX for left position
      edgingDataFiltered.forEach((row) => {
        const omaLabel = OmaUtils.ExtractOmaLabelFromRow(row)
        const omaValue = OmaUtils.ExtractOmaValueFromRow(row)
        switch (omaLabel) {
          case "DBL":
            shapeJson.dbl = parseFloat(omaValue)
            break
          case "HBOX":
            shapeJson.right.hbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.hbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          case "VBOX":
            shapeJson.right.vbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[0]
            shapeJson.left.vbox = omaValue
              .split(";")
              .map((x) => parseFloat(x))[1]
            break
          default:
            break
        }
      })

      // Get DRILL (Drilling Points)
      shapeJson.drillingPoints = ShapeUtils.GetDrillingPoints(edgingData)
    }

    // Shape
    if (shape) {
      const shapeFiltered = shape
        .split("\r\n")
        .filter((s) => !s.includes("Z=") && !s.includes("ZFMT="))

      // Get Radius
      const radius = this.ExtractRadiusFromRows(shapeFiltered)
      var convertValueRight = await OmaUtils.ConvertGenericPoints(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, radius.right.radius, 400)
      var convertValueLeft = await OmaUtils.ConvertGenericPoints(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, radius.left.radius, 400)
      // Convert radius to points
      shapeJson.right.points = this.radiusToPointsForEdgingData(
        convertValueRight.split(";"),
      )
      shapeJson.left.points = this.radiusToPointsForEdgingData(
        convertValueLeft.split(";"),
      )
      return shapeJson
    }

  }

  static radiusToPointsForEdgingData(radius) {
    let points = []
    var x, y
    for (var i = 0; i < radius.length; i++) {
      x = radius[i] * Math.cos((i * 2 * 3.14159265) / 400)
      y = radius[i] * Math.sin((i * 2 * 3.14159265) / 400)
      points.push(Math.floor(x + 0.5))
      points.push(Math.floor(y + 0.5))
    }
    return points
  }

  static calculateDistance(x1, y1, x2, y2) {
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    return distance;
  }

}
