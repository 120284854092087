const en = {
  translation: {
    1: "error: impossible to print Job",
    2: "error: impossible to save Job",
    3: "the diameter must be greater than 1",
    4: "the diameter must be numerical",
    5: "no Shape available",
    6: "the value of the sphere must be numerical",
    7: "the value of the sphere is incorrect",
    8: "value of the sphere out of bounds (-30 / +30)",
    9: "the cylinder value must be numeric",
    10: "the cylinder value is incorrect",
    11: "cylinder value out of limit (-10 / +10)",
    12: "the axis value must be numeric",
    13: "the value of the axis must be positive",
    14: "the axis value must be less than 360",
    15: "the axis value must be an integer",
    16: "the value must be numeric",
    17: "the value must be positive",
    18: "the value must be greater than",
    19: "the value must be less than",
    20: "the value must be between -10 and 10",
    21: "the diameter must be less than 99",
    22: "no local tracer specified.",
    23: "PegasoDriver - Device not available (Service not active or port already busy). Contact customer service for support.",
    24: "TIMEOUT: No shape received",
    25: "the value must be an integer",
    26: "this optician code already exists.",
    27: "error during saving optician.",
    28: "operation Failed",
    29: "product or shape is missing.",
    30: "shape is missing.",
    31: "required fields missing.",
    32: "fields with errors.",
    33: "company info not found",
    34: "catalogue Header not found",
    35: "impossible to open job",
    36: "impossible to insert job",
    37: "impossible to clone job",
    38: "session expired",
    39: "FROM DATE must be less than A DATE",
    40: "the value is incorrect",
    41: "impossible to update status of job",
    42: "impossible to send job to suppliers",
    43: "error",
    44: "no supplier found",
    45: "no catalogue available",
    46: "product id is missing",
    47: "catalogue not found",
    48: "pegasoTools error fetch",
    49: "impossible to import catalogue",
    50: "no local blocker specified.",
    51: "Login failed",
    5000: "aspheric",
    5001: "toric",
    5002: "bifocal",
    5003: "curve top",
    5004: "double segment",
    5005: "e-line multifocal",
    5006: "flat top",
    5007: "lenticular",
    5008: "progressive",
    5009: "quadra focal",
    5010: "round",
    5011: "single vision",
    5012: "trifocal",
    10000: "addition",
    10001: "aspheric",
    10002: "toric",
    10003: "automatic",
    10004: "axis (T)",
    10005: "bevel",
    10006: "position value",
    10007: "bevel position",
    10008: "bifocal",
    10009: "brand",
    10010: "cancel",
    10011: "closed",
    10012: "city",
    10013: "company",
    10014: "complete your profile",
    10015: "country",
    10016: "curve top",
    10017: "cylinder",
    10018: "date",
    10019: "updated by",
    10020: "uploaded by",
    10021: "update",
    10022: "downloaded by",
    10023: "dashboard",
    10024: "days",
    10025: "dbl",
    10026: "devices",
    10027: "device",
    10028: "diameter",
    10029: "disabled",
    10030: "double segment",
    10031: "edging data",
    10032: "edit",
    10033: "edit profile",
    10034: "e-line multifocal",
    10035: "edge type",
    10036: "filter Job statistic",
    10037: "filter status",
    10038: "notes",
    10039: "flat top",
    10040: "frame type",
    10041: "from date",
    10042: "groove",
    10043: "horizontal box",
    10044: "home",
    10045: "id",
    10046: "code",
    10047: "increased jobs from last week",
    10048: "insert by",
    10049: "pupillary distance",
    10050: "job",
    10051: "job list",
    10052: "optician list",
    10053: "job printed",
    10054: "jobs of the day",
    10055: "jobs of the week",
    10056: "jobs of the year",
    10057: "job send correctly",
    10058: "job selected",
    10059: "job statistic",
    10060: "laboratory",
    10061: "laboratory note",
    10062: "lab reference",
    10063: "labs",
    10064: "last name",
    10065: "left lens",
    10066: "odred reference external lab",
    10067: "lenticular",
    10068: "list of the jobs inserted today",
    10069: "list of the jobs of the last year",
    10070: "lens type",
    10071: "login",
    10072: "logout",
    10073: "e-mail address",
    10074: "metal",
    10075: "model",
    10076: "months",
    10077: "name",
    10078: "new jobs arrived in laboratory",
    10079: "no",
    10080: "no Job selected",
    10081: "no Job to edit",
    10082: "no results found with these filters",
    10083: "no Shape available",
    10084: "export shape",
    10085: "shape from file",
    10086: "shape from tracer",
    10087: "filters",
    10088: "fitting height",
    10089: "offset",
    10090: "open",
    10091: "open Job",
    10092: "optician",
    10093: "optician customer",
    10094: "optician note",
    10095: "optyl",
    10096: "order number",
    10097: "password",
    10098: "phone",
    10099: "plastic",
    10100: "please Filter the list of jobs you want to see",
    10101: "please select the period of statistic you want to see",
    10102: "polish",
    10103: "print",
    10104: "reprint",
    10105: "profile",
    10106: "progressive",
    10107: "province",
    10108: "quadra focal",
    10109: "recent Jobs",
    10110: "result type",
    10111: "right lens",
    10112: "rimless",
    10113: "round",
    10114: "save",
    10115: "select bevel position",
    10116: "select polish",
    10117: "search Job year",
    10118: "search Job",
    10119: "search optician",
    10120: "search optician note",
    10121: "select a Job to edit",
    10122: "select a Job to see details",
    10123: "settings",
    10124: "select edge type",
    10125: "select fishing type",
    10126: "single vision",
    10127: "select lens type",
    10128: "sphere",
    10129: "state",
    10130: "statistic",
    10131: "statistic period",
    10132: "street",
    10133: "n.",
    10134: "active",
    10135: "subscription date",
    10136: "to date",
    10137: "trifocal",
    10138: "type",
    10139: "update profile",
    10140: "update to",
    10141: "updated Job",
    10142: "username",
    10143: "user settings",
    10144: "vertical box",
    10145: "weeks",
    10146: "in progress",
    10147: "yes",
    10148: "ZIP",
    10149: "authentication Server Error",
    10150: "invalid Credential",
    10151: "invalid file format",
    10152: "insert new job",
    10153: "press to view job",
    10154: "press to edit job",
    10155: "press to copy job",
    10156: "no records to display",
    10157: "add",
    10158: "delete",
    10159: "edit",
    10160: "invalid",
    10161: "save",
    10162: "are you sure delete this row?",
    10163: "turn on the Tracer.",
    10164: "place the frame on the Tracer.",
    10165: "press the green button with the glasses to do the reading.",
    10166: "after the reading of the shape press the green button with the glasses again.",
    10167: "press the -Receive- button on the Pc program.",
    10168: "after the reading of the shape (when you hear the BEEP), press the -Receive- button on the Pc program.",
    10169: "press the green button to read.",
    10170: "while the Tracer reads the shape, press the -Receive- button on the Pc program.",
    10171: "do the reading of the frame.",
    10172: "press the -Save- button from the tracer to send the shape to the PC.",
    10173: "press the -Trans- button on the Tracer, once he has finished reading.",
    10174: "press -B (BOTH)- for reading.",
    10175: "check that on the Tracer Screen you can see on the right -RSA- (In case its different press CTL and +/- until it changes to -RSA- and restart the tracer).",
    10176: "press twice -Start- on the tracer to start reading the frame.",
    10177: "press -DATA- button at the end of the trace, when the led above lights on.",
    10178: "press the Start button to read.",
    10179: "if GT500: Press -DATA- button at the end of the trace, when the led above lights on.",
    10180: "connect Single",
    10181: "receive Single",
    10182: "disconnect",
    10183: "receive Cycle",
    10184: "receive",
    10185: "connecting to device...",
    10186: "waiting for data from device...",
    10187: "disconnecting from device...",
    10188: "rows select:",
    10189: "rows per page:",
    10190: "previous Page",
    10191: "next Page",
    10192: "prices",
    10193: "vat number",
    10194: "number",
    10195: "address",
    10196: "catalogue",
    10197: "catalogue management",
    10198: "upload",
    10199: "",
    10200: "catalogue updated",
    10201: "levels",
    10202: "details",
    10203: "values",
    10204: "external codes",
    10205: "confirm",
    10206: "are you sure you want to delete the catalog",
    10207: "uploaded catalogue",
    10208: "attention",
    10209: "drag and drop an xlsx file here or click",
    10210: "search product",
    10211: "front",
    10212: "percent",
    10213: "back",
    10214: "modify",
    10215: "product",
    10216: "you are adding this product",
    10217: "drag headers here to group by",
    10218: "refresh Job List",
    10219: "required Fields",
    10220: "required fields updated",
    10221: "shape data",
    10222: "job Data",
    10223: "remove product",
    10224: "order products by:",
    10225: "remote-Support",
    10226: "order required fields",
    10227: "select a single order or a period of orders to search",
    10228: "select two order periods to search for year",
    10229: "OK",
    10230: "clear",
    10231: "empty",
    10232: "today",
    10233: "reset filters",
    10234: "send job to supplier",
    10235: "select the supplier to which you want to forward the order",
    10236: "send",
    10237: "year",
    10238: "press to close",
    10239: "job sent to supplier",
    10240: "delete job",
    10241: "attention",
    10242: "are you sure you want to delete the current job?",
    10243: "cancel",
    10244: "confirm",
    10245: "created by",
    10246: "tabo system",
    10247: "minimum thickness at the edge 2mm",
    10248: "order closed correctly",
    10249: "close order",
    10250: "manual",
    10251: "",
    10252: "select brand",
    10253: "device name",
    10254: "serial",
    10255: "ip",
    10256: "comunication port",
    10257: "enter only the port number",
    10258: "speed port",
    10259: "parity bit",
    10260: "data bit",
    10261: "stop bit",
    10262: "ip address",
    10263: "ip port",
    10264: "none",
    10265: "even",
    10266: "odd",
    10267: "advanced settings protocol",
    10268: "option download",
    10269: "option upload",
    10270: "save edit",
    10271: "advanced settings serial",
    10272: "ip protocol",
    10273: "job without barcode",
    10274: "nack",
    10275: "offset metal",
    10276: "offset plastic",
    10277: "single side",
    10278: "tracer id",
    10279: "without barcode",
    10280: "req",
    10281: "trcfmtbin",
    10282: "trcfmtpoints",
    10283: "zfmtbin",
    10284: "zfmtpoints",
    10285: "selected",
    10286: "timeout",
    10287: "handshake",
    10288: "readBufferSize",
    10289: "writeBufferSize",
    10290: "readTimeout",
    10291: "writeTimeout",
    10292: "rts",
    10293: "dtr",
    10294: "discardNull",
    10295: "offset other",
    10296: "saved settings log out to take effect",
    10297: "job edited correctly",
    10298: "send job to supplier as tracer (SERIAL PORT)",
    10299: "connect must be at least version 1.5.1",
    10300: "lens material",
    10301: "coating lens",
    10302: "not slippery",
    10303: "edge thickness",
    10304: "center thickness",
    10305: "groove width",
    10306: "groove depth",
    10307: "up safety",
    10308: "low safety",
    10309: "select lens material",
    10310: "select lens slippery",
    10311: "low slippery",
    10312: "high slippery",
    10313: "tracer settings",
    10314: "calibration settings updated",
    10315: "calibration settings",
    10316: "height value  (Y)",
    10317: "width value  (X)",
    10318: "for a correct calibration of the drawing on the screen it is necessary to modify the X and Y values ​​so that both sides of the square have a length of 80 mm",
    10319: "drill management",
    10320: "add drill",
    10321: "delete drills",
    10322: "save drills",
    10323: "choose job number",
    10324: "manual job number",
    10325: "maximum",
    10326: "frames management",
    10327: "suppliers list",
    10328: "find supplier",
    10329: "supplier already exist",
    10330: "supplier",
    10331: "frame data",
    10332: "brand",
    10333: "frame id",
    10334: "model name",
    10335: "color",
    10336: "caliber",
    10337: "brands list",
    10338: "find brand",
    10339: "brand already exist",
    10340: "edit frame",
    10341: "delete frame",
    10342: "impossible to open frame",
    10343: "save failed: frame with this id already exist",
    10344: "frame not saved",
    10345: "shape from database",
    10346: "press the button to start the read.",
    10347: "modify shape",
    10348: "save shape",
    10349: "up",
    10350: "down",
    10351: "right",
    10352: "left",
    10353: "maximum 1 month",
    10354: "type edit drill",
    10355: "select the type of drill edit",
    10356: "in the center",
    10357: "on the edge",
    10358: "autocad converter",
    10359: "OMA file converted correctly in DXF. Click to download",
    10360: "please check that the file is correct than try again",
    10361: "error: Try again, if the problem persist contact Pegaso",
    10362: "OMA to DXF converter",
    10363: "drag and drop the OMA file or click here",
    10364: "click to convert the file",
    10365: "convert file",
    10366: "download file",
    10367: "DXF to OMA converter",
    10368: "what result do you want?",
    10369: "both",
    10370: "how is the file composed?",
    10371: "drag and drop the DXF file or click here",
    10372: "DXF file converted correctly in OMA. Click to download",
    10373: "the AutoCad project must contain only SPLINE and POLYLINE for the representation of the shape, and CIRCLE for the representation of the drills",
    10374: "any other element in the AutoCad project will be ignored",
    10375: "OMA file to convert must respect all standard of OMA/VCA protocol.",
    10376: "frame",
    10377: "frame brand",
    10378: "frame supplier",
    10379: "fields visible by customers",
    10380: "true",
    10381: "false",
    10382: "add new frame",
    10383: "supplier id",
    10384: "brand id",
    10385: "side",
    10386: "drag and drop a shape file or click here",
    10387: "search optician customer",
    10388: "converter operation guide: ",
    10389: "height from Box",
    10390: "edger back pin bevel width",
    10391: "edger front pin bevel width",
    10392: "select",
    10393: "CR - plastic",
    10394: "polycarbonate",
    10395: "glass",
    10396: "pattern",
    10397: "hi - index",
    10398: "trivex",
    10399: "tribird",
    10400: "frame curve",
    10401: "front curve of a lens",
    10402: "frame wrap angle",
    10403: "lens index",
    10404: "select lens index",
    10405: "select the tracer type and the serial communication port of the tracer for job creation via web interface",
    10406: "lens data",
    10407: "cancel",
    10408: "submit",
    10409: "height from Edge",
    10410: "export catalogue excel",
    10411: "coating",
    10412: "select search type of jobs",
    10413: "search single job",
    10414: "search jobs in period",
    10415: "restore job for external system",
    10416: "job restored correctly",
    10417: "job not restored",
    10418: "pantoscopic angle",
    10419: "corneal apex distance",
    10420: "order settings",
    10421: "mounting height entry method",
    10422: "from box",
    10423: "from edge",
    10424: "deleted",
    10425: "impossible to delete shape",
    10426: "are you sure you want to delete this shape?",
    10427: "status",
    10428: "Y position value too large (hole ",
    10429: "X position value too large (hole ",
    10430: "shape precal",
    10431: "shapes precal management",
    10432: "refresh precal shapes",
    10433: "add new shape",
    10434: "shape id",
    10435: "description",
    10436: "keep proportions",
    10437: "language",
    10438: "settings saved correctly, exit from the program",
    10439: "send job to blocker (SERIAL PORT)",
    10440: "blocker is being disconnected",
    10441: "connecting to the blocker",
    10442: "waiting to send data",
    10443: "data sent correctly",
    10445: "no shape sent",
    10446: "fist page",
    10447: "last page",
    10448: "remove tracer and save",
    10449: "attention: you are trying to send an order that has already been submitted. Do you want to continue?",
    10450: "minimum diameter",
    10451: "selected diameter",
    10452: "calculate diameter",
    10453: "for the calculation, interpupillary distance, mounting height and horizontal and vertical template are necessary",
  }
}
export default en
