import React from "react"

// core components
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

/*---------  import CSS ---------*/
import { useScrollStyles } from "styles/scrollCss"

/*************************************************************************
                  VALORI PER SELECT
 *************************************************************************/

export default class ScrollUtils {
  static ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  static AppBarScroll(props) {
    const { onHandleClose, t } = props
    const classes = useScrollStyles()
    
    return (
      <AppBar
        className={classes.appbar}
        position="sticky"
      >
        <Box display="flex" flexDirection="row-reverse" p={1}>
          <Box p={1}>
            <IconButton
              aria-label= {t("10238")}
              variant="outlined"
              onClick={onHandleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
    )
  }
}
