import React from "react"

// @material-ui/core components
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Favicon from "react-favicon"
import Paper from "@material-ui/core/Paper"

// Pegaso components
import { useLoginStyles } from "styles/loginCss"
import LoginFooter from "components/Footer/LoginFooter"


export default (props) => {
  const classes = useLoginStyles()
  const companyFooter = {
    site: "",
    email: "",
    telephoneNumber: "",
    mobilePhone: "",
  }
  var language = window.navigator.userLanguage || window.navigator.language

  return (
    <div>
      <Favicon url={'favicon.ico'} />
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <CardMedia component="img" image={'/resources/images/logoConnect.png'} />
          {
            language === 'it-IT' || language === 'it' ? (
              <div>
                <center>
                  <h3>ERRORE COLLEGAMENTO URL</h3>
                </center>
                <center>
                  <h5>Riavvia il browser</h5>
                </center>
              </div>
            ) : (
              <div>
                <center>
                  <h3>URL LINK ERROR</h3>
                </center>
                <center>
                  <h5>Reboot your browser</h5>
                </center>
              </div>
            )
          }
        </Paper>

        {<LoginFooter
          companyFooter={companyFooter}
        />}
      </main>
    </div>
  )
}
