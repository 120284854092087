import React, { useEffect, useState, useContext, Fragment } from "react"
import { RootContext } from "../../RootContext"

// Core components
import { Stage, Layer, Line } from "react-konva"

// Pegaso components
import JobUtils from "components/Utils/JobUtils"
import ShapeUtils from "components/Utils/ShapeUtils"

// Css
import { useShapeStyle } from "styles/shape/shapeCss"

export default function SingleSideShape(props) {
  // Compontents of table
  const {
    shape,
    edgingData,
    scaleX,
    scaleY,
    editMode,
    offset,
  } = props

  const {
    getCalibration,
  } = useContext(RootContext)
  const shapeClass = useShapeStyle.shape
  const [shapeJson, setShapeJson] = useState()
  const [shapeExists, setShapeExists] = useState(editMode ? false : true)
  const canvasHeight = 220
  const canvasWidth = 450
  const startRightLens = 120
  const originY = 110
  const [calibration] = useState(getCalibration())
  const [currentShape, setCurrentShape] = useState("")
  const [currentEdgingData, setCurrentEdgingData] = useState(edgingData)

  const origin = { x: startRightLens, y: originY }

  useEffect(() => {
    setCurrentShape(JobUtils.GetStandardShape({ shape: { standard: shape } }))
    setCurrentEdgingData(edgingData)
  }, [shape, edgingData])

  useEffect(() => {
    const shapeToDraw = ShapeUtils.extractShapeFromOMA(
      currentShape,
      currentEdgingData,
      scaleX,
      scaleY,
      offset,
      calibration
    )
    setShapeJson(shapeToDraw)

    if (
      shapeToDraw.right.points.length > 1 ||
      shapeToDraw.left.points.length > 1
    ) {
      setShapeExists(true)
    } else {
      setShapeExists(false)
    }
  }, [
    calibration,
    currentShape,
    shape,
    edgingData,
    currentEdgingData,
    scaleX,
    scaleY,
    offset,
  ])

  return (
    <Fragment>

      {currentShape ? (
        <Stage
          width={canvasWidth}
          height={canvasHeight}
          onMouseEnter={e => {
            const container = e.target.getStage().container();
            container.style.cursor = 'pointer';
            container.style.backgroundColor = "#EEEEEE";
          }}
          onMouseOut={e => {
            const container = e.target.getStage().container();
            container.style.cursor = 'default';
            container.style.backgroundColor = "#FFFFFF";
          }}
        >
          {/* DISEGNO FORMA (BLU) */}
          {shapeExists ? (
            <Layer>
              {/* 1 - LENTE DESTRA (origine = centro lente) */}
              <Line
                closed
                fill={shapeClass.fill}
                points={shapeJson ? shapeJson.right.points : []}
                stroke={shapeClass.stroke}
                strokeWidth={shapeClass.strokeWidth}
                tension={0}
                x={origin.x}
                y={origin.y}
              />
            </Layer>
          ) : null}
        </Stage>
      ) : null}
    </Fragment>


  )
}
