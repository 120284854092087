import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

// core components
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AddIcon from "@material-ui/icons/Add"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import RefreshIcon from "@material-ui/icons/Refresh"
import Tooltip from "@material-ui/core/Tooltip"
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography"
import { fetchWithToken } from "../../components/Fetch/api-fetch"
import { titleCase } from "title-case"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useJobsTableStyles } from "styles/jobs/jobsTableCss"

//Pegaso component
import ErrorUtils from "components/Tools/ErrorUtils"
import FrameListTableHead from "views/FramesManagement/FrameListTableHead"
import FrameListTableBody from "views/FramesManagement/FrameListTableBody"
import Frame from "views/FramesManagement/Frame"
import FrameListToolbar from "views/FramesManagement/FrameListToolbar"

export default function FramesList(props) {
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, } = useContext(RootContext)
  const { framesRows, frameBrands, frameSuppliers, frameSuppliersFilterOption, frameBrandsFilterOption, endLoadingFrames, setFramesRows, setIsFetchingFrames } = props


  const [actionFetch, setActionFetch] = useState(false)
  const classes = useJobsStyles()
  const classesTable = useJobsTableStyles()
  const [creation, setCreation] = useState(false)
  const [count, setCount] = useState(1)
  const [dialogMsg, setDialogMsg] = useState("")
  const [frameData, setFrameData] = useState("")
  const [frameSupplierId, setFrameSupplierId] = useState(0)
  const [frameBrandId, setFrameBrandId] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openFrame, setOpenFrame] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [frameToDelete, setFrameToDelete] = useState("")
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, framesRows.length - page * rowsPerPage)


  /*
    **      HANDLE EVENTS - FETCH
    */

  // Fetch Open frame
  function fetchFrameGet(frameId) {
    const url = `${apiUrl}/companies/${companyId}/frame/${frameId}`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se la forma esiste
        setActionFetch(false)
        setFrameData(data)
      })
      .catch((err) => {
        // Se Frame non esiste
        setActionFetch(false)
        setFrameData("")
        setOpenFrame(false)
        setDialogMsg(t(""))
        setOpenDialog(true)
        ErrorUtils.errorLog("Fetch Job Error", err)
      })
  }

  ///Fetch dei frame supplier
  const fetchFrameBySupplier = useCallback((frameSupplierId) => {
    fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames/frameSupplier/${frameSupplierId}`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        const { frames } = body
        setFramesRows(frames)
        setIsFetchingFrames(false)
      })
      .catch(err => {
        ErrorUtils.errorLog("fetchFrameBySupplier", err)
        setIsFetchingFrames(false)
        ErrorUtils.errorLog("Frame fetch error.", err.message)
      })
  }, [access_token, apiUrl, companyId, refresh_token, setAccess_token, setFramesRows, setIsFetchingFrames])


  ///Fetch dei frame brand
  const fetchFrameByBrand = useCallback((frameBrandId) => {
    fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames/frameBrand/${frameBrandId}`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        const { frames } = body
        setFramesRows(frames)
        setIsFetchingFrames(false)
      })
      .catch(err => {
        ErrorUtils.errorLog("fetchFrameByBrand", err)
        setIsFetchingFrames(false)
        ErrorUtils.errorLog("Frame fetch error.", err.message)
      })
  }, [access_token, apiUrl, companyId, refresh_token, setAccess_token, setFramesRows, setIsFetchingFrames])

  // Fetch Delete
  function fetchDeleteFrame() {
    setActionFetch(true)
    const url = `${apiUrl}/companies/${companyId}/frame/${frameToDelete}`
    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se ha eliminato
        setOpenDeleteDialog(false)
        setActionFetch(false)
        let newRows = framesRows.filter(option => option.frameId !== frameToDelete)
        setFramesRows(newRows)
      })
      .catch((err) => {
        // Se non riese a eliminare
        const { body } = err
        setActionFetch(false)
        setFrameData("")
        setOpenFrame(false)
        setDialogMsg(t("Impossibile cancellare frame"))
        setOpenDialog(true)
        ErrorUtils.errorLog("Fetch Frame Error", body.error)
      })
  }

  // Click change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Click rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  //chiusura del pannello per errore
  const handleClose = () => {
    setOpenDialog(false)
    handleCloseDelete()
  }

  // Click Open New Frame
  function handleClickOpenNew() {
    setOpenFrame(true)
    setCreation(true)
  }

  // Click Close Frame
  function handleClickClose() {
    setFrameData("")
    setOpenFrame(false)
    setCreation(false)
  }

  // Click Open Frame
  function handleClickOpen(frameId) {
    setOpenFrame(true)
    setActionFetch(true)
    setCreation(false)
    fetchFrameGet(frameId)
  }

  //Click Open dialog delete
  function handleClickDelete(frameId) {
    setFrameToDelete(frameId)
    setOpenDeleteDialog(true)
  }

  //Click Close dialog delete
  function handleCloseDelete() {
    setOpenDeleteDialog(false)
    setFrameToDelete("")

  }

  // Click refresh
  function handleClickRefresh() {
    setIsFetchingFrames(true)
  }

  function handleChangeFilter(filterField, targetValue) {
    setIsFetchingFrames(true)
    setCount(0)
    if (filterField === "frameSupplier") {
      fetchFrameBySupplier(targetValue)
      setFrameSupplierId(targetValue)
    }
    else if (filterField === "frameBrand") {
      setFrameBrandId(targetValue)
      fetchFrameByBrand(targetValue)
    }
  }



  return (
    <div>
      {/*Pannello filtri*/}
      {<Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{titleCase(t("10087"))}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardHeader color="primary">
              <FrameListToolbar
                frameBrandId={frameBrandId}
                frameSupplierId={frameSupplierId}
                handleChangeFilter={(filterField, targetValue) => {
                  handleChangeFilter(filterField, targetValue)
                }}
                selectOptionFrameSuppliers={frameSuppliersFilterOption}
                selectOptionFrameBrands={frameBrandsFilterOption}
              />
            </CardHeader>
          </Card>
        </AccordionDetails>
      </Accordion>}
      <Card>
        <CardBody>

          <Grid container direction="row-reverse" justifyContent="space-between">
            {/* TASTO NUOVO JOB */}

            <Grid item xs={1}>
              {" "}
              <Tooltip title={t("10382")}>
                <Fab
                  color="primary"
                  onClick={handleClickOpenNew}
                  aria-label="add"
                  align="right"
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Grid>

            {/* TASTO REFRESH JOBS*/}
            <Grid item xs={1}>
              <Tooltip title={t("10218")}>
                <IconButton
                  onClick={handleClickRefresh}
                  aria-label="refresh"
                  align="left"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={10}></Grid>
          </Grid>

          {!endLoadingFrames ? (
            <CircularProgress size={42} style={{ marginLeft: '50%' }}
            />
          ) : (<div>

            <Table className={classes.table} aria-label="simple table">
              <FrameListTableHead isFullTable={true} />
              <FrameListTableBody
                isFullTable={true}
                rows={framesRows}
                classes={classesTable}
                emptyRows={emptyRows}
                page={page}
                rowsPerPage={rowsPerPage}
                handleClickOpen={handleClickOpen}
                handleClickDelete={handleClickDelete}
              />
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}

        </CardBody>
      </Card>


      {/*DIALOG INSERIMENTO FRAME*/}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={openFrame}
        maxWidth="xl"
      >
        <DialogContent>
          <Frame
            creation={creation}
            frame={frameData}
            framesRows={framesRows}
            frameBrands={frameBrands}
            frameSuppliers={frameSuppliers}
            onHandleClose={handleClickClose}
            setFramesRows={setFramesRows}
          />
        </DialogContent>
      </Dialog>

      {/*DIALOG PER ERRORE FETCH*/}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("28")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {" "}
            Ok{" "}
          </Button>
        </DialogActions>
      </Dialog>

      {/*DIALOG PER ELIMINAZIONE FRAME*/}
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("28")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("10426")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={fetchDeleteFrame} color="primary" autoFocus>
            {" "}
            {t("10244")}{" "}
          </Button>

          <Button onClick={handleCloseDelete} color="primary" autoFocus>
            {" "}
            {t("10243")}{" "}
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG LOADING */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={actionFetch}
        maxWidth="xl"
      >
        <DialogContent>
          <CircularProgress size={42} style={{ color: "primary" }} />
        </DialogContent>
      </Dialog>
    </div>
  )
}