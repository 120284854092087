// @material-ui/icons
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import Person from "@material-ui/icons/Person";
import TrendingUp from "@material-ui/icons/TrendingUp";
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
// core components/views for Admin layout
import Catalogue from "views/Catalogue/Catalogue.js";
import Jobs from "views/Jobs/Jobs";
import Opticians from "views/Opticians/Opticians";
import RequiredFields from "views/RequiredFields/RequiredFields.js";
import EdgingStatistic from "views/Statistics/EdgingStatistic.js";
import Settings from "views/Settings/Settings.js"
import UserProfile from "views/UserProfile/UserProfile.js";
import FramesManagement from 'views/FramesManagement/FramesManagement';
import AutocadConverter from "views/AutocadConverter/AutocadConverter";
import LoadedShapes from "views/LoadedShape/LoadedShapes"



const dashboardRoutes = [
  {
    path: "/jobs",
    name: "jobList",
    rtlName: "jobList",
    icon: "content_paste",
    component: Jobs,
    description: "10051",
    layout: "/admin"
  },
  {
    path: "/opticians",
    name: "opticianList",
    rtlName: "opticianList",
    icon: StoreIcon,
    component: Opticians,
    description: "10052",
    layout: "/admin"
  },
  {
    path: "/statistic",
    name: "statistic",
    rtlName: "statistic",
    icon: TrendingUp,
    component: EdgingStatistic,
    description: "10130",
    layout: "/admin"
  },
  {
    path: "/user",
    name: "userSettings",
    rtlName: "userSettings",
    icon: Person,
    component: UserProfile,
    description: "10143",
    layout: "/admin"
  }, 
  {
    path: "/catalogue",
    name: "catalogue",
    rtlName: "catalogue",
    icon: ShoppingCartIcon,
    component: Catalogue,
    description: "10196",
    layout: "/admin"
  },
  {
    path: "/frames",
    name: "frames",
    rtlName: "frames",
    icon: MotorcycleIcon,
    component: FramesManagement,
    description: "10326",
    layout: "/admin"
  },
  {
    path: "/autocadConverter",
    name: "autocadConverter",
    rtlName: "autocadConverter",
    icon: CompareArrowsIcon,
    component: AutocadConverter,
    description: "10358",
    layout: "/admin"
  },
  {
    path: "/requiredfields",
    name: "requiredfields",
    rtlName: "requiredfields",
    icon: EditAttributesIcon,
    component: RequiredFields,
    description: "10219",
    layout: "/admin"
  },
  {
    path: "/loadedShapes",
    name: "loadedShapes",
    rtlName: "loadedShapes",
    icon: AddPhotoAlternateIcon,
    component: LoadedShapes,
    description: "10430",
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "settings",
    rtlName: "settings",
    icon: SettingsIcon,
    component: Settings,
    description: "10123",
    layout: "/admin"
  },
];


export default dashboardRoutes;
