import React, {
  useContext,
  Fragment,
} from "react"
import { RootContext } from "RootContext"

// Core components
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"

// Griglia per scheda del JobRouting
function JobRestoreExternalSystemGridContainer(props) {
  const {
    t,
  } = useContext(RootContext)
  const { isJobRestored, onHandleClose, openJobRestore } = props

  return (
    openJobRestore ? (
      <div>
        <Dialog
          open={true}
          onClose={onHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {isJobRestored ?
                  t("10416") : t("10417")
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onHandleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>

      </div>
    ) : (
      null
    )
  )
}

// Griglia interna alla finestra del JobRouting
function JobRestoreExportExternalSystem(props) {
  const { actionFetch } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (!actionFetch) {
    return <JobRestoreExternalSystemGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}

export default JobRestoreExportExternalSystem
