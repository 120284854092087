import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

// core components
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Chip from "@material-ui/core/Chip"
import DeleteIcon from "@material-ui/icons/Delete"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MaterialTable from "@material-table/core"
import _ from "lodash"
import { CircularProgress } from "@material-ui/core"
import { fetchWithToken } from "../../components/Fetch/api-fetch"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useStylesProductTable } from "styles/job/jobCss"

//PegasoComponent
import CustomPagination from "components/CustomMaterialTable/CustomPagination"
import CustomFilterComponent from "components/Filter/CustomFilterComponent"
//import { fi } from "date-fns/locale"

function SelectedProduct({ currentProduct }) {
  return currentProduct ? <Chip color="primary" label={currentProduct} /> : null
}

function AddProductButton({ handleClick, editMode }) {
  const { t } = useContext(RootContext)
  return editMode ? (
    <Button
      onClick={handleClick}
      variant="outlined"
      color="secondary"
      size="small"
      startIcon={<AddShoppingCartIcon />}
    >
      {t("10210")}
    </Button>
  ) : null
}

function RemoveProductButton({ handleClick, disabled, editMode }) {
  const { t } = useContext(RootContext)
  return editMode ? (
    <Button
      onClick={handleClick}
      variant="outlined"
      color="secondary"
      size="small"
      disabled={disabled}
      startIcon={<DeleteIcon />}
    >
      {t("10223")}
    </Button>
  ) : null
}

function SaveProductButton({ handleClick, currentProduct, handleCancel }) {
  const { t } = useContext(RootContext)
  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
      <Grid item xs={10}>
        {" "}
        {t("10216")} <Chip label={currentProduct} />{" "}
      </Grid>
      <Grid item xs={1}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          color="secondary"
          size="small"
        >
          {t("10010")}
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          onClick={handleClick}
          variant="outlined"
          color="secondary"
          size="small"
        >
          {t("10114")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default function ProductTable({
  opticianId,
  editMode,
  editModeProduct,
  handleOpenClick,
  handleCloseClick,
  className,
  handleAddProduct,
  creation = true,
  product,
  coating,
}) {
  const {
    companyId,
    t,
    apiUrl,
    access_token,
    getLanguage,
    getCurrency,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)
  const [companySettings, setCompanySettings] = useState()
  const [currentProduct, setCurrentProduct] = useState()
  const [currentCoating, setCurrentCoating] = useState()
  const [currentPersonalized, setCurrentPersonalized] = useState()
  const [fetch, setFetch] = useState(false)
  const [levelsKeys, setLevelsKeys] = useState([])
  const [state, setState] = useState({ columns: [], data: [] })
  const [fetching, setFetching] = useState(true)
  const [viewProducts, setViewProducts] = useState(true)
  const classesJob = useJobsStyles()
  const classesProduct = useStylesProductTable()
  const locale = getLanguage() !== undefined ? getLanguage() : "it-it"
  const currency = getCurrency() !== undefined ? getCurrency() : "EUR"
  /*
   *     FUNCTIONS
   */
  function getVisibleFieldsFromSettings(companySettings, prices) {
    const result = []
    const strPrices = prices.map((p) => p.toString())
    if (!companySettings) return ""
    const { details, values, levels, coatingExternalCode, lensFamilyExternalCode, isPersonalized } = companySettings
    Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate))

    // DETAILS
    const detailsFields = Object.keys(Object.filter(details, ([k, v]) => v["visible"] && v["active"])).map((k) => "d" + k).join(",")
    if (detailsFields) {
      result.push(detailsFields)
    }

    // VALUES
    const valuesFields = Object.keys(Object.filter(values, ([k, v]) => { return v["visible"] && v["active"] && strPrices.includes(k) })).map((k) => "v" + k).join(",")
    if (valuesFields) {
      result.push(valuesFields)
    }

    // LEVELS
    const levelsFields = Object.keys(Object.filter(levels, ([k, v]) => v["visible"] && v["active"])).map((k) => "l" + k).join(",")
    if (levelsFields) {
      result.push(levelsFields)
    }

    // LENS FAMILY (visible)
    if (lensFamilyExternalCode) {
      if (lensFamilyExternalCode["active"]) {
        if (lensFamilyExternalCode["visible"]) {
          result.push("lensFamilyExternalCode")
        }
      }
    }

    // COATING (visible)
    if (coatingExternalCode) {
      if (coatingExternalCode["active"]) {
        if (coatingExternalCode["visible"]) {
          result.push("coatingExternalCode")
        }
      }
    }

    // PERSONALIZED (visible)
    if (isPersonalized) {
      if (isPersonalized["active"]) {
        if (isPersonalized["visible"]) {
          result.push("isPersonalized")
        }
      }
    }

    return result.join(",")
  }

  function getHiddenFieldsFromSettings(companySettings) {
    const result = []
    if (!companySettings) return ""
    const { coatingExternalCode, lensFamilyExternalCode, isPersonalized } = companySettings
    Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate))

    // LENS FAMILY (hidden)
    if (lensFamilyExternalCode) {
      if (lensFamilyExternalCode["active"]) {
        if (!lensFamilyExternalCode["visible"]) {
          result.push("lensFamilyExternalCode")
        }
      }
    }

    // COATING (hidden)
    if (coatingExternalCode) {
      if (coatingExternalCode["active"]) {
        if (!coatingExternalCode["visible"]) {
          result.push("coatingExternalCode")
        }
      }
    }

    // PERSONALIZED (hidden)
    if (isPersonalized) {
      if (isPersonalized["active"]) {
        if (!isPersonalized["visible"]) {
          result.push("isPersonalized")
        }
      }
    }

    return result.join(",")
  }

  function resetFilter(state) {
    if (state.columns) {
      state.columns.map((columns) => {
        return columns.tableData !== undefined ? columns.tableData.filterValue !== undefined ? columns.tableData.filterValue = undefined : null : null
      })
    }
    if (state.initColumns) {
      state.initColumns.map((columns) => {
        return columns.tableData !== undefined ? columns.tableData.filterValue !== undefined ? columns.tableData.filterValue = undefined : null : null
      })
    }
    setState((prevState) => {
      return {
        ...prevState,
        data: prevState.initData,
      }
    })
  }


  const CustomFilter = useCallback((props) => {
    const { columnDef, onFilterChanged } = props
    const { field, lookup, tableData } = columnDef
    const { columnOrder } = tableData

    // Prepare array of values for SELECT
    let lookupArray = []
    for (var key in lookup) {
      lookupArray.push(lookup[key]) //qui va aggiunto un controllo per evitare doppioni
    }
    lookupArray.sort()

    return (
      <CustomFilterComponent
        classes={classesProduct}
        columnOrder={columnOrder}
        field={field}
        lookupArray={lookupArray}
        onFilterChanged={onFilterChanged}
      />
    )
  }, [classesProduct])


  function searchInJSON(obj, wordToSearch) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Ricorsione per oggetti o array nidificati
        if (searchInJSON(obj[key], wordToSearch)) {
          return true;
        }
      } else {
        // Confronto parola (anche parziale)
        if (typeof obj[key] === 'string' && obj[key].includes(wordToSearch)) {
          return true;
        }
      }
    }
    return false;
  }



  /*
   *     FETCH
   */
  const fetchProducts = useCallback(
    (visibleFields, hiddenFields) => {
      let url = `${apiUrl}/companies/${companyId}/products?${visibleFields ? `&fields=${visibleFields}${hiddenFields ? "," + hiddenFields : ""}` : ""}`
      fetchWithToken(
        url,
        {
          method: "GET",
          headers: {
            Accept: +"application/json",
            "Content-Type": "application/json",
          },
          apiUrl: apiUrl,
          companyId: companyId,
          access_token: access_token,
          refresh_token: refresh_token,
          setAccess_token: setAccess_token,
        }
      ).then((response) => {
        setFetching(false)
        const body = response.body
        let { products } = body
        products = products.slice(1, products.length)
        Object.filter = (obj, predicate) =>
          Object.fromEntries(Object.entries(obj).filter(predicate))

        const productsOnlyLevels = products.map((p) =>
          Object.filter(p, ([k, v]) => k.includes("l"))
        )
        const productsOnlyValues = products.map((p) =>
          Object.filter(p, ([k, v]) => k.includes("v"))
        )

        const levelsKeys = Object.keys(productsOnlyLevels[0])
        const valuesKeys = Object.keys(productsOnlyValues[0])

        setLevelsKeys(levelsKeys)

        const lookups = {}

        levelsKeys.forEach((levelsKey) => {
          const productsOnlyThisLevels = productsOnlyLevels.map((p) => {
            const result = Object.filter(p, ([k, v]) => k === levelsKey)
            return result
          })
          lookups[levelsKey] = {}
          _.uniqBy(productsOnlyThisLevels, levelsKey).forEach((o) => {
            if (o[levelsKey] !== "")
              lookups[levelsKey][o[levelsKey]] = o[levelsKey]
          })
        })

        setState((prevState) => {
          let newColumns = prevState.columns
          levelsKeys.forEach((levelsKey) => {
            newColumns = newColumns.map((o) => {
              if (o.field === levelsKey && o.field !== "lensFamilyExternalCode" && o.field !== "coatingExternalCode" && o.field !== "isPersonalized") {
                return {
                  ...o,
                  lookup: lookups[levelsKey],
                }
              } else return o
            })
          })

          valuesKeys.forEach((valuesKey) => {
            newColumns = newColumns.map((o) => {
              if (o.field === valuesKey) {
                return {
                  ...o,
                  filtering: false,
                  searchable: false,
                  type: "currency",
                  currencySetting: {
                    locale: locale,
                    currencyCode: currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                }
              } else {
                if (o.field.indexOf("l") >= 0 && o.field !== "lensFamilyExternalCode" && o.field !== "coatingExternalCode" && o.field !== "isPersonalized") {
                  return {
                    ...o,
                    searchable: true,
                    filterComponent: CustomFilter,
                  }
                } else {
                  return { ...o, searchable: true }
                }
              }
            })
          })

          return {
            columns: newColumns,
            data: products,
            initColumns: newColumns,
            initData: products,
          }
        })
      })
    },
    [
      access_token,
      apiUrl,
      companyId,
      currency,
      locale,
      refresh_token,
      setAccess_token,
      CustomFilter,
    ]
  )

  const fetchOpticianCatalogueSettings = useCallback(
    (visibleFields, hiddenFields) => {
      let url = `${apiUrl}/companies/${companyId}/products?header=true${visibleFields ? `&fields=${visibleFields}` : ""}`
      fetchWithToken(url,
        {
          method: "GET",
          headers: {
            Accept: +"application/json",
            "Content-Type": "application/json",
          },
          apiUrl: apiUrl,
          companyId: companyId,
          access_token: access_token,
          refresh_token: refresh_token,
          setAccess_token: setAccess_token,
        }
      )
        .then((response) => {
          const { body } = response
          const { header } = body
          const columns = _.values(
            _.transform(header, function (r, v, k) {
              r[k] = { title: v, field: k }
            })
          )
          setState((prevState) => {
            return {
              ...prevState,
              columns,
            }
          })
        })
        .then(() => {
          fetchProducts(visibleFields, hiddenFields)
        })
    },
    [
      access_token,
      apiUrl,
      companyId,
      fetchProducts,
      refresh_token,
      setAccess_token,
    ]
  )

  const fetchCatalogue = useCallback(() => {
    fetchWithToken(`${apiUrl}/companies/${companyId}/catalogue`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        setFetching(true)
        const { body } = response
        const { catalogueScope } = body

        if (catalogueScope == null) {
          setViewProducts(false)
          return {}
        } else {
          setViewProducts(true)
          const { companySettings } = catalogueScope
          return companySettings
        }
      })
      .then((companySettings) => {
        const { currentCatalogue } = companySettings

        setCompanySettings(
          _.isEmpty(companySettings) ? undefined : companySettings
        )

        if (currentCatalogue) {
          fetchWithToken(
            `${apiUrl}/companies/${companyId}/opticians/${opticianId}`,
            {
              method: "GET",
              apiUrl: apiUrl,
              companyId: companyId,
              access_token: access_token,
              refresh_token: refresh_token,
              setAccess_token: setAccess_token,
            }
          )
            .then((response) => {
              return response.body.prices
            })
            .then((prices) => {
              const fields = getVisibleFieldsFromSettings(companySettings, prices)
              const hiddenFields = getHiddenFieldsFromSettings(companySettings)
              if (fields === "") {
                setState({ columns: [], data: [] })
              } else {
                fetchOpticianCatalogueSettings(fields, hiddenFields)
              }
            })
        }
      })
  }, [
    access_token,
    apiUrl,
    companyId,
    fetchOpticianCatalogueSettings,
    opticianId,
    refresh_token,
    setAccess_token,
  ])

  /*
   *     USE EFFECT
   */
  useEffect(() => {
    fetchCatalogue()
  }, [fetchCatalogue])
  return companySettings ? (
    companySettings.currentCatalogue ? (
      <Grid item xs={12} className={className}>
        {editModeProduct && !currentProduct ? (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <IconButton
                  aria-label={t("10238")}
                  variant="outlined"
                  onClick={() => {
                    handleCloseClick(undefined)
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12}>
                  <MaterialTable
                    className={classesJob.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    title={""}
                    columns={state.columns}
                    data={state.data}
                    components={{
                      Pagination: (props) => {
                        const { rowsPerPage, rowsPerPageOptions } = props
                        return <CustomPagination
                          {...props}
                          count={state.data ? state.data.length : 0}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={rowsPerPageOptions}
                        />
                      }
                    }}
                    onRowClick={(e, row) => {
                      Object.filter = (obj, predicate) =>
                        Object.fromEntries(
                          Object.entries(obj).filter(predicate)
                        )

                      let lensFamilyExternalCode = ""
                      if (row["lensFamilyExternalCode"]) {
                        if (row["lensFamilyExternalCode"] !== "") {
                          lensFamilyExternalCode = row["lensFamilyExternalCode"]
                        }
                      }
                      let coatingExternalCode = ""
                      if (row["coatingExternalCode"]) {
                        if (row["coatingExternalCode"] !== "") {
                          coatingExternalCode = row["coatingExternalCode"]
                        }
                      }

                      let personalizedLens = false
                      if (row["isPersonalized"]) {
                        if (row["isPersonalized"] !== "") {
                          personalizedLens = row["isPersonalized"] === "S" ? true : false
                        }
                      }

                      let isProgressive = false
                      if (searchInJSON(row, "PROGR") === true) {
                        isProgressive = true
                      }

                      // PRODUCT  
                      let nElem = 0
                      let newCoating = coatingExternalCode ? "[" + coatingExternalCode + "]" : ""
                      let newProduct = _.values(Object.filter(row, ([k, v]) =>
                        k.includes("d") &&
                        k !== "id" &&
                        k !== "lensFamilyExternalCode" &&
                        k !== "coatingExternalCode" &&
                        k !== "isPersonalized"
                      ))
                        .map((elem) => {
                          if (nElem === 0) {
                            if (lensFamilyExternalCode !== "") {
                              // 1) Cerca "codice prodotto" da "lensFamilyExternalCode" (NEW)          
                              elem = "[" + lensFamilyExternalCode + "] -" + elem
                            }
                          }
                          nElem += 1
                          return elem
                        })
                        .join(" ")
                        .trim()
                      setCurrentProduct(newProduct)
                      setCurrentCoating(newCoating)
                      setCurrentPersonalized(personalizedLens)
                      handleAddProduct(newProduct, newCoating, personalizedLens, isProgressive)
                    }}
                    onFilterChange={(rowDataColumn) => {
                      let notCheck = []
                      let notRefresh = false
                      let filteredData = [...state.initData]

                      rowDataColumn.forEach((column) => {
                        notCheck.push(column.column.field)
                        filteredData = filteredData.filter((row) => {
                          let found = false
                          if (column.value.length === 0) {
                            found = true
                          }
                          if (Array.isArray(column.value)) {
                            column.value.forEach((term) => {
                              found = row[column.column.field] === term
                            })
                          } else {
                            found = (row[column.column.field].indexOf(column.value) >= 0)
                            notRefresh = true
                          }
                          return found
                        })
                      })

                      const lookups = {}
                      levelsKeys.forEach((levelsKey) => {
                        const productsOnlyThisLevels = filteredData.map((p) => {
                          const result = Object.filter(
                            p,
                            ([k, v]) => k === levelsKey
                          )
                          return result
                        })
                        lookups[levelsKey] = {}
                        _.uniqBy(productsOnlyThisLevels, levelsKey).forEach(
                          (o) => {
                            if (o[levelsKey] !== "")
                              lookups[levelsKey][o[levelsKey]] = o[levelsKey]
                          }
                        )
                      })

                      if (filteredData.length === 0) {
                        if (!notRefresh) {
                          setFetch(!fetch)
                        }
                      } else {
                        setState((prevState) => {
                          let newColumns = prevState.columns
                          levelsKeys.forEach((levelsKey) => {
                            newColumns = newColumns.map((o) => {
                              let r = o
                              if (
                                notCheck.indexOf(o.field) < 0 &&
                                o.field === levelsKey &&
                                o.field.indexOf("l") >= 0
                              ) {
                                r = {
                                  ...o,
                                  lookup: lookups[levelsKey],
                                }
                              }
                              return r
                            })
                          })
                          const result = {
                            columns: newColumns,
                            data: filteredData,
                            initColumns: prevState.initColumns,
                            initData: prevState.initData,
                          }
                          return result
                        })
                      }
                    }}
                    isLoading={fetching}
                    options={{
                      filtering: true,
                      actionsColumnIndex: 0,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 15],
                      draggable: true,
                      showFirstLastPageButtons: false,
                      grouping: true,
                    }}
                    localization={{
                      toolbar: {
                        searchTooltip: t("10210"),
                        searchPlaceholder: t("10210"),
                      },
                      grouping: {
                        placeholder: t("10217"),
                        groupedBy: t("10224"),
                      },
                      header: { actions: "" },
                      body: {
                        emptyDataSourceMessage: t("10156"),
                        addTooltip: t("10157"),
                        deleteTooltip: t("10158"),
                        editTooltip: t("10159"),
                        editRow: {
                          deleteText: t("10162"),
                          cancelTooltip: t("10010"),
                          saveTooltip: t("10161"),
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {currentProduct && !creation ? (
              <SaveProductButton
                currentProduct={currentProduct}
                currentCoating={currentCoating}
                currentPersonalized={currentPersonalized}
                handleClick={() => {
                  handleCloseClick(undefined)
                  setCurrentProduct(undefined)
                  setCurrentCoating(undefined)
                  setCurrentPersonalized(undefined)
                }}
                handleCancel={() => {
                  setCurrentProduct(undefined)
                  setCurrentCoating(undefined)
                  setCurrentPersonalized(undefined)
                }}
              />
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <SelectedProduct currentProduct={product} />
                </Grid>
                <Grid item xs={3}>
                  <AddProductButton
                    handleClick={() => {
                      handleOpenClick()
                      setCurrentProduct(undefined)
                      setCurrentCoating(undefined)
                      setCurrentPersonalized(undefined)
                      resetFilter(state)
                    }}
                    editMode={creation || editMode}
                  />
                </Grid>
                <Grid item xs={3}>
                  <RemoveProductButton
                    currentProduct={product}
                    currentCoating={coating}
                    handleClick={() => {
                      setCurrentProduct(undefined)
                      setCurrentCoating(undefined)
                      handleAddProduct("", "")
                      handleCloseClick(undefined)
                    }}
                    disabled={!product}
                    editMode={creation || editMode}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    ) : null
  ) : viewProducts ? (
    <Grid container direction="row" justifyContent="space-around" alignItems="center">
      <Grid item xs={3}>
        <CircularProgress size={30} />
      </Grid>
    </Grid>
  ) : null
}
