import React, { useEffect, useState, useCallback, useContext } from "react"

// Core components
import CircularProgress from "@material-ui/core/CircularProgress"
import { RootContext } from 'RootContext';
import $ from 'jquery';
import { titleCase } from "title-case"
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

// Pegaso components
import ErrorUtils from "components/Tools/ErrorUtils"
import TracerUtils from "components/Utils/TracerUtils"
import useIsMounted from "components/Utils/UseIsMounted"

export default function BlockerLoading(props) {
  const { getServicePort } = useContext(RootContext)
  const {
    JSON_tracerJson,
    JSON_serviceMessages,
    setServiceError,
    tracerEventRequest,
    shapeForBlocker,
    setTracerEventRequest,
    setIsSaving
  } = props

  const tracerJson = JSON.parse(JSON_tracerJson)
  const messages = JSON.parse(JSON_serviceMessages)
  const UrlTcpTracerNew = "http://127.0.0.1:"
  const PortaTcpTracerNew = getServicePort()
  const IncrementoSecondiCiclo = 1000;
  const MaxCicli = 60 * 3 * IncrementoSecondiCiclo;
  const [output, SetOutput] = useState(null)
  const [secondiReali, setSecondiReali] = useState(0);
  const [driverParms, setDriverParms] = useState(null)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [showCircularProgress, setShowCircularProgress] = useState(true)
  const [doEvent1, setDoEvent1] = useState(false)
  const [doEvent2, setDoEvent2] = useState(false)
  const isMounted = useIsMounted()
  /*
      USE CALLBACK
  */
  const ErroreServizio = useCallback((bottone, xhr, textStatus, error) => {
    // error[0] = Nessun Blocker Locale specificato
    // error[1] = PegasoDriver - Dispositivo non raggiungibile (servizio...)
    // error[2] = TimeOut - Nessuna forma ricevuta

    // Log Generico
    ErrorUtils.errorLog("TracerLoading", 'Service Error ' + bottone + ': xhr=' + xhr.statusText + ' textStatus=' + textStatus + ' error=' + error)
    var ErrorMess = '';
    var Sts = xhr.statusText;

    // Errore Servizio: Servizio Non Attivo
    if (Sts === 'error' && textStatus === 'error' && (error === '' || error === null)) {
      ErrorMess = messages.error[1];
    } else if (Sts !== '' || Sts !== null) {
      // Errore Servizio: Generico (se 'In Ricezione' non fare alert)
      //if (Sts.toString().substr(6,2) !== '31') {
      ErrorMess = Sts.toString();
      //}
    }

    // Se c� messaggio di errore f� alert e chiude finestra
    if (ErrorMess !== '') {
      setServiceError(ErrorMess)
    }
  }, [messages, setServiceError])

  const StatusServizio = useCallback((sts) => {
    switch (sts) {
      case 0:
        SetOutput(messages.status[0]) // Discconect
        break;
      case 1:
        SetOutput(messages.status[1]) // Connect
        break;
      case 2:
        SetOutput(messages.status[2]) // attesa invio dati
        break;
      case 3:
        SetOutput(messages.status[3]) // dati inviati
        break;
      default:
        break;
    }
  }, [messages, SetOutput])

  /*/disconnect-blocker
      MANUAL REQUEST
  */
  // POST disconnectAndRespond - Status 2
  const disconnectAndRespond = useCallback(() => {
    StatusServizio(0);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        blocker: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        blockerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1,
      }),
      dataType: 'json',
      success: function (response) {
        console.log("disconnectAndRespond -> SUCCESS");
        setTracerEventRequest(null)
        setIsSaving(false)
      },
      error: function (xhr, textStatus, error) {
        console.log("disconnectAndRespond -> ERROR");
        ErroreServizio('DISCONNECT', xhr, textStatus, error)
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, ErroreServizio, setTracerEventRequest, setIsSaving])


  // POST disconnectAndEnd  - Fine lettura dati
  const disconnectAndEnd = useCallback(() => {
    StatusServizio(3);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        blocker: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        blockerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1,
      }),
      dataType: 'json',
      success: function (response) {
        console.log("disconnectAndEnd -> SUCCESS");
        setTracerEventRequest(null)
        setIsSaving(false)
      },
      error: function (xhr, textStatus, error) {
        console.log("disconnectAndEnd -> ERROR");
        ErroreServizio('DISCONNECT', xhr, textStatus, error)
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, ErroreServizio, setTracerEventRequest, setIsSaving])

  // POST connectAndRespond - Status 3
  const connectAndRespond = useCallback(() => {
    StatusServizio(1);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/connect-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        blocker: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        blockerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1,
        shape: shapeForBlocker
      }),
      dataType: 'json',
      success: function (response) {
        console.log("connectAndRespond -> SUCCESS");
      },
      error: function (xhr, textStatus, error) {
        console.log("connectAndRespond -> ERROR");
        ErroreServizio('CONNECT', xhr, textStatus, error)
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, ErroreServizio, shapeForBlocker])

  // POST receiveAndRespond - Status 4
  const receiveAndRespond = useCallback(() => {
    StatusServizio(2);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/receive-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        blocker: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        blockerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1,
      }),
      dataType: 'json',
      success: function (response) {
        if (atob(response) === "OK") {
          setDoEvent1(false)
          setDoEvent2(false)
          StatusServizio(3);
          setShowCircularProgress(false)
          TracerUtils.sleep(5000).then(() => {
            disconnectAndRespond()
          });
        }
        console.log("receiveAndRespond -> SUCCESS");
      },
      error: function (xhr, textStatus, error) {
        console.log("receiveAndRespond -> ERROR");
        ErroreServizio('RECEIVE (Wait)', xhr, textStatus, error)
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, ErroreServizio, disconnectAndRespond])

  /*
      AUTOMATIC REQUEST
  */
  // POST receiveWaitModeAndRespond - Status 5
  const receiveWaitModeAndRespond = useCallback((seconds, handleSetSeconds) => {
    StatusServizio(2);
    if (isMounted()) {
      $.post({
        traditional: true,
        url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/receive-blocker`,
        contentType: 'application/json',
        crossDomain: true,
        data: JSON.stringify({
          blocker: driverParms.DescrizioneBlocker,
          codBlocker: driverParms.Blocker,
          comName: driverParms.PortaBlocker,
          blockerSetting: driverParms.TracerSettingJAVA,
          token1: driverParms.Token1,
        }),
        dataType: 'json',
        success: function (response) {
          if (atob(response) === "OK") {
            console.log("receiveAndRespond -> SUCCESS");
            setDoEvent1(false)
            setDoEvent2(false)
            StatusServizio(3);
            setShowCircularProgress(false)
            TracerUtils.sleep(5000).then(() => {
              disconnectAndEnd()
            });
          }
        },
        error: function (xhr, textStatus, error) {
          if (isMounted()) {
            handleSetSeconds(seconds + IncrementoSecondiCiclo)
          }
        }
      });
    }
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, IncrementoSecondiCiclo, isMounted, disconnectAndEnd])

  // CicloReceiveWaitMode - Status 6
  const CicloReceiveWaitMode = useCallback(() => {
    StatusServizio(2);
    setSecondiReali(IncrementoSecondiCiclo)
  }, [StatusServizio, setSecondiReali, IncrementoSecondiCiclo])

  // POST connectAndReceive - Status 7
  const connectAndReceive = useCallback(() => {
    StatusServizio(1);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/connect-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        blocker: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        blockerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1,
        shape: shapeForBlocker
      }),
      dataType: 'json',
      success: function (response) {
        console.log("connectAndRespond -> SUCCESS");
        CicloReceiveWaitMode();
      },
      error: function (xhr, textStatus, error) {
        console.log("connectAndRespond -> ERROR");
        ErroreServizio('CONNECT', xhr, textStatus, error)
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, CicloReceiveWaitMode, ErroreServizio, shapeForBlocker])

  // CicloConnect_Receive 
  const CicloConnect_Receive = useCallback(() => {
    setTimeout(connectAndReceive(), 300);
  }, [connectAndReceive])

  // POST allAndRespond - Status 8
  const allAndRespond = useCallback(() => {
    StatusServizio(0);
    $.post({
      traditional: true,
      url: `${UrlTcpTracerNew}${PortaTcpTracerNew}/disconnect-blocker`,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify({
        tracer: driverParms.DescrizioneBlocker,
        codBlocker: driverParms.Blocker,
        comName: driverParms.PortaBlocker,
        tracerSetting: driverParms.TracerSettingJAVA,
        token1: driverParms.Token1
      }),
      dataType: 'json',
      success: function (response) {
        CicloConnect_Receive();
      },
      error: function (xhr, textStatus, error) {
        CicloConnect_Receive();
      }
    });
  }, [driverParms, UrlTcpTracerNew, PortaTcpTracerNew, StatusServizio, CicloConnect_Receive])

  /*
      USE EFFECT
  */
  useEffect(() => {
    if (tracerJson) {
      if (!driverParms) {
        setDriverParms(
          {
            Token1: tracerJson.token1,
            Blocker: tracerJson.codTracer,
            PortaBlocker: tracerJson.comName,
            TracerSettingJAVA: tracerJson.tracerSetting,
            DescrizioneBlocker: tracerJson.tracer
          })
      }
    }
  }, [tracerJson, driverParms])

  useEffect(() => {
    if (tracerEventRequest) {
      setCurrentEvent(tracerEventRequest)
      setDoEvent1(true)
    }
  }, [tracerEventRequest])

  useEffect(() => {
    if (doEvent1) {
      switch (currentEvent) {
        case "ALL":
          allAndRespond()
          setDoEvent2(true)
          break;
        case "DISCONNECT":
          disconnectAndRespond();
          setDoEvent2(true)
          break;
        case "CONNECT":
          connectAndRespond()
          setDoEvent2(true)
          break;
        case "RECEIVE":
          receiveAndRespond()
          setDoEvent2(true)
          break;
        default:
          break;
      }
      setDoEvent1(false)
    }
  }, [allAndRespond, connectAndRespond, disconnectAndRespond, receiveAndRespond, currentEvent, doEvent1, IncrementoSecondiCiclo])

  useEffect(() => {
    if (driverParms) {
      if (driverParms.Blocker) {
        if (doEvent2) {
          if (currentEvent === "ALL") {
            if (secondiReali > 0) {
              if (secondiReali < MaxCicli) {
                TracerUtils.sleep(IncrementoSecondiCiclo).then(() => {
                  receiveWaitModeAndRespond(secondiReali, setSecondiReali);
                });
              } else {
                setServiceError(messages.error[2])
              }
            }
          }
        }
      }
    }
  }, [driverParms, doEvent2, secondiReali, MaxCicli, receiveWaitModeAndRespond, IncrementoSecondiCiclo, currentEvent, setServiceError, messages])

  return (<div>
    {output ? titleCase(output) : null}
    {showCircularProgress ?
      <CircularProgress />
      : <ThumbUpIcon />
    }
    <br />
  </div>)
}