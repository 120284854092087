import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../../RootContext"

// core components
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Paper from '@material-ui/core/Paper';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

// CSS
import { makeStyles } from '@material-ui/core/styles';

//Pegaso component
import { fetchWithToken } from "../../../components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import SingleSideShape from "views/LoadedShape/SingleSideShape"

export default function JobLoadedShape(props) {
  const {
    companyId,
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token
  } = useContext(RootContext)


  const { setOpenLoadedShape, onHandleClose, handleFrameUpload, handleNotesChangePrecal } = props

  const [isFetchingShapes, setIsFetchingShapes] = useState(true)
  const [shapes, setShapes] = useState([])

  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  const useStyles = makeStyles(() => ({
    paper: { borderRadius: 20, borderColor: "#000000", padding: 10 }
  }));

  const classesPaper = useStyles();

  //Aggiunge un foro cliccando
  const onClickPaper = (shapeId) => {
    var newShapeData = shapes.filter(option => option.shapeId === shapeId)[0]
    handleFrameUpload(newShapeData.shape.original, newShapeData.shape.standard, newShapeData.shape.edgingData, false)
    handleNotesChangePrecal(true)
    setOpenLoadedShape(false)
  }

  function ShowShapes() {
    return (
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {shapes.map((item) => (
          <Grid item xs={2}
            key={item.shapeId}
          >
            <Paper className={classesPaper.paper}
              variant="outlined"
              onClick={() => { onClickPaper(item.shapeId) }}
            >
              {item.shapeId + " - " + item.description}
              <SingleSideShape
                creation={false}
                edgingData={""}
                editMode={false}
                frameMode={true}
                isGlasant={false}
                jobId={item.shapeId}
                jobSoftware={""}
                offset={0}
                scaleX={4}
                scaleY={4}
                shape={item.shape.standard}
              />
            </Paper>
          </Grid>

        ))}
      </Grid>
    )
  }


  /**********************************************
   *  FETCH
   **********************************************/
  //Fetch delle forme
  const fetchShapes = useCallback(() => {
    if (isFetchingShapes === true) {
      fetchWithToken(`${apiUrl}/companies/${companyId}/listLoadedShape`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { shapes } = body
          setShapes(shapes)
          setIsFetchingShapes(false)
        })
        .catch(err => {
          setIsFetchingShapes(false)
          ErrorUtils.errorLog("RequiredField Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingShapes, refresh_token, setAccess_token,])

  /**********************************************
   *  USE EFFECT
   **********************************************/
  useEffect(() => {
    if (isFetchingShapes) {
      fetchShapes()
    }
  }, [isFetchingShapes, fetchShapes])

  return (
    isFetchingShapes ? (
      <CircularProgress size={42} style={{ color: "primary" }} />
    ) : (
      <div>
        {/* X CHIUSURA IN ALTO */}
        <ElevationScroll {...props}>
          <Box display="flex" flexDirection="row-reverse" p={1}>
            <Box p={1}>
              <IconButton
                aria-label={t("10238")}
                variant="outlined"
                onClick={onHandleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

        </ElevationScroll>
        <ShowShapes />
      </div>
    )

  )
}