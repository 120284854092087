import React, { Fragment } from "react"

// Core components
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

export default function DialogError(props) {
  const { dialogMsg, handleClose, open } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
      {open ? (
        <Fragment>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogContent>
            <DialogContent>
              <CircularProgress size={42} style={{ color: "primary" }} />
            </DialogContent>
          </DialogContent>
        </Fragment>
      )}
    </Dialog>
  )
}
