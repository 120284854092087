import JobUtils from "components/Utils/JobUtils"
import ShapeUtils from "components/Utils/ShapeUtils"

/*************************************************************************
                  VALORI PER SELECT
 *************************************************************************/

export default class EdgingDataUtils {
  static async convertSeghtToOcht(currentRightValues, currentLeftValues, currentStandardShape, currentEdgingData, currentEdgingValues, apiUrl, access_token, refresh_token, setAccess_token, t) {
    var differenzaDx = 30000
    var differenzaSx = 30000
    var nuovadifferenzaDx, nuovadifferenzaSx, RaggioYDx, RaggioYSx, puntoX, puntoY, sideShape
    var deltaDx, deltaSx, altezzaBoxDx, altezzaBoxSx
    var shapeConverted = JobUtils.GetStandardShape({ shape: { standard: currentStandardShape } })
    var shape = await ShapeUtils.extractRadiusFromOma(
      shapeConverted,
      currentEdgingData,
      apiUrl, access_token, refresh_token, setAccess_token, t
    )

    // const shape = result
    var valueConverted = ""
    var puntoXDX = ((currentRightValues.hbox / 2) + (currentEdgingValues.dbl / 2)) - currentRightValues.ipd
    var puntoXSX = ((currentLeftValues.hbox / 2) + (currentEdgingValues.dbl / 2)) - currentLeftValues.ipd
    sideShape = shape.right.points

    //LENTE DESTRA
    for (var j = 0; j < sideShape.length - 1; j = j + 2) {
      puntoX = sideShape[j]
      puntoY = sideShape[j + 1]
      if (parseFloat(currentRightValues.ipd) > ((currentRightValues.hbox / 2) + (currentEdgingValues.dbl / 2))) {
        if (j >= 400 && j <= 599) {
          nuovadifferenzaDx = (Math.abs(puntoXDX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaDx = Math.abs(nuovadifferenzaDx)
          if (nuovadifferenzaDx < differenzaDx) {
            differenzaDx = nuovadifferenzaDx
            RaggioYDx = puntoY
          }
        }
      } else {
        if (j >= 600 && j <= 799) {
          nuovadifferenzaDx = (Math.abs(puntoXDX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaDx = Math.abs(nuovadifferenzaDx)

          if (nuovadifferenzaDx < differenzaDx) {
            differenzaDx = nuovadifferenzaDx
            RaggioYDx = puntoY
          }
        }
      }
    }

    //LENTE SINISTRA
    sideShape = shape.left.points
    for (var k = 0; k < sideShape.length - 1; k = k + 2) {
      puntoX = sideShape[k]
      puntoY = sideShape[k + 1]
      if (parseFloat(currentLeftValues.ipd) > ((currentLeftValues.hbox / 2) + (currentEdgingValues.dbl / 2))) {
        if (k >= 600 && k <= 799) {
          nuovadifferenzaSx = (Math.abs(puntoXSX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaSx = Math.abs(nuovadifferenzaSx)
          if (nuovadifferenzaSx < differenzaSx) {
            differenzaSx = nuovadifferenzaSx
            RaggioYSx = puntoY
          }
        }
      } else {
        if (k >= 400 && k <= 599) {
          nuovadifferenzaSx = (Math.abs(puntoXSX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaSx = Math.abs(nuovadifferenzaSx)
          if (nuovadifferenzaSx < differenzaSx) {
            differenzaSx = nuovadifferenzaSx
            RaggioYSx = puntoY
          }
        }
      }
    }
    
    deltaDx = (parseFloat(currentRightValues.vbox / 2)) - (Math.abs(RaggioYDx / 100))
    deltaSx = (parseFloat(currentLeftValues.vbox / 2)) - (Math.abs(RaggioYSx / 100))
    altezzaBoxDx = (parseFloat(currentRightValues._seght) + parseFloat(deltaDx)).toFixed(2)
    altezzaBoxSx = (parseFloat(currentLeftValues._seght) + parseFloat(deltaSx)).toFixed(2)
    if (!isNaN(altezzaBoxDx) || !isNaN(altezzaBoxSx)) {
      valueConverted = "OCHT="
      if (!isNaN(altezzaBoxDx)) valueConverted += altezzaBoxDx
      valueConverted += ";"
      if (!isNaN(altezzaBoxSx)) valueConverted += altezzaBoxSx
    }
    return valueConverted
  }

  static async convertOchtToSeght(currentRightValues, currentLeftValues, currentStandardShape, currentEdgingData, currentEdgingValues, apiUrl, access_token, refresh_token, setAccess_token, t) {
    var differenzaDx = 30000
    var differenzaSx = 30000
    var nuovadifferenzaDx, nuovadifferenzaSx, RaggioYDx, RaggioYSx, puntoX, puntoY, sideShape
    var deltaDx, deltaSx, altezzaBordoDx, altezzaBordoSx
    var shapeConverted = JobUtils.GetStandardShape({ shape: { standard: currentStandardShape } })
    var shape = await ShapeUtils.extractRadiusFromOma(
      shapeConverted,
      currentEdgingData,
      apiUrl, access_token, refresh_token, setAccess_token, t
    )

    // const shape = result
    var valueConverted = ""
    var puntoXDX = ((currentRightValues.hbox / 2) + (currentEdgingValues.dbl / 2)) - currentRightValues.ipd
    var puntoXSX = ((currentLeftValues.hbox / 2) + (currentEdgingValues.dbl / 2)) - currentLeftValues.ipd
    sideShape = shape.right.points

    //LENTE DESTRA
    for (var j = 0; j < sideShape.length - 1; j = j + 2) {
      puntoX = sideShape[j]
      puntoY = sideShape[j + 1]
      if (parseFloat(currentRightValues.ipd) > ((currentRightValues.hbox / 2) + (currentEdgingValues.dbl / 2))) {
        if (j >= 400 && j <= 599) {
          nuovadifferenzaDx = (Math.abs(puntoXDX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaDx = Math.abs(nuovadifferenzaDx)
          if (nuovadifferenzaDx < differenzaDx) {
            differenzaDx = nuovadifferenzaDx
            RaggioYDx = puntoY
          }
        }
      } else {
        if (j >= 600 && j <= 799) {
          nuovadifferenzaDx = (Math.abs(puntoXDX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaDx = Math.abs(nuovadifferenzaDx)

          if (nuovadifferenzaDx < differenzaDx) {
            differenzaDx = nuovadifferenzaDx
            RaggioYDx = puntoY
          }
        }
      }
    }

    //LENTE SINISTRA
    sideShape = shape.left.points
    for (var k = 0; k < sideShape.length - 1; k = k + 2) {
      puntoX = sideShape[k]
      puntoY = sideShape[k + 1]
      if (parseFloat(currentLeftValues.ipd) > ((currentLeftValues.hbox / 2) + (currentEdgingValues.dbl / 2))) {
        if (k >= 600 && k <= 799) {
          nuovadifferenzaSx = (Math.abs(puntoXSX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaSx = Math.abs(nuovadifferenzaSx)
          if (nuovadifferenzaSx < differenzaSx) {
            differenzaSx = nuovadifferenzaSx
            RaggioYSx = puntoY
          }
        }
      } else {
        if (k >= 400 && k <= 599) {
          nuovadifferenzaSx = (Math.abs(puntoXSX) * 100) - (Math.abs(puntoX))
          nuovadifferenzaSx = Math.abs(nuovadifferenzaSx)
          if (nuovadifferenzaSx < differenzaSx) {
            differenzaSx = nuovadifferenzaSx
            RaggioYSx = puntoY
          }
        }
      }
    }
    
    deltaDx = (parseFloat(currentRightValues.vbox / 2)) - (Math.abs(RaggioYDx / 100))
    deltaSx = (parseFloat(currentLeftValues.vbox / 2)) - (Math.abs(RaggioYSx / 100))
    altezzaBordoDx = (parseFloat(currentRightValues.ocht) + parseFloat(deltaDx)).toFixed(2)
    altezzaBordoSx = (parseFloat(currentLeftValues.ocht) + parseFloat(deltaSx)).toFixed(2)
    if (!isNaN(altezzaBordoDx) || !isNaN(altezzaBordoSx)) {
      valueConverted = "_SEGHT="
      if (!isNaN(altezzaBordoDx)) valueConverted += altezzaBordoDx
      valueConverted += ";"
      if (!isNaN(altezzaBordoSx)) valueConverted += altezzaBordoSx
    }
    return valueConverted
  }
}
