//Pannello per la scelta del device
import React, { useContext, useEffect, } from "react"
import { RootContext } from "../../RootContext"

//core components
import Autocomplete from '@material-ui/lab/Autocomplete';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useDeviceStylesText } from "styles/device/deviceCss.js"

//Componenti Pegaso
import DeviceUtils from "components/Utils/DeviceUtils"

export default function DownloadOption(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    downloadLabel,
    downloadOption,
    setChangeDevice,
    setOpenPanelOption,
    setDownloadLabel,
    setDownloadOption
  } = props

  const classesText = useDeviceStylesText()

  const omaLabels = DeviceUtils.omaLabels
  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    if (changeDevice) {
      setOpenPanelOption(false)
      setDownloadLabel(downloadOption.omaLabels)
      //setDownloadOption(downloadOption)
      setChangeDevice(false)
    }
  }, [changeDevice, downloadOption.omaLabels, setChangeDevice, setOpenPanelOption, setDownloadLabel, setDownloadOption])


  //Cambia field
  const handleChange = (name) => (event) => {
    let value = event.target.value.trim()
    value = (value.length === 0 ? 0 : parseInt(value))
    setDownloadOption({ ...downloadOption, [name]: value })
  }


  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"reqDownload"}
            label={t("10280")}
            className={classesText.textField}
            value={downloadOption.req}
            margin="normal"
            onChange={handleChange("req")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtOptbinDownload"}
            label={t("10281")}
            className={classesText.textField}
            value={downloadOption.trcfmtbin}
            margin="normal"
            onChange={handleChange("trcfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"trcfmtpointsDownload"}
            label={t("10282")}
            className={classesText.textField}
            value={downloadOption.trcfmtpoints}
            margin="normal"
            onChange={handleChange("trcfmtpoints")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtbinDownload"}
            label={t("10283")}
            className={classesText.textField}
            value={downloadOption.zfmtbin}
            margin="normal"
            onChange={handleChange("zfmtbin")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id={"zfmtpointsDownload"}
            label={t("10284")}
            className={classesText.textField}
            value={downloadOption.zfmtpoints}
            margin="normal"
            onChange={handleChange("zfmtpoints")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple
            id="omaLabels"
            options={omaLabels}
            getOptionLabel={(option) => option}
            value={downloadLabel}
            onChange={(event, newValue) => {
              setDownloadLabel(newValue)
              setDownloadOption({
                ...downloadOption, omaLabels:
                  newValue
              });
            }}
            disableCloseOnSelect
            /*renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Multiple values"
                placeholder="Favorites"
              />
            )}*/
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="omaLabels" placeholder={t("10285")} />
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}