import React, { useContext } from 'react'
import { RootContext } from 'RootContext'

// COre
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Pegaso Components
import FilterDatePicker from 'components/Filter/FilterDatePicker'
import FilterSelect from 'components/Filter/FilterSelect'
import FilterTextField from "components/Filter/FilterTextField"
import { emptyValue } from 'variables/statisticFilters'
import { useStatisticToolbarStyles, useStatisticToolbarDatePickerStyles, useStatisticToolbarSelectStyles, useStatisticToolbarTextFieldStyles } from 'styles/statistics/statisticToolbarCss'

// Toolbar -> FILTERS
function ToolbarFilters(props) {
    const { t } = useContext(RootContext)
    const { allBrandsDevices, filter, handleCancelFetch, handleChangeFilter, models } = props; // maxDays
    const classStatisticToolbarSelectStyles = useStatisticToolbarSelectStyles()

    const selectOptionEmpty = [emptyValue]
    const selectOptionBrands = selectOptionEmpty.concat(allBrandsDevices.length > 0 ? allBrandsDevices.map(option => {
        return {
            value: option.brandId,
            text: option.brandId + " - " + option.brand
        }
    }) : [])
    const selectOptionModels = selectOptionEmpty.concat(models.length > 0 ? models.map(option => {
        return {
            value: option.modelId,
            text: option.modelId + " - " + option.description
        }
    }) : [])

    const selectOptionResultType = [
        {
            "value": "days",
            "text": t("10024")
        },
        {
            "value": "weeks",
            "text": t("10145")
        },
        {
            "value": "months",
            "text": t("10076")
        },
    ]

    const showAllFilter = false     // DA ELIMINARE in PARTE 2

    return (
        <div style={{ width: '100%', padding: '4px' }}>
            <Grid container bgcolor="background.paper"
                direction="row"
                justifyContent="flex-start"
                alignItems="center">

                {/* 1 RIGA */}
                <Grid item xs={2}>
                    <FilterDatePicker
                        id="searchFromDate"
                        classes={useStatisticToolbarDatePickerStyles()}
                        label={t("10041")}
                        defaultValue={filter.fromDate}
                        handleClick1={handleCancelFetch}
                        handleChange={handleChangeFilter}
                        type="date"
                        filterField={"fromDate"} />
                </Grid>
                <Grid item xs={2}>
                    <FilterDatePicker
                        id="searchToDate"
                        classes={useStatisticToolbarDatePickerStyles()}
                        label={t("10136")}
                        defaultValue={filter.toDate}
                        handleClick1={handleCancelFetch}
                        handleChange={handleChangeFilter}
                        type="date"
                        filterField={"toDate"} />
                </Grid>
                <Grid item xs={2}>
                    {showAllFilter ? <FilterSelect
                        classes={classStatisticToolbarSelectStyles}
                        id="searchResultType"
                        label={t("10110")}
                        defaultValue={filter.resultType}
                        handleClick1={handleCancelFetch}
                        handleChange1={handleChangeFilter}
                        options={selectOptionResultType}
                        filterField={"resultType"} />
                        : null}
                </Grid>

                <Grid item xs={2}>
                    <FilterTextField
                        classes={useStatisticToolbarTextFieldStyles()}
                        id="searchOptician"
                        label={t("10092") + " (" + t("10046") + ")"}
                        defaultValue={filter.optician}
                        handleClick1={handleCancelFetch}
                        handleChange1={handleChangeFilter}
                        filterField={"optician"}
                    />
                </Grid>

                {/* 2 RIGA */}
                <Grid item xs={2}>
                    {showAllFilter ? <FilterSelect
                        classes={classStatisticToolbarSelectStyles}
                        id="searchBrand"
                        label={t("10332")}
                        handleChange1={handleChangeFilter}
                        options={selectOptionBrands}
                        filterField={"brand"}
                        value={filter.brand}
                    /> : null}
                </Grid>
                <Grid item xs={2}>
                    {showAllFilter ? <FilterSelect
                        classes={classStatisticToolbarSelectStyles}
                        id="searchModel"
                        label={t("10075")}
                        handleClick1={handleCancelFetch}
                        handleChange1={handleChangeFilter}
                        options={selectOptionModels}
                        filterField={"model"}
                        value={filter.model}
                    /> : null}
                </Grid>
            </Grid>
        </div >
    );
}

export default function EdgingStatisticToolbar(props) {
    const classes = useStatisticToolbarStyles();
    const { allBrandsDevices, filter, handleCancelFetch, handleChangeFilter, maxDays, models } = props;

    return (
        <div >
            <Paper className={classes.paper}>
                <ToolbarFilters
                    allBrandsDevices={allBrandsDevices}
                    filter={filter}
                    handleCancelFetch={handleCancelFetch}
                    handleChangeFilter={handleChangeFilter}
                    maxDays={maxDays}
                    models={models}
                />
            </Paper>
        </div>
    );
};
