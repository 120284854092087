import React, { useState, useEffect, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

// core components
import AccessTime from "@material-ui/icons/AccessTime"
import AddIcon from "@material-ui/icons/Add"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import RefreshIcon from "@material-ui/icons/Refresh"
import Table from "@material-ui/core/Table"
import TimeAgo from "react-timeago"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { useSnackbar } from "notistack"
import { titleCase } from "title-case"
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

// pegaso views/components
import DatesUtils from "components/Tools/DatesUtils"
import DialogError from "components/Dialog/DialogError"
import ErrorUtils from "components/Tools/ErrorUtils"
import Job from "views/Job/Job"
import JobManualJob from "views/Job/JobManualJob"
import JobRoutingSqs from "views/Job/JobRouting/JobRoutingSqs"
import JobRoutingHub from "views/Job/JobRouting/JobRoutingHub"
import JobRestoreExportExternalSystem from "views/Job/JobRestore/JobRestoreExportExternalSystem"
import JobsToolbar from "views/Jobs/JobsToolbar"
import JobsTableHead from "views/Jobs/JobsTableHead"
import JobsTableBody from "views/Jobs/JobsTableBody"
import JobsTableFooter from "views/Jobs/JobsTableFooter"
import JobToBlocker from "views/Blocker/JobToBlocker"
import JobsUtils from "components/Utils/JobsUtils"
import StringUtils from "components/Tools/StringUtils"
import { fetchWithToken, refreshToken } from "components/Fetch/api-fetch"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useJobsTableStyles } from "styles/jobs/jobsTableCss"

// Job Table -> TABLE
export default function Jobs() {
  const classes = useJobsStyles()
  const classesTable = useJobsTableStyles()
  const {
    companyId,
    mqttClient,
    t,
    apiUrl,
    getLab,
    getOptician,
    getUtc,
    getManualJobActive,
    access_token,
    refresh_token,
    setAccess_token,
    isLabUser,
  } = useContext(RootContext)
  const [jobs, setJobs] = useState([])
  const [mqttJob, setMqttJob] = useState()
  const [jobsFiltered, setJobsFiltered] = useState(0)
  const [fetching, setFetching] = useState({
    cancel: false,
    isFetching: false,
    error: false,
  })
  const [globalReader, setGlobalReader] = useState()
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [filter, setFilter] = useState({
    jobId: "",
    fromDate: DatesUtils.getDaysAgoDate("-", 30),
    toDate: DatesUtils.getCurrentDate("-"),
    opticianView: "",
    note: "",
    optcst: "",
    software: "",
    status: "",
    year: year,
    createdBy: "",
  })

  const [loading, setLoading] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  // Compontents of table
  const [order, setOrder] = useState("desc")
  const [updateAgo, setUpdateAgo] = useState()
  const [orderBy, setOrderBy] = useState("")
  const [jobsSelected, setJobsSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [startMqtt, setStartMqtt] = useState(false)
  const isSelected = (jobId) => jobsSelected.indexOf(jobId) !== -1
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, jobs.length - page * rowsPerPage)
  const [openJob, setOpenJob] = useState(false)
  const [creation, setCreation] = useState(false)
  const [jobData, setJobData] = React.useState("")
  const { labId, internalOpticianId, internalOpticianName } = getLab()
  const [newJobId, setNewJobId] = React.useState()
  const [actionFetch, setActionFetch] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMsg, setDialogMsg] = useState("")
  const [openJobRoutingSqs, setOpenJobRoutingSqs] = useState(false)
  const [openJobRoutingHub, setOpenJobRoutingHub] = useState(false)
  const [openJobToBlocker, setOpenJobToBlocker] = useState(false)
  const [openJobManualJob, setOpenJobManualJob] = useState(false)
  const [openJobRestore, setOpenJobRestore] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarJobId, setSnackBarJobId] = useState(undefined)
  const [routingSqs, setRoutingSqs] = useState({
    jobId: "",
    year: "",
  })
  const [routingHub, setRoutingHub] = useState({
    jobId: "",
    year: "",
  })
  const [toBlocker, setToBlocker] = useState({
    jobId: "",
    year: "",
  })
  const [manualJobId, setManualJobId] = useState("")
  const [isJobRestored, setIsJobRestored] = useState(false)

  let opticianId = ""
  const optician = getOptician()
  if (optician) {
    opticianId = optician.opticianId
  }

  // Click refresh
  const handleClickRefresh = useCallback(() => {
    setStartMqtt(false)
    setLoading(true)
    setJobs([])
    setFetching({ ...fetching, isFetching: false })
  }, [fetching])

  /*
   **      HANDLE EVENTS - FETCH (USATE DENTRO USE EFFECT)
   */

  const checkStreamingToken = useCallback((elems) => {
    let result = true
    if (elems.length > 0) {
      let elemsFiltered = elems.filter((elem) => {
        const { error } = elem
        if (error) {
          if (error === "token_expired") {
            return elem
          } else {
            return null
          }
        } else {
          return null
        }
      })
      if (elemsFiltered.length > 0) {
        result = false
      }
    }
    return result
  }, [])

  // Fetch jobs in streaming
  const fetchJobsStream = useCallback(
    (url) => {
      let streamingJobs = []
      let currentI = 0
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        streaming: true,
      })
        .then((response) => response.body)
        .then((body) => {
          var reader = body.getReader()
          setGlobalReader(reader)
          const stream = new ReadableStream({
            start(controller) {
              // The following function handles each data chunk
              function push() {
                // "done" is a Boolean and value a "Uint8Array"
                reader.read().then(({ done, value }) => {
                  // Is there no more data to read?
                  if (done) {
                    // Check if token is not expired
                    if (checkStreamingToken(streamingJobs)) {
                      setJobs(streamingJobs)
                      setLoading(false)
                    } else {
                      // Refresh Token
                      setLoading(true)
                      refreshToken()
                      handleClickRefresh()
                    }

                    // Tell the browser that we have finished sending data
                    controller.close()
                    return
                  }
                  // Get the data and send it to the browser via the controller
                  controller.enqueue(value)
                  const byteString = StringUtils.stringFromUTF8Array(value)
                  if (byteString === "EMPTY") {
                    setJobs([])
                    setLoading(false)
                  } else {
                    const currentStream = StringUtils.decodeJsonStreamingChunk(
                      value
                    )
                    currentI = currentI + currentStream.length
                    streamingJobs = streamingJobs.concat(currentStream)

                    if (currentI < rowsPerPage) {
                      setJobs(streamingJobs)
                      setLoading(false)
                    }
                  }
                  push()
                })
              }

              push()
            },
          })

          return new Response(stream, {
            headers: { "Content-Type": "text/html" },
          })
        })
        .catch((err) => {
          // Se Job non esiste
          setLoading(false)
          ErrorUtils.errorLog("Fetch Job Error", err)
        })
    },
    [
      access_token,
      apiUrl,
      checkStreamingToken,
      companyId,
      refresh_token,
      rowsPerPage,
      setAccess_token,
      handleClickRefresh
    ]
  )

  // Fetch jobs filtrando per data (streaming)
  const fetchJobsInDate = useCallback(() => {
    const url = opticianId
      ? `${apiUrl}/companies/${companyId}/opticians/${opticianId}/jobs?fromDate=${filter.fromDate}&toDate=${filter.toDate}`
      : `${apiUrl}/companies/${companyId}/labs/${labId}/jobs?fromDate=${filter.fromDate}&toDate=${filter.toDate}`
    if (fetching.isFetching === false) {
      setFetching({ ...fetching, isFetching: true })
      setPage(0)
      fetchJobsStream(url)
      setUpdateAgo(Date.now())
    }
  }, [
    apiUrl,
    companyId,
    fetchJobsStream,
    fetching,
    filter.fromDate,
    filter.toDate,
    labId,
    opticianId,
  ])

  // Fetch singolo job (no streaming)
  const fetchSingleJob = useCallback(() => {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${filter.jobId}?year=${filter.year}`
    if (fetching.isFetching === false) {
      setFetching({ ...fetching, isFetching: true })
      setPage(0)

      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          // Se il Job esiste
          setLoading(false)
          let singleJob = []
          let validOptician = false

          // Verifica che OpticianId sia ok per quel job.Optician.id
          if (data.jobId) {
            if (isLabUser()) {
              validOptician = true
            } else if (data.optician.id) {
              if (data.optician.id === opticianId) {
                validOptician = true
              }
            }
          }

          if (validOptician) {
            setJobs(singleJob.concat(data))
          } else {
            // Se Job non valido mette vuoto
            setJobs(singleJob)
          }
        })
        .catch((err) => {
          // Se Job non esiste
          setLoading(false)
          ErrorUtils.errorLog("Fetch Job Error", err)
        })

      setUpdateAgo(Date.now())
    }
  }, [
    access_token,
    apiUrl,
    companyId,
    fetching,
    filter.jobId,
    filter.year,
    isLabUser,
    labId,
    opticianId,
    refresh_token,
    setAccess_token,
  ])

  // Connect to broker Mqtt for Job-add in real time
  const connectMqtt = useCallback(() => {
    // Mqtt doMessage -> Handler che gestisce il Job che ricevo via mqtt
    mqttClient.doMessage(
      (topic, message) => {
        //console.log(new Date() + " Mqtt doMessage - Message received: " + topic);
        const arraybufferToString = new TextDecoder("utf-8").decode(message)
        const jobMqtt = StringUtils.decodeJsonStreamingChunkFromString(
          arraybufferToString
        )[0]
        setMqttJob(jobMqtt)
      },
      companyId,
      labId,
      opticianId,
      isLabUser()
    )
  }, [companyId, mqttClient, labId, opticianId, isLabUser])

  /*
   **      USE EFFECT
   */

  // 1 Connette a Mqtt in automatico per la ricezione di nuovi jobs
  useEffect(() => {
    if (!startMqtt) {
      connectMqtt()
      setStartMqtt(true)
    }
  }, [startMqtt, connectMqtt])

  // 2 Se filtro Job/data -> Fetch dei jobs nella lista
  useEffect(() => {
    // UseEffect -> fetching
    if (fetching.cancel) {
      if (globalReader) {
        globalReader.cancel()
      }
      setFetching({
        ...fetching,
        cancel: false,
        isFetching: false,
      })
    }

    // UseEffect -> filter
    if (filter.jobId) {
      // Job singolo
      setJobs([])
      if (filter.jobId && filter.year) {
        fetchSingleJob()
      }
    } else {
      if (filter.fromDate && filter.toDate) {
        if (filter.fromDate !== "" && filter.toDate !== "") {
          setJobs([])
          // Periodo di Jobs in streaming (stesso anno)
          if (filter.toDate.substr(0, 4) === filter.fromDate.substr(0, 4)) {
            if (filter.fromDate <= filter.toDate) {
              fetchJobsInDate()
            } else {
              setLoading(false)
            }
          } else {
            setLoading(false)
          }
        }
      }
    }
  }, [
    fetchJobsInDate,
    fetchSingleJob,
    fetching,
    filter.fromDate,
    filter.jobId,
    filter.toDate,
    filter.year,
    globalReader,
  ])

  // 3  Se arriva un Job Mqtt -> Aggiungo il Job alla lista
  useEffect(() => {
    if (mqttJob) {
      if (!filter.jobId) {
        const from = filter.fromDate
        const to = filter.toDate
        if (from && to) {
          if (from !== "" && to !== "") {
            const jobMqttDate = DatesUtils.formatDateToString(
              JobsUtils.formatJobCreationDateFromString(mqttJob),
              false,
              false
            )
            if (jobMqttDate >= from && jobMqttDate <= to) {
              if (mqttJob.year === parseInt(from.substr(0, 4))) {
                mqttJob.createdOn = mqttJob.created.createdOn
                const checkJob = (a, b) => a.jobId === b.jobId
                const jobFound = jobs.find((job) => checkJob(job, mqttJob))
                const newJobs = jobFound
                  ? jobs.map((job) => (checkJob(job, mqttJob) ? mqttJob : job))
                  : [mqttJob].concat(
                    jobs.filter((job) => !checkJob(job, mqttJob))
                  )
                const oldLength = jobs.length
                setJobs(newJobs)
                if (newJobs.length !== oldLength) {
                  setOpenSnackBar(true)
                  setSnackBarJobId(mqttJob ? mqttJob.jobId : "")
                  // enqueueSnackbar(
                  //   ` ${t("10141")} ${mqttJob ? mqttJob.jobId : ""}`
                  // )
                }
              }
            }
          }
        }
      }
    }
    setUpdateAgo(Date.now())
    return () => {
      setMqttJob(undefined)
    }
  }, [
    mqttJob,
    enqueueSnackbar,
    jobs,
    t,
    filter.jobId,
    filter.fromDate,
    filter.toDate,
  ])

  /*
   **      HANDLE EVENTS - FETCH
   */

  // Fetch Open job
  function fetchJobGet(year, jobId) {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}?year=${year}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se il Job esiste
        setActionFetch(false)
        setJobData(data)
      })
      .catch((err) => {
        // Se Job non esiste
        setActionFetch(false)
        setJobData("")
        setOpenJob(false)
        setDialogMsg(t("35"))
        setOpenDialog(true)
        ErrorUtils.errorLog("Fetch Job Error", err)
      })
  }

  // Fetch New job
  function fetchJobNew() {
    setNewJobId(undefined)
    const manualJobActive = getManualJobActive()

    if (manualJobActive && isLabUser()) {
      // Job Id MANUALE
      setActionFetch(false)
      setManualJobId("")
      setOpenJobManualJob(true)
    } else {
      // Job Id AUTOMATICO
      const url = `${apiUrl}/companies/${companyId}/labs/${labId}/externalJobId`
      fetchWithToken(url, {
        method: "POST",
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          // Se il Job esiste
          const { externalJobId } = data
          setActionFetch(false)
          setNewJobId(externalJobId.toString())
          setOpenJob(true)
        })
        .catch((err) => {
          // Se Job non esiste
          setActionFetch(false)
          setJobData("")
          setOpenJob(false)
          setDialogMsg(t("36"))
          setOpenDialog(true)
          ErrorUtils.errorLog("Fetch externalJobId Error", err)
        })
    }
  }


  // Fetch Clone job
  function fetchJobClone(year, jobId) {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/externalJobId`
    fetchWithToken(url, {
      method: "POST",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se il Job esiste
        const { externalJobId } = data

        const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}?year=${year}`
        fetchWithToken(url, {
          method: "GET",
          apiUrl: apiUrl,
          companyId: companyId,
          access_token: access_token,
          refresh_token: refresh_token,
          setAccess_token: setAccess_token,
        })
          .then((response) => {
            return response.body // data
          })
          .then((job) => {
            // 2020/02/27 - Lab prende proprio opticianId, Optician mantiene il proprio opticianI
            if (!optician) {
              job.optician = {
                id: internalOpticianId,
                name: internalOpticianName,
              }
            }

            if (!job.notes.lensProduct) {
              job.notes.lensProduct = ""
            }

            if (!job.notes.coating) {
              job.notes.coating = ""
            }

            if (job.notes.lensProduct !== "") {
              var lensProduct = job.notes.lensProduct;
              if (lensProduct.indexOf("[") >= 0 && lensProduct.indexOf("]") > 0) {
                var lensProductCode = lensProduct.substr(1, lensProduct.indexOf("]") - 1)
                const url = `${apiUrl}/companies/${companyId}/product?lensFamilyExternalCode=${lensProductCode}`
                fetchWithToken(url, {
                  method: "GET",
                  apiUrl: apiUrl,
                  companyId: companyId,
                  access_token: access_token,
                  refresh_token: refresh_token,
                  setAccess_token: setAccess_token,
                })
                  .then((response) => {
                    // Se il prodotto esiste
                    setNewJobId(externalJobId.toString())
                    setActionFetch(false)
                    setJobData(job)
                  })
                  .catch((err) => {
                    //Se il prodotto non esiste
                    if (err.status === 404 && err.body.code === "E4") {
                      job.notes.lensProduct = ""
                      setNewJobId(externalJobId.toString())
                      setActionFetch(false)
                      setJobData(job)
                    }
                    else {
                      setActionFetch(false)
                      setJobData("")
                      setOpenJob(false)
                      setDialogMsg(t("37"))
                      setOpenDialog(true)
                      ErrorUtils.errorLog("Fetch Job Error", err)
                    }
                  })
              }
              else {
                // Se il Job esiste
                setNewJobId(externalJobId.toString())
                setActionFetch(false)
                setJobData(job)
              }
            }
            else {
              // Se il Job esiste
              setNewJobId(externalJobId.toString())
              setActionFetch(false)
              setJobData(job)
            }
          })
          .catch((err) => {
            // Se Job non esiste
            setActionFetch(false)
            setJobData("")
            setOpenJob(false)
            setDialogMsg(t("37"))
            setOpenDialog(true)
            ErrorUtils.errorLog("Fetch Job Error", err)
          })
      })
      .catch((err) => {
        // Se Job non esiste
        setActionFetch(false)
        setJobData("")
        setOpenJob(false)
        setDialogMsg(t("37"))
        setOpenDialog(true)
        ErrorUtils.errorLog("Fetch externalJobId Error", err)
      })
  }

  //Ripristina ordine per external system
  function fetchRestoreJob(year, jobId) {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}/restoreForExternalSystem?year=${year}`
    fetchWithToken(url, {
      method: "POST",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        setIsJobRestored(true)
        setActionFetch(false)
      })
      .catch((err) => {
        let errorMessage = err.body.error
        setActionFetch(false)
        setIsJobRestored(false)
        ErrorUtils.errorLog("Fetch Job Error", errorMessage)
      })
  }


  /*
   **      HANDLE EVENTS - CLICK
   */

  // Cancels Fecth if Filter changes
  function handleCancelFetch() {
    setFetching({ ...fetching, cancel: true })
  }

  // Click when change data con Filter
  function handleChangeFilter(filterField, targetValue) {
    setLoading(true)
    if (
      filterField === "fromDate" ||
      filterField === "toDate" ||
      filterField === "jobId"
    ) {
      setJobs([])
    }
    setFilter({ ...filter, [filterField]: targetValue })
    setPage(0)
  }
  // Click reset Filters
  function handleClickResetFilters() {
    setFilter({
      jobId: "",
      fromDate: DatesUtils.getDaysAgoDate("-", 30),
      toDate: DatesUtils.getCurrentDate("-"),
      opticianView: "",
      note: "",
      software: "",
      status: "",
      year: new Date().getFullYear(),
      createdBy: "",
      statusView: "",
    })
    handleClickRefresh()
  }

  // Click sort by coloumn
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc"
    setOrder(isDesc ? "asc" : "desc")
    setOrderBy(property)
  }

  // Click row goes selected
  function handleClickRow(event, jobId) {
    const selectedIndex = jobsSelected.indexOf(jobId)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(jobId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(jobsSelected.slice(1))
    } else if (selectedIndex === jobsSelected.length - 1) {
      newSelected = newSelected.concat(jobsSelected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        jobsSelected.slice(0, selectedIndex),
        jobsSelected.slice(selectedIndex + 1)
      )
    }

    setJobsSelected(newSelected)
  }

  // Click change page
  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  // Click rows per page
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    handleClickRefresh()
  }

  // Click Close Job
  function handleClickClose() {
    setJobData("")
    setOpenJob(false)
    setCreation(false)
  }

  // Click Open Job
  function handleClickOpen(event, year, jobId) {
    setOpenJob(true)
    setYear(year)
    setActionFetch(true)
    setCreation(false)
    fetchJobGet(year, jobId)
  }

  // Click Open New Job
  function handleClickOpenNew() {
    setYear(new Date().getFullYear())
    setActionFetch(true)
    setCreation(true)
    fetchJobNew()
  }

  // Click Clone Job
  function handleOpenClone(event, year, jobId) {
    setYear(new Date().getFullYear())
    setActionFetch(true)
    setOpenJob(true)
    fetchJobClone(year, jobId)
    setCreation(true)
  }

  // Click Close Job Routing SQS
  function handleClickCloseRoutingSqs() {
    setRoutingSqs({
      year: "",
      jobId: "",
    })
    setOpenJobRoutingSqs(false)
  }

  // Click Routing Job Routing SQS
  function handleOpenRoutingSqs(event, year, jobId) {
    setRoutingSqs({
      year: year,
      jobId: jobId,
    })
    setOpenJobRoutingSqs(true)
  }

  // Click Close Job Routing HUB
  function handleClickCloseRoutingHub() {
    setRoutingHub({
      year: "",
      jobId: "",
    })
    setOpenJobRoutingHub(false)
  }

  // Click Routing Job Routing HUB
  function handleOpenRoutingHub(event, year, jobId) {
    setRoutingHub({
      year: year,
      jobId: jobId,
    })
    setOpenJobRoutingHub(true)
  }

  // Click Close Job TO BLOCKER
  function handleClickCloseToBlocker() {
    setToBlocker({
      year: "",
      jobId: "",
    })
    setOpenJobToBlocker(false)
  }

  // Click Job TO BLOCKER
  function handleOpenToBlocker(event, year, jobId) {
    setToBlocker({
      year: year,
      jobId: jobId,
    })
    setOpenJobToBlocker(true)
  }

  // Click Close Job Routing HUB
  function handleClickCloseManualJob() {
    setOpenJobManualJob(false)
  }

  // Click Restore Job for external system
  function handleOpenRestoreJob(event, year, jobId) {
    setActionFetch(true)
    fetchRestoreJob(year, jobId)
    setOpenJobRestore(true)
  }

  // Click Restore Job for external system
  function handleCloseRestoreJob(event) {
    setOpenJobRestore(false)
    setIsJobRestored(false)
  }

  // Click OK close dialog
  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  //Ingresso SnackBar
  function TransitionLeft(props) {
    return <Slide {...props} direction="right" />;
  }

  /*
   **      RENDER
   */
  return (
    <div>
      {<Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{titleCase(t("10087"))}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardHeader color="primary">
              <JobsToolbar
                filter={filter}
                handleCancelFetch={handleCancelFetch}
                handleChangeFilter={(filterField, targetValue) => {
                  handleChangeFilter(filterField, targetValue)
                }}
                handleClickResetFilters={handleClickResetFilters}
                singleJob={filter.jobId ? true : false}
              />
            </CardHeader>
          </Card>
        </AccordionDetails>
      </Accordion>}
      <Card>
        <CardBody>
          <div className={classes.tableWrapper}>

            <Grid container direction="row-reverse" justifyContent="space-between">
              {/* TASTO NUOVO JOB */}
              {actionFetch && creation ? (
                <Grid item xs={1}>
                  <CircularProgress size={42} style={{ color: "primary" }} />{" "}
                </Grid>
              ) : (
                <Grid item xs={1}>
                  {" "}
                  <Tooltip title={t("10152")}>
                    <Fab
                      color="primary"
                      // disabled={creation}
                      onClick={handleClickOpenNew}
                      aria-label="add"
                      align="right"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
              )}
              {/* TASTO REFRESH JOBS*/}
              <Grid item xs={1}>
                <Tooltip title={t("10218")}>
                  <IconButton
                    onClick={handleClickRefresh}
                    aria-label="refresh"
                    align="left"
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={10}></Grid>
            </Grid>
            <Table
              className={classesTable.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <JobsTableHead
                classes={classesTable}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <JobsTableBody
                classes={classesTable}
                emptyRows={emptyRows}
                filter={filter}
                loading={loading}
                isSelected={isSelected}
                getUtc={getUtc}
                handleClick={handleClickRow}
                handleClickOpen={handleClickOpen}
                handleOpenClone={handleOpenClone}
                handleOpenRoutingSqs={handleOpenRoutingSqs}
                handleOpenRoutingHub={handleOpenRoutingHub}
                handleOpenRestoreJob={handleOpenRestoreJob}
                handleOpenToBlocker={handleOpenToBlocker}
                jobs={jobs}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                setJobsFiltered={setJobsFiltered}
                t={t}
              />
            </Table>
            <Dialog
              onClose={handleClickClose}
              aria-labelledby="customized-dialog-title"
              open={openJob}
              maxWidth="xl"

            >
              <DialogContent
                style={{
                  paddingTop: '0px',
                }}
              >
                <Job
                  job={jobData}
                  creation={creation}
                  year={year}
                  onHandleClose={handleClickClose}
                  newJobId={newJobId}
                  handleClickRefresh={handleClickRefresh}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              onClose={handleClickCloseRoutingSqs}
              aria-labelledby="customized-dialog-title"
              open={openJobRoutingSqs}
              maxWidth="xl"
            >
              <DialogContent>
                <JobRoutingSqs
                  jobId={routingSqs.jobId}
                  year={routingSqs.year}
                  onHandleClose={handleClickCloseRoutingSqs}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              onClose={handleCloseRestoreJob}
              aria-labelledby="customized-dialog-title"
              open={openJobRestore}
              maxWidth="xl"
            >
              <DialogContent>
                <JobRestoreExportExternalSystem
                  isJobRestored={isJobRestored}
                  actionFetch={actionFetch}
                  openJobRestore={openJobRestore}
                  onHandleClose={handleCloseRestoreJob}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              onClose={handleClickCloseRoutingHub}
              aria-labelledby="customized-dialog-title"
              open={openJobRoutingHub}
              maxWidth="xl"
            >
              <DialogContent>
                <JobRoutingHub
                  jobId={routingHub.jobId}
                  year={routingHub.year}
                  onHandleClose={handleClickCloseRoutingHub}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              onClose={handleClickCloseToBlocker}
              aria-labelledby="customized-dialog-title"
              open={openJobToBlocker}
              maxWidth="xl"
            >
              <DialogContent>
                <JobToBlocker
                  jobId={toBlocker.jobId}
                  year={toBlocker.year}
                  onHandleClose={handleClickCloseToBlocker}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              onClose={handleClickCloseManualJob}
              aria-labelledby="customized-dialog-title"
              open={openJobManualJob}
              maxWidth="xl"
            >
              <DialogContent>
                <JobManualJob
                  jobId={manualJobId}
                  setJobId={setManualJobId}
                  onHandleClose={handleClickCloseManualJob}
                  setNewJobId={setNewJobId}
                  setOpenJob={setOpenJob}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={actionFetch}
              maxWidth="xl"
            >
              <DialogContent>
                <CircularProgress size={42} style={{ color: "primary" }} />
              </DialogContent>
            </Dialog>
            <JobsTableFooter
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              count={jobsFiltered}
              page={page}
              rowsPerPage={rowsPerPage}
            />
            <DialogError
              classes={classes}
              dialogMsg={dialogMsg}
              handleClose={handleDialogClose}
              open={openDialog}
            />

          </div>
        </CardBody>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBarJobId(undefined)
            setOpenSnackBar(false)
          }}
          open={openSnackBar}
          message={<span>{t("10141")} {snackBarJobId}</span>}
          TransitionComponent={TransitionLeft}
        />
        <CardFooter chart>
          <div className={classes.stats}>
            {updateAgo && <AccessTime />}
            {updateAgo && " updated "}
            {updateAgo && <TimeAgo date={updateAgo} />}
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}
