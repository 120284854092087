import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
  useCallback,
} from "react"
import { RootContext } from "RootContext"

// pegaso views/components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import ErrorUtils from "components/Tools/ErrorUtils"
import ScrollUtils from "components/Tools/ScrollUtils.js"
import { fetchWithToken } from "components/Fetch/api-fetch"

// Core components
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

/*---------  import CSS ---------*/
import { useJobStyles, useJobStylesText } from "styles/job/jobCss"

// Griglia per scheda del JobRouting
function JobRoutingGridContainer(props) {
  const {
    t,
    apiUrl,
    companyId,
    getHubScopes,
    getLab,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const { labId } = getLab()
  const hubScopes = getHubScopes()

  const classes = useJobStyles()
  const classesText = useJobStylesText() // TEXT
  const { jobId, year, onHandleClose } = props
  const [jobChecked, setJobChecked] = useState(false)
  const [jobErrorMsg, setJobErrorMsg] = useState("")
  const [supplier, setSupplier] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMsg, setDialogMsg] = useState("")
  const [orderRoute, setOrderRoute] = useState(true)
  const [routings, setRoutings] = useState()

  // Legge il job
  const fecthJob = useCallback(() => {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}?year=${year}`

    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        const { shape } = data
        const { standard } = shape

        // Controllo che esista forma
        if (!standard) {
          setJobErrorMsg(t("30"))
        }
        setJobChecked(true)
      })
      .catch((err) => {
        // Se Job non esiste
        setJobErrorMsg(t("35"))
        setJobChecked(true)
        ErrorUtils.errorLog("Fetch Job Error", err)
      })
  }, [
    access_token,
    apiUrl,
    companyId,
    jobId,
    labId,
    refresh_token,
    setAccess_token,
    t,
    year,
  ])

  // Fà controlli sul job
  useEffect(() => {
    if (!jobChecked) {
      fecthJob()
    }

    // Solo utente laboratorio con hub con hubScope = 4 attivo vede il bottone
    if (orderRoute) {
      let hubScopesFiltered = []
      let route = undefined
      let supplier = undefined

      if (hubScopes) {
        hubScopesFiltered = hubScopes.filter((elem) => {
          return elem.scopeId === 4
        })

        if (hubScopesFiltered) {
          if (hubScopesFiltered.length > 0) {
            route = hubScopesFiltered
          }
        }

        if (route) {
          if (route.length > 0) {
            route.sort(function (a, b) {
              if (a.hubId.toString() + "_" + a.hubScopeId.toString() >= b.hubId.toString() + "_" + b.hubScopeId.toString()) {
                return 1
              } else {
                return -1
              }
            })
            supplier = route[0].hubId.toString() + "_" + route[0].hubScopeId.toString()
          }
        }
      }


      if (!supplier) {
        setJobErrorMsg(t("44"))
      }

      setRoutings(route)
      setSupplier(supplier)
      setOrderRoute(false)
    }
  }, [fecthJob, jobChecked, hubScopes, orderRoute, t])

  // Handle Supplier Change
  const handleSupplierChange = (event) => {
    const value = event.target.value
    setSupplier(value)
  }

  // Handle Save Button
  const handleSave = () => {
    if (supplier) {
      setIsSaving(true)

      // Inoltro job a "Pegaso-Routing"
      let supplierFields = supplier.split("_")
      const hubId = supplierFields[0]
      const hubScopeId = supplierFields[1]
      const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}/routingHub/${hubId}?year=${year}&hubScopeId=${hubScopeId}`
      fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // => data
        })
        .then((data) => {
          // Chiudo job
          const urlCloseJob = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}/status/3?year=${year}`
          fetchWithToken(urlCloseJob, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
          })
            .then((response) => {
              return response.body // => data
            })
            .then((data) => {
              setDialogMsg(t("10239"))
              setOpenDialog(true)
              setIsSaving(false)
            })
            .catch((err) => {
              ErrorUtils.errorLog(t("41"), err)
              setDialogMsg(t("41"))
              setOpenDialog(true)
              setIsSaving(false)
            })
        })
        .catch((err) => {
          ErrorUtils.errorLog(t("27"), err)
          setDialogMsg(t("27"))
          setOpenDialog(true)
          setIsSaving(false)
        })
    }
  }

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("10298")}</h4>
        </CardHeader>
        <CardBody>
          {!jobErrorMsg && jobChecked && routings ? (
            <div>
              <ScrollUtils.ElevationScroll {...props}>
                <ScrollUtils.AppBarScroll {...props} t={t} />
              </ScrollUtils.ElevationScroll>
              <Grid
                container
                spacing={2}
                alignContent="flex-end"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Grid item xs={12}>
                  {t("10235")}
                  {
                    <TextField
                      required
                      select
                      id={"supplier"}
                      label={""}
                      className={classesText.textField}
                      value={supplier}
                      onChange={handleSupplierChange}
                      SelectProps={{
                        MenuProps: {
                          className: classesText.menu,
                        },
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {routings.map((option) => (
                        <MenuItem
                          key={option.hubId.toString() + "_" + option.hubScopeId.toString()}
                          value={option.hubId.toString() + "_" + option.hubScopeId.toString()}
                        >
                          {`${option.hubSettings.supplier.name} -> ${option.hubSettings.supplier.comPort} (Hub: ${option.hubId.toString()})`}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                </Grid>
                <Grid item xs={4}>
                  <strong>{t("10050")}: </strong>
                  {jobId}
                </Grid>
                <Grid item xs={4}>
                  <strong>{t("10237")}: </strong>
                  {year}
                </Grid>
                <Grid item xs={4}>
                  <Button
                    disabled={supplier === ""}
                    variant="outlined"
                    color="secondary"
                    onClick={handleSave}
                  >
                    {t("10236")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <u>({t("10299")})</u>
                </Grid>
              </Grid>

              <Dialog
                open={isSaving || openDialog}
                onClose={onHandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                {openDialog ? (
                  <Fragment>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {dialogMsg}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onHandleClose} color="primary" autoFocus>
                        Ok
                  </Button>
                    </DialogActions>
                  </Fragment>
                ) : (
                  <Fragment>
                    <DialogContent>
                      <DialogContent>
                        <CircularProgress size={42} style={{ color: "primary" }} />
                      </DialogContent>
                    </DialogContent>
                  </Fragment>
                )}
              </Dialog>
            </div>
          ) : jobErrorMsg ? (
            <div>
              <ScrollUtils.ElevationScroll {...props}>
                <ScrollUtils.AppBarScroll {...props} t={t} />
              </ScrollUtils.ElevationScroll>
              {`${t("43")}: ${t("42")}`}
              <br />
              <br />
              {`${jobErrorMsg}`}
              <br />
              <br />
            </div>
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </CardBody>
      </Card>
    </div>)
}

// Griglia interna alla finestra del JobRouting
function JobRoutingHub(props) {
  const { jobId, year } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()

  if (jobId && year) {
    return <JobRoutingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}

export default JobRoutingHub
