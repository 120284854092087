import { RandomPassword } from "components/Password/RandomPassword"
import StringUtils from "components/Tools/StringUtils"

export default class UserUtils {

  static languageList() {
    return [
      {
        label: "",
        value: "",
      },
      {
        value: "en-us",
        label: "english",
      },
      {
        value: "it-it",
        label: "italian",
      },
      {
        value: "gr",
        label: "greek",
      }
    ]
  }

  static UTCList() {
    let list = []
    let i
    for (i = -12; i <= 13; i++) {
      list.push({
        value: i,
        label: i.toString()
      })
    }
    return list
  }

  //Funzione per generare la password automaticamente
  static generatePassword() {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(true)
      .generate();
    return pwd
  }

  //Funzione per generare la password automaticamente
  static generatePasswordLettersNumbers() {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(false)
      .generate();
    return pwd
  }



  //Setta i dati completi del device per aggiornare la tabella
  static getBrandModel(selectedDeviceJSON, allBrandsDevices, hubDeviceId) {
    return (selectedDeviceJSON ? (
      allBrandsDevices.map((model) =>
        model.models.map((option) => ({
          deviceId: hubDeviceId,
          active: selectedDeviceJSON.active,
          connection: selectedDeviceJSON.connection,
          model: {
            modelId: (option.modelId === selectedDeviceJSON.model) ? selectedDeviceJSON.model : option.modelId,
            type: option.type,
            brand: {
              brandId: model.brandId,
              brand: model.brand
            },
            description: option.description //testare con option
          },
          protocol: selectedDeviceJSON.protocol,
          serial: (selectedDeviceJSON.connection === "serial" ? selectedDeviceJSON.serial : undefined),
          ip: (selectedDeviceJSON.connection === "ip" ? selectedDeviceJSON.ip : undefined)
        })
        ).filter((option) => { return (option.model.modelId === selectedDeviceJSON.model) })[0]
      ).filter((option) => { return option !== undefined })[0]
    ) : (undefined)

    )
  }

  static getBrandModelHub(selectedDeviceJSON, allBrandsDevices) {
    let newJson = []
    if (selectedDeviceJSON.length > 0) {
      selectedDeviceJSON.map((device) =>
        newJson.push(UserUtils.getBrandModel(device, allBrandsDevices))
      )
    }

    return newJson
  }


  //Crea il Json dei device dell'utente per fare poi la modifica
  static createJsonDevices = (deviceRow, isHub) => {
    let device = deviceRow
    if (isHub) {
      return (
        device.map((device) => (
          {
            active: device.active,
            connection: device.connection,
            model: device.model.modelId,
            protocol: device.protocol,
            serial: (device.connection === "serial") ? {
              port: device.serial.port,
              settings: device.serial.settings,
              timeout: device.serial.timeout,
              handshake: device.serial.handshake,
              readBufferSize: device.serial.readBufferSize,
              writeBufferSize: device.serial.writeBufferSize,
              readTimeout: device.serial.readTimeout,
              writeTimeout: device.serial.writeTimeout,
              rts: device.serial.rts,
              dtr: device.serial.dtr,
              discardNull: device.serial.discardNull,
            } : undefined
            ,
            ip: (device.connection === "ip") ? {
              address: device.ip.address,
              port: device.ip.port,
              timeout: device.ip.timeout
            } : undefined
          }
        ))
      )
    }
    else {
      return (
        {
          active: device.active,
          connection: device.connection,
          model: device.model.modelId,
          protocol: device.protocol,
          serial: (device.connection === "serial") ? {
            port: device.serial.port,
            settings: device.serial.settings,
            timeout: device.serial.timeout,
            handshake: device.serial.handshake,
            readBufferSize: device.serial.readBufferSize,
            writeBufferSize: device.serial.writeBufferSize,
            readTimeout: device.serial.readTimeout,
            writeTimeout: device.serial.writeTimeout,
            rts: device.serial.rts,
            dtr: device.serial.dtr,
            discardNull: device.serial.discardNull,
          } : undefined
          ,
          ip: (device.connection === "ip") ? {
            address: device.ip.address,
            port: device.ip.port,
            timeout: device.ip.timeout
          } : undefined
        }
      )
    }
  }


  //Crea la codifica esatta dell'username 
  static createUsernameUtil(userId, labOrStore, rows, companyId) {
    let countOptician = 1
    /* pesco optician id con contatore finale piu alto ed incremento di 1 */
    let rowsFiltered = rows.filter(function (elem) {
      let appId = (labOrStore === "L" ? elem.lab.labId : elem.optician.opticianId)
      return (appId === userId) && (elem.username.indexOf("demo") < 0)
    }).sort(function (a, b) {
      return -(a.username.localeCompare(b.username))
    })
    if (rowsFiltered.length > 0) {
      let highestElement = rowsFiltered[0].username
      let uPosition = highestElement.indexOf("U")
      countOptician = parseInt(highestElement.substring(uPosition + 1)) + 1
    }

    //formatto struttura username
    if (countOptician < 10) {
      return (companyId + labOrStore + userId + "U00" + countOptician)
    } else if (countOptician >= 10 && countOptician < 100) {
      return (companyId + labOrStore + userId + "U0" + countOptician)
    } else {
      return (companyId + labOrStore + userId + "U" + countOptician)
    }
  }

  // Format Optician
  static formatOptician(row) {
    const { opticianView } = row
    if (!opticianView) {
      let result = ""
      const { optician } = row
      if (optician) {
        const { opticianId, name } = optician
        if (StringUtils.checkIfNotNull(opticianId) !== "") {
          result = `${opticianId} - ${String.prototype.trim.call(name)}`
          row.opticianIdSort = parseInt(opticianId)
        }
      }
      row.opticianView = result
    }
    return row.opticianView
  }

  // Format Lab
  static formatLab(row) {
    const { labView } = row
    if (!labView) {
      let result = ""
      const { lab } = row
      if (lab) {
        const { labId, name } = lab
        if (StringUtils.checkIfNotNull(labId) !== "") {
          result = `${labId} - ${String.prototype.trim.call(name)}`
        }
      }
      row.labView = result
    }
    return row.labView
  }

  // Format Device
  static formatDevice(row) {
    const { deviceView } = row
    if (!deviceView) {
      let result = ""
      const { userDevice } = row
      if (userDevice) {
        const { model } = userDevice
        if (model) {
          const { brand, description } = model
          if (description) {
            result = `${String.prototype.trim.call(
              brand.brand
            )} - ${String.prototype.trim.call(description)}`
          }
        }
      }
      row.deviceView = result
    }
    return row.deviceView
  }

  // Format Description
  static formatDescription(row) {
    const { descriptionView } = row
    if (!descriptionView) {
      let result = ""
      const { description } = row
      if (description) {
        result = String.prototype.trim.call((row.description + ' '.repeat(20)).substring(0, 20))
      }
      row.descriptionView = result
    }
    return row.descriptionView
  }


  // Format Retailer
  static formatRetailer(row) {
    const { retailerView } = row
    if (!retailerView) {
      let result = ""
      const { retailer } = row
      if (retailer) {
        const { retailerId, name } = retailer
        if (StringUtils.checkIfNotNull(retailerId) !== "") {
          result = `${retailerId} - ${String.prototype.trim.call(name)}`
          row.retailerIdSort = parseInt(retailerId)
        }
      }
      row.retailerView = result
    }
    return row.retailerView
  }

  static formatBrand(row){
    const { brandView } = row
    if (!brandView) {
      let result = ""
      const { brand } = row
      if (brand) {
        const { brandId } = brand
        if (brandId) {
          result = ` ${brandId} - ${String.prototype.trim.call(brand.brand)}`
          row.brand = parseInt(brandId)
        }
      }
      row.brandView = result
    }
    return row.brandView
  }

}