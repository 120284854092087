import React, { useContext } from "react"
import { RootContext } from "RootContext"

// Core components
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import InputIcon from '@material-ui/icons/Input'

// Button che apre la finestra con i dati del Job
function ButtonDialogJob(props) {
  const { handleClickOpen, year, job } = props
  const { t, isLabUser, getHubScopes } = useContext(RootContext)

  let seeButton = false
  const hubScopes = getHubScopes()

  // Solo utente laboratorio con labScope = 8 attivo vede il bottone
  if (isLabUser()) {
    let checkScope = []
    if (hubScopes) {
      checkScope = hubScopes.filter((elem) => {
        return elem.scopeId === 4
      })
    }
    seeButton = checkScope.length > 0
  }

  return seeButton ? (
    <div>
      <Tooltip title={t("10298")}>
        <IconButton
          aria-label={t("10298")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(year, job)
          }}
        >
          <InputIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null
}

function JobRoutingHubButton(props) {
  return (
    <div id="contenitore">
      <ButtonDialogJob {...props} />
    </div>
  )
}

export default JobRoutingHubButton
