import AWS from "aws-sdk/global"
import AWSMqttClient from "aws-mqtt"
import { brokerUrl, brokerPort, brokerPrefix } from "RootContext"
import mqtt from "mqtt"

// DOCUMENTATION:
// - http://www.steves-internet-guide.com/using-node-mqtt-client/
// - https://github.com/thomasnordquist/MQTT-Explorer
// - https://medium.com/@cri.bh6/in-this-simple-example-im-going-to-show-how-to-write-a-very-simple-expressjs-api-that-uses-mqtt-to-57aa3ecdcd9e

export default class MqttHandler {
  constructor() {
    // Variabili
    this.clientMqtt = null
    this.handleOnMessage = null
    this.reconnectAttempts = 0

    // Costanti
    this.maxReconnectAttemps = 50;
    this.keepAliveTime = 90
    this.connectTime = 5 * 60 * 1000
  }

  connect(companyId, labId, opticianId, isLabUser, handleOnMessage) {
    try {
      this.logMqttAttempt("connect", true, "")
      
      if (this.reconnectAttempts < this.maxReconnectAttemps) {
        if (brokerUrl === "localhost") {
          this.clientMqtt = mqtt.connect(`ws://${brokerUrl}:${brokerPort}`, {
            clientId:
            "pegaso_mini_mqttjs_" +
            Math.random()
            .toString(16)
            .substr(2, 8),
            timeout: 3,
            keepAliveInterval: this.keepAliveTime,
            cleanSession: true,
            useSSL: false
          })
        } else {
          // 4) [Production] OK: AWS + AmazonMQ (network.websocket.allowInsecureFromHTTPS: false)
          // Initialize the Amazon Cognito credentials provider
          AWS.config.region = "eu-central-1" // Region
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY
          })

          // Mqtt - Connect with credentials (in case of needed, otherwise we can omit 2nd param)
          this.clientMqtt = new AWSMqttClient({
            region: AWS.config.region,
            credentials: AWS.config.credentials,
            endpoint: brokerUrl, // NOTE: get this value with `aws iot describe-endpoint`
            expires: 24 * 60 * 60, // Sign url with expiration of 600 seconds
            clientId: "pegaso-miniGui-" + Math.floor(Math.random() * 100000 + 1), // clientId to register with MQTT broker. Need to be unique per client
            will: {
              topic: "WillMsg",
              payload: "Connection Closed abnormally..!",
              qos: 0,
              retain: false
            },
            keepAlive: this.keepAliveTime,
            KeepAliveInterval: this.keepAliveTime,
            connectTimeout: this.connectTime,
          })
        }
      }
      this.clientMqtt.on("message", handleOnMessage)
      this.handleOnMessage = handleOnMessage

      this.logMqttAttempt("connect", false, "")
    } catch (err) {
      this.errorMqttAttempt("connect", err)
    }

    // Mqtt - Connection callback
    this.clientMqtt.on("connect", () => {
      var mqttUrl = ""
      if (!isLabUser) {
        mqttUrl = brokerPrefix + `companies/${companyId}/opticians/${opticianId}/jobs/newJob`
      } else {
        mqttUrl = brokerPrefix + `companies/${companyId}/labs/${labId}/jobs/newJob`
      }
      this.logMqttAttempt("onConnect", true, mqttUrl)
      this.clientMqtt.subscribe(mqttUrl, { qos: 1 })
      this.logMqttAttempt("onConnect", false, mqttUrl)
    })

    // Mqtt - Error callback
    this.clientMqtt.on("error", err => {
      this.errorMqttAttempt("onError", err)
      this.clientMqtt.end()

      // Mqtt - Recconect
      this.doReconnect(companyId, labId, opticianId, isLabUser)
    })

    // Mqtt - Disconnection callback
    this.clientMqtt.on("close", () => {
      this.logMqttAttempt("onClose", true, "")
      // . . .
      this.logMqttAttempt("onClose", false, "")
    })

    // Mqtt - Offline callback
    this.clientMqtt.on("offline", () => {
      this.logMqttAttempt("onOffline", true, "")

      // Mqtt - Recconect
      this.doReconnect(companyId, labId, opticianId, isLabUser)
      this.logMqttAttempt("onOffline", false, "")
    })
  }

  // doConnect
  doConnect(companyId, labId, opticianId, isLabUser, handleOnMessage) {
    this.logMqttAttempt("doConnect", true, "")
    this.connect(companyId, labId, opticianId, isLabUser, handleOnMessage)
    this.logMqttAttempt("doConnect", false, "")
  }

  // diDisconnect
  doDisconnect() {
    if (this.clientMqtt) {
      if (this.clientMqtt.connected) {
        this.logMqttAttempt("doDisconnect", true, "")
        this.clientMqtt.end()
        this.logMqttAttempt("doDisconnect", false, "")
      }
    }
  }

  // doMessage
  doMessage(handleOnMessage, companyId, labId, opticianId, isLabUser) {
    this.logMqttAttempt("doMessage", true, "")
    this.doDisconnect()
    this.doConnect(companyId, labId, opticianId, isLabUser, handleOnMessage)
    this.logMqttAttempt("doMessage", false, "")
  }

  // doReconnect
  doReconnect(companyId, labId, opticianId, isLabUser) {
    if (this.reconnectAttempts < this.maxReconnectAttemps) {
      this.logMqttAttempt("doReconnect", true, "")
      this.reconnectAttempts += 1;

      // ReConnect
      setTimeout(() => {
        this.doDisconnect()
        this.doConnect(companyId, labId, opticianId, isLabUser, this.handleOnMessage)
      }, this.connectTime);

      this.logMqttAttempt("doReconnect", false, "")
    }
  }

  // doSendMessage - Sends a mqtt message to topic: mytopic
  doSendMessage(topic, message) {
    this.clientMqtt.publish(brokerPrefix + topic, message)
  }

  // LOG
  logMqttAttempt(routine, isStart = true, url = "") {
    //var msg = `${new Date()} (Attempt: ${this.reconnectAttempts}) Mqtt ${routine} -> ${isStart ? "Start" : "End"} ${url !== "" ? " -> " + url : ""}`
    //console.log(msg)
  }

  // ERROR
  errorMqttAttempt(routine, error = "") {
    var msg = `${new Date()} (Attempt: ${this.reconnectAttempts}) Mqtt ${routine} -> Error: ${error}`
    console.log(msg)
  }
}
