import React, { Fragment } from "react"

// Core components
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

// Pegaso components
import { stableSort, getSorting } from "views/Jobs/JobsTableSort"
import { CircularIndeterminate } from "../Loading"
import DateUtils from "components/Tools/DatesUtils"
import JobsUtils from "components/Utils/JobsUtils"
import JobButton from "components/Buttons/JobButton"
import JobCloneButton from "components/Buttons/JobCloneButton"
import JobRoutingSqsButton from "components/Buttons/JobRoutingSqsButton"
import JobRoutingHubButton from "components/Buttons/JobRoutingHubButton"
import JobToBlockerButton from "components/Buttons/JobToBlockerButton"
import JobRestoreExportSystemButton from "components/Buttons/JobRestoreExportSystemButton"

import Slide from "@material-ui/core/Slide"

function filterField(job, jobValue, filterValue, searchSame = false) {
  if (filterValue !== null && filterValue !== undefined) {
    filterValue = String.prototype.trim.call(filterValue)
    if (filterValue !== "") {
      if (jobValue !== null && jobValue !== undefined) {
        jobValue = String.prototype.trim.call(jobValue)
        if (filterValue !== "") {
          if (
            (searchSame && jobValue === filterValue) ||
            (!searchSame &&
              jobValue.toUpperCase().includes(filterValue.toUpperCase()))
          ) {
            return job
          }
        } else {
          return job
        }
      } else {
        // Elemento da scartare
      }
    } else {
      return job
    }
  } else {
    return job
  }
}

// Job Table -> BODY
export default function JobsTableBody(props) {
  const {
    classes,
    emptyRows,
    isSelected,
    filter,
    getUtc,
    loading,
    jobs,
    handleClick,
    handleClickOpen,
    handleOpenClone,
    handleOpenRoutingSqs,
    handleOpenRoutingHub,
    handleOpenRestoreJob,
    handleOpenToBlocker,
    order,
    orderBy,
    page,
    rowsPerPage,
    setJobsFiltered,
    t,
  } = props

  // Filter data
  let tableFiltered = jobs
  if (jobs.length > 0) {
    tableFiltered = jobs
      .filter((job) => {
        return filterField(
          job,
          JobsUtils.formatJobOptician(job),
          filter.opticianView
        )
      })
      .filter((job) => {
        return filterField(
          job,
          JobsUtils.formatJobOpticianNote(job),
          filter.note
        )
      })
      .filter((job) => {
        return filterField(
          job,
          JobsUtils.formatJobOpticianCustomer(job),
          filter.optcst
        )
      })
      .filter((job) => {
        return filterField(
          job,
          JobsUtils.formatJobStatus(job, t),
          filter.status
        )
      })
      .filter((job) => {
        return filterField(
          job,
          JobsUtils.formatJobSoftware(job, t),
          filter.software
        )
      })
  }

  //Sort data
  tableFiltered = stableSort(tableFiltered, getSorting(order, orderBy))
  setJobsFiltered(tableFiltered.length)

  //Filter data (take jobs with token errors off)
  tableFiltered = tableFiltered.filter((elem) => {
    const { error } = elem
    if (!error) {
      return elem
    } else {
      return null
    }
  })

  return (
    <Fragment>
      <TableBody>
        {jobs.length > 0 || (jobs.length === 0 && !loading) ? (
          tableFiltered.length > 0 ? (
            tableFiltered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((job) => {
                const jobId = JobsUtils.formatJobId(job)
                const isItemSelected = isSelected(jobId)

                let createdOnVar
                const { created, createdOn } = job
                if (createdOn) {
                  createdOnVar = createdOn
                } else if (created) {
                  if (created.createdOn) {
                    createdOnVar = created.createdOn
                  }
                }

                const year = job.year ? job.year : createdOnVar.substring(0, 4)
                const dateWithUtc = DateUtils.formatDateWithUtc(
                  createdOnVar,
                  getUtc()
                )

                return (
                  <Slide
                    key={"jobSlide-" + jobId}
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                  >
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, jobId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={"jobTableRow-" + jobId}
                      selected={isItemSelected}
                    >
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell1-" + jobId}
                        align="left"
                      >
                        {jobId}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell2-" + jobId}
                        align="left"
                      >
                        {dateWithUtc}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell3-" + jobId}
                        align="left"
                      >
                        {JobsUtils.formatJobOptician(job)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell4-" + jobId}
                        align="left"
                      >
                        {JobsUtils.formatJobOpticianNote(job)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell5-" + jobId}
                        align="left"
                      >
                        {JobsUtils.formatJobOpticianCustomer(job)}
                      </TableCell>
                      <TableCell
                        className={job.status === "ERROR" ? classes.tableCellError : classes.tableCell}
                        key={"jobTableCell6-" + jobId}
                        align="left"
                      >
                        {JobsUtils.formatJobStatus(job, t)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell7-" + jobId}
                        align="left"
                      >
                        <JobButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleClickOpen(event, year, jobId)
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell8-" + jobId}
                        align="left"
                      >
                        <JobCloneButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleOpenClone(event, year, jobId)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell9-" + jobId}
                        align="left"
                      >
                        <JobRoutingSqsButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleOpenRoutingSqs(event, year, jobId)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell10-" + jobId}
                        align="left"
                      >
                        <JobRoutingHubButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleOpenRoutingHub(event, year, jobId)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell11-" + jobId}
                        align="left"
                      >
                        <JobRestoreExportSystemButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleOpenRestoreJob(event, year, jobId)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        key={"jobTableCell12-" + jobId}
                        align="left"
                      >
                        <JobToBlockerButton
                          job={jobId}
                          year={year}
                          handleClickOpen={(event) =>
                            handleOpenToBlocker(event, year, jobId)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Slide>
                )
              })
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                {t("10082")}
              </TableCell>
            </TableRow>
          )
        ) : (
          <TableRow>
            <TableCell colSpan={6} align="center">
              <CircularIndeterminate />
            </TableCell>
          </TableRow>
        )}
        {emptyRows > 0 ? (
          <TableRow style={{ height: 49 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        ) : null}
      </TableBody>
    </Fragment>
  )
}
