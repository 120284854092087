import React, { useContext } from 'react'
import IconButton from '@material-ui/core/IconButton';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import Tooltip from '@material-ui/core/Tooltip';
import { RootContext } from 'RootContext';

// Button che apre la finestra con i dati del Job
function ButtonDialogJob(props) {
  const { handleClickOpen, year, job } = props
  const { t, isLabUser, getExistsExternalSystem } = useContext(RootContext)
  let seeButton = false

  // Solo utente laboratorio con almeno un external system attivo vede il bottone
  if (isLabUser()) {
    if (getExistsExternalSystem()) {
      seeButton = true
    }
  }

  return (seeButton ? (
    <div>
      <Tooltip title={t("10415")}>
        <IconButton aria-label={t("10415")} variant="outlined" onClick={() => { handleClickOpen(year, job) }}>
          <RestorePageIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null
  );
}

function JobRestoreExportSystemButton(props) {
  return (
    <div id="contenitore">
      <ButtonDialogJob  {...props} />
    </div>
  );
}

export default JobRestoreExportSystemButton;


