import { fetchWithTokenPegasoTools } from "components/Fetch/tools-api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"

export default class OmaUtils {

  // Estrae la label da un record OMA
  static ExtractOmaLabelFromRow(omaRecord) {
    omaRecord = omaRecord.trim()
    if (omaRecord !== "")
      return omaRecord.substring(0, omaRecord.indexOf("=", 0))
  }

  // Estrae il valore da un record OMA
  static ExtractOmaValueFromRow(omaRecord) {
    omaRecord = omaRecord.trim()
    if (omaRecord !== "")
      return omaRecord.split("=")[1]
  }

  // Estrae la label da un record OMA
  // static ExtractOmaLabelFromRow(row) {
  //   return row.split("=")[0]
  // }

  // Data una stringa OMA (che contiene piu righe), ricava riga di un etichetta OMA da una stringa contentente tutto il file
  static ExtractOmaValueFromString(omaString, omaLabel, keepQuotes) {
    var result = ""
    if (omaString !== "") {
      if (omaString.indexOf(omaLabel) >= 0) {
        result = omaString.substring(omaString.indexOf(omaLabel) + omaLabel.length)
        if (result.substring(0, 1) === "=")
          result = result.substring(1)
        if (result.indexOf(String.fromCharCode(13)) > 0)
          result = result.substring(0, result.indexOf(String.fromCharCode(13)))
        result = result.replace("" + String.fromCharCode(10), "").replace("" + String.fromCharCode(13), "")
        if (keepQuotes === false) {
          result = result.replace("" + String.fromCharCode(34), "")
        }
      }
    }
    return result
  }

  // Data una stringa OMA, ricava il lato (Stringa prima/dopo ';') decidendo se tenere o no gli apici
  static ExtractSideValueFromString(valueOMA, rightSide, keepQuotes) {
    var result = valueOMA
    if (valueOMA.indexOf(";") >= 0) {
      var posSeparator = valueOMA.indexOf(";")
      if (rightSide)
        result = valueOMA.substring(0, posSeparator)
      else
        result = valueOMA.substring(posSeparator)
    }
    if (keepQuotes === false)
      result = result.replace("" + String.fromCharCode(34), "")
    return result
  }

  // Sostistuice in una stringa OMA (che contiene piu righe), un valore OMA specificandone l'etichetta
  static ReplaceSingleValueOMA(buf, labelOMA, valueOMA) {
    var result = buf
    var label
    var stringSeparators = ["\r\n"]
    var stringArray = result.split(stringSeparators)

    for (var i = 0; i < stringArray.length; i++) {
      label = OmaUtils.ExtractOmaLabelFromRow(stringArray[i])
      if (label === labelOMA) {
        stringArray[i] = label + "=" + valueOMA
        break;
      }
    }
    result = stringArray.join("\r\n")
    return result
  }

  // Crea un record Oma confrontando i valori
  // Se singleValue = true -> crea il record per le label che accettano solo un valore (considera solo rightValue)
  // Se singleValue = false -> crea il record per le label che accettano due valori (se vuoti entrambi restituisce stringa vuota)
  static CreateOmaRecord(omaLabel, rightValue, leftValue, singleValue) {
    var strRightValue = rightValue ? rightValue.toString() : ""
    var strLeftValue = leftValue ? leftValue.toString() : ""
    switch (singleValue) {
      case false:
        if (strRightValue.trim() === "" && strLeftValue.trim() === "") {
          return ""
        } else {
          if (strRightValue.trim() !== "" && strLeftValue.trim() === "") {
            return omaLabel + "=" + strRightValue + ";"
          } else if (
            strRightValue.trim() === "" &&
            strLeftValue.trim() !== ""
          ) {
            return omaLabel + "=;" + strLeftValue
          } else {
            return omaLabel + "=" + strRightValue + ";" + strLeftValue
          }
        }
      case true:
      default:
        if (strRightValue.trim() === "") {
          return ""
        } else {
          return omaLabel + "=" + strRightValue
        }
    }
  }

  // Rimuove tutti i record con una certa etichetta OMA
  static RemoveOmaLabel(arrayOldData, omaLabel) {
    var arrayNewData = []
    // Aggiungo elementi del old al new (tranne omaLabel)
    arrayOldData.map((elem) => {
      if (OmaUtils.ExtractOmaLabelFromRow(elem) !== omaLabel) {
        arrayNewData.push(elem)
      }
      return elem
    })

    return arrayNewData
  }

  static GetOmaLabels(arrayOldData, omaLabel) {
    var arrayNewData = []
    // Aggiungo elementi del old al new (tranne omaLabel)
    arrayOldData.map((elem) => {
      if (OmaUtils.ExtractOmaLabelFromRow(elem) === omaLabel) {
        arrayNewData.push(elem)
      }
      return elem
    })

    return arrayNewData
  }

  // Crea un array sostituendo i vecchi valori Oma con i nuovi, e inserendo quelli non modificati
  static SearchAndReplaceOmaData(arrayOldData, arrayGuiData) {
    var arrayNewData = []

    const checkLabel = (a, b) =>
      OmaUtils.ExtractOmaLabelFromRow(a) === OmaUtils.ExtractOmaLabelFromRow(b)
    const addElement = (element) => arrayNewData.push(element)

    // Aggiungo elementi dell old al new
    arrayOldData.map((elemOld) => addElement(elemOld))

    // Aggiungo elementi del gui al new (sostituisco se trovo)
    arrayGuiData.map((elemGui) => {
      const labelFound = arrayNewData.find((elemNew) =>
        checkLabel(elemGui, elemNew)
      )

      return labelFound
        ? (arrayNewData = arrayNewData.map((newArrayElem) =>
          checkLabel(newArrayElem, elemGui) ? elemGui : newArrayElem
        ))
        : addElement(elemGui)
    })
    return arrayNewData
  }

  //Formatta la stringa dei fori nel modo corretto
  static CreateOmaDrillRecord(drillValue) {
    let xStart = drillValue.xStart ? drillValue.xStart.toFixed(2) : ""
    let xEnd = drillValue.xEnd ? drillValue.xEnd.toFixed(2) : ""
    let yStart = drillValue.yStart ? drillValue.yStart.toFixed(2) : ""
    let yEnd = drillValue.yEnd ? drillValue.yEnd.toFixed(2) : ""
    return "B;" + xStart + ";" + yStart + ";" + drillValue.diam.toFixed(2) + ";" + xEnd + ";" + yEnd + ";;F;;"
  }

  //Converte da DRILL a DRILLE
  static async CreateOmaDrilleRecord(apiUrl, drillValue, access_token, refresh_token, setAccess_token, t) {
    var convertValue = ""
    await fetchWithTokenPegasoTools(`${apiUrl}/ShapeConverter/convert-drill-to-drille`, {
      method: "POST",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ edgingData: drillValue }),
      apiUrl_pegaso_tools: apiUrl,
      access_token_pegaso_tools: access_token,
      refresh_token_pegaso_tools: refresh_token,
      setAccess_token_pegaso_tools: setAccess_token
    })
      .then(response => {
        convertValue = response.body
      })
      .catch(err => {
        ErrorUtils.errorLog(t("48"), err.message)
      })
    return convertValue
    //return convertValue.substring(convertValue.indexOf("=") + 1)
  }

  //Converte una string OMA in oma standard
  static async ConvertDataToOma(apiUrl, access_token, refresh_token, setAccess_token, t, tracerData, tracerId) {
    var convertShape = ""
    await fetchWithTokenPegasoTools(`${apiUrl}/ShapeConverter/data-to-oma`, {
      method: "POST",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ shapeIn: tracerData, tracerId: tracerId }),
      apiUrl_pegaso_tools: apiUrl,
      access_token_pegaso_tools: access_token,
      refresh_token_pegaso_tools: refresh_token,
      setAccess_token_pegaso_tools: setAccess_token
    })
      .then(response => {
        const { body } = response
        convertShape = body
      })
      .catch(err => {
        ErrorUtils.errorLog(t("48"), err.message)
      })
    return convertShape
  }

  //Converte la forma in oma dati i raggi sinistro e destro
  static async CreateOmaShapeFromData(apiUrl, access_token, refresh_token, setAccess_token, t, radiusR, radiusL, newEdgingData) {
    var convertShape = ""
    await fetchWithTokenPegasoTools(`${apiUrl}/ShapeConverter/oma-shape-from-data`, {
      method: "POST",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        right: {
          r: radiusR
        },
        left: {
          r: radiusL
        },
        edgingData: newEdgingData
      }),
      apiUrl_pegaso_tools: apiUrl,
      access_token_pegaso_tools: access_token,
      refresh_token_pegaso_tools: refresh_token,
      setAccess_token_pegaso_tools: setAccess_token
    })
      .then(response => {
        const { body } = response
        convertShape = body
      })
      .catch(err => {
        ErrorUtils.errorLog(t("48"), err.message)
      })
    return convertShape
  }

  //Converte la forma nel numero di punti definiti nel parametro points
  static async ConvertGenericPoints(apiUrl, access_token, refresh_token, setAccess_token, t, radius, points) {
    var convertShape = ""
    var stringRadius = radius.join(";")
    await fetchWithTokenPegasoTools(`${apiUrl}/ShapeConverter/convert-generic-points?apunti=${points}`, {
      method: "POST",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'shapeIn': stringRadius,
        'tracerId': 3
      }),
      apiUrl_pegaso_tools: apiUrl,
      access_token_pegaso_tools: access_token,
      refresh_token_pegaso_tools: refresh_token,
      setAccess_token_pegaso_tools: setAccess_token
    })
      .then(response => {
        const { body } = response
        convertShape = body
      })
      .catch(err => {
        ErrorUtils.errorLog(t("48"), err.message)
      })
    return convertShape
  }

  static MergeShapeWithEdgingData(firstParam, secondParam) {
    const firstRecords = firstParam.split('\r\n');
    const secondRecords = secondParam.split('\r\n');
    const result = OmaUtils.SearchAndReplaceOmaData(firstRecords, secondRecords);
    return result.join('\r\n');
  }

  //Converte una string OMA in oma standard
  static async CalculateOpticalCenter(apiUrl, access_token, refresh_token, setAccess_token, t, tracerData, edgingData) {
    const shapeToSend = tracerData + edgingData
    var convertShape = {
      right: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      },
      left: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      }
    }
    await fetchWithTokenPegasoTools(`${apiUrl}/Shape/calcluate-optical-center`, {
      method: "POST",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ shapeIn: shapeToSend }),
      apiUrl_pegaso_tools: apiUrl,
      access_token_pegaso_tools: access_token,
      refresh_token_pegaso_tools: refresh_token,
      setAccess_token_pegaso_tools: setAccess_token
    })
      .then(response => {
        const { body } = response
        convertShape = body
      })
      .catch(err => {
        ErrorUtils.errorLog(t("48"), err.message)
      })
    return convertShape
  }

}
