import React, { useState, useContext, useEffect, Fragment, useRef } from "react"
import { RootContext } from "../../RootContext"

import DeleteIcon from "@material-ui/icons/Delete"

// pegaso views/components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import JobUtils from "components/Utils/JobUtils"
import JobNewUtils from "components/Utils/JobNewUtils"
import OmaUtils from "components/Utils/OmaUtils"
import ProductTable from "views/Products/ProductTable"
import Shape from "views/Shape/Shape"
import {
  LensDataGrid,
  EdgingDataGrid,
  NotesDataGrid,
  HeadDataGrid,
} from "views/Job/JobGrid"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import AccordionDetails from '@material-ui/core/AccordionActions'
import { Accordion, AccordionSummary } from '@material-ui/core'
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import ErrorUtils from "components/Tools/ErrorUtils"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import ReactToPrint from 'react-to-print';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Zoom from "@material-ui/core/Zoom"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"

/*---------  import CSS ---------*/
import { useJobStyles } from "styles/job/jobCss"
import EdgingDataUtils from "components/Utils/EdgingDataUtils"

// Griglia per scheda del Job
function EdgingGridContainer(props) {
  const {
    t,
    apiUrl,
    apiUrlPegasoTools,
    companyId,
    getLab,
    companyName,
    getOptician,
    getUsername,
    access_token,
    refresh_token,
    setAccess_token,
    isLabUser,
    isOpticianUser,
    catalogueScope,
    getEdgingEightType,
    getDiameterMinimumCalculationActive,
    access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    setAccess_token_pegaso_tools
  } = useContext(RootContext)

  const {
    labId,
    name: labName,
    internalOpticianId,
    internalOpticianName,
  } = getLab()
  const edgingEightType = getEdgingEightType()
  let opticianId = ""
  let opticianName = ""

  const optician = getOptician()
  if (optician) {
    opticianId = optician.opticianId
    opticianName = optician.name
  } else {
    opticianId = internalOpticianId
    opticianName = internalOpticianName
  }

  const classes = useJobStyles()
  const {
    job,
    year,
    onHandleClose,
    creation,
    newJobId,
    handleClickRefresh,
  } = props
  let { requiredFields } = useContext(RootContext)

  const [edgingData, setEdgingData] = useState(JobUtils.GetEdgingData(job))
  const { rightValues, leftValues, edgingValues, notesValues, headValues } =
    job !== ""
      ? JobUtils.createDefaultState(job)
      : JobNewUtils.createDefaultState(
        job,
        companyId,
        companyName,
        labId,
        labName,
        opticianId,
        opticianName
      )
  const [editMode, setEditMode] = useState(creation)
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [dialogMsg, setDialogMsg] = useState("")
  const componentRef = useRef();
  const jobSoftware = useState(JobUtils.GetJobSoftware(job))

  // Valori iniziali dei dati
  const [currentHeadValues, setCurrentHeadValues] = useState(headValues)
  const [currentRightValues, setCurrentRightValues] = useState(rightValues)
  const [currentLeftValues, setCurrentLeftValues] = useState(leftValues)
  const [currentEdgingValues, setCurrentEdgingValues] = useState(edgingValues)
  const [currentNotesValues, setCurrentNotesValues] = useState(notesValues)
  const [editProductOpen, setEditProductOpen] = useState(undefined)
  const [currentStandardShape, setCurrentStandardShape] = useState("")
  const [currentOriginalShape, setCurrentOriginalShape] = useState("")
  const [hasDeviceUploader, setHasDeviceUploader] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  let { sx, dx } = currentNotesValues
  if (sx === undefined) sx = true
  if (dx === undefined) dx = true
  const [allRequired, setAllRequired] = useState({
    leftLens: sx ? (!creation ? true : requiredFields.length === 0) : true,
    rightLens: dx ? (!creation ? true : requiredFields.length === 0) : true,
    edgingData: !creation ? true : requiredFields.length === 0,
  })

  const [allErrorCheckFields, setAllErrorCheckFields] = useState({
    leftLens: false,
    rightLens: false,
    edgingData: false,
  })

  const diameterMinimumCalculationActive = getDiameterMinimumCalculationActive();
  const [opticalCenter, setOpticalCenter] = useState({right: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isRight: false, },
    left: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isLeft: false, }})

  useEffect(() => {
    const { rightValues, leftValues, edgingValues, notesValues } =
      job !== ""
        ? JobUtils.createDefaultState(job)
        : JobNewUtils.createDefaultState(
          job,
          companyId,
          companyName,
          labId,
          labName,
          opticianId,
          opticianName
        )

    setCurrentRightValues(rightValues)
    setCurrentLeftValues(leftValues)
    setCurrentEdgingValues(edgingValues)
    const createEdgingData = (r, l, e) => {
      const result = JobUtils.createSingleOmaEdgingData(e)
        .concat(JobUtils.createRightLeftOmaEdgingData(r, l))
        .join("\r\n")
      return result
    }
    setEdgingData(createEdgingData(rightValues, leftValues, edgingValues))

    setCurrentNotesValues(notesValues)
    const { shape } = job
    if (shape) {
      const { standard } = shape
      if (standard) setCurrentStandardShape(standard)

      const { original } = shape
      if (original) setCurrentOriginalShape(original)
    }
  }, [job, companyId, companyName, labId, labName, opticianId, opticianName])

  useEffect(() => {
    const createEdgingData = (r, l, e) => {
      const result = JobUtils.createSingleOmaEdgingData(e)
        .concat(JobUtils.createRightLeftOmaEdgingData(r, l))
        .join("\r\n")
      return result
    }
    setEdgingData(
      createEdgingData(
        currentRightValues,
        currentLeftValues,
        currentEdgingValues
      )
    )
  }, [currentRightValues, currentLeftValues, currentEdgingValues])

  useEffect(() => {
    const isGlasant = currentEdgingValues["etyp"] === "3"

    if (creation) {
      setAllRequired({
        leftLens: sx
          ? JobUtils.checkAllRequiredCompiled(
            currentLeftValues,
            requiredFields,
            isGlasant,
            edgingEightType,
            currentNotesValues.isPersonalized
          )
          : true,
        rightLens: dx
          ? JobUtils.checkAllRequiredCompiled(
            currentRightValues,
            requiredFields,
            isGlasant,
            edgingEightType,
            currentNotesValues.isPersonalized
          )
          : true,
        edgingData: JobUtils.checkAllRequiredCompiled(
          currentEdgingValues,
          requiredFields,
          isGlasant,
          edgingEightType,
          currentNotesValues.isPersonalized
        ),
      })
    }
    setAllErrorCheckFields({
      leftLens: sx ? !JobUtils.checkFormatLens(currentLeftValues, t) : false,
      rightLens: dx ? !JobUtils.checkFormatLens(currentRightValues, t) : false,
      edgingData: !JobUtils.checkFormatEdgingData(
        currentEdgingValues,
        isGlasant,
        t
      ),
    })
  }, [
    creation,
    currentLeftValues,
    currentRightValues,
    currentEdgingValues,
    currentStandardShape,
    currentNotesValues,
    edgingEightType,
    dx,
    sx,
    requiredFields,
    setAllRequired,
    t,
  ])

  function handleUpdateEdgingData(
    standardShape,
    edgingData,
    originalShape,
    deviceUploader = false
  ) {
    const {
      rightValues,
      leftValues,
      edgingValues,
    } = JobUtils.createDefaultState({ shape: { edgingData } })
    const rightValuesFromTracer = _.pickBy(rightValues, function (value, key) {
      return value !== "" && !currentRightValues[key]
    })

    const leftValuesFromTracer = _.pickBy(leftValues, function (value, key) {
      return value !== "" && !currentLeftValues[key]
    })

    const edgingValuesFromTracer = _.pickBy(edgingValues, function (value, key) {
      return value !== "" && !currentEdgingValues[key]
    })
    // 2020/06/30 - Aggiungo manualmente drillingPoints (xk è array)
    edgingValuesFromTracer.drillingPoints = edgingValues.drillingPoints
    edgingValuesFromTracer.drillePoints = edgingValues.drillePoints

    //2020/10/05 - Aggiorno i dati di dima orizzontale - dima verticale - ponte
    //Lente destra
    rightValuesFromTracer.hbox = rightValues.hbox
    rightValuesFromTracer.vbox = rightValues.vbox
    //Lente sinistra
    leftValuesFromTracer.hbox = leftValues.hbox
    leftValuesFromTracer.vbox = leftValues.vbox
    //Ponte
    edgingValuesFromTracer.dbl = edgingValues.dbl

    let tmpRightValues = { ...currentRightValues }
    _.forEach(rightValuesFromTracer, (v, k) => {
      tmpRightValues[k] = v
    })

    let tmpLeftValues = { ...currentLeftValues }
    _.forEach(leftValuesFromTracer, (v, k) => {
      tmpLeftValues[k] = v
    })

    let tmpEdgingValues = { ...currentEdgingValues }
    _.forEach(edgingValuesFromTracer, (v, k) => {
      tmpEdgingValues[k] = v
    })
    setCurrentRightValues(tmpRightValues)
    setCurrentLeftValues(tmpLeftValues)
    setCurrentEdgingValues(tmpEdgingValues)
    setCurrentOriginalShape(originalShape)
    setCurrentStandardShape(standardShape)
    setHasDeviceUploader(deviceUploader)
  }

  const { jobId } = job

  /*************************************************************************
                   FUNZIONI MODIFICA VALORI
  *************************************************************************/

  // Funzione per modificare valori lente destra
  const handleRightChange = (name) => (event) => {
    const value = event.target.value
    setCurrentRightValues({ ...currentRightValues, [name]: value })
  }

  // Funzione per modificare valori lente sinistra
  const handleLeftChange = (name) => (event) => {
    const value = event.target.value
    setCurrentLeftValues({ ...currentLeftValues, [name]: value })
  }

  // Funzione per modificare valori lente destra
  const handleRightOnBlur = (name, format) => (event) => {
    const value = event.target.value
    setCurrentRightValues({ ...currentRightValues, [name]: format(value) })
  }

  // Funzione per modificare valori lente sinistra
  const handleLeftOnBlur = (name, format) => (event) => {
    const value = event.target.value
    setCurrentLeftValues({ ...currentLeftValues, [name]: format(value) })
  }

  // Funzione per modificare valori edging data
  const handleEdgingDataOnBlur = (name, format) => (event) => {
    const value = event.target.value
    setCurrentEdgingValues({ ...currentEdgingValues, [name]: format(value) })
  }

  // Funzione per modificare valori montaggio
  const handleEdgingChange = (name) => (event) => {
    const value = event.target.value
    // onChange particolari (regole di ottica applicate)
    switch (name) {
      case "etyp":
        onChangeEtyp(name, value)
        break
      case "bevp":
        onChangeBevp(name, value)
        break
      case "bevm":
        onChangeBevm(name, value)
        break
      default:
        setCurrentEdgingValues({ ...currentEdgingValues, [name]: value })
        break
    }
  }
  // Funzione per modificare i valori dei fori
  const handleDrillChange = (drillingValues) => {
    var drillOma = []
    var drilleOma = []
    var appDrilleOma = []
    for (var i = 0; i < drillingValues.length; i++) {
      var drillValue = OmaUtils.CreateOmaDrillRecord(drillingValues[i])
      drillOma.push(drillValue)
      appDrilleOma.push("DRILL=" + drillValue)
    }
    var drillString = appDrilleOma.join("\r\n")
    var pegasoToolsCall = OmaUtils.CreateOmaDrilleRecord(apiUrlPegasoTools, drillString, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t)
    pegasoToolsCall.then(function (result) {
      var data = result
      drilleOma = data.split("\r\n", -1)
      drilleOma = drilleOma.map(x => x.substring(x.indexOf("=") + 1)).filter(x => x.length > 0)
      setCurrentEdgingValues({ ...currentEdgingValues, drillingPoints: drillOma, drillePoints: drilleOma })
    })
  }
  // Funzione per modificare lo stato del JOB
  const handleUpdateSatus = (name) => (event) => {
    setCurrentHeadValues({ ...currentHeadValues, status: event.target.value })
  }

  function onChangeEtyp(name, value) {
    if (value === "3") {
      // NYLOR
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevp: "7",
        bevm: "",
        ftyp: "",
      })
    } else if (value === "2") {
      // GLASANT
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevp: "4",
        bevm: "50",
        ftyp: "3",
      })
    } else {
      // BISELLO
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevp: "2",
        bevm: "33",
        ftyp: "",
      })
    }
  }

  function onChangeBevp(name, value) {
    if (value === "2") {
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevm: "33",
      })
    } else if (value === "4") {
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevm: "50",
      })
    } else {
      setCurrentEdgingValues({
        ...currentEdgingValues,
        [name]: value,
        bevm: "",
      })
    }
  }

  function onChangeBevm(name, value) {
    var newValue = value.trim()
    if (newValue !== "") {
      newValue = value + ";" + value
    }
    setCurrentEdgingValues({
      ...currentEdgingValues,
      [name]: newValue,
    })
  }

  //Funzione che modifica l'OMA label DO in base alle lenti selezionate
  function checkActiveSideForDo(right, left) {
    if (right && left) {
      setCurrentEdgingValues({ ...currentEdgingValues, do: "B" })
    }
    else if (!right && left) {
      setCurrentEdgingValues({ ...currentEdgingValues, do: "L" })
    }
    else if (right && !left) {
      setCurrentEdgingValues({ ...currentEdgingValues, do: "R" })
    }
  }

  // Funzione per modificare valori note
  const handleNotesChange = (name) => (event) => {
    const value = event.target.value
    setCurrentNotesValues({ ...currentNotesValues, [name]: value })
  }

  // Funzione per modificare valori note
  const handleNotesChangeProduct = (lensProductValue, coatingValue, isPersonalized, isProgressive) => {
    setCurrentNotesValues({ ...currentNotesValues, lensProduct: lensProductValue, coating: coatingValue, isPersonalized: isPersonalized, isProgressive: isProgressive })
    if(isProgressive){
      setCurrentLeftValues({ ...currentLeftValues, ltyp:"PR"  })
      setCurrentRightValues({ ...currentRightValues, ltyp:"PR"  })
      setOpticalCenter({right: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isRight: false, },
        left: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isLeft: false, }})
    }
    else{
      setCurrentLeftValues({ ...currentLeftValues, ltyp:""  })
      setCurrentRightValues({ ...currentRightValues, ltyp:""  })
      setOpticalCenter({right: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isRight: false, },
        left: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isLeft: false, }})
    }
  }

  const handleNotesChangeActiveDx = (event) => {
    setCurrentNotesValues((prevState) => {
      return { ...prevState, dx: event.target.checked }
    })
    checkActiveSideForDo(event.target.checked, currentNotesValues.sx)

  }

  const handleNotesChangeActiveSx = (event) => {
    setCurrentNotesValues((prevState) => {
      return { ...prevState, sx: event.target.checked }
    })
    checkActiveSideForDo(currentNotesValues.dx, event.target.checked)
  }

  const handleNotesChangePrecal = (value) => {
    setCurrentNotesValues((prevState) => {
      return { ...prevState, precal: value }
    })
  }

  const handleClose = () => {
    setOpenDialog(false)
    onHandleClose()
  }
  /*************************************************************************
                    FUNZIONE BOTTONI
   **************************************************************************/

  // Funzione associata al bottone "Edit"
  const handleEdit = () => {
    if (editMode) {
      setCurrentRightValues({ ...rightValues })
      setCurrentLeftValues({ ...leftValues })
      setCurrentEdgingValues({ ...edgingValues })
      setCurrentNotesValues({ ...notesValues })
    }
    setEditMode(!editMode)
  }

  // Funzione associata al bottone "Ristampa"
  const handlePrint = () => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}/printRequest?year=${year}`
    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        setDialogMsg(t("10053"))
        setOpenDialog(true)
        setIsSaving(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("1"), err)
        setDialogMsg(t("1"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Funzione associata al bottone "Save"
  async function handleSave() {
    setIsSaving(true)
    var newLine = "\r\n"
    var newOmaValues // Array con i nuovi valori di montaggio (originali + nuovi da GUI)
    var guiOmaValues // Array con i valori della GUI
    var originalOmaValues = edgingData.split(newLine) // Array con i valori presi da Job
    var drillingValues // Array con i valori dei fori
    // Creazione array con i dati della GUI
    guiOmaValues = JobUtils.createRightLeftOmaEdgingData(
      currentRightValues,
      currentLeftValues
    )
    guiOmaValues = guiOmaValues.concat(
      JobUtils.createSingleOmaEdgingData(currentEdgingValues)
    )
    if (edgingEightType === "EDGE") {
      if (currentStandardShape) {
        var ochtValue = await EdgingDataUtils.convertSeghtToOcht(currentRightValues, currentLeftValues, currentStandardShape, edgingData, currentEdgingValues, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t)
        if (ochtValue !== "") originalOmaValues.push(ochtValue)
      }
    } else if (edgingEightType === "BOX") {
      if (currentStandardShape) {
        var seghtValue = await EdgingDataUtils.convertOchtToSeght(currentRightValues, currentLeftValues, currentStandardShape, edgingData, currentEdgingValues, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t)
        if (seghtValue !== "") originalOmaValues.push(seghtValue)
      }
    }

    // 2020/06/30 - Tolgo i vecchi drillingPoints ed estrapolo i nuovi
    originalOmaValues = OmaUtils.RemoveOmaLabel(originalOmaValues, "DRILL")
    drillingValues = OmaUtils.GetOmaLabels(guiOmaValues, "DRILL")
    guiOmaValues = OmaUtils.RemoveOmaLabel(originalOmaValues, "DRILL")

    originalOmaValues = OmaUtils.RemoveOmaLabel(originalOmaValues, "DRILLE")
    guiOmaValues = OmaUtils.RemoveOmaLabel(originalOmaValues, "DRILLE")

    // Creo la nuva stringa con i dati montaggio
    newOmaValues = OmaUtils.SearchAndReplaceOmaData(
      originalOmaValues,
      guiOmaValues
    ).join(newLine)

    // 2020/06/30 - Aggiungo i nuovi drillingPoints
    if (drillingValues.length > 0) {
      newOmaValues = newOmaValues + newLine + drillingValues.join(newLine)
    }
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${creation ? newJobId : jobId
      }?year=${year}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        creation
          ? {
            jobId: newJobId,
            year,
            lab: labId,
            optician: opticianId,
            shape: {
              edgingData: newOmaValues,
              offset: currentEdgingValues.offset
                ? currentEdgingValues.offset !== ""
                  ? currentEdgingValues.offset
                  : 0.0
                : 0.0,
              original: currentOriginalShape,
              standard: currentStandardShape,
            },
            notes: currentNotesValues,

            uploader: {
              software: "minigui",
              username: getUsername(),
              fromHub: false,
              fromScope: false,
              hasDeviceUploader: hasDeviceUploader,
            },
          }
          : {
            jobId: jobId,
            lab: labId,
            shape: {
              edgingData: newOmaValues,
              original: currentOriginalShape, // Aggiorna forma solo se jobId < maxInternalJobId
              standard: currentStandardShape, // Aggiorna forma solo se jobId < maxInternalJobId
            },
            notes: currentNotesValues,
            username: getUsername(),
            status: currentHeadValues.status,
          }
      ),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        creation ? setDialogMsg(t("10057")) : setDialogMsg(t("10297"));
        setOpenDialog(true)
        setIsSaving(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("2"), err)
        setDialogMsg(t("2"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Funzione associata al bottone "Delete"
  const handleDelete = () => {
    setOpenConfirmDelete(true)
  }

  // Click Chiudi finestra DELETE Row
  function handleCloseConfirmDelete() {
    setOpenConfirmDelete(false)
  }

  const fetchDeleteJob = () => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}?year=${year}`
    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        setDialogMsg(jobId + t("10424"))
        setOpenConfirmDelete(false)
        setIsSaving(false)
        handleClickRefresh()
        onHandleClose()
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("1"), err)
        setDialogMsg(t("1"))
        setOpenDialog(true)
        setIsSaving(false)
        onHandleClose()
      })
  }

  /*************************************************************************
                    FUNZIONE SCROLL (BARRA IN ALTO FISSATA)
   **************************************************************************/
  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  function ScrollTop(props) {
    const { children, classes } = props
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      )
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    return (
      <Zoom in={true}>
        <div onClick={handleClick} role="presentation" className={classes.zoom}>
          {children}
        </div>
      </Zoom>
    )
  }

  function handleCloneRight() {
    setCurrentLeftValues({ ...currentRightValues, side: "L" })
  }

  function handleCloneLeft() {
    setCurrentRightValues({ ...currentLeftValues, side: "R" })
  }

  //2022/02/07 -- Funzione che controlla la presenza di forma o prodotto --> Regola: se ho catalogueScope abilitata controlla che ci sia forma o prodotto. 
  //Se catalogueScope non è abilitata controlla solo la presenza della forma
  function calculateShapeOrProductMissing(catalogueScope, shapeMissing, productMissing) {
    if (isLabUser()) return false
    if (catalogueScope) {
      if (shapeMissing && productMissing) return true
      if (shapeMissing || productMissing) return false
      if (!shapeMissing && !productMissing) return false
    }
    else return shapeMissing
  }

  function checkMinimumDiameter() {
    if (diameterMinimumCalculationActive) {
      if(parseInt(currentRightValues.dia) < opticalCenter.right.diameter){
        return true
      }
      if(parseInt(currentLeftValues.dia) < opticalCenter.left.diameter){
        return true
      }
      return false
    }
    else return false
  }

  const checkFieldErrors =
    allErrorCheckFields["leftLens"] ||
    allErrorCheckFields["rightLens"] ||
    allErrorCheckFields["edgingData"]
  const requiredFieldMissing =
    !allRequired["leftLens"] ||
    !allRequired["rightLens"] ||
    !allRequired["edgingData"]
  const shapeMissing = !currentStandardShape
  const productMissing = catalogueScope
    ? !currentNotesValues.lensProduct
    : false
  const shapeOrProductMissing = calculateShapeOrProductMissing(catalogueScope, shapeMissing, productMissing)
  const diameterNotCorrect = checkMinimumDiameter()
  const disabledSave = (checkFieldErrors || requiredFieldMissing || shapeOrProductMissing || diameterNotCorrect)

  return (
    <div className={classes.jobRoot} ref={componentRef}>
      <Card className={classes.card}>
        <CardBody
          className={classes.card}
        >
          <Toolbar id="back-to-top-anchor" style={{
            minHeight: '0px',
          }} />

          {/* X CHIUSURA IN ALTO */}
          <ElevationScroll {...props}>
            <AppBar
              className={creation ? classes.appbar : (classes.appbarShowJob)}
              position="sticky"
            >
              <Box display="flex" flexDirection="row-reverse" p={1}>
                <Box p={1}>
                  <IconButton
                    aria-label={t("10238")}
                    variant="outlined"
                    onClick={onHandleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </AppBar>
          </ElevationScroll>

          {isOpticianUser() ? (
            <Box display="flex" p={1} className={classes.jobGridToolbar}>
              {/* OPTICIAN USER -> TOP BUTTONS */}
              {creation ? (
                <Box p={1} flexGrow={1} />
              ) : (
                <Fragment>
                  <ReactToPrint
                    trigger={() =>
                      <Button
                        disabled={editMode}
                        variant="outlined"
                        color="secondary"
                      >
                        {t("10103")}
                      </Button>
                    }
                    content={() => componentRef.current}
                  />
                </Fragment>)
              }
            </Box>
          ) : (
            <Box display="flex" p={1} className={classes.jobGridToolbar}>
              {/* LAB USER -> TOP BUTTONS */}
              {creation ? (
                <Box p={1} flexGrow={1} />
              ) : (
                <Fragment>
                  <Box p={1} flexGrow={1}>
                    <Button
                      disabled={editMode}
                      variant="outlined"
                      color="secondary"
                      onClick={handlePrint}
                    >
                      {t("10104")}
                    </Button>
                  </Box>
                  <Box p={1} /*flexGrow={2}*/ marginRight={85}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={editMode}
                      onClick={handleDelete}
                      startIcon={<DeleteIcon />}
                    >
                      {t("10240")}
                    </Button>
                  </Box>
                  <Box p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={editMode}
                      onClick={handleEdit}
                    >
                      {t("10032")}
                    </Button>
                  </Box>
                  {/* DIALOG - OPEN DELETE ROW */}
                  <Dialog
                    open={openConfirmDelete}
                    onClose={handleCloseConfirmDelete}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {t("10241")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {t("10242")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseConfirmDelete}
                        color="primary"
                      >
                        {t("10243")}
                      </Button>
                      <Button onClick={fetchDeleteJob} color="primary">
                        {t("10244")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Fragment>
              )}
            </Box>
          )}

          <Typography className={classes.typographyStyle}>{t("10222").toUpperCase()}</Typography>

          <Grid container spacing={2}>
            <Grid className={classes.jobGridHeadValues} item xs={12}>
              <HeadDataGrid
                headValues={currentHeadValues}
                onChange={handleUpdateSatus}
                requiredFields={requiredFields}
                creation={creation}
                editMode={editMode}
                jobId={newJobId}
              />
            </Grid>
            {/*<Grid className={classes.jobGridDeviceValues} item xs={12}>
              <DeviceDataGrid jobDevices={jobDevices} />
            </Grid>*/}

            {/*PRODUCTS*/}
            {
              catalogueScope ? (
                <Accordion defaultExpanded={true} className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.typographyStyle}>{t("10215").toUpperCase()}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProductTable
                      opticianId={!creation ? job.optician.id : opticianId}
                      editModeProduct={editProductOpen}
                      editMode={editMode}
                      className={classes.jobGridCurrentNotesValues}
                      handleOpenClick={() => {
                        setEditProductOpen(true)
                      }}
                      handleCloseClick={setEditProductOpen}
                      handleAddProduct={handleNotesChangeProduct}
                      setEditModeProduct={setEditProductOpen}
                      creation={creation}
                      product={currentNotesValues.lensProduct}
                      coating={currentNotesValues.coating}
                      personalized={currentNotesValues.isPersonalized}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null
            }
            {/*LENS DATA*/}
            <Accordion defaultExpanded={true} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.typographyStyle}>{t("10406").toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className={classes.jobGridCurrentRightValues} item xs={6}>
                  <LensDataGrid
                    handleClone={handleCloneRight}
                    edgingDetails={currentRightValues}
                    editMode={editMode}
                    onChange={handleRightChange}
                    onblur={handleRightOnBlur}
                    active={dx}
                    onChangeActive={handleNotesChangeActiveDx}
                    requiredFields={creation ? requiredFields : []}
                    personalized={currentNotesValues.isPersonalized}
                  />
                </Grid>
                <Grid className={classes.jobGridCurrentLeftValues} item xs={6}>
                  <LensDataGrid
                    handleClone={handleCloneLeft}
                    edgingDetails={currentLeftValues}
                    editMode={editMode}
                    onChange={handleLeftChange}
                    onblur={handleLeftOnBlur}
                    active={sx}
                    onChangeActive={handleNotesChangeActiveSx}
                    requiredFields={creation ? requiredFields : []}
                    personalized={currentNotesValues.isPersonalized}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/*SHAPE DATA*/}
            <Accordion defaultExpanded={true} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.typographyStyle}>{t("10221").toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className={classes.jobGridStandardShape} item xs={12}>
                  <Shape
                    creation={creation}
                    currentEdgingValues={currentEdgingValues}
                    edgingData={edgingData}
                    editMode={editMode}
                    isGlasant={currentEdgingValues["ftyp"] === "3"}
                    isProgressive={currentNotesValues.isProgressive}
                    leftValues={currentLeftValues}
                    jobId={jobId}
                    jobSoftware={jobSoftware}
                    handleUpdateEdgingData={handleUpdateEdgingData}
                    handleDrillChange={handleDrillChange}
                    handleNotesChangePrecal={handleNotesChangePrecal}
                    offset={currentEdgingValues.offset}
                    opticalCenter={opticalCenter}
                    rightValues={currentRightValues}
                    scaleX={4}
                    scaleY={4}
                    setOpticalCenter={setOpticalCenter}
                    shape={currentStandardShape ? currentStandardShape : ""}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/*EDGING DATA*/}
            <Accordion defaultExpanded={true} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.typographyStyle}>{t("10031").toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className={classes.jobGridCurrentEdgingValues} item xs={12}>
                  <EdgingDataGrid
                    creation={creation}
                    edgingDetails={currentEdgingValues}
                    editMode={editMode}
                    notesDetails={currentNotesValues}
                    onChangeEdgingData={handleEdgingChange}
                    onChangeNotes={handleNotesChange}
                    onblur={handleEdgingDataOnBlur}
                    requiredFields={
                      creation
                        ? currentEdgingValues["etyp"] === "3"
                          ? requiredFields.filter(
                            (f) => f !== "bevm" && f !== "bevp"
                          )
                          : requiredFields
                        : []
                    }
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/*NOTES DATA*/}
            <Accordion defaultExpanded={true} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.typographyStyle}>{t("10038").toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className={classes.jobGridCurrentNotesValues} item xs={12}>
                  <NotesDataGrid
                    edgingDetails={currentNotesValues}
                    editMode={editMode}
                    onChange={handleNotesChange}
                    status={currentHeadValues.status}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

          </Grid>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.jobGridToolbar}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              p={1}
              style={{ color: "red" }}
            >
              {editMode && catalogueScope && shapeOrProductMissing && !isLabUser() ? (
                <Box p={1}>{t("29")} </Box>
              ) : null}
              {editMode && !catalogueScope && shapeMissing && !isLabUser() ? (
                <Box p={1}> {t("30")} </Box>
              ) : null}
              {editMode && requiredFieldMissing && !isLabUser() ? (
                <Box p={1}>{t("31")} </Box>
              ) : null}
              {editMode && checkFieldErrors && !isLabUser() ? (
                <Box p={1}> {t("32")} </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            className={classes.jobGridToolbar}
          >
            {editMode && !creation ? (
              <Box p={1} marginRight={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEdit}
                >
                  {t("10010")}
                </Button>
              </Box>
            ) : null}

            {editMode ? (
              <Box p={1}>
                <Button
                  disabled={disabledSave}
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                >
                  {creation ? t("10236") : t("10114")}
                </Button>
              </Box>
            ) : null}
          </Box>

          {/* SCROLL TO TOP */}
          <ScrollTop {...props} classes={classes}>
            <Fab color="secondary" size="small">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>

          {/* MESSAGGI DIALOGO */}
          <Dialog
            open={isSaving || openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            {openDialog ? (
              <Fragment>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {dialogMsg}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Fragment>
            ) : (
              <Fragment>
                <DialogContent>
                  <DialogContent>
                    <CircularProgress size={42} style={{ color: "primary" }} />
                  </DialogContent>
                </DialogContent>
              </Fragment>
            )}
          </Dialog>
        </CardBody>
      </Card>
    </div >
  )
}

// Griglia interna alla finestra del Job
function Job(props) {
  const { job, creation } = props

  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()

  if (job) {
    return <EdgingGridContainer {...props} />
  } else if (!job && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}

export default Job
