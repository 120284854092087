import React, { useContext } from 'react'
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { RootContext } from 'RootContext';

// Button che apre la finestra con i dati del Job
function ButtonDialogJob(props) {
  const {handleClickOpen, year, job } = props
  const { t } = useContext(RootContext)

  return (
    <div>
      <Tooltip title={t("10155")}>
        <IconButton aria-label={t("10155")} variant="outlined" onClick={()=>{handleClickOpen(year, job)}}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

function JobCloneButton(props) {
  return (
    <div id="contenitore">
      <ButtonDialogJob  {...props} />
    </div>
  );
}

export default JobCloneButton;


