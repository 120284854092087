/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";  // NON ELIMINARE HashRouter
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './styles/theme'
import './locales/i18n'
import RootContext from './RootContext'
import Login from './components/Login/Login'

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import LoginError from "components/Login/LoginError";

const hist = createBrowserHistory();

ReactDOM.render(
  <RootContext>
    <MuiThemeProvider theme={theme}>

      <Router history={hist}>
        <Switch>
          <PublicRoute exact path="/:companyId" component={Login} />
          <PublicRoute exact path="/:null" component={LoginError} />
          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute path="/rtl" component={RTL} />
          <PublicRoute exact path="/" component={LoginError} />
          {/* <Redirect from="/" to="/59Cuy8Nz" /> */}
        </Switch>
      </Router>
    </MuiThemeProvider>
  </RootContext>,
  document.getElementById("root")
);

export function PublicRoute({ component: Component, ...rest }) {
  //const { authenticated } = useContext(RootContext)
  const authenticated = window.sessionStorage.getItem('authenticated') || null
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Redirect
            to={{
              pathname: `/admin`,
              state: { from: props.location },
            }}
          />
        ) :
          (
            <Component {...props} />
          )
      }
    />
  )
}

export function PrivateRoute({ component: Component, ...rest }) {
  //const { authenticated,companyId} = useContext(RootContext)   
  const company_id = window.sessionStorage.getItem('company_id') || null
  const authenticated = window.sessionStorage.getItem('company_id') || null
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/${company_id}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}