import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

// Core components
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import { titleCase } from "title-case"

// Pegaso components
import DeviceUtils from "components/Utils/DeviceUtils"
import OmaUtils from "components/Utils/OmaUtils"
import TracerUtils from "components/Utils/TracerUtils"
import TracerBar from "views/Tracer/TracerBar"
import TracerLoading from "views/Tracer/TracerLoading"
import { useTracerStyle } from "styles/shape/tracerCss"

export default function Tracer({ handleClose, handleUpdateShape, handleNotesChangePrecal }) {
  const classes = useTracerStyle()
  const { t,
    apiUrlPegasoTools,
    access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    setAccess_token_pegaso_tools,
    userDevice
  } = useContext(RootContext)

  const [fetching, setFetching] = useState(false)
  const [init, setInit] = useState(true)
  const [tracerData, setTracerData] = useState("")

  const device = userDevice
  const { protocol } = device
  const { options } = protocol
  const { tracerId } = options
  const { serial } = device
  const { port, settings } = serial

  const tracerName = DeviceUtils.tracerIdValue().find((elem) => {
    return tracerId === elem.value
  }).label

  const serviceMessages = {
    status: [t("10185"), t("10186"), t("10187")],
    error: [t("22"), t("23"), t("24")]
  }
  const tracerJSON = TracerUtils.getJsonDevice(device)

  // Tracer Service 
  const [debugMode, setDebugMode] = useState(false)
  const [waitMode, setWaitMode] = useState(false)
  const [tracerEventRequest, setTracerEventRequest] = useState(null)
  const [serviceError, setServiceError] = useState("")
  const [serviceResponse, setServiceResponse] = useState(null)

  // TracerLoading - ALL
  const handleTracerPopupAll = useCallback(() => {
    setInit(false)
    setFetching(true)
    setTracerEventRequest("ALL")
  }, [])

  // TracerLoading - DISCONNECT
  const handleTracerPopupDisconnect = useCallback(() => {
    setFetching(true)
    setTracerEventRequest("DISCONNECT")
    handleClose()
  }, [handleClose])

  // TracerLoading - CONNECT
  function handleTracerPopupConnect() {
    setFetching(true)
    setTracerEventRequest("CONNECT")
  }

  // TracerLoading - RECEIVE
  function handleTracerPopupReceive() {
    setFetching(true)
    setTracerEventRequest("RECEIVE")
  }

  /*
        USE EFFECT
  */

  // USE EFFECT -> INIT -> Disconnette porta, attiva Connetti Ciclico se WaitMode
  useEffect(() => {
    if (init) {
      var activeWaitMode = true
      if (tracerId === 1 || tracerId === 2) {
        activeWaitMode = false
      } else {
        handleTracerPopupAll()
      }
      setWaitMode(activeWaitMode)
    }

    return () => {
      if (!init) {
        // Disconnetti senza fare set state
        handleTracerPopupDisconnect()
      }
    }
  }, [
    init,
    tracerId,
    handleTracerPopupAll,
    handleTracerPopupDisconnect
  ])

  // USE EFFECT -> ERROR -> Alert di errore se ricevo errore
  useEffect(() => {
    if (serviceError !== "") {
      alert(serviceError)
      handleClose()
    }
  }, [serviceError, handleClose])

  // USE EFFECT -> DATA -> Se ricevo i dati dal tracer, li converto per il disegno
  useEffect(() => {
    var originalShape
    var standardShape
    var edgingData
    if (tracerData !== "") {
      originalShape = tracerData
      console.log("OriginalShape: " + originalShape)

      var tracerDataConverted = OmaUtils.ConvertDataToOma(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, originalShape, tracerId)
      tracerDataConverted.then(function (result) {
        var dataToOma = result
        // Preparo i 3 campi da passare per il disegno della shape
        standardShape = dataToOma[0]
        console.log("StandardShape: " + standardShape)
        edgingData = dataToOma[1]
        console.log("Edging data: " + edgingData)
        handleUpdateShape(originalShape, standardShape, edgingData, true)
        handleNotesChangePrecal(false)
        handleClose()
      })


    }
  }, [tracerData, tracerId, handleClose, handleUpdateShape, handleNotesChangePrecal, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t])


  // USE EFFECT -> 
  useEffect(() => {
    if (serviceResponse) {
      if (serviceResponse.length > 300) {
        // Ricevo risposta dal servizio -> dati del tracer
        var bufferStr = Buffer.from(serviceResponse, "base64").toString()
        console.log(bufferStr)
        setTracerData(bufferStr)
      } else {
        // Ricevo risposte dal servizio -> errori / warning
        console.log("Received message from PegasoDriver")
        console.log("Message ->" + serviceResponse)

        // Se errore 31 chiudo
        if (serviceResponse.indexOf("ERROR 31") >= 0) {
          setServiceResponse(t("24"))
        } else {
          setServiceResponse(t("23"))
        }
      }
    }
  }, [serviceResponse, t])

  // USE EFFECT -> attiva debug mode cliccando SHIFT + D
  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key.charCodeAt() === 68 && event.shiftKey) {
        var notDebugMode = !debugMode
        setDebugMode(notDebugMode)
      };
    });
  }, [debugMode]);

  return (
    <div className={classes.tracerContainer}>
      <Grid container spacing={2}>
        {/* FOTO TRACER */}
        <Grid align="center" item xs={2}>
          <div className={classes.tracerFoto}>
            <CardMedia
              component="img"
              image={TracerUtils.printTracer(tracerId)}
            />
          </div>
          {`${tracerName} - ${port} (${settings})`}
        </Grid>

        {/* ISTRUZIONI */}
        <Grid align="left" item xs={4}>
          {TracerUtils.tracerInstructions(tracerId, t)
            .split("\n")
            .map((item, i) => {
              return <p key={i}>{titleCase(item)}</p>
            })}
        </Grid>

        {/* NEW LOADING */}
        <Grid align="left" item xs={4}>
          {fetching && tracerJSON ? (
            <TracerLoading
              JSON_tracerJson={JSON.stringify(tracerJSON)}
              JSON_serviceMessages={JSON.stringify(serviceMessages)}
              setServiceError={setServiceError}
              setServiceResponse={setServiceResponse}
              tracerEventRequest={tracerEventRequest}
            />
          ) : null}
        </Grid>

        {/* PULSANTI */}
        <Grid align="left" item xs={2}>
          <TracerBar
            debugMode={debugMode}
            waitMode={waitMode}
            handleTracerPopupAll={handleTracerPopupAll}
            handleTracerPopupConnect={handleTracerPopupConnect}
            handleTracerPopupDisconnect={handleTracerPopupDisconnect}
            handleTracerPopupReceive={handleTracerPopupReceive}
          />
        </Grid>
      </Grid>
    </div>
  )
}
