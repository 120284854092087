import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RootContext } from '../../RootContext'

// Job Table -> HEAD


export default function JobsTableHead(props) {
  const { t } = useContext(RootContext)
  const headCells = [
    { id: 'jobId', numeric: false, disablePadding: true, label: t("10050") },
    { id: 'createdOn', numeric: false, disablePadding: true, label: t("10018") },
    { id: 'opticianView', numeric: false, disablePadding: false, label: t("10092") },
    { id: 'note', numeric: false, disablePadding: false, label: t("10094") },
    { id: 'optcst', numeric: false, disablePadding: false, label: t("10093") },
    { id: 'status', numeric: false, disablePadding: false, label: t("10129") },
    { id: 'editJob', numeric: false, disablePadding: false, label: '' },
    { id: 'copyJob', numeric: false, disablePadding: false, label: '' },
  ];
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead  >
            <TableRow >
                {headCells.map(headCell => (
                    <TableCell
                        className={classes.tableHeadCell}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label.toUpperCase()}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

JobsTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};
