//Pannello per la scelta del device
import React, { useContext, useEffect, } from "react"
import { RootContext } from "../../RootContext"

//core components
import Checkbox from "@material-ui/core/Checkbox"
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useDeviceStylesText } from "styles/device/deviceCss.js"

//Componenti Pegaso
import DeviceUtils from "components/Utils/DeviceUtils"

export default function OptionDevice(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    optionDevice,
    setChangeDevice,
    setOpenPanelOption,
    setOptionDevice,
  } = props

  //Classes utilizzate per CSS
  const classesText = useDeviceStylesText()

  const tracerIdValue = DeviceUtils.tracerIdValue()
  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    if (changeDevice) {
      setOpenPanelOption(false)
      setChangeDevice(false)
    }
  }, [changeDevice, setOptionDevice,  setChangeDevice, setOpenPanelOption])

  /*OPTION HANDLER*/

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setOptionDevice({ ...optionDevice, [name]: event.target.checked })
  }

  //Cambia field
  const handleChange = (name) => (event) => {
    var value =event.target.value
    switch(name){
      case "offsetPlastic":
      case "offsetMetal":
      case "offsetOther":
        value = (value.length === 0 ? 0 : parseInt(value))
        setOptionDevice({ ...optionDevice, [name]: value })
        break
      default:
        value = event.target.value.trim()
        setOptionDevice({ ...optionDevice, [name]: value })
        break
    }
  }

  const handleChangeTracerId = (event) => {
    setOptionDevice({ ...optionDevice, tracerId: event.target.value })
  }

  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"idProtocol"}
            label={t("10272")}
            className={classesText.textField}
            value={optionDevice.idProtocol}
            margin="normal"
            onChange={handleChange("idProtocol")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"jobWithoutBarcode"}
            label={t("10273")}
            className={classesText.textField}
            value={optionDevice.jobWithoutBarcode}
            margin="normal"
            onChange={handleChange("10273")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.nack}
                onChange={handleChangeChecked("nack")}
                name="nack"
              />
            }
            label={t("10274")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetMetal"}
            label={t("10275")}
            className={classesText.textField}
            value={optionDevice.offsetMetal}
            margin="normal"
            onChange={handleChange("10275")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetOther"}
            label={t("10295")}
            className={classesText.textField}
            value={optionDevice.offsetOther}
            margin="normal"
            onChange={handleChange("10295")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"offsetPlastic"}
            label={t("10276")}
            className={classesText.textField}
            value={optionDevice.offsetPlastic}
            margin="normal"
            onChange={handleChange("10276")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.singleSide}
                onChange={handleChangeChecked("singleSide")}
                name="singleSide"
              />
            }
            label={t("10277")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            id={"tracerId"}
            label={t("10278")}
            className={classesText.textField}
            value={optionDevice.tracerId}
            margin="normal"
            onChange={handleChangeTracerId}
            helperText={t("10278")}
          >
            {tracerIdValue.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={optionDevice.withoutBarcode}
                onChange={handleChangeChecked("withoutBarcode")}
                name="withoutBarcode"
              />
            }
            label={t("10279")}
          />
        </Grid>
      </Grid>
    </div>
  )
}