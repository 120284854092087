//Pannello per la scelta del device
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"

//import CSS
import {
  useDeviceEditScreenStyles,
  useDeviceStylesText,
} from "styles/device/deviceCss.js"
import UserUtils from "components/Utils/UserUtils"

//pegaso/views/components
import ScrollUtils from "components/Tools/ScrollUtils.js"
import DeviceUtils from "components/Utils/DeviceUtils"
import FilterDeviceType from "../Device/FilterDeviceType"

export default function FilterDevice(props) {
  const { t } = useContext(RootContext)

  const {
    adminTableRows,
    allBrandsDevices,
    creation,
    isHub,
    handleClickClose,
    hideAdvanced,
    hubDevicesJSON,
    selectedDeviceJSON,
    setAdminTableRows,
    setHubDevicesJSON,
    setSelectedDeviceJSON,
    userDevice,
  } = props

  //Classes utilizzate per CSS
  const classes = useDeviceEditScreenStyles()
  const classesText = useDeviceStylesText()

  //Variabili
  const [isRetailer] = useState(false)

  //Funzioni per settari i valori
  const ipPortValue = () => {
    if (connectionType === "ip") {
      return userDevice.ip.port
    } else {
      return ""
    }
  }
  const portValue = () => {
    if (userDevice.connection === "serial") {
      let app = userDevice.serial.port.split("COM")
      return app[1]
    } else {
      return ""
    }
  }
  const ipValue = () => {
    if (connectionType === "ip") {
      return userDevice.ip.address
    } else {
      return ""
    }
  }
  const settingValue = () => {
    if (connectionType === "serial") {
      let appData = userDevice.serial.settings.split(",")
      return {
        speedPort: appData[0],
        parityBit: appData[1],
        dataBit: appData[2],
        stopBit: appData[3],
      }
    } else {
      return {
        speedPort: "",
        parityBit: "",
        dataBit: "",
        stopBit: "",
      }
    }
  }

  //Valori dei dati

  var hubDeviceId = 0
  if (isHub) {
    hubDeviceId =
      creation || !userDevice
        ? DeviceUtils.countDeviceId(adminTableRows)
        : userDevice.deviceId
  }

  const [active, setActive] = useState(
    creation || !userDevice ? false : userDevice.active
  )
  const [brandId, setBrandId] = useState(
    creation || !userDevice ? "" : userDevice.model.brand.brandId
  )
  const [brandModels, setBrandModels] = useState(
    creation || !userDevice
      ? []
      : allBrandsDevices.filter((option) => {
        return option.brandId === brandId
      })[0].models
  )
  const [changeDevice, setChangeDevice] = useState(false)
  const [comunicationPort, setComunicationPort] = useState(
    creation || !userDevice ? undefined : portValue
  )
  const [connectionType, setConnetionType] = useState(
    creation || !userDevice ? undefined : userDevice.connection
  )
  const [ipAddress, setIpAddress] = useState(ipValue)
  const [ipPort, setIpPort] = useState(ipPortValue)
  const [protocol, setProtocol] = useState(
    creation || !userDevice ? undefined : userDevice.protocol
  )
  const [selectedDeviceId, setSelectedDeviceId] = useState(
    creation || !userDevice ? "" : userDevice.model.modelId
  )
  const [selectedDeviceData, setSelectedDeviceData] = useState(
    creation || !userDevice
      ? {}
      : brandModels.filter((option) => {
        return option.modelId === selectedDeviceId
      })[0]
  )
  const [settings, setSettings] = useState(settingValue)

  var editableBrand = true
  if (isHub && !creation) {
    editableBrand = false
  }

  //Valori setting porta di comunicazione (DA ESPORTARE IN UN FILE UTILS)
  const speedPortValue = DeviceUtils.speedPortValue()

  const dataBitValue = DeviceUtils.dataBitValue()

  const stopBitValue = DeviceUtils.stopBitValue()

  const parityBitValue = DeviceUtils.parityBitValue()
  /*************************************
   *
   * Funzioni
   *
   *************************************/

  //Inserisce in brandModels i device del brand selezionato
  function changeBrandModels(brandId) {
    setBrandModels(
      allBrandsDevices.filter((option) => {
        return option.brandId === brandId
      })[0].models
    )
  }

  function getSettings(device) {
    let appData = device.serial.settings.split(",")
    return {
      speedPort: appData[0],
      parityBit: appData[1],
      dataBit: appData[2],
      stopBit: appData[3],
    }
  }

  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia brand utente
  const handleChangeBrandId = (event) => {
    let value = event.target.value
    setBrandId(value)
    setSelectedDeviceId("")
    changeBrandModels(value)
    setSelectedDeviceJSON(undefined)
    setConnetionType(undefined)
  }

  //Cambia il device selezionato
  const handleSelectDevice = (event) => {
    let value, selected
    value = event.target.value
    setSelectedDeviceId(value)
    selected = brandModels.filter((option) => {
      return option.modelId === value
    })[0]
    setSelectedDeviceData(selected)
    setComunicationPort(selected.serial.port.split("COM")[1])
    setSettings(getSettings(selected))
    setChangeDevice(true)
    setProtocol(selected.protocol)
    setConnetionType("serial")
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      model: value,
      protocol: selected.protocol,
      connection: "serial",
      serial: selected.serial,
    })
  }

  //Attiva il device
  const handleChangeActive = (event) => {
    let value = event.target.checked
    setActive(value)
    setSelectedDeviceJSON({ ...selectedDeviceJSON, active: value })
  }

  //Cambia il tipo di connessione, SERIALE
  const handleChangeSerial = (event) => {
    let value = event.target.checked
    if (value) {
      setConnetionType("serial")
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: "serial",
        serial: selectedDeviceData.serial,
      })
    } else {
      setConnetionType(undefined)
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: undefined,
        serial: undefined,
        ip: undefined,
      })
    }
  }

  //Cambia il tipo di connessione, IP
  const handleChangeIp = (event) => {
    let value = event.target.checked
    if (value) {
      setConnetionType("ip")
      setSelectedDeviceJSON({ ...selectedDeviceJSON, connection: "ip" })
    } else {
      setConnetionType(undefined)
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        connection: undefined,
        serial: undefined,
        ip: undefined,
      })
    }
  }

  //Cambia porta com o indirizzo IP
  const handleComunicationPort = (event) => {
    let value = event.target.value
    setComunicationPort(value)
    if (connectionType === "serial") {
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        serial: {
          port: "COM" + value,
          timeout: selectedDeviceData.serial.timeout,
          settings:
            settings.speedPort +
            "," +
            settings.parityBit +
            "," +
            settings.dataBit +
            "," +
            settings.stopBit,
          handshake: selectedDeviceData.serial.handshake,
          readBufferSize: selectedDeviceData.serial.readBufferSize,
          writeBufferSize: selectedDeviceData.serial.writeBufferSize,
          readTimeout: selectedDeviceData.serial.readTimeout,
          writeTimeout: selectedDeviceData.serial.writeTimeout,
          rts: selectedDeviceData.serial.rts,
          dtr: selectedDeviceData.serial.dtr,
          discardNull: selectedDeviceData.serial.discardNull,
        },
      })
    } else {
      setSelectedDeviceJSON({
        ...selectedDeviceJSON,
        ip: {
          address: value,
        },
      })
    }
  }

  //Modifica indirizzo/porta nel JSON
  const onBlurComunicationPort = () => {
    /*if (opticianId === "") {
       setUsername("")
       setOpticianName("")
       setOpticianPhone("")
     } else {
       fetchOpticianId()
     }*/
  }

  //Modifica velocità porta
  const handleChangeSpeedPort = (event) => {
    let value = event.target.value
    setSettings({ ...settings, speedPort: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          value +
          "," +
          settings.parityBit +
          "," +
          settings.dataBit +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica parityBit
  const handleChangeParityBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, parityBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          value +
          "," +
          settings.dataBit +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica dataBit
  const handleChangeDataBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, dataBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          settings.parityBit +
          "," +
          value +
          "," +
          settings.stopBit,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica stopBit
  const handleChangeStopBit = (event) => {
    let value = event.target.value
    setSettings({ ...settings, stopBit: value })
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      serial: {
        port: "COM" + comunicationPort,
        timeout: selectedDeviceData.serial.timeout,
        settings:
          settings.speedPort +
          "," +
          settings.parityBit +
          "," +
          settings.dataBit +
          "," +
          value,
        handshake: selectedDeviceData.serial.handshake,
        readBufferSize: selectedDeviceData.serial.readBufferSize,
        writeBufferSize: selectedDeviceData.serial.writeBufferSize,
        readTimeout: selectedDeviceData.serial.readTimeout,
        writeTimeout: selectedDeviceData.serial.writeTimeout,
        rts: selectedDeviceData.serial.rts,
        dtr: selectedDeviceData.serial.dtr,
        discardNull: selectedDeviceData.serial.discardNull,
      },
    })
  }

  //Modifica address indirizzo ip
  const handleAddressIp = (event) => {
    let value = event.target.value
    setIpAddress(value)
  }

  const onBlurAddressIp = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      ip: {
        address: ipAddress,
        port: ipPort,
        timeout: 0,
      },
    })
  }

  //Modifica porta IP
  const handleIpPort = (event) => {
    let value = event.target.value
    setIpPort(value)
  }

  const onBlurIpPort = () => {
    setSelectedDeviceJSON({
      ...selectedDeviceJSON,
      ip: {
        address: ipAddress,
        port: ipPort,
        timeout: 0,
      },
    })
  }

  //Associata al bottone save, utilizzata solo da Hub salva i dati del device aggiunto
  const handleSave = () => {
    let newJson

    if (creation) {
      var device = UserUtils.getBrandModel(
        selectedDeviceJSON,
        allBrandsDevices,
        hubDeviceId
      )
      setHubDevicesJSON((hubDevicesJSON) => [...hubDevicesJSON, device])
    } else {
      newJson = hubDevicesJSON.map((elem) => {
        if (elem.deviceId === hubDeviceId) {
          return selectedDeviceJSON
        } else return elem
      })
      setHubDevicesJSON(newJson)
    }

    let newAdminTableRows = creation ? adminTableRows.concat(device) : newJson
    setAdminTableRows(newAdminTableRows)
    handleClickClose()
  }


  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      brandId === "" ||
      selectedDeviceId === "" ||
      !connectionType
    return result
  }

  return (
    <div>
      {isHub ? (
        <ScrollUtils.ElevationScroll
          {...props}
          handleClickClose={handleClickClose}
        />
      ) : null}

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10027").toUpperCase()}</u>
          </p>
        </Grid>

        {/*Riga 1*/}

        <Grid item xs={5}>
          <TextField
            select
            id="brands"
            label={t("10009")}
            value={brandId}
            style={{ width: 150 }}
            onChange={handleChangeBrandId}
            helperText={t("10252")}
            disabled={!editableBrand}
          >
            {allBrandsDevices
              .sort(function compare(a, b) {
                if (a.brand < b.brand) {
                  return -1
                }
                if (a.brand > b.brand) {
                  return 1
                }
                return 0
              })
              .map((option) => (
                <MenuItem key={option.brandId} value={option.brandId}>
                  {option.brand}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item xs={5}>
          <TextField
            select
            id="nameDevice"
            label={t("10253")}
            style={{ width: 150 }}
            value={selectedDeviceId ? selectedDeviceId : ""}
            onChange={handleSelectDevice}
            helperText={t("10253")}
            disabled={!editableBrand}
          >
            {brandModels
              .sort(function compare(a, b) {
                if (a.description < b.description) {
                  return -1
                }
                if (a.description > b.description) {
                  return 1
                }
                return 0
              })
              .map((option) => (
                <MenuItem key={option.modelId} value={option.modelId}>
                  {option.description}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        {!hideAdvanced ?
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={handleChangeActive}
                  disabled={!brandId ? true : false}
                  name="active"
                />
              }
              label={t("10134")}
            />
          </Grid>
          :
          null
        }

        {/*Riga 2*/}
        {!hideAdvanced ?
          <div>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={connectionType === "serial"}
                    disabled={
                      connectionType === "ip" || selectedDeviceId === ""
                    }
                    onChange={handleChangeSerial}
                    name="serial"
                  />
                }
                label={t("10254")}
              />
            </Grid>

            <Grid item xs={7} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={connectionType === "ip"}
                    disabled={
                      connectionType === "serial" || selectedDeviceId === ""
                    }
                    onChange={handleChangeIp}
                    name="ip"
                  />
                }
                label={t("10255")}
              />
            </Grid>
          </div>
          :
          null
        }

        {connectionType === undefined ? null : connectionType ===
          "serial" ? (
          <Grid item xs={10}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={2}>
                <TextField
                  id={"comunicationPort"}
                  label={t("10256")}
                  className={classesText.textField}
                  style={{ width: 150 }}
                  value={comunicationPort}
                  helperText={t("10257")}
                  disabled={selectedDeviceId === ""}
                  margin="normal"
                  onChange={handleComunicationPort}
                  onBlur={onBlurComunicationPort}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  select
                  id="speedPort"
                  label={t("10258")}
                  disabled={selectedDeviceId === ""}
                  style={{ width: 150 }}
                  value={settings.speedPort}
                  onChange={handleChangeSpeedPort}
                  helperText={t("10258")}
                >
                  {speedPortValue.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  select
                  id="parityBit"
                  label={t("10259")}
                  value={settings.parityBit}
                  style={{ width: 150 }}
                  disabled={selectedDeviceId === ""}
                  onChange={handleChangeParityBit}
                  helperText={t("10259")}
                >
                  {parityBitValue.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  select
                  id="dataBit"
                  label={t("10260")}
                  style={{ width: 150 }}
                  value={settings.dataBit}
                  disabled={selectedDeviceId === ""}
                  onChange={handleChangeDataBit}
                  helperText={t("10260")}
                >
                  {dataBitValue.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  select
                  id="stopBit"
                  label={t("10261")}
                  style={{ width: 150 }}
                  value={settings.stopBit}
                  disabled={selectedDeviceId === ""}
                  onChange={handleChangeStopBit}
                  helperText={t("10261")}
                >
                  {stopBitValue.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={3}>
                <TextField
                  id={"ipAddress"}
                  label={t("10262")}
                  className={classesText.textField}
                  value={ipAddress}
                  disabled={selectedDeviceId === ""}
                  margin="normal"
                  onChange={handleAddressIp}
                  onBlur={onBlurAddressIp}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id={"ipPort"}
                  label={t("10263")}
                  className={classesText.textField}
                  value={ipPort}
                  disabled={selectedDeviceId === ""}
                  margin="normal"
                  onChange={handleIpPort}
                  onBlur={onBlurIpPort}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!isRetailer && active && !hideAdvanced ? (
        <FilterDeviceType
          changeDevice={changeDevice}
          connectionType={connectionType}
          deviceProtocol={protocol}
          hideAdvanced={hideAdvanced}
          selectedDeviceData={selectedDeviceData}
          setDeviceProtocol={setProtocol}
          selectedDeviceJSON={selectedDeviceJSON}
          setChangeDevice={setChangeDevice}
          setSelectedDeviceJSON={setSelectedDeviceJSON}
        />
      ) : null}
      {isHub ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          p={1}
          className={classes.gridDeviceToolbar}
        >
          <Box p={1}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSave}
              disabled={disableSave()}
            >
              {t("10114")}
            </Button>
          </Box>
        </Box>
      ) : null}

    </div>
  )
}
