const gr = {
  translation: {
    1: "Σφάλμα: η παραγγελια δεν εκτυπώθηκε",
    2: "Σφάλμα: η παραγγελία δεν αποθηκεύτηκε",
    3: "Η διάμετρος πρέπει να είναι μεγαλύτερη από 1",
    4: "Η διάμετρος πρέπει να αναγράφεται αριθμητικά",
    5: "Δεν υπάρχει σχήμα",
    6: "Το σφαίρωμα πρέπει να αναγράφεται αριθμητικά ",
    7: "Λανθασμένο σφαίρωμα",
    8: "Σφαίρωμα εκτός ορίων (-30 / +30)",
    9: "Ο κύλινδρος πρέπει να αναγράφεται αριθμητικά",
    10: "Λανθασμένος κύλινδρος",
    11: "Κύλινδρος εκτός ορίων (-10 / +10)",
    12: "Οι μοίρες πρεπει να αναγράφονται αριθμητικά",
    13: "Η τιμή στις μοίρες πρέπει να εχει θετικό πρόσιμο (+)",
    14: "Η τιμή στις μοίρες πρέπει να είναι μικρότερη των 360",
    15: "Η τιμή στις μοίρες πρέπει να είναι ακέραιος",
    16: "Η τιμή πρέπει να είναι αριθμητική",
    17: "Η τιμή πρέπει να είναι θετικός",
    18: "Η τιμή πρέπει να είναι μεγαλύτερη από",
    19: "Η τιμή πρέπει να είναι μικρότερη από",
    20: "Η τιμή πρέπει να είναι μεταξύ -10 και +10",
    21: "Η διάμετρος πρέπει να είναι μικρότερη από 99",
    22: "Δεν έχει καθοριστεί Tracer",
    23: "PegasoDriver – Η συσκευή δεν είναι διαθέσιμη (η υπηρεσία δεν είναι ενεργή ή η θύρα είναι απασχολημένη). Επικοινωνήστε με τεχνικό.",
    24: "TIMEOUT: Δεν υπάρχει σχήμα",
    25: "Η τιμή πρέπει να είναι ακέραιος",
    26: "Ο κωδικός οπτικού υπάρχει ήδη",
    27: "Λάθος κατά την αποθήκευση του οπτικού",
    28: "Σφάλμα λειτουργίας",
    29: "Δεν υπάρχει σχήμα ή προιόν",
    30: "Το σχήμα λείπει",
    31: "Συμπλήρωμα υποχρεωτικών πεδίων",
    32: "Κάποια πεδία έχουν λάθη",
    33: "Δεν βρέθηκαν στοιχεία εταιρίας",
    34: "Επικεφαλίδα καταλόγου δεν βρέθηκε",
    35: "Αδυναμία κατά το άνοιγμα παραγγελίας",
    36: "Αδυναμία εισαγωγής παραγγελίας",
    37: "Αδυναμία αντιγραφής παραγγελίας",
    38: "Η συνεδρία έληξε",
    39: "ΗΜΕΡΟΜΗΝΙΑ ΑΠΟ πρέπει να είναι μικρότερη του ΗΜΕΡΟΜΗΝΙΑ ΕΩΣ",
    40: "Η τιμή είναι λανθασμένη",
    41: "Η κατάσταση της παραγγελίας δεν μπορεί να ενημερωθεί",
    42: "Η παραγγελία δεν μπορεί να σταλεί",
    43: "ERROR",
    44: "Δεν βρέθηκε προμηθευτής",
    45: "Δεν υπάρχει κατάλογος",
    46: "Δεν υπάρχει αναγνωριστικό προιόντος",
    47: "catalogue not found",
    48: "pegasoTools error fetch",
    49: "impossible to import catalogue",
    50: "no local blocker specified.",
    51: "Login failed",
    5000: "Ασφαιρικό",
    5001: "Τορικό",
    5002: "Διπλεστιακό",
    5003: "Curve top",
    5004: "Double segment",
    5005: "E-line multifocal",
    5006: "Flat-Top",
    5007: "Lenticular",
    5008: "Πολυεστιακό",
    5009: "Τετραπλεστιακό",
    5010: "Στρογγυλό",
    5011: "Μονοεστιακό",
    5012: "Τριπλεστιακό",
    10000: "Addition",
    10001: "Ασφαιρικό",
    10002: "Τορικό",
    10003: "Automatic",
    10004: "Άξονες",
    10005: "Bevel",
    10006: "Τιμή bevel",
    10007: "Θέση bevel",
    10008: "Διπλεστιακό",
    10009: "Μάρκα",
    10010: "Ακύρωση",
    10011: "Κλειστό",
    10012: "Πόλη",
    10013: "Εταιρία",
    10014: "Συμπληρώστε το προφίλ",
    10015: "Χώρα",
    10016: "Κορυφή καμπύλης",
    10017: "Κύλινδρος ",
    10018: "Ημερομηνία",
    10019: "Ενημερώθηκε από",
    10020: "Μεταφορτώθηκε από",
    10021: "Ενημέρωση",
    10022: "Λήθφηκε από",
    10023: "Ταμπλό",
    10024: "Ημέρες",
    10025: "Γέφυρα",
    10026: "Συσκευές",
    10027: "Συσκευή",
    10028: "Διάμετρος",
    10029: "Απενεργοποιήθηκε",
    10030: "Διπλό τμήμα",
    10031: "Πληροφορίες τροχίσματος",
    10032: "Επεξεργασία",
    10033: "Επεξεργασία προφίλ",
    10034: "E-line multifocal",
    10035: "Τύπος τροχίσματος",
    10036: "Φιλτράρισμα στατιστικών παραγγελιών",
    10037: "Κατάσταση φίλτρου",
    10038: "Σημειώσεις",
    10039: "Flat-Top",
    10040: "τύπος πλαισίου",
    10041: "Ημερομηνία από:",
    10042: "Groove",
    10043: "Horizontal Box",
    10044: "Home",
    10045: "id",
    10046: "Κωδικός",
    10047: "Αύξηση παραγγελιών προηγούμενης εβδομάδας",
    10048: "Εισαγωγή από",
    10049: "Κορική απόσταση",
    10050: "Παραγγελία",
    10051: "Λίστα παραγγελιών",
    10052: "Λίστα οπτικών",
    10053: "Η παραγγελία εκτυπώθηκε",
    10054: "Παραγγελίες ημέρας",
    10055: "Παραγγελίες εβδομλαδας",
    10056: "Ετήσιες παραγγελίες",
    10057: "Η παραγγελία εστάλει επιτυχώς",
    10058: "Επιλογή παραγγελίας",
    10059: "Στατιστικά παραγγελίας",
    10060: "Εργαστήριο",
    10061: "Σημειώσεις εργαστηρίου",
    10062: "Αναφορά εργαστηρίου",
    10063: "Εργαστήριο",
    10064: "Επίθετο",
    10065: "Αριστερός φακός",
    10066: "Ordered receference external lab",
    10067: "Lenticular",
    10068: "Λίστα ημερισίων παραγγελιών",
    10069: "Λίστα ετησίων παραγγελιών",
    10070: "Τύπος φακού",
    10071: "Σύνδεση",
    10072: "Αποσύνδεση",
    10073: "Διεύθυνση e-mail",
    10074: "Μεταλλικο",
    10075: "Μοντέλο",
    10076: "Μήνες",
    10077: "Όνομα",
    10078: "Νέες παραγγελίες",
    10079: "Όχι",
    10080: "Δεν επιλέχθηκε παραγγελία",
    10081: "Δεν υπάρχει παραγγελία για επεξεργασία",
    10082: "Δεν βρέθηκε αποτέλεσμα",
    10083: "Μη διαθέσιμο σχήμα",
    10084: "Εξαγωγή σχήματος",
    10085: "Εισαγωγή σχήματος από αρχείο",
    10086: "Σχήμα από το tracer",
    10087: "Φίλτρα",
    10088: "Ύψος τροχίσματος",
    10089: "Offset",
    10090: "Άνοιγμα",
    10091: "Άνοιγμα παραγγελίας",
    10092: "Οπτικός",
    10093: "Πελάτης οπτικού",
    10094: "Σημειώσεις οπτικού",
    10095: "Οπτύλιο",
    10096: "Αριθμός παραγγελίας",
    10097: "Κωδικός",
    10098: "Τηλέφωνο",
    10099: "Πλαστικό",
    10100: "Φιλτράρετε την λίστα παραγγελιών για ανάγνωση",
    10101: "Επιλογή περιόδου στατιστικών",
    10102: "Γυάλισμα",
    10103: "Εκτύπωση",
    10104: "Επανεκτύπωση",
    10105: "Προφίλ",
    10106: "Πολυεστιακό",
    10107: "Επαρχία",
    10108: "Τετραπλεστιακό",
    10109: "Πρόσφατες παραγγελίες",
    10110: "Τύπος αποτελέσματος",
    10111: "Δεξιός φακός",
    10112: "Griff",
    10113: "Στρογγυλό",
    10114: "Αποθήκευση",
    10115: "Επιλογή θέσης Bevel",
    10116: "Γυάλισμα",
    10117: "Αναζήτηση παραγγελίας ανα έτος",
    10118: "Αναζήτηση παραγγελίας ",
    10119: "Αναζήτηση οπτικού",
    10120: "Αναζήτηση σημειώσεων οπτικού",
    10121: "Επιλογή παραγγελίας για επεξεργασία",
    10122: "Επιλογή παραγγελίας για ανάγνωση λεπτομεριών",
    10123: "Ρυθμίσεις",
    10124: "Τύπος τροχίσματος",
    10125: "Τύπος φινιρίσματος",
    10126: "Μονοεστιακό",
    10127: "Επιλογή τύπου φακού",
    10128: "Σφαίρωμα",
    10129: "Κατάσταση",
    10130: "Στατιστικές",
    10131: "Περίοδος στατιστικών",
    10132: "Οδός",
    10133: "Νούμερο",
    10134: "Eγγεγραμμένος",
    10135: "Ημερομηνία εγγραφής",
    10136: "Ημερομηνία εώς",
    10137: "Τριπλεστιακό",
    10138: "Τύπος",
    10139: "Ενημέρωση προφίλ",
    10140: "Ενημέρωση σε",
    10141: "Ενημερωμένη παραγγελία",
    10142: "Όνομα χρήστη",
    10143: "Ρυθμίσεις χρήστη",
    10144: "Vertical box",
    10145: "Εβδομάδες",
    10146: "Σε επεξεργασία",
    10147: "Ναι",
    10148: "Τ.Κ.",
    10149: "Σφάλμα αυθεντικοποίησης απο τον Server",
    10150: "Λανθασμένα στοιχεία",
    10151: "Λάθος τύπος αρχείου",
    10152: "Εισαγωγή νέας παραγγελίας",
    10153: "Ανάγνωση παραγγελίας",
    10154: "Επεξεργασία παραγγελίας",
    10155: "Αντιγραφή παραγγελίας",
    10156: "Δεν υπάρχει διαθέσιμη καταγραφή",
    10157: "Εισαγωγή",
    10158: "Διαγραφή",
    10159: "Επεξεργασία",
    10160: "Μη έγκυρο",
    10161: "Αποθήκευση",
    10162: "Διαγραφή σειράς;",
    10163: "Ενεργοποιήστε το Tracer",
    10164: "Αλλάξτε σκελετό στο Tracer",
    10165: "Πιέστε το πράσινο κουμπί για σκανάριμα",
    10166: "Μετά το σκανάρισμα πιέστε το πράσινο κουμπί ξανά",
    10167: "Πιέστε το κουμπί -Receive- στον Η/Υ",
    10168: "Μετά το σκανάριμα του σκελετού (όταν ακούσετε το BEEP), πατήστε το κουμπί -Receive- στον Η/Υ",
    10169: "Πιέστε το πράσινο κουμπί για ανάγνωση",
    10170: "Καθώς το Tracer σκανάρει, πιέστε το κουμπί -Receive- στον Η/Υ",
    10171: "Σκανάρισμα σκελετού",
    10172: "Πιέστε το κουμπί Save στο tracer για να στείλετε το σχήμα στον Η/Υ",
    10173: "Πιέστε το κουμπι Trans στο tracer, αφού σκανάρει τον σκελετό",
    10174: "Πιέστε B (BOTH) για ανάγνωση",
    10175: "Ελέγξατε στο tracer δεξιά ότι εμφανίζεται το RSA (Εάν όχι πιέστε CTL και +/- μέχρι να αλλάξει σε RSA και επανεκκινήστε το tracer",
    10176: "Πιέστε δύο φορές το START στο tracer για να ξεκινήσει το σκανάρισμα",
    10177: "Πιέστε το DATA κατά την λήξη του σκαναρίσματος, αφού τα LED ανάψουν",
    10178: "Πιέστε START για ανάγνωση",
    10179: "GT500: Πιέστε DATA κατά την λήξη του σκαναρίσματος αφού τα LED ανάψουν",
    10180: "Connect Single",
    10181: "Receive Single",
    10182: "Αποσύνδεση",
    10183: "Receive Cycle",
    10184: "Λήψη",
    10185: "Σύνδεση συσκευής…",
    10186: "Αναμονή Tracer…",
    10187: "Αποσύνδεση Tracer…",
    10188: "Επιλογή σειράς:",
    10189: "Σειρά ανά σελίδα:",
    10190: "Προηγούμενη σελίδα",
    10191: "Επόμενη σελίδα",
    10192: "Τιμές",
    10193: "Φ.Π.Α.",
    10194: "Αριθμός ",
    10195: "Διεύθυνση",
    10196: "Κατάλογος",
    10197: "Διαχείριση καταλόγου",
    10198: "Μεταφόρτωση",
    10199: "",
    10200: "Ο κατάλογος ενημερώθηκε",
    10201: "Επίπεδα",
    10202: "Λεπτομέριες",
    10203: "Τιμές",
    10204: "Εξωτερικοί κωδικοί",
    10205: "Επιβεβαίωση",
    10206: "Διαγραφή καταλόγου;",
    10207: "Μεταφορτωμένοι κατάλογοι",
    10208: "Προσοχή",
    10209: "Σύρετε το αρχείο XLSX εδώ ή κάντε κλικ",
    10210: "Αναζήτηση προιόντος",
    10211: "Εμπρός",
    10212: "τοις εκατό",
    10213: "Πίσω",
    10214: "Τροποποίηση",
    10215: "Προιόν",
    10216: "Εισαγωγή προιόντος",
    10217: "Σὐρετε τις επικεφαλίδες εδώ για ομαδοποίηση",
    10218: "Ανανέωση λίστας παραγγελιών",
    10219: "Υποχρεωτικά πεδία",
    10220: "Υποχρεωτικά πεδία ενημερώθηκαν",
    10221: "Σχήμα",
    10222: "Στοιχεία παραγγελίας",
    10223: "Αφαίρεση προιόντος",
    10224: "Παραγγελία προιόντος ανά:",
    10225: "Απομακρυσμένη τεχνική υποστήριξη",
    10226: "Υποχρεωτικά πεδία παραγγελίας",
    10227: "Επιλέξτε παραγγελία ή περίοδο αυτής",
    10228: "Επιλέξτε δύο περιόδους παραγγελίας για αναζήτηση έτους",
    10229: "ΟΚ",
    10230: "Καθαρισμός",
    10231: "Άδειο",
    10232: "Σήμερα",
    10233: "Επαναφορά φίλτρων",
    10234: "Αποστολή παραγγελίας στον προμηθευτή",
    10235: "Επιλογή προμηθευτή για προώθηση παραγγελίας",
    10236: "Αποστολή ",
    10237: "Έτος",
    10238: "Πατήστε για κλείσιμο",
    10239: "Παραγγελία εστάλει επιτυχώς",
    10240: "Διαγραφή παραγγελίας",
    10241: "Προσοχή",
    10242: "Θελετε να διαγράψετε την παραγγελία;",
    10243: "Ακύρωση",
    10244: "Επιβεβαίωση",
    10245: "Σύνταξη από:",
    10246: "Tabo system",
    10247: "Ελάχιστο πάχος 2mm",
    10248: "Η παραγγελία έκλεισε επιτυχώς",
    10249: "Κλείσιμο παραγγελίας",
    10250: "Οδηγίες",
    10251: "",
    10252: "Επιλογή μάρκας",
    10253: "Όνομα συσκευής",
    10254: "Σειριακός αριθμός",
    10255: "Ip",
    10256: "Θύρα επικοινωνίας",
    10257: "Enter only the port number",
    10258: "Speed port",
    10259: "Parity bit",
    10260: "Data bit",
    10261: "Stop bit",
    10262: "Διεύθυνση IP",
    10263: "Πόρτα IP",
    10264: "None",
    10265: "Even",
    10266: "Odd",
    10267: "Advanced settings protocol",
    10268: "Option download",
    10269: "Option upload",
    10270: "Αποθήκευση αλλαγών",
    10271: "Advanced settings serial",
    10272: "Πρωτόκολλο IP",
    10273: "Παραγγελία χωρίς barcode",
    10274: "Nack",
    10275: "Offset metal",
    10276: "Offset plastic",
    10277: "Μονή πλευρά",
    10278: "Αναγνωριστικό tracer",
    10279: "Χωρίς barcode",
    10280: "Reg",
    10281: "trcfmtbin",
    10282: "trcfmtpoints",
    10283: "zfmtbin",
    10284: "zfmtpoints",
    10285: "Selected",
    10286: "Timeout",
    10287: "Handshake",
    10288: "readBufferSize",
    10289: "writeBufferSize",
    10290: "readTimeout",
    10291: "writeTimeout",
    10292: "Rts",
    10293: "Dtr",
    10294: "discardNull",
    10295: "Offset other",
    10296: "Οι αποθηκευμένες ρυθμίσεις αποσυνδεθείτε για να τεθούν σε ισχύ",
    10297: "Η παραγγελία διορθώθηκε επιτυχώς",
    10298: "Send job to supplier as tracer (SERIAL PORT)",
    10299: "Connect must be at least version 1.5.1",
    10300: "Υλικό Φακού",
    10301: "Επίστρωση",
    10302: "Not slippery",
    10303: "Edge thickness",
    10304: "center thickness",
    10305: "Groove width",
    10306: "Groove depth",
    10307: "Up safety",
    10308: "Low safety",
    10309: "Επιλογή τύπου φακού",
    10310: "Select lens slippery",
    10311: "Low slippery",
    10312: "High slippery",
    10313: "Ρυθμίσεις Tracer",
    10314: "Calibration settings updated",
    10315: "Calibration settings",
    10316: "Height value (Y)",
    10317: "Width value (X)",
    10318: "For a correct calibration of the drawing on the screen it is necessary to modify the X and Y values so that both sides of the square have a length of 80 mm",
    10319: "Διαχείριση τρυπανιού",
    10320: "Εισαγωγή τρυπήματος",
    10321: "Διαγραφή τρυπήματος",
    10322: "Αποθήκευση τρυπήματος",
    10323: "Επιλέξτε αριθμό παραγγελίας",
    10324: "Εισαγωγή αριθμού παραγγελίας",
    10325: "Μέγιστο",
    10326: "Διαχείριση Σκελετών",
    10327: "Λίστα προμηθευτών",
    10328: "Αναζήτηση προμηθευτή",
    10329: "Ο προμηθευτής υπάρχει ήδη",
    10330: "Προμηθευτής",
    10331: "Στοιχεία σκελετού",
    10332: "Μάρκα",
    10333: "Κωδικός σκελετού",
    10334: "Όνομα μοντέλου",
    10335: "Χρώμα",
    10336: "Διαμέτρημα",
    10337: "Λίστα μάρκας",
    10338: "Αναζήτηση μάρκας",
    10339: "Η μάρκα υπάρχει ήδη",
    10340: "Επεξεργασία σκελετού",
    10341: "Διαγραφή σκελετού",
    10342: "Αδυναμία κατά το άνοιγμα σκελετου ????",
    10343: "Η αποθήκευση απέτυχε: ο σκελετός με αυτό το id υπάρχει ήδη",
    10344: "Ο σκελετός δεν αποθυκεύτηκε",
    10345: "Επιλογή σκελετού",
    10346: "press the button to start the read.",
    10347: "modify shape",
    10348: "save shape",
    10349: "up",
    10350: "down",
    10351: "right",
    10352: "left",
    10353: "maximum 1 month",
    10354: "type edit drill",
    10355: "select the type of drill edit",
    10356: "in the center",
    10357: "on the edge",
    10358: "autocad converter",
    10359: "OMA file converted correctly in DXF. Click to download",
    10360: "please check that the file is correct than try again",
    10361: "error: Try again, if the problem persist contact Pegaso",
    10362: "OMA to DXF converter",
    10363: "drag and drop the OMA file or click here",
    10364: "click to convert the file",
    10365: "convert file",
    10366: "download file",
    10367: "DXF to OMA converter",
    10368: "what result do you want?",
    10369: "both",
    10370: "how is the file composed?",
    10371: "drag and drop the DXF file or click here",
    10372: "DXF file converted correctly in OMA. Click to download",
    10373: "the AutoCad project must contain only SPLINE and POLYLINE for the representation of the shape, and CIRCLE for the representation of the drills",
    10374: "any other element in the AutoCad project will be ignored",
    10375: "OMA file to convert must respect all standard of OMA/VCA protocol.",
    10376: "frame",
    10377: "frame brand",
    10378: "frame supplier",
    10379: "fields visible by customers",
    10380: "true",
    10381: "false",
    10382: "add new frame",
    10383: "supplier id",
    10384: "brand id",
    10385: "side",
    10386: "drag and drop a shape file or click here",
    10387: "search optician customer",
    10388: "converter operation guide: ",
    10389: "height from Box",
    10390: "edger back pin bevel width",
    10391: "edger front pin bevel width",
    10392: "select",
    10393: "CR - plastic",
    10394: "polycarbonate",
    10395: "glass",
    10396: "pattern",
    10397: "hi - index",
    10398: "trivex",
    10399: "tribird",
    10400: "frame curve",
    10401: "front curve of a lens",
    10402: "frame wrap angle",
    10403: "lens index",
    10404: "select lens index",
    10405: "select the tracer type and the serial communication port of the tracer for job creation via web interface",
    10406: "lens data",
    10407: "cancel",
    10408: "submit",
    10409: "height from Edge",
    10410: "export catalogue excel",
    10411: "coating",
    10412: "select search type of jobs",
    10413: "search single job",
    10414: "search jobs in period",
    10415: "restore job for external system",
    10416: "job restored correctly",
    10417: "job not restored",
    10418: "pantoscopic angle",
    10419: "corneal apex distance",
    10420: "order settings",
    10421: "mounting height entry method",
    10422: "from box",
    10423: "from edge",
    10424: "deleted",
    10425: "impossible to delete shape",
    10426: "are you sure you want to delete this shape?",
    10427: "status",
    10428: "Y position value too large (hole ",
    10429: "X position value too large (hole ",
    10430: "shape precal",
    10431: "shapes precal management",
    10432: "refresh precal shapes",
    10433: "add new shape",
    10434: "shape id",
    10435: "description",
    10436: "keep proportions",
    10437: "language",
    10438: "settings saved correctly, exit from the program",
    10439: "send job to blocker (SERIAL PORT)",
    10440: "blocker is being disconnected",
    10441: "connecting to the blocker",
    10442: "waiting to send data",
    10443: "data sent correctly",
    10445: "no shape sent",
    10446: "fist page",
    10447: "last page",
    10448: "remove tracer and save",
    10449: "attention: you are trying to send an order that has already been submitted. Do you want to continue?",
    10450: "minimum diameter",
    10451: "selected diameter",
    10452: "calculate diameter",
    10453: "for the calculation, interpupillary distance, mounting height and horizontal and vertical template are necessary",
  }
}
export default gr
