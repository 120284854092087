import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import { RootContext } from "RootContext"

// pegaso views/components
import Box from "@material-ui/core/Box"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import ErrorUtils from "components/Tools/ErrorUtils"
import JobUtils from "components/Utils/JobUtils"
import OmaUtils from "components/Utils/OmaUtils"
import ScrollUtils from "components/Tools/ScrollUtils.js"
import BlockerLoading from "views/Blocker/BlockerLoading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import { fetchWithTokenPegasoTools } from "components/Fetch/tools-api-fetch"
import TracerUtils from "components/Utils/TracerUtils"
import ShapeUtils from "components/Utils/ShapeUtils"

// Core components
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { Stage, Layer, Line } from "react-konva"
import { titleCase } from "title-case"


/*---------  import CSS ---------*/
import { useJobStyles } from "styles/job/jobCss"
import { useShapeStyle } from "styles/shape/shapeCss"

// Griglia per scheda del JobToBlocker
function JobToBlockerGridContainer(props) {
  const {
    apiUrl,
    apiUrlPegasoTools,
    companyId,
    access_token,
    access_token_pegaso_tools,
    getLab,
    getCalibration,
    refresh_token,
    refresh_token_pegaso_tools,
    setAccess_token,
    setAccess_token_pegaso_tools,
    t,
    userDevice,
    getEdgingEightType
  } = useContext(RootContext)

  const { labId } = getLab()

  const classes = useJobStyles()
  const { jobId, year } = props
  const [jobChecked, setJobChecked] = useState(false)
  const [jobErrorMsg, setJobErrorMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [shapeForBlocker, setShapeForBlocker] = useState(null)
  const [offset, setOffset] = useState(0)
  const [tracerEventRequest, setTracerEventRequest] = useState(null)
  const serviceMessages = {
    status: [t("10440"), t("10441"), t("10442"), t("10443")],
    error: [t("50"), t("23"), t("24")]
  }
  const [rightValues, setRightValues] = useState(null)
  const [leftValues, setLeftValues] = useState(null)
  const [endgingDataValues, setEdgingDataValues] = useState(null)
  var edgingEightType = getEdgingEightType()

  //DATI DEVICE
  const device = userDevice
  const [tracerId, setTracerId] = useState()
  const [tracerJSON, setTracerJSON] = useState()
  const [modelToShow, setModelToShow] = useState() 
  const [settingsToShow, setSettingsToShow] = useState()

  //VARIABILE PER DISEGNO
  const [calibration] = useState(getCalibration())
  const canvasHeight = 300
  const canvasWidth = 800
  const [edgingDataString, setEdgingDataString] = useState(null)
  const originY = 150
  const scaleX = 4
  const scaleY = 4
  const shapeClass = useShapeStyle.shape
  const [shapeExists, setShapeExists] = useState(false)
  const [shapeJson, setShapeJson] = useState()
  const [startLeftLens, setStartLeftLens] = useState(0)
  const [startRightLens, setStartRightLens] = useState(250)
  const [standardShape, setStandardShape] = useState(null)
  const origin = { x: startRightLens, y: originY }

  // Legge il job
  const fecthJob = useCallback(() => {
    const url = `${apiUrl}/companies/${companyId}/labs/${labId}/jobs/${jobId}?year=${year}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        const job = JobUtils.createDefaultState(data)
        setRightValues(job.rightValues)
        setLeftValues(job.leftValues)
        setEdgingDataValues(job.edgingValues)

        // DA UNIRE
        const standard = JobUtils.GetStandardShape(data)
        const edgingData = JobUtils.GetEdgingData(data)
        const standardShape = OmaUtils.MergeShapeWithEdgingData(standard, edgingData)
        // Controllo che esista forma
        if (!standardShape) {
          setJobErrorMsg(t("30"))
        } else {
          setEdgingDataString(edgingData)
          setStandardShape(standardShape)
          setOffset(data.shape.offset)
          // Convert shape
          let routeToConvert = "tracer-shape-to-oma"
          switch (tracerId) {
            case 1:
              routeToConvert = "tracer-shape-to-takubo"
              break;
            default:
              break;
          }

          fetchWithTokenPegasoTools(`${apiUrlPegasoTools}/ShapeConverter/${routeToConvert}`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ shapeIn: standardShape, tracerId: 3 }),
            apiUrl_pegaso_tools: apiUrlPegasoTools,
            access_token_pegaso_tools: access_token_pegaso_tools,
            refresh_token_pegaso_tools: refresh_token_pegaso_tools,
            setAccess_token_pegaso_tools: setAccess_token_pegaso_tools
          })
            .then(response => {
              setShapeForBlocker(response.body)
              setJobChecked(true)
            })
            .catch(err => {
              ErrorUtils.errorLog(t("48"), err.message)
            })
        }
      })
      .catch((err) => {
        // Se Job non esiste
        setJobErrorMsg(t("35"))
        setJobChecked(true)
        ErrorUtils.errorLog("Fetch Job Error", err)
      })
  }, [
    access_token,
    apiUrl,
    companyId,
    jobId,
    labId,
    refresh_token,
    setAccess_token,
    t,
    year,
    tracerId,
    access_token_pegaso_tools,
    apiUrlPegasoTools,
    refresh_token_pegaso_tools,
    setAccess_token_pegaso_tools
  ])

  // Fà controlli sul job
  useEffect(() => {
    if (!jobChecked) {
      fecthJob()
    }
  }, [fecthJob, jobChecked])

  useEffect(() => {
    if (standardShape != null) {
      const shapeToDraw = ShapeUtils.extractShapeFromOMA(
        standardShape,
        edgingDataString,
        scaleX,
        scaleY,
        offset,
        calibration
      )
      setShapeJson(shapeToDraw)
      var rightHbox = shapeToDraw.right.hbox ? shapeToDraw.right.hbox : 55
      var leftHbox = shapeToDraw.left.hbox ? shapeToDraw.left.hbox : 55
      setStartRightLens(
        canvasWidth / 2 - (shapeToDraw.dbl * 50 / 25 + rightHbox * 50 / 25)
      )
      setStartLeftLens(
        canvasWidth / 2 + (shapeToDraw.dbl * 50 / 25 + leftHbox * 50 / 25)
      )
      if (
        shapeToDraw.right.points.length > 1 ||
        shapeToDraw.left.points.length > 1
      ) {
        setShapeExists(true)
      } else {
        setShapeExists(false)
      }

    }
  }, [
    calibration,
    edgingDataString,
    scaleX,
    scaleY,
    offset,
    standardShape
  ])

  useEffect(() => {
    if (device != null) {
      const { protocol } = device
      const { options } = protocol
      const { tracerId } = options
      setTracerId(tracerId)
      setTracerJSON(TracerUtils.getJsonDevice(device)) 
      setModelToShow(`${titleCase(t("10075"))}: ${device.model.brand.brand} ${device.model.description}`)
      setSettingsToShow(`${titleCase(t("10256"))}: ${device.serial.port} ${device.serial.settings}`)
    }
  }, [
    device,t
  ])


  // Handle Save Button
  const handleSave = () => {
    setIsSaving(true)
    setTracerEventRequest("ALL")
  }

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("10439")}</h4>
        </CardHeader>
        <CardBody>
          {!jobErrorMsg && jobChecked ? (
            <div>
              <ScrollUtils.ElevationScroll {...props}>
                <ScrollUtils.AppBarScroll {...props} t={t} />
              </ScrollUtils.ElevationScroll>
              <Grid
                container
                spacing={2}
                alignContent="flex-end"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Grid item xs={4}>
                  <strong>{t("10050")}: </strong>
                  {jobId}
                </Grid>
                <Grid item xs={4}>
                  <strong>{t("10237")}: </strong>
                  {year}
                </Grid>
                <Grid item xs={4} />
              </Grid>

              {
                rightValues != null && leftValues != null && endgingDataValues != null ?
                  <Grid
                    container
                    spacing={2}
                    alignContent="flex-end"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                  >
                    <Grid container justifyContent="space-between">
                      {/* LENTE DESTRA*/}
                      <Grid className={classes.jobGridCurrentRightValues} item xs={6}>
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <p align="center">
                              <u>{t("10111").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10049").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10088").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10043").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10144").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {rightValues.ipd}
                            </p>
                          </Grid>
                          {
                            edgingEightType === "BOX" ? (
                              <Grid item xs={3}>
                                <p align="center">
                                  {rightValues.ocht}
                                </p>
                              </Grid>
                            ) : (
                              <Grid item xs={3}>
                                <p align="center">
                                  {rightValues._seght}
                                </p>
                              </Grid>
                            )
                          }

                          < Grid item xs={3}>
                            <p align="center">
                              {rightValues.hbox}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {rightValues.vbox}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* LENTE SINISTRA*/}
                      <Grid className={classes.jobGridCurrentRightValues} item xs={6}>
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <p align="center">
                              <u>{t("10065").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10049").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10088").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10043").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              <u>{t("10144").toUpperCase()} </u>
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {leftValues.ipd}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {leftValues.ocht}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {leftValues.hbox}
                            </p>
                          </Grid>
                          <Grid item xs={3}>
                            <p align="center">
                              {leftValues.vbox}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* PONTE */}
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <p align="center">
                          <u>{t("10025").toUpperCase()} </u> {endgingDataValues.dbl}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  : null

              }
              <Grid
                container
                spacing={2}
                alignContent="flex-end"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Grid item xs={6}>
                  {modelToShow}
                </Grid>
                <Grid item xs={6}>
                  {settingsToShow}
                </Grid>
              </Grid>

              {
                shapeExists ?
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    flexDirection="column"
                  >

                    <Box p={1} alignSelf="center">
                      <Stage
                        width={canvasWidth}
                        height={canvasHeight}
                      >
                        <Layer>
                          {/* 1 - LENTE DESTRA (origine = centro lente) */}
                          <Line
                            closed
                            fill={shapeClass.fill}
                            points={shapeJson ? shapeJson.right.points : []}
                            stroke={shapeClass.stroke}
                            strokeWidth={shapeClass.strokeWidth}
                            tension={0}
                            x={origin.x}
                            y={origin.y}
                          />
                          {/* FORI (Lente Destra) */}
                          {/* <Drills
                      onDragEnd={onDragEnd}
                      openDrillsManagement={openDrillsManagement}
                      origin={origin}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeJson={shapeJson}
                      shapeClass={shapeClass}
                      startLens={origin.x}
                      side={"right"}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                    /> */}
                          { }
                          {/* 2 - LENTE SINISTRA (origine = centro lente) */}
                          <Line
                            closed
                            fill={shapeClass.fill}
                            points={shapeJson ? shapeJson.left.points : []}
                            tension={0}
                            stroke={shapeClass.stroke}
                            strokeWidth={shapeClass.strokeWidth}
                            x={startLeftLens}
                            y={origin.y}
                          />
                          {/* FORI (Lente Sinistra) */}
                          {/* <Drills
                      onDragEnd={onDragEnd}
                      openDrillsManagement={openDrillsManagement}
                      origin={origin}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeJson={shapeJson}
                      shapeClass={shapeClass}
                      startLens={startLeftLens}
                      side={"left"}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                    /> */}
                        </Layer>

                      </Stage>
                    </Box>
                  </Box>
                  : null
              }

              {/*BOTTONE SALVA*/}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                p={1}
                className={classes.gridToolbar}
              >
                <Box p={1}>
                  {isSaving ?
                    <BlockerLoading
                      JSON_tracerJson={JSON.stringify(tracerJSON)}
                      JSON_serviceMessages={JSON.stringify(serviceMessages)}
                      tracerEventRequest={tracerEventRequest}
                      shapeForBlocker={shapeForBlocker}
                      modelToShow={modelToShow}
                      settingsToShow={settingsToShow}
                      setTracerEventRequest={setTracerEventRequest}
                      setIsSaving={setIsSaving}
                    />
                    : null
                  }
                </Box>
                <Box p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    // disabled={disableSave()}
                    onClick={handleSave}
                  >
                    {t("10236")}
                  </Button>
                </Box>
              </Box>

            </div>
          ) : jobErrorMsg ? (
            <div>
              <ScrollUtils.ElevationScroll {...props}>
                <ScrollUtils.AppBarScroll {...props} t={t} />
              </ScrollUtils.ElevationScroll>
              {`${t("43")}: ${t("42")}`}
              <br />
              <br />
              {`${jobErrorMsg}`}
              <br />
              <br />
            </div>
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </CardBody>
      </Card>
    </div >)
}

// Griglia interna alla finestra del JobToBlocker
function JobToBlocker(props) {
  const { jobId, year } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()

  if (jobId && year) {
    return <JobToBlockerGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}

export default JobToBlocker
