/*eslint-disable*/
import React, { useContext } from 'react'
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { RootContext } from '../../RootContext'
const useStyles = makeStyles(styles);


export default function LoginFooter(props) {
  const classes = useStyles();
  const { t, publicUrl } = useContext(RootContext)
  const { companyFooter } = props
  const site = companyFooter?.site || 'http://www.pegasovenezia.com';

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/* <div className={classes.left}> */}
        {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={classes.block}>
                {t('home')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={classes.block}>
                {t('company')}
              </a>
            </ListItem>
          </List>
        </div> */}


        <span>
          <p>
            <a href={publicUrl + '/resources/PegasoDriver.msi'} target="_blank" download='PegasoDriver.msi' >
              <img src="/resources/images/setup.png" width="16px" height="16px" />
            </a>
            &nbsp; &nbsp;  &copy; Copyright {1900 + new Date().getYear()} Pegaso Informatica Venezia
          </p>
          <p>Sito: <a href={site !== "" ? site : 'http://www.pegasovenezia.com'}>{site !== "" ? site : 'http://www.pegasovenezia.com'}</a>
           - Mail: <a href={companyFooter.email === "" ? 'mailto:info@pegasovenezia.com' : 'mailto:' + companyFooter.email}> {companyFooter.email === "" ? "info@pegasovenezia.com" : companyFooter.email} </a></p>
          <p>Tel: {companyFooter.telephoneNumber === "" ? " (+39) 041 3094100" : companyFooter.telephoneNumber} - Cell:  {companyFooter.mobilePhone === "" ? " (+39) 349 3105314" : companyFooter.mobilePhone} </p>
          <p>
          <a href={publicUrl + '/resources/SupremoPegaso.exe'} target="_blank" download='SupremoPegaso.exe' >{t("10225")}</a>
          </p>
        </span>
      </div>
    </footer>
  );
}
