import { makeStyles } from "@material-ui/styles"

/*---------  SHAPE  ---------*/
const useShapeStyle = {
    shape: {
        stroke: '#3a93d2',
        fill: '#d7ebff',
        strokeWidth: 1,
    },
    shapeDrill: {
        stroke: 'red',
    },
}

/*---------  GRIDS  ---------*/
const useShapeStyleGrids = makeStyles(() => ({
    shapeDrillGrid: {
        width: "100%",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    shapeDrillButtonGrid: {
      paddingBottom: 20,
  },
    shapeModifyGrid: {
        width: "100%",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
}))

/*---------  DIRECTION/NUMBER FIELDS  ---------*/
const useShapeStylesNumber = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: "0.875rem",
    },
    textField: {
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
  }))

export { useShapeStyle, useShapeStyleGrids, useShapeStylesNumber }