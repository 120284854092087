
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography"

//pegaso/views/components
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken, refreshToken } from "components/Fetch/api-fetch"
import StringUtils from "components/Tools/StringUtils"
import SettingsSaveButton from "components/Buttons/SettingsSaveButton"
import SettingsTableOkSaved from "views/Settings/SettingsTableOkSaved"
import UserUtils from "components/Utils/UserUtils"

export default function OrderSettings(props) {
  const {
    t,
    companyId,
    access_token,
    apiUrl,
    getEdgingEightType,
    getLanguage,
    getUsername,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)


  //Variabili
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openPanel, setOpenPanel] = useState(false)
  const [username] = useState(getUsername())
  const [edgingEightType, setEdgingEightType] = useState(getEdgingEightType())
  const [language, setLanguage] = useState(getLanguage())
  const languageList = UserUtils.languageList()


  /*************************************
    *
    * Handle
    *
    *************************************/


  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/users/${username}/settings`
    fetchWithToken(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edgingEightType: edgingEightType,
        language: language
      }),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10438")}\n\nUsername: ${username}\n`
        )
        refreshToken()
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("28"), err)
        setDialogMsg(t("28"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setEdgingEightType(name)
  }

  //Cambia field
  const handleChangeLanguage = (name) => (event) => {
    const targetValue = event.target.value
    setLanguage(targetValue)
  }

  //Chiude o apre il pannello contenente la tarratura
  const changeOpenPanel = (event) => {
    var value = !openPanel
    setOpenPanel(value)
  }

  return (
    <div>
      <Accordion expanded={openPanel} onChange={changeOpenPanel} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10420").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              <Grid
                container
                bgcolor="background.paper"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid item xs={2}>
                    <TextField
                      select
                      id="language"
                      label={t("10437")}
                      style={{ width: 100 }}
                      value={language}
                      onChange={handleChangeLanguage("language")}
                    >
                      {languageList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Typography style={{ width: "100%" }}><u>{t("10421").toUpperCase()}</u></Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={edgingEightType === "BOX"}
                        onChange={handleChangeChecked("BOX")}
                        name="demo"
                      />
                    }
                    label={t("10422")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={edgingEightType === "EDGE"}
                        onChange={handleChangeChecked("EDGE")}
                        name="demo"
                      />
                    }
                    label={t("10423")}
                  />
                </Grid>

              </Grid>
              {/*BOTTONI PER SALVATAGGIO*/}
              <SettingsSaveButton
                {...props}
                handleSave={handleSave}
              />
              <SettingsTableOkSaved
                {...props}
                dialogMsg={dialogMsg}
                handleClose={handleClose}
                isSaving={isSaving}
                openDialog={openDialog}
              />
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>
    </div >
  )
}