import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"


// Material ui component
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from "@material-ui/icons/Close"
import { DataGrid } from '@material-ui/data-grid';
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import TextField from '@material-ui/core/TextField';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

// Css

//Pegaso component

export default function DrillsManagement(props) {
  const {
    t,
  } = useContext(RootContext)
  const { classes, handleAddDrill, handleDrillChange, shapeJson, setShapeJson, setOpenDrillsManagement } = props
  const columns = [
    { field: 'id', headerName: 'Id', width: 50, editable: false },
    { field: 'xStart', headerName: 'X Start', width: 100, editable: true },
    { field: 'yStart', headerName: 'Y start', width: 100, editable: true, },
    { field: 'diam', headerName: t('10028'), width: 100, editable: true, },
    {
      field: 'isSlot', headerName: 'Foro/Asola', width: 130, editable: false, renderCell: (params) => (
        <Checkbox size="small" id={params.row.id.toString()} checked={params.row.isSlot} onClick={handleChangeChecked} />
      ),
    },
    { field: 'xEnd', headerName: 'X End', width: 100, editable: true },
    { field: 'yEnd', headerName: 'Y End', width: 100, editable: true },
  ];
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useState(shapeJson.drillingPoints);
  const [oldShapeJson] = useState(shapeJson)
  const diamLabelValue = undefined
  /*************************************
  *
  * Funzioni
  *
  *************************************/

  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  function IsCellEditable(params) {
    if (!params.row.isSlot) {
      if (params.field === "xEnd" || params.field === "yEnd")
        return false
      else return true
    }
    else return true
  }

  /*************************************
  *
  * Handler per modificare i valori
  *
  *************************************/

  //Handler change checekd
  function handleChangeChecked(event) {
    const value = event.target.checked
    const id = parseFloat(event.target.id)
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        var newRow
        if (value === true) {
          (row.yEnd && row.xEnd) ? (
            newRow = { ...row, isSlot: value }
          ) : (
            newRow = { ...row, isSlot: value, xEnd: 6.01, yEnd: 6.01 }
          )
        }
        else {
          newRow = { ...row, isSlot: value, xEnd: undefined, yEnd: undefined }
        }
        return newRow
      }
      return row;
    });
    setRows(updatedRows);
    setShapeJson({ ...shapeJson, "drillingPoints": updatedRows })
  }

  //Modifica i valori di un foro
  const handleEditCellChangeCommitted = useCallback(
    (params) => {
      const updatedRows = rows.map((row) => {
        if (row.id === params.id) {
          var error = (params.value < 35 && params.value > -35) ? false : true
          if(!error){
            return { ...row, [params.field]: parseFloat(params.value) };
          }
          else{
            if(params.field.indexOf("y") >= 0){
              alert(t("10428") + row.id + ")");
            }
            else{
              alert(t("10429") + row.id + ")");
            }
          }
        }
        return row;
      });
      setRows(updatedRows);
      setShapeJson({ ...shapeJson, "drillingPoints": updatedRows })
    },
    [rows, setShapeJson, shapeJson,t],
  );

  //Salva i fori
  const handleSaveDrill = () => {
    handleDrillChange(shapeJson.drillingPoints)
    setOpenDrillsManagement(false)
  }

  //Handler per la chiusura della card dei fori
  function handleCloseDrill() {
    setShapeJson(oldShapeJson)
    setOpenDrillsManagement(false)
  }

  //Aggiunge un foro
  const handleAdd = () => {
    setRows([...rows, { id: rows.length + 1, xStart: 5.01, yStart: 5.01, diam: 1.00, isSlot: false }])
    handleAddDrill(5.01, 5.01)
  }

  //Handler per eliminare il foro selezionato
  const handleDeleteSelectedDrill = () => {
    var newRows = shapeJson.drillingPoints
    newRows = newRows.filter(row => selectedRows.indexOf(row.id) < 0)
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })
  }


  //Sposta i fori selezionati verso l'alto
  const onClickUp = () => {
    var newRows = rows
    var error = false
    newRows = newRows.map((row) => {
      selectedRows.map((selected) => {
        if (row.id === selected) {
          var newValStart = row.yStart + 1
          var newValEnd = row.yEnd + 1
          error = row.isSlot ? (newValStart > 35 || newValEnd > 35) : newValStart > 35
          if (!error) {
            newValStart = parseFloat(newValStart.toFixed(2));
            newValEnd = parseFloat(newValEnd.toFixed(2));
            row.isSlot ? (
              row = { id: row.id, xStart: row.xStart, yStart: newValStart, diam: row.diam, xEnd: row.xEnd, yEnd: newValEnd, isSlot: true }
            ) : (
              row = { id: row.id, xStart: row.xStart, yStart: newValStart, diam: row.diam, isSlot: false }
            )
          }
          else {
            alert(t("10428") + row.id + ")");
          }
        }
        return row
      })
      return row
    })
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })
  }

  //Sposta i fori selezionati in basso
  const onClickDown = () => {
    var error = false
    var newRows = rows
    newRows = newRows.map((row) => {
      selectedRows.map((selected) => {
        if (row.id === selected) {
          var newValStart = row.yStart - 1
          var newValEnd = row.yEnd - 1
          error = row.isSlot ? (newValStart < -35 || newValEnd < -35) : newValStart < -35
          if (!error) {
            newValStart = parseFloat(newValStart.toFixed(2));
            newValEnd = parseFloat(newValEnd.toFixed(2));
            row.isSlot ? (
              row = { id: row.id, xStart: row.xStart, yStart: newValStart, diam: row.diam, xEnd: row.xEnd, yEnd: newValEnd, isSlot: true }
            ) : (
              row = { id: row.id, xStart: row.xStart, yStart: newValStart, diam: row.diam, isSlot: false }
            )
          }
          else {
            alert(t("10428") + row.id + ")");
          }
        }
        return row
      })
      return row
    })
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })
  }

  //Sposta i fori selezionati a sinistra
  const onClickLeft = () => {
    var error = false
    var newRows = rows
    newRows = newRows.map((row) => {
      selectedRows.map((selected) => {
        if (row.id === selected) {
          var newValStart = row.xStart - 1
          var newValEnd = row.xEnd - 1
          error = row.isSlot ? (newValStart < -35 || newValEnd < -35) : newValStart < -35
          if (!error) {
            newValStart = parseFloat(newValStart.toFixed(2));
            newValEnd = parseFloat(newValEnd.toFixed(2));
            row.isSlot ? (
              row = { id: row.id, xStart: newValStart, yStart: row.yStart, diam: row.diam, xEnd: newValEnd, yEnd: row.yEnd, isSlot: true }
            ) : (
              row = { id: row.id, xStart: newValStart, yStart: row.yStart, diam: row.diam, isSlot: false }
            )
          }
          else {
            alert(t("10429") + row.id + ")");
          }
        }
        return row
      })
      return row
    })
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })
  }

  //Sposta i fori selezionati a destra
  const onClickRight = () => {
    var error = false
    var newRows = rows
    newRows = newRows.map((row) => {
      selectedRows.map((selected) => {
        if (row.id === selected) {
          var newValStart = row.xStart + 1
          var newValEnd = row.xEnd + 1
          newValStart = parseFloat(newValStart.toFixed(2));
          newValEnd = parseFloat(newValEnd.toFixed(2));
          error = row.isSlot ? (newValStart > 35 || newValEnd > 35) : newValStart > 35
          if (!error) {
            row.isSlot ? (
              row = { id: row.id, xStart: newValStart, yStart: row.yStart, diam: row.diam, xEnd: newValEnd, yEnd: row.yEnd, isSlot: true }
            ) : (
              row = { id: row.id, xStart: newValStart, yStart: row.yStart, diam: row.diam, isSlot: false }
            )
          }
          else {
            alert(t("10429") + row.id + ")");
          }
        }
        return row
      })
      return row
    })
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })
  }

  //Modifica il diametro dei fori selezionati
  const onBlurDiameter = (event) => {
    var newRows = rows
    var newDiam = event.target.value.length > 0 ? event.target.value : 0
    newRows = newRows.map((row) => {
      selectedRows.map((selected) => {
        if (row.id === selected) {
          var newVal = parseFloat(newDiam)
          row = { id: row.id, xStart: row.xStart, yStart: row.yStart, diam: newVal }
        }
        return row
      })
      return row
    })
    setRows(newRows)
    setShapeJson({ ...shapeJson, "drillingPoints": newRows })

  }

  return (
    <div>
      {/* X CHIUSURA IN ALTO */}
      <ElevationScroll {...props}>
        <Box display="flex" flexDirection="row-reverse" p={1}>
          <Box p={1}>
            <IconButton
              aria-label={t("10238")}
              variant="outlined"
              onClick={handleCloseDrill}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </ElevationScroll>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10319").toUpperCase()}</u>
          </p>
        </Grid>

        <Grid className={classes.shapeDrillButtonGrid} container alignItems="center" spacing={4}>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAdd}
            >
              {t("10320")}
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeleteSelectedDrill}
            >
              {t("10321")}
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSaveDrill}
            >
              {t("10322")}
            </Button>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={7}>
            <DataGrid
              autoHeight
              checkboxSelection
              columns={columns}
              disableSelectionOnClick
              isCellEditable={(params) =>
                IsCellEditable(params)
              }
              rows={shapeJson.drillingPoints}
              onCellEditCommit={handleEditCellChangeCommitted}
              onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
            />
          </Grid>

          <Grid container item xs={5}>

            {/*RIGA BOTTONE SU*/}
            <Grid item xs={4} />
            <Grid item xs={2} align={"center"}>
              <IconButton
                variant="outlined"
                onClick={onClickUp}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} />

            {/*RIGA BOTTONI SINISTRA, DESTRA E DIAMETRO*/}
            <Grid item xs={2} />
            <Grid item xs={2} align={"right"}>
              <IconButton
                variant="outlined"
                onClick={onClickLeft}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2} align={"center"}>
              <TextField id="outlined-basic" variant="outlined" label={t("10028")} onBlur={onBlurDiameter} value={diamLabelValue} />
            </Grid>
            <Grid item xs={2} align={"left"}>
              <IconButton
                variant="outlined"
                onClick={onClickRight}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4} />

            {/*RIGA BOTTONE GIU*/}
            <Grid item xs={4} />
            <Grid item xs={2} align={"center"}>
              <IconButton
                variant="outlined"
                onClick={onClickDown}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2} />

          </Grid>

        </Grid>
      </Grid>
    </div>
  )
}