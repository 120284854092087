import StringUtils from './StringUtils'
import moment from 'moment'

export default class DatesUtils {
    static day_of_the_month(d) {
        return (d.getDate() < 10 ? '0' : '') + d.getDate();
    }

    static getCurrentDate(separator = '') {

        let newDate = new Date()
        let day = this.day_of_the_month(newDate);
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${day}`
    }

    static getMonthsAgoDate(separator = '', months) {

        let newDate = new Date()
        let day = this.day_of_the_month(newDate);
        let month = newDate.getMonth() - months + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${day}`
    }

    // Get previous date (from days ago)
    static getDaysAgoDate(separator = '', days) {
        let date
        let month
        let year
        let newDate = new Date()

        // If January set 01/01/yyyy
        if (newDate.getMonth() === 0) {
            date = "01";
        } else {
            newDate.setDate(newDate.getDate() - days)
            date = this.day_of_the_month(newDate);
        }
        month = newDate.getMonth() + 1;
        year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }

    // Format Date to String 
    static formatDateToString(date, slash = true, withTime = true, withSeconds = false) {
        return (StringUtils.checkIfNotNull(date) === "" ? "" :
            slash === true ?
                this.dateToStringSlash(date, withTime, withSeconds)
                :
                this.dateToStringMinus(date, withTime, withSeconds)
        )
    }

    // Format -> DD/MM/YYYY (from isodate)
    static dateToStringSlash(date, withTime = true, withSeconds = false) {
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = x.substr(11, 2);
        var mi = x.substr(14, 2);
        var ss = x.substr(17, 2);
        if (withTime) {
            if (withSeconds) {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;
            } else {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}`;
            }
        } else {
            return `${dd}/${mm}`;
        }
    }

    // Format -> YYYY-MM-DD (from isodate)
    static dateToStringMinus(date, withTime = true, withSeconds = false) {
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = x.substr(11, 2);
        var mi = x.substr(14, 2);
        var ss = x.substr(17, 2);
        if (withTime) {
            if (withSeconds) {
                return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
            } else {
                return `${yyyy}-${mm}-${dd} ${hh}:${mi}`;
            }
        } else {
            return `${yyyy}-${mm}-${dd}`;
        }
    }

    static changeTimezone(date, utc) {
        let result = date
        var tzString = this.GetTZ(utc)
        var newDate = moment.utc(date).tz(tzString);
        var dateType = newDate.format('YYYY-MM-DD, HH:mm:ss').toLocaleString("it-IT")
        var date1 = dateType.split(',')
        var reverseDate = date1[0].split('/').reverse().join('/')
        result = new Date( reverseDate + date1[1])
        return result
    }

    // Formattazione che aggiungere/toglie fuso orario ad una data
    static formatDateWithUtc(dateInput, utc) {
        if (!utc) {
            utc = 0;
        }
        var date2 = this.changeTimezone(dateInput, utc);
        var date3 = this.dateToStringStandard(date2, true, true);
        return date3;
    }

    // Format -> DD/MM/YYYY (from to string)
    static dateToStringStandard(date, withTime = true, withSeconds = false) {
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = (date.getHours() < 10 ? '0' : '') + date.getHours();
        var mi = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        var ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
        if (withTime) {
            if (withSeconds) {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;
            } else {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}`;
            }
        } else {
            return `${dd}/${mm}`;
        }
    }

    static GetTZ(utc) {
        let result = "Europe/Rome"
        switch (utc) {
            case -12:
                result = "Etc/GMT+12"
                break;
            case -11:
                result = "Pacific/Pago_Pago"
                break;
            case -10:
                result = "Pacific/Honolulu"
                break;
            case -9:
                result = "Pacific/Gambier"
                break;
            case -8:
                result = "Pacific/Pitcairn"
                break;
            case -7:
                result = "America/Phoenix"
                break;
            case -6:
                result = "America/Belize"
                break;
            case -5:
                result = "America/Panama"
                break;
            case -4:
                result = "America/Port_of_Spain"
                break;
            case -3:
                result = "America/Buenos_Aires"
                break;
            case -2:
                result = "America/Noronha"
                break;
            case -1:
                result = "Atlantic/Azores"
                break;
            case 0:
                result = "Europe/London"
                break;
            case 1:
                result = "Europe/Rome"
                break;
            case 2:
                result = "Europe/Athens"
                break;
            case 3:
                result = "Europe/Istanbul"
                break;
            case 4:
                result = "Asia/Dubai"
                break;
            case 5:
                result = "Asia/Tashkent"
                break;
            case 6:
                result = "Asia/Urumqi"
                break;
            case 7:
                result = "Asia/Bangkok"
                break;
            case 8:
                result = "Asia/Shanghai"
                break;
            case 9:
                result = "Asia/Tokyo"
                break;
            case 10:
                result = "Australia/Sydney"
                break;
            case 11:
                result = "Asia/Magadan"
                break;
            case 12:
                result = "Pacific/Auckland"
                break;
            case 13:
                result = "Pacific/Tongatapu"
                break;
            case 14:
                result = "Pacific/Kiritimati"
                break;
            default:
                result = "Europe/Rome"
        }
        return result
    }

}