import React, { Fragment } from "react"

//core components
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"


export default function SettingsTableOkSaved(props) {
  const { dialogMsg, handleClose, isSaving, openDialog } = props

  return (
      <Dialog
          open={isSaving || openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          {openDialog ? (
              <Fragment>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          {dialogMsg}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleClose} color="primary" autoFocus>
                          Ok
            </Button>
                  </DialogActions>
              </Fragment>
          ) : (
                  <Fragment>
                      <DialogContent>
                          <DialogContent>
                              <CircularProgress size={42} style={{ color: "primary" }} />
                          </DialogContent>
                      </DialogContent>
                  </Fragment>
              )}
      </Dialog>
  )
}