import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

import Grid from "@material-ui/core/Grid"
import TracerButton from "components/Buttons/TracerButton"

export default function TracerBar({
  debugMode,
  waitMode,
  handleTracerPopupAll,
  handleTracerPopupConnect,
  handleTracerPopupDisconnect,
  handleTracerPopupReceive
}) {
  const { t } = useContext(RootContext)

  return (
    <div>
      {debugMode ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TracerButton
              handleClick={handleTracerPopupReceive}
              textButton={t("10183")}
            />
          </Grid>
          <Grid item xs={4}>
            <TracerButton
              handleClick={handleTracerPopupConnect}
              textButton={t("10180")}
            />
          </Grid>
          <Grid item xs={4}>
            <TracerButton
              handleClick={handleTracerPopupDisconnect}
              textButton={t("10010")}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {!waitMode ? (
              <TracerButton
                handleClick={handleTracerPopupAll}
                textButton={t("10184")}
              />
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <TracerButton
              handleClick={handleTracerPopupDisconnect}
              textButton={t("10010")}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
