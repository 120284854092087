import React, { useCallback, useState, useEffect, useContext } from "react";
import { RootContext } from 'RootContext'

// @material-ui/core components
import AccessTime from "@material-ui/icons/AccessTime";
import Chartist from "chartist";
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartistGraph from "react-chartist";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TimeAgo from "react-timeago"
import { chartAnimation } from "variables/charts"

// Pegaso components
import EdgingStatisticFetch from "views/Statistics/EdgingStatisticFetch"
import ErrorUtils from 'components/Tools/ErrorUtils'
import { fetchWithToken } from 'components/Fetch/api-fetch'

// Css
import { useStatisticStyles } from "styles/statistics/statisticCss"

export default function EdgingStatistic() {
  const classes = useStatisticStyles();
  const { apiUrl, access_token, refresh_token, setAccess_token, t } = useContext(RootContext)

  const [allBrandsDevices, setAllBrandsDevices] = useState([])
  const [statFetched, setStatFetched] = useState(false)
  const [updateAgo, setUpdateAgo] = useState()
  const [jobsChartData, setJobsChartData] = useState(
    {
      labels: [],
      series: [[]],
    });
  const [maxData, setMaxData] = useState(0)

  /*
      FETCH
  */
  //Fetch -  Elenco devices
  const fetchDevices = useCallback(() => {
    const url = `${apiUrl}/brands`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ brands }) => {
        brands.sort(function (a, b) {
          return parseInt(a.brandId) - parseInt(b.brandId);
        });
        setAllBrandsDevices(brands)
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchDevices  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token])

  /*
    USE EFFECT
  */
  useEffect(() => {
    fetchDevices()
  }, [fetchDevices])

  useEffect(() => {
    setMaxData(Math.max.apply(this, jobsChartData.series[0]) + 10)
  }, [jobsChartData])

  const jobsChart = {
    data: {
      labels: jobsChartData.labels,
      series: jobsChartData.series,
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      height: '300px',
      low: 0,
      high: maxData,
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    // for animation
    animation: chartAnimation
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {allBrandsDevices.length > 0 ?
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("10036").toUpperCase()}</h4>
                <p className={classes.cardCategoryWhite}>{t("10101")} ({t("10353")})</p>
                <EdgingStatisticFetch allBrandsDevices={allBrandsDevices} setJobsChartData={setJobsChartData} setUpdateAgo={setUpdateAgo} setStatFetched={setStatFetched} />
              </CardHeader>
              {statFetched ?
                <CardBody>
                  <Card chart>
                    <CardHeader color="success">
                      <ChartistGraph
                        className="ct-chart"
                        data={jobsChart.data}
                        type="Bar"
                        options={jobsChart.options}
                        responsiveOptions={jobsChart.responsiveOptions}
                        listener={jobsChart.animation}
                      />
                    </CardHeader>
                    <CardFooter chart>
                      <div className={classes.stats}>
                        {updateAgo && (<AccessTime />)}{updateAgo && (" updated ")}{updateAgo && (<TimeAgo date={updateAgo} />)}
                      </div>
                    </CardFooter>
                  </Card>
                </CardBody>
                : <div style={{ position: 'absolute', left: '50%', top: '150%', transform: 'scale(2, 2)', }}>
                  <CircularProgress />
                </div>}
            </Card>
            : <Card><div style={{ position: 'absolute', left: '50%', top: '150%', transform: 'scale(2, 2)', }}>
              <CircularProgress />
            </div></Card>
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
