import React, { useContext } from "react"
import { RootContext } from "RootContext"

// Core components
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"

// Button che apre la finestra con i dati del Job
function ButtonDialogJob(props) {
  const { handleClickOpen, year, job } = props
  const { t, isLabUser, labScopes } = useContext(RootContext)

  let seeButton = false
  // Solo utente laboratorio con labScope = 8 attivo vede il bottone
  if (isLabUser()) {
    let checkScope = []
    if (labScopes) {
      checkScope = labScopes.filter((elem) => {
        return elem.active === true && elem.scopeId === 8
      })
    }
    seeButton = checkScope.length > 0
  }

  return seeButton ? (
    <div>
      <Tooltip title={t("10234")}>
        <IconButton
          aria-label={t("10234")}
          variant="outlined"
          onClick={() => {
            handleClickOpen(year, job)
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : null
}

function JobRoutingSqsButton(props) {
  return (
    <div id="contenitore">
      <ButtonDialogJob {...props} />
    </div>
  )
}

export default JobRoutingSqsButton
