import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

// core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paper from "@material-ui/core/Paper"
import { titleCase } from "title-case";

// pegaso views/components
import FilterDatePicker from "components/Filter/FilterDatePicker"
import FilterTextField from "components/Filter/FilterTextField"
import FilterSelect from "components/Filter/FilterSelect"

// CSS
import {
  useJobsToolbarStyles,
  useJobsToolbarTextFieldStyles,
  useJobsToolbarDatePickerStyles,
  useJobsToolbarSelectStyles,
  useJobsToolbarButton,
} from "styles/jobs/jobsToolbarCss"


// Toolbar -> FILTERS
function ToolbarFilters(props) {
  const {
    classes,
    filter,
    handleCancelFetch,
    handleChangeFilter,
    handleClickResetFilters,
  } = props
  const { t, isLabUser, } = useContext(RootContext)
  const filterSelectClass = useJobsToolbarSelectStyles()
  const filterTextFieldClass = useJobsToolbarTextFieldStyles()
  const filterDatePickerClass = useJobsToolbarDatePickerStyles()
  const filterButtonClass = useJobsToolbarButton()
  const [selectedRadioArea, SetSelectedRadioArea] = useState("2")

  const selectOptionsStatus = [
    {
      value: "",
      text: "",
    },
    {
      value: t("10090"),
      text: t("10090"),
    },
    {
      value: t("10146"),
      text: t("10146"),
    },
    {
      value: t("10011"),
      text: t("10011"),
    },
    {
      value: t("43"),
      text: t("43"),
    },
  ]

  const selectOptionsCreatedJobs = [
    {
      value: "",
      text: "",
    },
    {
      value: t("10092"),
      text: t("10092"),
    },
    {
      value: t("10060"),
      text: t("10060"),
    },

  ]

  const currentYear = new Date().getFullYear()
  const startYear = currentYear - 2
  const selectOptionsJobYear = [
    {
      value: "",
      text: "",
    },
  ]

  for (let i = startYear; i <= currentYear; i++) {
    const item = { value: i, text: i }
    selectOptionsJobYear.push(item)
  }

  function handleChangeRadioArea(event) {
    handleCancelFetch()
    if(event.target.value === "2"){
      handleChangeFilter("jobId", "")
    }
    SetSelectedRadioArea(event.target.value)
  }


  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >

        {/*   RIGA 1    */}
        <Grid item xs={6} >
        <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              p={1}
            >
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">{titleCase(t("10412"))}
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" >
                    <FormControlLabel label={titleCase(t("10413"))} value="1" control={<Radio checked={selectedRadioArea === '1'} onChange={handleChangeRadioArea} />} />
                    <FormControlLabel label={titleCase(t("10414"))} value="2" control={<Radio checked={selectedRadioArea === '2'} onChange={handleChangeRadioArea} />} />
                </RadioGroup>
            </FormLabel>
          </FormControl>
        </Box>
        </Grid>
  
        <div style={{display: selectedRadioArea === '1' ? 'block' : 'none'}}>
        <Grid item xs={4} >
          <FilterSelect 
            classes={filterSelectClass}
            id="searchJobYear"
            label={t("10117")}
            defaultValue={filter.year}
            disabled={selectedRadioArea === '2'}
            handleChange1={handleChangeFilter}
            handleClick1={handleCancelFetch}
            options={selectOptionsJobYear}
            filterField={"year"}
          />
        </Grid>
        </div>

        <div style={{display: selectedRadioArea === '1' ? 'block' : 'none'}}>
        <Grid item xs={4}>
          <FilterTextField
            classes={filterTextFieldClass}
            id="searchJob"
            label={t("10118")}
            defaultValue={filter.jobId}
            disabled={selectedRadioArea === '2'}
            handleChange1={handleChangeFilter}
            handleClick1={handleCancelFetch}
            filterField={"jobId"}
          />
        </Grid>
        </div>

        <div style={{display: selectedRadioArea === '2' ? 'block' : 'none'}}>
        <Grid item xs={4}>
          <FilterDatePicker
            classes={filterDatePickerClass}
            id="searchFromDate"
            label={t("10041")}
            defaultValue={filter.fromDate}
            handleChange={handleChangeFilter}
            handleClick1={handleCancelFetch}
            disabled={selectedRadioArea === '1'}
            type="date"
            filterField={"fromDate"}
          />
        </Grid>
        </div>

        <div style={{display: selectedRadioArea === '2' ? 'block' : 'none'}}>
        <Grid item xs={4}>
          <FilterDatePicker
            classes={filterDatePickerClass}
            id="searchToDate"
            label={t("10136")}
            defaultValue={filter.toDate}
            handleChange={handleChangeFilter}
            handleClick1={handleCancelFetch}
            disabled={selectedRadioArea === '1'}
            type="date"
            filterField={"toDate"}
          />
        </Grid>
        </div>

        {/* Seleziona job  */
          /*filter.jobId === "" &&
            filter.toDate === "" &&
            filter.fromDate === "" ? (
            <Grid item xs={12} align="center">
              <p className={classes.selectFilterMessage}>({t("10227")})</p>
            </Grid>
            ) : null*/}


        {/* Verifica che periodi siano stesso anno */
          filter.toDate !== "" && filter.fromDate !== "" ? (
            filter.toDate.substr(0, 4) !== filter.fromDate.substr(0, 4) ? (
              <Grid item xs={12} align="center">
                <p className={classes.selectFilterMessage}>({t("10228")})</p>
              </Grid>
            ) : filter.fromDate > filter.toDate ? (
              <Grid item xs={12} align="center">
                <p className={classes.selectFilterMessage}>({t("39")})</p>
              </Grid>
            ) : null
          ) : null}

        {/*   RIGA 2    */}
        {
          isLabUser() ? (
            <Grid item xs={4}>
              <FilterTextField
                classes={filterTextFieldClass}
                id="searchOptician"
                label={t("10119")}
                defaultValue={filter.opticianView}
                handleChange1={handleChangeFilter}
                filterField={"opticianView"}
              />
            </Grid>
          ) : (
            null
          )
        }
 
        <Grid item xs={4}>
          <FilterTextField
            classes={filterTextFieldClass}
            id="searchOpticianNote"
            label={t("10120")}
            defaultValue={filter.note}
            handleChange1={handleChangeFilter}
            filterField={"note"}
          />
        </Grid>
        <Grid item xs={4}>
          <FilterTextField
            classes={filterTextFieldClass}
            id="searchOpticianCustomer"
            label={t("10387")}
            defaultValue={filter.optcst}
            handleChange1={handleChangeFilter}
            filterField={"optcst"}
          />
        </Grid>

        {/*   RIGA 3    */}
        <Grid item xs={4}>
          <FilterSelect
            classes={useJobsToolbarSelectStyles}
            id="searchStatus"
            label={t("10037")}
            defaultValue={filter.statusView}
            handleChange1={handleChangeFilter}
            options={selectOptionsStatus}
            filterField={"status"}
          />
        </Grid>

        {
          isLabUser() ? (
            <Grid item xs={4}>
              <FilterSelect
                classes={filterSelectClass}
                id="searchCreatedBy"
                label={t("10245")}
                defaultValue={filter.software}
                handleChange1={handleChangeFilter}
                options={selectOptionsCreatedJobs}
                filterField={"software"}
              />
            </Grid>
          ) : (
            null
          )
        }

        <Grid item xs={4}>
          <Button
            classes={filterButtonClass}
            variant="outlined"
            color="secondary"
            onClick={handleClickResetFilters}
          >
            {t("10233")}
          </Button>
        </Grid>




      </Grid>
    </div>
  )
}

// Toolbar -> TOOLBAR
export default function JobsToolbar(props) {
  const classes = useJobsToolbarStyles()
  return (
    <div>
      <Paper className={classes.paperToolbar}>
        <ToolbarFilters classes={classes} {...props} />
      </Paper>
    </div>
  )
}
