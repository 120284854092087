import { makeStyles } from '@material-ui/core/styles';

const useTracerStyle = makeStyles({
  tracerContainer: {
    maxHeight: 400,
  },
  tracerFoto: {
    height: 200,
    maxWidth: 230
  }
})

export { useTracerStyle }
