import React, { useContext, useEffect, useState } from 'react'
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ErrorUtils from '../../components/Tools/ErrorUtils'
import { CircularIndeterminate } from '../Loading'
import { RootContext } from '../../RootContext'
import { fetchWithToken } from '../../components/Fetch/api-fetch'
// Css
import { useUserProfileStyles } from 'styles/UserProfile/userProfileCss'

export default function UserProfile() {
  const { t, apiUrl, companyId, getLab, access_token, refresh_token, setAccess_token } = useContext(RootContext)
  const { labId } = getLab()
  const classes = useUserProfileStyles();
  const [labFetched, setLabFetched] = useState(false)
  const [labData, setLabData] = useState({})
  const [devices, setDevices] = useState([])
  const url = `${apiUrl}/companies/${companyId}/labs/${labId}/devices`

  /*
  *   USE EFFECT
  */
  useEffect(() => {

    function fetchLab() {

      fetchWithToken(url, {
        method: 'GET',
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then(response => {
          return response.body // data
        })
        .then(data => {
          setLabData(data.lab)
          let tmpDevices = []
          data.hubs.forEach(hub => {
            hub.devices.forEach(devices => {
              tmpDevices = tmpDevices.concat(devices)
            });
          });
          setDevices(tmpDevices)
          setLabFetched(true)
        })
        .catch(err => {
          // Se lab non esiste
          ErrorUtils.errorLog('Fetch Lab Error', err)
          setLabFetched(true)
        })
    }

    if (!labFetched) {
      fetchLab()
    }
  }, [labFetched, access_token, apiUrl, companyId, refresh_token, setAccess_token, url])


  const { address } = labData

  return (
    <div>
      {labFetched ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("10033")}</h4>
                <p className={classes.cardCategoryWhite}>{t("10014")}</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10013")}
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: labData.name,
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10098")}
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: labData.phone,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10073")}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: labData.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10132")}
                      id="street"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.street : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={t("10133")}
                      id="number"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.number : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10012")}
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.city : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={t("10107")}
                      id="province"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.province : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      labelText={t("10148")}
                      id="zipCode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.zipCode : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={t("10015")}
                      id="state"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{ value: address ? address.state : "" }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("10026")}
                      id="devices"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        multiline: true,
                        rows: 5,
                        value: devices.map(device => {
                          const { deviceId, connection, model } = device
                          const { brand, description, type } = model
                          return `\n${deviceId} - ${brand.brand} ${description} ${type} ${connection}`
                        })
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary">{t("10139")}</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        :
        <CircularIndeterminate />
      }
    </div>
  );
}