import React, { Fragment } from "react"

// Core components
import { Layer, Line } from "react-konva"

// Pegaso components


// Css
import Drills from "components/Drills/Drills"

export default function ShapeEdit(props) {
  
  const {
    onDragEnd,
    openDialog,
    origin,
    scaleX,
    scaleY,
    shapeJson,
    shapeClass,
    startLeftLens,
    stroke
  } = props

  return (
    <Fragment>
      <Layer>
        {/* 1 - LENTE DESTRA (origine = centro lente) */}
        <Line
          closed
          fill={null}
          points={shapeJson ? shapeJson.right.points : []}
          stroke={'red'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
          x={origin.x}
          y={origin.y}
        />
        {/* FORI (Lente Destra) */}
        <Drills
          isEditShape={true}
          onDragEnd={onDragEnd}
          openDialog={openDialog}
          origin={origin}
          scaleX={scaleX}
          scaleY={scaleY}
          side={"right"}
          shapeJson={shapeJson}
          shapeClass={shapeClass}
          startLens={origin.x}
          stroke={stroke}
        />
        {/* 2 - LENTE SINISTRA (origine = centro lente) */}
        <Line
          closed
          fill={null}
          points={shapeJson ? shapeJson.left.points : []}
          tension={0}
          stroke={'red'}
          strokeWidth={shapeClass.strokeWidth}
          x={startLeftLens}
          y={origin.y}
        />
        {/* FORI (Lente Sinistra) */}
        <Drills
          isEditShape={true}
          onDragEnd={onDragEnd}
          openDialog={openDialog}
          origin={origin}
          scaleX={scaleX}
          scaleY={scaleY}
          shapeJson={shapeJson}
          shapeClass={shapeClass}
          startLens={startLeftLens}
          side={"left"}
          stroke={stroke}
        />
      </Layer>
    </Fragment>

  )

}