
import React, { useContext, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Box from "@material-ui/core/Box"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography"
import { Stage, Layer, Line } from "react-konva"

//pegaso/views/components
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken, refreshToken } from "components/Fetch/api-fetch"
import StringUtils from "components/Tools/StringUtils"
import SettingsSaveButton from "components/Buttons/SettingsSaveButton"
import SettingsTableOkSaved from "views/Settings/SettingsTableOkSaved"

//import CSS
import { useDeviceStylesText } from "styles/device/deviceCss.js"

export default function CalibrationSettings(props) {
  const {
    t,
    companyId,
    getUsername,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
    getCalibration,
  } = useContext(RootContext)


  //Variabili
  const [calibration, setCalibration] = useState(getCalibration())
  const [canvasWidth] = useState(800)
  const [canvasHeight] = useState(400)
  const [posXStart] = useState(canvasWidth / 2)
  const [posYStart] = useState(canvasHeight / 2)

  const classesText = useDeviceStylesText()
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openPanelCalibration, setOpenPanelCalibration] = useState(false)
  const [username] = useState(getUsername())

  //Disegna il rettangolo
  function DrawRectangle() {
    var points = []

    // A) NORTH - WEST (START)
    points[0] = posXStart - (4000 / calibration.dpiX)
    points[1] = posYStart - (4000 / calibration.dpiY)

    // B) NORTH - EAST
    points[2] = posXStart + (4000 / calibration.dpiX)
    points[3] = posYStart - (4000 / calibration.dpiY)

    // C) SOUTH - EAST
    points[4] = posXStart + (4000 / calibration.dpiX)
    points[5] = posYStart + (4000 / calibration.dpiY)

    // D) SOUTH - WEST
    points[6] = posXStart - (4000 / calibration.dpiX)
    points[7] = posYStart + (4000 / calibration.dpiY)

    // E) NORTH - WEST (END)
    points[8] = posXStart - (4000 / calibration.dpiX)
    points[9] = posYStart - (4000 / calibration.dpiY)

    return points
  }


  /*************************************
    *
    * Handle
    *
    *************************************/


  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/users/${username}/settings`
    fetchWithToken(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calibration: calibration,
      }),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10314")}\n\nUsername: ${username}\n`
        )
        refreshToken()
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("28"), err)
        setDialogMsg(t("28"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
  }


  //Cambia field
  const handleChange = (name) => (event) => {
    let value = event.target.value.trim()
    value = (value.length === 0 ? 0 : parseInt(value))
    setCalibration({ ...calibration, [name]: parseInt(value) })
  }

  //Chiude o apre il pannello contenente la tarratura
  const changeOpenPanelCalibration = (event,) => {
    var value = !openPanelCalibration
    setOpenPanelCalibration(value)
  }

  return (
    <div>
      <Accordion expanded={openPanelCalibration} onChange={changeOpenPanelCalibration} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10315").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              <Grid
                container
                bgcolor="background.paper"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    id={"dpiX"}
                    label={t("10317")}
                    error={calibration.dpiX <= 14}
                    value={calibration.dpiX}
                    className={classesText.textField}
                    onChange={handleChange("dpiX")}
                    helperText={calibration.dpiX <= 14 ? t("18") + "14" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"dpiY"}
                    label={t("10316")}
                    error={calibration.dpiY <= 14}
                    value={calibration.dpiY}
                    className={classesText.textField}
                    onChange={handleChange("dpiY")}
                    helperText={calibration.dpiY <= 14 ? t("18") + "14" : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  {t("10318")}
                </Grid>
              </Grid>

              <Box p={1} alignSelf="center" >
                <Stage width={canvasWidth} height={canvasHeight}>
                  <Layer>
                    <Line
                      points={calibration.dpiX >= 15 && calibration.dpiY >= 15 ? DrawRectangle() : []}
                      stroke="black"
                    />
                  </Layer>
                </Stage>
              </Box>

              {/*BOTTONI PER SALVATAGGIO*/}
              <SettingsSaveButton
                {...props}
                handleSave={handleSave}
              />
              <SettingsTableOkSaved
                {...props}
                dialogMsg={dialogMsg}
                handleClose={handleClose}
                isSaving={isSaving}
                openDialog={openDialog}
              />
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>
    </div >
  )
}