import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme'

const useStatisticToolbarStyles = makeStyles({
  filters: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
});

const useStatisticToolbarTextFieldStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const useStatisticToolbarDatePickerStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
}));

const useStatisticToolbarSelectStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export { useStatisticToolbarStyles, useStatisticToolbarTextFieldStyles, useStatisticToolbarDatePickerStyles, useStatisticToolbarSelectStyles }
