import { useTranslation } from "react-i18next"

export default class TracerUtils {
  static sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  static printTracer(tracer) {
    const tracerImage = t => `/resources/images/Tracer/${t}.png`
    switch (tracer.toString()) {
      case "1":
        return tracerImage("takubo") //Takubo
      case "2":
        return tracerImage("nidek") //Nidek
      case "3":
        return tracerImage("essilor") //Essilor
      case "4":
        return tracerImage("blocker") //Briot
      case "5":
        return tracerImage("weco") //Weco
      case "6":
        return tracerImage("hoya-oma") //Hoya (OMA)
      case "7":
        return tracerImage("topcon")
      case "8":
        return tracerImage("huvitz") //Huvitz
      case "9":
        return tracerImage("hoya") //Hoya (HOYALOG)
      case "10":
        return tracerImage("wecoT2") //Weco Trace 2
      case "11":
        return tracerImage("hoya5000") //Hoya Gt 5000
      case "12":
        return tracerImage("nop4TX") //National Optronics 4TX
      case "13":
        return tracerImage("essilorKappa") //Essilor Kappa (OMA)
      default:
        return null
    }
  }

  static tracerInstructions(tracer) {
    const { t } = useTranslation()
    const newLine = "\n"

    switch (tracer.toString()) {
      case "1":
        // Takubo
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10165") +
          newLine +
          t("10166") +
          newLine +
          t("10167")
        )
      case "2":
        // Nidek
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10169") +
          newLine +
          t("10168")
        )
      case "3":
        // Essilor
        // [10166;10167;10172]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10169")
        )
      case "4":
        // Blocker (OMA)
        // [10166;10167;10174;10175]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10171") +
          newLine +
          t("10172")
        )
      case "5":
        // Briot / Weco / Nidek (OMA)
        // [10166;10167;10174]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10171")
        )
      case "6":
        // Hoya (OMA)
        // [10166;10167;11240;11241]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10178") +
          newLine +
          t("10179")
        )
      case "7":
        // Topcon-Takubo (OMA)
        // [10166;10167;10174;10177]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10171") +
          newLine +
          t("10173")
        )
      case "8":
        // Huvitz
        // [10166;10167;10178]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10174")
        )
      case "9":
        // Hoya (HOYALOG)
        // [10166;10167;11240]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10178")
        )
      case "10":
        // Weco Trace 2
        // [10166;11128;11129]
        return (
          t("10163") +
          newLine +
          t("10175") +
          newLine +
          t("10176")
        )
      case "11":
        // Hoya Gt 5000
        // [10166;10167;11240;11191]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10178") +
          newLine +
          t("10177")
        )
      case "12":
        // National Optronics
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10346")
        )
      case "13":
        // Essilor Kappa (OMA)
        // [10166;10167;10178]
        return (
          t("10163") +
          newLine +
          t("10164") +
          newLine +
          t("10174")
        )
      default:
        return t("22")
    }
  }

  static createToken() {
    var today = new Date()
      .toJSON()
      .split("-")
      .join("")
      .substring(0, 8)
    return btoa(btoa(btoa(today)))
  }

  static getJsonDevice(opticianUserDevice) {
    const { protocol, model, serial } = opticianUserDevice
    const { options } = protocol
    const { tracerId: codTracer } = options
    const { settings, port: comName } = serial

    const { brand } = model
    const { brand: bName } = brand

    const token1 = this.createToken()
    const tracer = `${codTracer} ${bName}`

    const tracerSetting = settings

    return { token1, tracer, tracerSetting, codTracer, comName }
  }
}
