import React, { Fragment } from "react"

// Core components
import { Circle, Layer, Line, Text, Arc } from "react-konva"

// Css
import { useShapeStyle } from "styles/shape/shapeCss"

export default function OpticalCenter(props) {
  const {
    opticalCenter,
    startLeftLens,
    origin,
    rightValues,
    leftValues,
    t,
    isProgressive,
    calibration,
    decY,
    decX,
    tolTouse
  } = props

  const shapeClass = useShapeStyle.shape

  function DrawCenterCross(props) {
    const {
      startX,
      startY,
    } = props
    return (
      <Fragment>
        <Line
          fill={shapeClass.fill}
          points={[startX - 8, startY, startX + 8, startY]}
          stroke={shapeClass.stroke}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />
        <Line
          fill={shapeClass.fill}
          points={[startX, startY - 8, startX, startY + 8]}
          stroke={shapeClass.stroke}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />
      </Fragment>
    )
  }

  function DrawTOpticalCenter(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
      side,
    } = props
    var opticalCenterX = opticalCenterSide.start.x * 100
    var opticalCenterY = opticalCenterSide.start.y * 100

    if (isProgressive) {
      if (side === "R") {
        opticalCenterX = (opticalCenterSide.start.x - decX) * 100
      }
      else {
        opticalCenterX = (opticalCenterSide.start.x + decX) * 100
      }
      opticalCenterY = (opticalCenterSide.start.y + decY) * 100
    }

    return (
      <Fragment>
        <Line
          fill={shapeClass.fill}
          points={[originPointX - (opticalCenterX - 200) / calibration.dpiX, originPointY - opticalCenterY / calibration.dpiX, originPointX - (opticalCenterX + 200) / calibration.dpiX, originPointY - opticalCenterY / calibration.dpiX]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />
        <Line
          fill={shapeClass.fill}
          points={[originPointX - opticalCenterX / calibration.dpiX, originPointY - opticalCenterY / calibration.dpiY, originPointX - opticalCenterX / calibration.dpiX, originPointY - (opticalCenterY - 200) / calibration.dpiX]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />
      </Fragment>

    )
  }

  function DrawMaxRadiusPoint(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
    } = props
    return (
      <Circle
        x={originPointX - opticalCenterSide.end.x * 100 / calibration.dpiX}
        y={originPointY - opticalCenterSide.end.y * 100 / calibration.dpiY}
        radius={2}
        fill="#666d72"
      />
    )
  }

  function DrawMaximumRadiusLine(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
    } = props
    var opticalCenterStartX = opticalCenterSide.start.x * 100 / calibration.dpiX
    var opticalCenterStartY = opticalCenterSide.start.y * 100 / calibration.dpiY
    var opticalCenterEndX = opticalCenterSide.end.x * 100 / calibration.dpiX
    var opticalCenterEndY = opticalCenterSide.end.y * 100 / calibration.dpiY
    return (
      <Line
        fill={shapeClass.fill}
        points={[originPointX - opticalCenterStartX, originPointY - opticalCenterStartY, originPointX - opticalCenterEndX, originPointY - opticalCenterEndY]}
        stroke={'#666d72'}
        strokeWidth={shapeClass.strokeWidth}
        tension={0}
      />)
  }

  function DrawMinDiameter(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
    } = props
    var rightEndWithTol = opticalCenterSide.end.x > 0 ? opticalCenterSide.end.x + tolTouse : opticalCenterSide.end.x - tolTouse
    var diameter = Math.sqrt((rightEndWithTol - opticalCenterSide.start.x) ** 2 + (opticalCenterSide.end.y - opticalCenterSide.start.y) ** 2)
    var opticalCenterStartX = opticalCenterSide.start.x * 100 / calibration.dpiX
    var opticalCenterStartY = opticalCenterSide.start.y * 100 / calibration.dpiY
    return (
      <Fragment>
        <Circle
          x={originPointX - opticalCenterStartX}
          y={originPointY - opticalCenterStartY}
          radius={diameter * 100 / calibration.dpiX}
          stroke="#666d72"
          strokeWidth={1}
        />
      </Fragment>
    )
  }

  function DrawFarCircle(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
      side,
    } = props
    var XCO = opticalCenterSide.start.x;
    var YCO = opticalCenterSide.start.y;
    XCO = side === "R" ? (XCO - decX) * 100 : (XCO + decX) * 100;
    YCO = (YCO + decY) * 100;
    var opticalCenterStartX = XCO / calibration.dpiX
    var opticalCenterStartY = YCO / calibration.dpiX
    return (
      <Fragment>
        <Arc
          x={originPointX - opticalCenterStartX}
          y={originPointY - opticalCenterStartY - 15}
          innerRadius={15}
          outerRadius={15}
          angle={90}
          rotation={135}
          stroke="#666d72"
          strokeWidth={1}
        />

        {/* Secondo arco (da 0 a 3/4 di RAD, in senso antiorario) */}
        <Arc
          x={originPointX - opticalCenterStartX}
          y={originPointY - opticalCenterStartY - 15}
          innerRadius={15}
          outerRadius={15}
          angle={270}
          rotation={135}
          stroke="#666d72"
          strokeWidth={1}
          fill="transparent"
          clockwise={false}

        />
      </Fragment>
    )
  }

  function DrawNearCircle(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
      side,
    } = props
    var XIniz = opticalCenterSide.start.x * 100
    var YIniz = opticalCenterSide.start.y
    var X_Vicino = side === "R" ? (opticalCenterSide.start.x - decX) * 100 : (opticalCenterSide.start.x + decX) * 100
    var Y_Vicino = (YIniz + 4) * 100
    var diffCanaleX = decX * 50

    return (
      <Circle
        x={originPointX - (X_Vicino + (X_Vicino - XIniz) + diffCanaleX) / calibration.dpiX + 10}
        y={originPointY - (Y_Vicino - (Y_Vicino - YIniz) - (1600 - 200)) / calibration.dpiY}
        radius={10}
        stroke="#666d72"
        strokeWidth={1}
      />
    )
  }

  function DrawFitPoint(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
      side
    } = props
    var opticalCenterStartY = opticalCenterSide.start.y * 100 / calibration.dpiY
    var XCO = opticalCenterSide.start.x;
    XCO = side === "R" ? (XCO - decX) * 100 : (XCO + decX) * 100;

    return (
      <Fragment>
        <Circle
          x={originPointX - XCO / calibration.dpiX}
          y={originPointY - opticalCenterStartY}
          radius={2}
          fill="#666d72"
        />

        <Line
          fill={shapeClass.fill}
          points={[originPointX - (XCO + 1000) / calibration.dpiX, originPointY - opticalCenterStartY, originPointX - (XCO + 1400) / calibration.dpiX, originPointY - opticalCenterStartY]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />

        <Circle
          x={originPointX - (XCO - 1700) / calibration.dpiX}
          y={originPointY - opticalCenterStartY}
          radius={2}
          stroke="#666d72"
          strokeWidth={1}
        />

        <Line
          fill={shapeClass.fill}
          points={[originPointX - (XCO + 2000) / calibration.dpiX, originPointY - opticalCenterStartY, originPointX - (XCO + 2400) / calibration.dpiX, originPointY - opticalCenterStartY]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />


        <Line
          fill={shapeClass.fill}
          points={[originPointX - (XCO - 1000) / calibration.dpiX, originPointY - opticalCenterStartY, originPointX - (XCO - 1400) / calibration.dpiX, originPointY - opticalCenterStartY]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />

        <Circle
          x={originPointX - (XCO + 1700) / calibration.dpiX}
          y={originPointY - opticalCenterStartY}
          radius={2}
          stroke="#666d72"
          strokeWidth={1}
        />


        <Line
          fill={shapeClass.fill}
          points={[originPointX - (XCO - 2000) / calibration.dpiX, originPointY - opticalCenterStartY, originPointX - (XCO - 2400) / calibration.dpiX, originPointY - opticalCenterStartY]}
          stroke={'#666d72'}
          strokeWidth={shapeClass.strokeWidth}
          tension={0}
        />
      </Fragment>
    )
  }

  function WriteDiameter(props) {
    const {
      originPointX,
      originPointY,
      opticalCenterSide,
      orderValues
    } = props

    var rightEndWithTol = opticalCenterSide.end.x > 0 ? opticalCenterSide.end.x + tolTouse : opticalCenterSide.end.x - tolTouse
    var diameter = Math.sqrt((rightEndWithTol - opticalCenterSide.start.x) ** 2 + (opticalCenterSide.end.y - opticalCenterSide.start.y) ** 2)
    var diameterToWrite = Math.floor(2 * diameter)
    return (
      <Fragment>
        <Text
          x={originPointX - 75}
          y={originPointY + 150}
          text={t("10450") + ": " + diameterToWrite}
          fontSize={15}
          fontFamily="Calibri"
          fill="black"
        />
        {
          orderValues.dia ?
            <Text
              x={originPointX - 75}
              y={originPointY + 165}
              text={t("10451") + ": " + orderValues.dia}
              fontSize={15}
              fontFamily="Calibri"
              fill={orderValues.dia < diameterToWrite ? "red" : "black"}
            /> : null
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Layer>
        {/* 1 - LENTE DESTRA (origine = centro lente) */}
        {opticalCenter.right.isRight ? (
          <Fragment>
            {/*Croce per centro lente*/}
            <DrawCenterCross
              startX={origin.x}
              startY={origin.y}
            />

            {/*T per centro ottico*/}
            <DrawTOpticalCenter
              originPointX={origin.x}
              originPointY={origin.y}
              opticalCenterSide={opticalCenter.right}
              side="R"
            />

            {/*Raggio massimo*/}
            <DrawMaxRadiusPoint
              originPointX={origin.x}
              originPointY={origin.y}
              opticalCenterSide={opticalCenter.right}
            />

            {/*Linea raggio massimo*/}
            <DrawMaximumRadiusLine
              originPointX={origin.x}
              originPointY={origin.y}
              opticalCenterSide={opticalCenter.right}
            />


            {/*Cerchio diametro minimo*/}
            <DrawMinDiameter
              originPointX={origin.x}
              originPointY={origin.y}
              opticalCenterSide={opticalCenter.right}
            />

            {
              isProgressive ? (
                <Fragment>
                  <DrawFitPoint
                    originPointX={origin.x}
                    originPointY={origin.y}
                    opticalCenterSide={opticalCenter.right}
                    side="R"
                  />
                  <DrawNearCircle
                    originPointX={origin.x}
                    originPointY={origin.y}
                    opticalCenterSide={opticalCenter.right}
                    side="R"
                  />

                  <DrawFarCircle
                    originPointX={origin.x}
                    originPointY={origin.y}
                    opticalCenterSide={opticalCenter.right}
                    side="R"
                  />

                </Fragment>

              ) : null
            }
            <WriteDiameter
              originPointX={origin.x}
              originPointY={origin.y}
              opticalCenterSide={opticalCenter.right}
              orderValues={rightValues}
            />
          </Fragment>
        ) : null}

        {/* 2 - LENTE SINISTRA (origine = centro lente) */}
        {
          opticalCenter.left.isLeft ? (
            
            <Fragment>
              {/*Croce per centro lente*/}
              <DrawCenterCross
                startX={startLeftLens}
                startY={origin.y}
              />

              {/*T per centro ottico*/}
              <DrawTOpticalCenter
                originPointX={startLeftLens}
                originPointY={origin.y}
                opticalCenterSide={opticalCenter.left}
                side="L"
              />

              {/*Raggio massimo*/}
              <DrawMaxRadiusPoint
                originPointX={startLeftLens}
                originPointY={origin.y}
                opticalCenterSide={opticalCenter.left}
              />

              {/*Linea raggio massimo*/}
              <DrawMaximumRadiusLine
                originPointX={startLeftLens}
                originPointY={origin.y}
                opticalCenterSide={opticalCenter.left}
              />


              {/*Cerchio diametro minimo*/}
              <DrawMinDiameter
                originPointX={startLeftLens}
                originPointY={origin.y}
                opticalCenterSide={opticalCenter.left}
              />

              {
                isProgressive ? (
                  <Fragment>
                    <DrawFitPoint
                      originPointX={startLeftLens}
                      originPointY={origin.y}
                      opticalCenterSide={opticalCenter.left}
                      side="L"
                    />
                    <DrawNearCircle
                      originPointX={startLeftLens}
                      originPointY={origin.y}
                      opticalCenterSide={opticalCenter.left}
                      side="L"
                    />

                    <DrawFarCircle
                      originPointX={startLeftLens}
                      originPointY={origin.y}
                      opticalCenterSide={opticalCenter.left}
                      side="L"
                    />

                  </Fragment>

                ) : null
              }

              <WriteDiameter
                originPointX={startLeftLens}
                originPointY={origin.y}
                opticalCenterSide={opticalCenter.left}
                orderValues={leftValues}
              />

            </Fragment>

          ) : null
        }

      </Layer>
    </Fragment>

  )

}