import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

//core components
import Button from "@material-ui/core/Button"

import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// CSS
import { useCardStyle } from "styles/autocadConverter/autocadConverterCss"

//Pegaso Components
import FromOmaToDXF from "views/AutocadConverter/FromOMAToDXF"
import FromDXFToOMA from "views/AutocadConverter/FromDXFToOMA"

export default function AutocadConverter() {
  const { t, publicUrl, getLanguage} = useContext(RootContext)
  const classes = useCardStyle()

  //Download guida
  const handleDownloadGuide = () => {
    var language = getLanguage()
    var fileName, linkFile
    
    switch (language) {
      case "it-it":
        fileName = `Guida convertitore Autocad-ITA.pdf`;
        linkFile = "ita/Guida convertitore Autocad-ITA.pdf"
        break;
    
      default:
        fileName = `Autocad converter guide-ENG.pdf`;
        linkFile = "eng/Autocad converter guide-ENG.pdf"
        break;
    }

    const link = document.createElement("a");
    link.download = fileName;
    link.href = publicUrl + '/resources/guide/' + linkFile;
    link.click();
  }

  return (
    <div>
      <Card className={classes.cardMarginBottom}>
        <CardHeader
          color="primary"
          className={classes.cardHeaderStyle}
        >
          <h4 className={classes.cardTitleWhite}>{t("10362").toUpperCase()}</h4>
          {t("10375")}
        </CardHeader>
        <CardBody>
          <FromOmaToDXF />
        </CardBody>
      </Card>

      <Card>
        <CardHeader
          color="primary"
          className={classes.cardHeaderStyle}
        >
          <h4 className={classes.cardTitleWhite}>{t("10367").toUpperCase()}</h4>
          {t("10373") + " (" + t("10374") + ")"}
        </CardHeader>
        <CardBody>
          <FromDXFToOMA />
        </CardBody>
      </Card>
      {t("10388").toUpperCase()}
      <Button
        //variant="outlined"
        color="secondary"
        onClick={handleDownloadGuide}
      >
        <PictureAsPdfIcon></PictureAsPdfIcon>
      </Button>

    </div>

  )
}
