import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "../../RootContext"

// @material-ui/core components
import Button from "@material-ui/core/Button"
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Favicon from "react-favicon"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"

// Pegaso components
import ErrorUtils from "components/Tools/ErrorUtils"
import { useLoginStyles } from "styles/loginCss"
import { CircularIndeterminate } from "views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import LoginFooter from "components/Footer/LoginFooter"
import LoginError from "components/Login/LoginError"
import LoginMaintenance from "components/Login/LoginMaintenance"

const EditField = ({ id, field, onChange, type = "text", value }) => (
  <FormControl margin="normal" required fullWidth>
    <InputLabel htmlFor={field}>{field}</InputLabel>
    <Input
      id={id}
      name={field}
      autoComplete={field}
      autoFocus
      onChange={onChange}
      type={type}
      value={value}
    />
  </FormControl>
)

export default (props) => {
  const classes = useLoginStyles()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { t } = useTranslation()
  const {
    access_token,
    apiUrlPegasoTools,
    apiAutoCadUser,
    apiAutoCadPassword,
    companyId,
    setCompanyId,
    companyName,
    setCompanyName,
    login,
    loginPegasoTools,
    apiUrl,
    getIsDemoToken,
    publicUrl,
    setAccess_token,
    setLensTypes,
    setCatalogueScope,
    setLabScopes,
    setUserDevice,
    setRequiredFields,
    getLabId,
  } = useContext(RootContext)
  const { match } = props
  const { params } = match
  const [checkManager, setCheckManager] = useState()
  const [fetching, setFetching] = useState(false)
  const getCompaniesParameter = "name,active,companyScopes,lensTypes,requiredFields,vatNumber,footer,license,address,email"
  const [loginError, setloginError] = useState()
  const [fetchingLogo, setFetchingLogo] = useState(true)
  const [fetchingCheckManager, setFetchingCheckManager] = useState(true)
  const [logoPng, setLogoPng] = useState()
  const [logoIco, setLogoIco] = useState()
  const [companyFooter, setCompanyFooter] = useState()
  const [fetchedLabScopes, setFetchedLabScopes] = useState(false)
  const [fetchedUserDevice, setFetchedUserDevice] = useState(false)
  const [fetchedPegasoTools, setFetchedPegasoTools] = useState(false)


  setCompanyId(params.companyId)

  /**********************************************
    *  FECTH
    **********************************************/


  const FetchCompanyLogo = useCallback(
    (format, setStateImage) => {
      fetch(`${apiUrl}/companies/${companyId}/image?format=${format}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          let result
          if (format === "ico") {
            result = blob.size === 0 ? ({ src: publicUrl + '/resources/images/connect.ico' }) : ({ src: URL.createObjectURL(blob) })
          }
          else {
            result = blob.size === 0 ? ({ src: publicUrl + '/resources/images/logoConnect.png' }) : ({ src: URL.createObjectURL(blob) })
          }
          setStateImage(result)
        })
        .catch((err) => {
          //setloginError({ message: "inv_logo" })
          ErrorUtils.errorLog("Login", err.message)
        })
    },
    [apiUrl, companyId, publicUrl]
  )

  const FetchCompanyFooter = useCallback(() => {
    fetch(`${apiUrl}/companies/${companyId}/footer`, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(result => setCompanyFooter(result))
      .catch((err) => {
        //setloginError({ message: "inv_logo" })
        ErrorUtils.errorLog("Login", err.message)
      })
  },
    [apiUrl, companyId,]
  )

  const FetchCheckManager = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/info/check-manager`, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
      .then(response => {
        response.status === 200 ? setCheckManager(true) : setCheckManager(false)
        setFetchingCheckManager(false)
      })
      .catch((err) => {
        setCheckManager(false)
        setFetchingCheckManager(false)
        setFetchingLogo(false)
      })
  },
    []
  )

  function FetchLabScopes(labId, access_token, refresh_token) {
    fetchWithToken(`${apiUrl}/companies/${companyId}/labs/${labId}/scopes`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        const labScopes = body.labScopes
        setLabScopes(labScopes)
        setFetchedLabScopes(true)
      })
      .catch(err => {
        setFetching(false)
        ErrorUtils.errorLog(t("33"), err.message)
      })

  }

  function FetchUserDevice(username, access_token, refresh_token) {
    fetchWithToken(`${apiUrl}/companies/${companyId}/users/${username}/device`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        setUserDevice(body.userDevice)
        setFetchedUserDevice(true)
      })
      .catch(err => {
        setFetching(false)
        ErrorUtils.errorLog(t("33"), err.message)
      })

  }

  const FetchLoginPegasoTools = useCallback(
    () => {
      const user = {
        username: apiAutoCadUser,
        password: apiAutoCadPassword
      }
      fetch(`${apiUrlPegasoTools}/Account/login`, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((res) =>
          res
            .json()
            .then((json) => {
              const { accessToken, refreshToken } = json
              if (accessToken !== undefined || refreshToken !== undefined) {
                loginPegasoTools(accessToken, refreshToken)
                setFetchedPegasoTools(true)
              } else throw Error(t("10150").toUpperCase())
            })
            .catch((err) => {
              setFetching(false)
              setloginError({ message: t("51") })
              ErrorUtils.errorLog("Pegaso Tools Login", err.message)
            })
        )
        .catch((err) => {
          setFetching(false)
          ErrorUtils.errorLog("loginPegasTools", err)
          const message = t("10149")
          setloginError({ message: "auth_server_error" })
          ErrorUtils.errorLog("Pegaso Tools Login", message)
        })
    }, [apiUrlPegasoTools, t, apiAutoCadPassword, apiAutoCadUser, loginPegasoTools])


  /**********************************************
    *  USE EFFECT
    **********************************************/


  // useEffect 1 - Se cambia company, reset logo
  useEffect(() => {
    if (companyId) {
      if (companyId !== 'null') {
        setLogoPng()
        setLogoIco()
        setCompanyFooter()
        setFetchingLogo(true)
      }
    }
  }, [companyId])

  // useEffect 2 - Pesca logo in base alla company
  useEffect(() => {
    if (companyId) {
      if (companyId !== 'null') {
        if (fetchingLogo) {
          if (!logoPng) {
            FetchCompanyLogo("png", setLogoPng)
          }
          if (!logoIco) {
            FetchCompanyLogo("ico", setLogoIco)
          }
          if (!companyFooter) {
            FetchCompanyFooter()
          }
          if (logoPng && logoIco && companyFooter) {
            setFetchingLogo(false)
          }
        }
      }
    }
  }, [
    companyId,
    logoPng,
    logoIco,
    FetchCompanyLogo,
    fetchingLogo,
    setLogoIco,
    setLogoPng,
    companyFooter,
    FetchCompanyFooter
  ])

  // useEffect 3 - Controllo che mini-manager è on-line
  useEffect(() => {
    if (fetchingCheckManager) {
      FetchCheckManager()
    }
  }, [FetchCheckManager, fetchingCheckManager])

  // useEffect 4 - Se ha eseguito tutte le fetch passsa alla pagina successiva
  useEffect(() => {
    if (access_token !== undefined) {
      if (fetchedLabScopes && fetchedUserDevice && fetchedPegasoTools) {
        const goto = `/admin/jobs` 
        props.history.push(goto)
      }
    }
  }, [fetchedLabScopes, getIsDemoToken, fetchedPegasoTools, fetchedUserDevice, access_token, props.history])


  /**********************************************
    *  HANDLER
    **********************************************/

  const handleChange = (e) => {
    if (e.target.id === "username") {
      setUsername(e.target.value)
    } else if (e.target.id === "password") {
      setPassword(e.target.value)
    }
  }

  const handleClickLogin = () => {
    const user = {
      username,
      password,
    }
    setFetching(true)
    fetch(`${apiUrl}/companies/${companyId}/login`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) =>
        res
          .json()
          .then((json) => {
            const { access_token, refresh_token } = json
            if (access_token !== undefined || refresh_token !== undefined) {
              fetchWithToken(`${apiUrl}/companies/${companyId}?get_Parameter=${getCompaniesParameter}`, {
                method: "GET",
                headers: {
                  Accept: +"application/json",
                  "Content-Type": "application/json",
                },
                apiUrl: apiUrl,
                companyId: companyId,
                access_token: access_token,
                refresh_token: refresh_token,
                setAccess_token: setAccess_token,
              })
                .then((response) => {
                  const body = response.body
                  const {
                    lensTypes,
                    name,
                    companyScopes,
                    requiredFields,
                  } = body

                  let catalogueScope = companyScopes.filter(
                    (scope) => scope.scopeId === 7 && scope.active
                  )

                  if (catalogueScope && catalogueScope.length !== 0) {
                    setCatalogueScope(catalogueScope[0])
                  }
                  setLensTypes(lensTypes)
                  setRequiredFields(requiredFields)
                  setCompanyName(name)
                  login(access_token, refresh_token)

                  //Decodifico token per avere labId
                  const labId = getLabId(access_token)

                  FetchLabScopes(labId, access_token, refresh_token)
                  FetchUserDevice(username, access_token, refresh_token)
                  FetchLoginPegasoTools()
                })
                .catch((err) => {
                  setFetching(false)
                  ErrorUtils.errorLog(t("33"), err.message)
                })
            } else throw Error(json.error)
          })
          .catch((err) => {
            setFetching(false)
            setloginError({ message: t("51") })
            ErrorUtils.errorLog("Login", err.message)
          })
      )
      .catch((err) => {
        setFetching(false)
        const message = "Authentication Server Error"
        setloginError({ message: "auth_server_error" })
        ErrorUtils.errorLog("Login", message)
      })
  }

  document.title = companyName


  return (
    companyId === 'null' ? (
      <LoginError />
    ) : (
      fetchingCheckManager || fetching || fetchingLogo ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "scale(4, 4)",
          }}
        >
          <CircularIndeterminate className={classes.progress} />
        </div>
      ) : (
        checkManager ? (
          <div
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                return handleClickLogin()
              }
            }}
          >
            <Favicon url={`${logoIco.src}`} />
            <main className={classes.main}>
              <CssBaseline />
              <Paper className={classes.paper}>
                <CardMedia component="img" image={logoPng.src} />
                <Typography align="right" color="error">
                  {loginError ? t(loginError.message) : ""}
                </Typography>
                <EditField
                  id="username"
                  field={t("10142")}
                  onChange={handleChange}
                />
                <EditField
                  id="password"
                  field={t("10097")}
                  onChange={handleChange}
                  type="password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleClickLogin}
                >
                  {t("10071")}
                </Button>
              </Paper>

              <LoginFooter
                companyFooter={companyFooter}
              />
            </main>
          </div>
        ) : (
          <LoginMaintenance />
        )
      )
    )
  )

}