import React from 'react'
import Button from '@material-ui/core/Button'

export default function TracerButton ({ handleClick, textButton }) {
  return (
    <Button variant='outlined' color='secondary' onClick={handleClick}>
      {textButton}
    </Button>
  )
}
