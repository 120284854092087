import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

import Button from "@material-ui/core/Button"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete"
import SaveIcon from '@material-ui/icons/Save';
import Grid from "@material-ui/core/Grid"
import AdjustIcon from '@material-ui/icons/Adjust';
import Tooltip from '@material-ui/core/Tooltip';

export default function ShapeBar({
  currentShape,
  device,
  disableDrillButton,
  frameManagementActive,
  handleCalculateDiameterClick,
  handleFrameClick,
  handleTracerClick,
  handleImportClick,
  handleExportClick,
  handleDeleteShapeClick,
  handleModifyShapeClick,
  handleOpenDialogDrill,
  handleLoadedClick,
  openDrillsManagement,
  openShapeModify,
  openTracer,
  showBar,
  showImport,
}) {
  const { t, getDrillManagementActive, getPrecalibrationActive, getDiameterMinimumCalculationActive } = useContext(RootContext)
  const drillManagementActive = getDrillManagementActive()
  const precalibrationActive = getPrecalibrationActive()
  const diameterMinimumCalculationActive = getDiameterMinimumCalculationActive();

  const xsGridValue = 3

  return (
    <div>
      {!openTracer ? (
        showImport && showBar ? (
          <div>
            <Grid container spacing={2}>

              <Grid align="center" item xs={xsGridValue}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleTracerClick}
                  disabled={!openShapeModify && !openDrillsManagement ? device ? (device.active ? false : true) : true : true}
                >
                  {t("10086")}
                </Button>
              </Grid>

              <Grid align="center" item xs={xsGridValue}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleImportClick}
                  disabled={!openShapeModify && !openDrillsManagement ? false : true}
                >
                  {t("10085")}
                </Button>
              </Grid>

              {
                frameManagementActive ? (
                  <Grid align="center" item xs={xsGridValue}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleFrameClick}
                      disabled={!openShapeModify && !openDrillsManagement ? false : true}
                    >
                      {t("10345")}
                    </Button>
                  </Grid>
                ) : (null)
              }

              {
                precalibrationActive ? (
                  <Grid align="center" item xs={xsGridValue}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleLoadedClick}
                      disabled={!openShapeModify && !openDrillsManagement ? false : true}
                    >
                      {t("10430")}
                    </Button>
                  </Grid>
                ) : (null)
              }

              {
                drillManagementActive ? (
                  <Grid align="center" item xs={xsGridValue}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenDialogDrill}
                      disabled={!openShapeModify ? disableDrillButton() || openDrillsManagement : true}
                      startIcon={<EditIcon />}
                    >
                      {t("10319")}
                    </Button>
                  </Grid>
                ) : null
              }

              <Grid align="center" item xs={xsGridValue}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleModifyShapeClick}
                  disabled={!openDrillsManagement ? !currentShape || openShapeModify : true}
                  startIcon={<EditIcon />}
                >
                  {t("10347")}
                </Button>
              </Grid>

              <Grid align="center" item xs={xsGridValue}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDeleteShapeClick}
                  disabled={!openShapeModify && !openDrillsManagement ? !currentShape : true}
                  startIcon={<DeleteIcon />}
                >
                  {t("10158")}
                </Button>
              </Grid>

              {
                currentShape ? (
                  <Grid align="center" item xs={xsGridValue}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleExportClick}
                      startIcon={<SaveIcon />}
                    >
                      {t("10084")}
                    </Button>
                  </Grid>
                ) : null
              }

              {
                diameterMinimumCalculationActive && currentShape ? (
                  <Grid align="center" item xs={xsGridValue}>
                    <Tooltip title={t("10453")}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCalculateDiameterClick}
                        startIcon={<AdjustIcon />}
                      >
                        {t("10452")}
                      </Button>
                    </Tooltip>
                  </Grid>
                ) : null
              }

            </Grid>

          </div>
        ) : currentShape ? (
          <div>
            <Grid container spacing={2}>
              <Grid align="right" item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleExportClick}
                  startIcon={<SaveIcon />}
                >
                  {t("10084")}
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : null
      ) : null}
    </div>
  )
}
