import React, { Fragment, useContext, useState, useEffect } from "react"
import { RootContext } from "RootContext";

// Core components
import Button from "@material-ui/core/Button"
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from "@material-ui/core/CircularProgress"
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { Stage, Layer, Line, Circle } from "react-konva"
import { CircularIndeterminate } from "../Loading"


//Pegaso Components
import { fetchWithToken } from "components/Fetch/api-fetch"
import JobUtils from "components/Utils/JobUtils"
import ShapeUtils from "components/Utils/ShapeUtils"

//Pegaso CSS
import { useShapeStyle } from "styles/shape/shapeCss"

function Row(props) {
  const { row, frameId, formattedFrameBrand, formattedFrameSupplier, isFullTable, handleClickOpen, handleClickDelete, handleFrameUpload, setOpenFrameList } = props;
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, getCalibration } = useContext(RootContext)

  const [calibration] = useState(getCalibration())
  const canvasHeight = 300
  const canvasWidth = 800
  const [currentShape, setCurrentShape] = useState("")
  const [currentEdgingData, setCurrentEdgingData] = useState()
  const [frameData, setFrameData] = useState()
  const [loading, setLoading] = useState(false);
  const offset = 0
  const originY = 150
  const [open, setOpen] = useState(false);
  const scaleX = 4
  const scaleY = 4
  const [shapeJson, setShapeJson] = useState()
  const [startRightLens, setStartRightLens] = useState(250)
  const [startLeftLens, setStartLeftLens] = useState(0)
  const [shapeExists, setShapeExists] = useState(false)
  const origin = { x: startRightLens, y: originY }


  const classes = useShapeStyle
  const shapeClass = classes.shape


  /*************************************************************************
                      FETCH
   **************************************************************************/

  // Fetch Open frame
  function fetchFrameGet(frameId) {
    const url = `${apiUrl}/companies/${companyId}/frame/${frameId}`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se la forma esiste
        setLoading(false)
        setFrameData(data)
      })
      .catch((err) => {
        // Se Frame non esiste
        setFrameData("")
        //ErrorUtils.errorLog("Fetch Job Error", err)
      })
  }

  //Click expand row
  function openFrame(frameId) {
    if (open === false) {
      setOpen(true)
      setLoading(true)
      fetchFrameGet(frameId)
    }
    else {
      setOpen(false)
      setFrameData("")
      setCurrentShape("")
      setCurrentEdgingData("")
      setShapeJson("")
    }
  }


  /**********************************************
    *  USE EFFECT
    **********************************************/


  useEffect(() => {
    if (frameData) {
      setCurrentShape(JobUtils.GetStandardShape({ shape: { standard: frameData.shape.standard } }))
      setCurrentEdgingData(frameData.shape.edgingData)
    }
  }, [frameData,])

  useEffect(() => {
    const shapeToDraw = ShapeUtils.extractShapeFromOMA(
      currentShape,
      currentEdgingData,
      scaleX,
      scaleY,
      offset,
      calibration
    )
    setShapeJson(shapeToDraw)
    var rightHbox = shapeToDraw.right.hbox ? shapeToDraw.right.hbox : 55
    var leftHbox = shapeToDraw.left.hbox ? shapeToDraw.left.hbox : 55
    setStartRightLens(
      canvasWidth / 2 - (shapeToDraw.dbl * 50 / 25 + rightHbox * 50 / 25)
    )
    setStartLeftLens(
      canvasWidth / 2 + (shapeToDraw.dbl * 50 / 25 + leftHbox * 50 / 25)
    )
    if (
      shapeToDraw.right.points.length > 1 ||
      shapeToDraw.left.points.length > 1
    ) {
      setShapeExists(true)
    } else {
      setShapeExists(false)
    }
  }, [
    calibration,
    currentShape,
    frameData,
    currentEdgingData,
    scaleX,
    scaleY,
    offset,
  ])


  function handleSelectShape() {
    handleFrameUpload(frameData.shape.original, frameData.shape.standard, frameData.shape.edgingData, false)
    setOpen(false)
    setOpenFrameList(false)
  }

  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={"jobTableRow-" + frameId}
      >
        {
          isFullTable ? (null) : (
            <TableCell key={"jobTableCell0-" + frameId}>
              <IconButton aria-label="expand row" size="small" onClick={() => openFrame(frameId)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )
        }

        <TableCell
          key={"jobTableCell1-" + frameId}
          align="left"
        >
          {frameId}
        </TableCell>

        <TableCell
          key={"jobTableCell2-" + frameId}
          align="left"
        >
          {formattedFrameSupplier}
        </TableCell>

        <TableCell
          key={"jobTableCell3-" + frameId}
          align="left"
        >
          {formattedFrameBrand}
        </TableCell>

        <TableCell
          key={"jobTableCell4-" + frameId}
          align="left"
        >
          {row.modelName}
        </TableCell>
        <TableCell
          key={"jobTableCell5-" + frameId}
          align="left"
        >
          {row.color}
        </TableCell>

        <TableCell
          key={"jobTableCell6-" + frameId}
          align="left"
        >
          {row.caliber}
        </TableCell>

        <TableCell
          key={"jobTableCell7-" + frameId}
          align="left"
        >
          {row.active ? t("10147") : t("10079")}
        </TableCell>
        {
          isFullTable ? (
            <TableCell
              key={"jobTableCell8-" + frameId}
              align="left"
            >
              <Tooltip title={t("")}>
                <IconButton aria-label={t("10154")} variant="outlined" onClick={() => { handleClickOpen(frameId) }}>
                  {<EditIcon />}
                </IconButton>
              </Tooltip >
            </TableCell>) : (null)
        }

        {
          isFullTable ? (
            <TableCell
              key={"jobTableCell9-" + frameId}
              align="left"
            >
              <Tooltip title={t("10341")}>
                <IconButton aria-label={t("10154")} variant="outlined" onClick={() => { handleClickDelete(frameId) }}>
                  {<DeleteIcon />}
                </IconButton>
              </Tooltip >
            </TableCell>) : (null)
        }

      </TableRow>


      {/*Espansione per visualizzare la forma*/}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={8}>
                  {loading ? (<CircularProgress size={42} style={{ color: "primary" }} />) : (
                    <Stage
                      width={canvasWidth}
                      height={canvasHeight}
                    >
                      {shapeExists ? (
                        <Layer>
                          {/* 1 - LENTE DESTRA (origine = centro lente) */}
                          <Line
                            closed
                            fill={shapeClass.fill}
                            points={shapeJson ? shapeJson.right.points : []}
                            stroke={shapeClass.stroke}
                            strokeWidth={shapeClass.strokeWidth}
                            tension={0}
                            x={origin.x}
                            y={origin.y}
                          />
                          {/* FORI (Lente Destra) */}
                          {shapeJson
                            ? shapeJson.drillingPoints.length > 0
                              ? shapeJson.drillingPoints.map((elem) => {
                                let keyDrill = "drill_point_right_" + elem.id
                                return (
                                  <Circle
                                    key={keyDrill}
                                    name={keyDrill}
                                    radius={(elem.diam * scaleX) / 2}
                                    stroke={shapeClass.stroke}
                                    strokeWidth={1}
                                    x={origin.x + elem.x * scaleX}
                                    y={origin.y - elem.y * scaleY}
                                  />
                                )
                              })
                              : null
                            : null}
                          {/* 2 - LENTE SINISTRA (origine = centro lente) */}
                          <Line
                            closed
                            fill={shapeClass.fill}
                            points={shapeJson ? shapeJson.left.points : []}
                            tension={0}
                            stroke={shapeClass.stroke}
                            strokeWidth={shapeClass.strokeWidth}
                            x={startLeftLens}
                            y={origin.y}
                          />
                          {/* FORI (Lente Sinistra) */}
                          {shapeJson
                            ? shapeJson.drillingPoints.length > 0
                              ? shapeJson.drillingPoints.map((elem) => {
                                let keyDrill =
                                  "drill_point_left_" + elem.id
                                return (
                                  <Circle
                                    key={keyDrill}
                                    name={keyDrill}
                                    radius={(elem.diam * scaleX) / 2}
                                    stroke={shapeClass.stroke}
                                    strokeWidth={1}
                                    //x={startLeftLens}
                                    // y={origin.y}
                                    x={startLeftLens - elem.x * scaleX}
                                    y={origin.y - elem.y * scaleY}
                                  />
                                )
                              })
                              : null
                            : null}
                        </Layer>
                      ) : null}
                    </Stage>
                  )}
                </Grid>
                <Grid item xs={4} align={"right"}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={!shapeExists}
                    onClick={handleSelectShape}
                  >
                    {t("10392")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}


export default function FrameListTableBody(props) {
  const {
    emptyRows,
    loading,
    rows,
    page,
    rowsPerPage,
    /* isSelected,
     filter,
     handleClick,
     handleOpenClone,
     order,
     orderBy,*/

  } = props
  const { t } = useContext(RootContext)
 
  return (
    <TableBody>
      {rows.length > 0 || (rows.length === 0 && !loading) ? (
        rows.length > 0 ? (
          rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const frameId = row.frameId
              const formattedFrameBrand = row.frameBrand.frameBrandId + " - " + row.frameBrand.frameBrandName
              const formattedFrameSupplier = row.frameBrand.frameSupplier.frameSupplierId + " - " + row.frameBrand.frameSupplier.frameSupplierName
              return (<Row {...props} key={frameId} row={row} frameId={frameId} formattedFrameBrand={formattedFrameBrand} formattedFrameSupplier={formattedFrameSupplier} />)
            })
        ) : (
          <TableRow>
            <TableCell colSpan={6} align="center">
              {t("10082")}
            </TableCell>
          </TableRow>
        )
      ) : (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <CircularIndeterminate />
          </TableCell>
        </TableRow>
      )}
      {emptyRows > 0 ? (
        <TableRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      ) : null}
    </TableBody>
  );
}
