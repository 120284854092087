import React, { useCallback, useState, useEffect } from "react"

// Core components
import { DebounceInput } from 'react-debounce-input'
import { titleCase } from "title-case";

export default function FilterTextField(props) {
  const {
    classes,
    defaultValue,
    disabled,
    filterField,
    handleChange1,
    handleChange2,
    handleChange3,
    handleClick1,
    id,
    label,
    rows,
    variant,
  } = props

  const [text, setText] = useState(defaultValue)

  useEffect(() => {
    setText(defaultValue)
  }, [defaultValue])

  // HandleChange callback
  const handleChangeCallback = useCallback(
    (value) => {
      setText(value)
      if (handleChange1) {
        handleChange1(filterField, value)
      }
      if (handleChange2) {
        handleChange2(value)
      }
      if (handleChange3) {
        handleChange3(id, value)
      }
      if (handleClick1) {
        handleClick1()
      }
    },
    [filterField, handleChange1, handleChange2, handleChange3, handleClick1, id]
  )

  return (
    <div>
    <label>
        {titleCase(label)}
        <DebounceInput
          className={classes.textField}
          debounceTimeout={500}
          disabled={disabled}
          id={id}
          margin="normal"
          onChange={e => handleChangeCallback(e.target.value)}
          rows={rows || "1"}
          variant={variant}
          value={text}
          />
      </label>
    </div>
  )
}
