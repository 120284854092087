import { makeStyles } from "@material-ui/styles"
import theme from "../theme"
import { defaultFont } from "assets/jss/material-dashboard-react.js"

const useJobStyles = makeStyles({
  jobGridToolbar: {
    bgcolor: "background.paper",
    padding:'0px'
  },
  jobGridHeadValues: {
    //borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  jobGridDeviceValues: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  jobGridStandardShape: {
    width: "100%",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  jobGridCurrentRightValues: {
    width: "25%",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(0, 0, 0, 100)",
  },
  jobGridCurrentLeftValues: {
    width: "25%",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  jobGridCurrentEdgingValues: {
    width: "16.6%",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  jobGridCurrentNotesValues: {
    width: "30%",
    //borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  cardTitleWhite: {
    ...defaultFont, 
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  zoom: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  appbar: {
    display: "flex",
    marginLeft: "auto", 
    background: "transparent", 
    boxShadow: "none",
    width: "50px",
    height: '0px',
  },
  appbarShowJob: {
    display: "flex",
    marginLeft: "auto", 
    background: "transparent", 
    boxShadow: "none",
    width: "50px",
    height: '50px',
  },
  card:{
    marginTop: '0px',
  },
  accordion:{
    width:"100%"
  },
  typographyStyle:{
    fontWeight: "bold",
    textDecoration:"underline",
    flexGrow: 1,
    textAlign: "center"
  }
})

/*---------  TEXT FIELDS  ---------*/
const useJobStylesText = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: "0.875rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}))

/*---------  TEXT NOTES FIELDS  ---------*/
const useJobStylesNotes = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    display: "grid",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textFieldError: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: "red"
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
    display: "grid",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}))


/*---------  PRODUCT TAABLE SELECT LENGTH ---------*/

const useStylesProductTable = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: "30%",
  },
}))

export { useJobStyles, useJobStylesText, useJobStylesNotes, useStylesProductTable }
