import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../../RootContext"

// core components
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import Box from "@material-ui/core/Box"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import IconButton from "@material-ui/core/IconButton"
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography"
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { titleCase } from "title-case"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useJobsTableStyles } from "styles/jobs/jobsTableCss"

//Pegaso component
import { fetchWithToken } from "../../../components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import FrameListToolbar from "views/FramesManagement/FrameListToolbar"
import FrameListTableHead from "views/FramesManagement/FrameListTableHead"
import FrameListTableBody from "views/FramesManagement/FrameListTableBody"

export default function JobFrame(props) {
  const { companyId, apiUrl, access_token, refresh_token, setAccess_token, t } = useContext(RootContext)
  const { handleFrameUpload, setOpenFrameList, onHandleClose } = props
  const classes = useJobsStyles()
  const classesTable = useJobsTableStyles()
  const [count, setCount] = useState(1)
  const [endLoadingFrames, setEndLoadingFrames] = useState(false)
  const [endLoadingFrameBrands, setEndLoadingFrameBrands] = useState(false)
  const [endLoadingFrameSuppliers, setEndLoadingFrameSuppliers] = useState(false)
  const [frameSupplierId, setFrameSupplierId] = useState(1)
  const [frameBrandId, setFrameBrandId] = useState(1)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [emptyRows, setEmptyRows] = useState(0)
  const [frames, setFrames] = useState([])
  const [frameBrands, setFrameBrands] = useState([])
  const [frameSuppliers, setFrameSuppliers] = useState([])
  const [isFetchingFrames, setIsFetchingFrames] = useState(true)
  const [isFetchingFrameBrands, setIsFetchingFrameBrands] = useState(false)
  const [isFetchingFrameSuppliers, setIsFetchingFrameSuppliers] = useState(false)


  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }


  /**********************************************
   *  FETCH
   **********************************************/

  ///Fetch dei frame
  const fetchFrame = useCallback((frameSupplierId, frameBrandId) => {
    if (isFetchingFrames === true) {
      setIsFetchingFrames(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames/frameSupplier/${frameSupplierId}/frameBrand/${frameBrandId}`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frames } = body
          setEmptyRows(rowsPerPage - Math.min(rowsPerPage, frames.length - page * rowsPerPage))
          setFrames(frames)
          setEndLoadingFrames(true)
          setIsFetchingFrameSuppliers(true)
          setIsFetchingFrameBrands(true)
        })
        .catch(err => {
          ErrorUtils.errorLog("fetchFrame", err)
          setEndLoadingFrames(true)
          ErrorUtils.errorLog("Frame fetch error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, page, rowsPerPage, refresh_token, setAccess_token, isFetchingFrames])


  //Fetch dei frame suppliers
  const fetchFrameSuppliers = useCallback(() => {
    if (isFetchingFrameSuppliers === true) {
      setIsFetchingFrameSuppliers(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameSupplier/active`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameSuppliers } = body
          var newSupplier = frameSuppliers.map(option => {
            return {
              value: option.frameSupplierId,
              text: option.frameSupplierId + " - " + option.frameSupplierName
            }
          })
          setFrameSuppliers(newSupplier)
          setEndLoadingFrameSuppliers(true)
        })
        .catch(err => {
          setEndLoadingFrameSuppliers(true)
          ErrorUtils.errorLog("Frame supplier fetch Error.", err.message)
        })

    }
  }, [access_token, apiUrl, companyId, refresh_token, setAccess_token, setIsFetchingFrameSuppliers, isFetchingFrameSuppliers])

  //Fetch dei frame brand
  const fetchFrameBrands = useCallback(() => {
    if (isFetchingFrameBrands === true) {
      setIsFetchingFrameBrands(false)
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameBrand/active`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameBrands } = body
          var newBrands = frameBrands.map((option) => {
            return {
              value: option.frameBrandId,
              text: option.frameBrandId + " - " + option.frameBrandName
            }
          })
          setFrameBrands(newBrands)
          setEndLoadingFrameBrands(true)
        })
        .catch(err => {
          setEndLoadingFrameBrands(true)
          ErrorUtils.errorLog("Frame Brands fetch Error.", err.message)
        })

    }
  }, [access_token, apiUrl, companyId, refresh_token, setAccess_token, isFetchingFrameBrands])


  /**********************************************
   *  USE EFFECT
   **********************************************/
  useEffect(() => {
    if ((isFetchingFrameSuppliers && !endLoadingFrameSuppliers) || (isFetchingFrameBrands && !endLoadingFrameBrands)) {
      fetchFrameSuppliers()
      fetchFrameBrands()
    }
    if (isFetchingFrames) {
      fetchFrame(frameSupplierId, frameBrandId)
    }
  },
    [
      endLoadingFrameSuppliers,
      endLoadingFrameBrands,
      isFetchingFrames,
      isFetchingFrameSuppliers,
      isFetchingFrameBrands,
      frameSupplierId,
      frameBrandId,
      fetchFrameSuppliers,
      fetchFrameBrands,
      fetchFrame
    ])

  /**********************************************
     *  HANDLER
     **********************************************/

  // Click change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Click rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  //Handler cambio filtri
  function handleChangeFilter(filterField, targetValue) {
    setIsFetchingFrames(true)
    setFrames([])
    setCount(0)
    if (filterField === "frameSupplier") {
      setFrameSupplierId(targetValue)
      setEndLoadingFrames(false)
      fetchFrame(targetValue, frameBrandId)
    }
    else if (filterField === "frameBrand") {
      setFrameBrandId(targetValue)
      setEndLoadingFrames(false)
      fetchFrame(frameSupplierId, targetValue)
    }

  }
  return (
    !endLoadingFrames ? (
      <CircularProgress size={42} style={{ color: "primary" }} />
    ) : (
      <div>
        {/* X CHIUSURA IN ALTO */}
        <ElevationScroll {...props}>
          <Box display="flex" flexDirection="row-reverse" p={1}>
            <Box p={1}>
              <IconButton
                aria-label={t("10238")}
                variant="outlined"
                onClick={onHandleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </ElevationScroll>

        {/*Pannello filtri*/}
        {<Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{titleCase(t("10087"))}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Card>
              {
                endLoadingFrameBrands && endLoadingFrameSuppliers ? (
                  <CardHeader color="primary">
                    <FrameListToolbar
                      frameBrandId={frameBrandId}
                      frameSupplierId={frameSupplierId}
                      handleChangeFilter={(filterField, targetValue) => {
                        handleChangeFilter(filterField, targetValue)
                      }}
                      selectOptionFrameSuppliers={frameSuppliers}
                      selectOptionFrameBrands={frameBrands}
                    />
                  </CardHeader>
                ) : (<CircularProgress size={42} style={{ color: "primary", marginLeft: '50%' }} />)
              }

            </Card>
          </AccordionDetails>
        </Accordion>}
        <Card>
          <CardBody>
            <Toolbar id="back-to-top-anchor" />
            <Table className={classes.table} aria-label="simple table">
              <FrameListTableHead isFullTable={false} />
              <FrameListTableBody
                isFullTable={false}
                rows={frames}
                classes={classesTable}
                emptyRows={emptyRows}
                page={page}
                rowsPerPage={rowsPerPage}
                setOpenFrameList={setOpenFrameList}
                handleFrameUpload={handleFrameUpload}
              />
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </div >
    )
  )
}