import { makeStyles } from '@material-ui/core/styles';

const useMaterialTableStyles = makeStyles({
    customPagination: {
        display: 'flex',
        justifyContent: 'right',
        width: "100%",
        alignItems: 'right',
        padding: '0px',
    }
});

export { useMaterialTableStyles }