import React, {
  useContext,
  useState,
} from "react"
import { RootContext } from "RootContext"

// pegaso views/components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import JobUtils from "components/Utils//JobUtils"
import ScrollUtils from "components/Tools/ScrollUtils.js"

// Core components
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

/*---------  import CSS ---------*/
import { useJobStyles } from "styles/job/jobCss"

// Griglia per scheda del ManualJob
function JobManualJobGridContainer(props) {
  const {
    t,
    getLab,
  } = useContext(RootContext)

  const classes = useJobStyles()
  const { maxInternalJobId } = getLab()
  const maxLabel = `(${t("10325")}: ${maxInternalJobId})`
  const { jobId, setJobId, setNewJobId, setOpenJob, onHandleClose } = props
  const [validJobId, setValidJobId] = useState(false)

  // Handle Change
  const handleManualJobChange = (event) => {
    const value = event.target.value
    setValidJobId(false)
    setJobId(value)
    if (value !== "") {
      if (JobUtils.isNumeric(value)) {
        if (parseInt(value) > 0) {
          if (parseInt(value) < parseInt(maxInternalJobId)) {
            setValidJobId(true)
          }
        }
      }
    }
  }

  // Handle Save Button
  const handleSave = () => {
    setNewJobId(jobId)
    setOpenJob(true)
    onHandleClose()
  }

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("10324")}</h4>
        </CardHeader>
        <CardBody>
          <div>
            <ScrollUtils.ElevationScroll {...props}>
              <ScrollUtils.AppBarScroll {...props} t={t} />
            </ScrollUtils.ElevationScroll>
            <Grid
              container
              spacing={2}
              alignContent="flex-end"
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Grid item xs={6}>
                {t("10323") + " " + maxLabel + ":"}
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label={t("10050")}
                  onChange={handleManualJobChange}
                  value={jobId} />
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={jobId === "" || !validJobId}
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                >
                  {t("10211") + " >>"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </CardBody>
      </Card>
    </div>)
}

// Griglia interna alla finestra del ManualJob
function JobManualJob(props) {
  return <JobManualJobGridContainer {...props} />
}

export default JobManualJob
