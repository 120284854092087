import React, { useEffect, useState, useContext, Fragment } from "react"
import { RootContext } from "../../RootContext"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import ErrorUtils from "components/Tools/ErrorUtils"
import { fetchWithToken } from "../../components/Fetch/api-fetch"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { FormControlLabel, Checkbox, CircularProgress } from "@material-ui/core"

export default function RequiredFields() {
    const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, requiredFields, setRequiredFields } = useContext(RootContext)
    const [refresh, setRefresh] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [checked, setChecked] = useState(requiredFields);
    const [fields, setFields] = useState([]);
    const [openDialog, setOpenDialog] = useState(false)
    const [saving, setSaving] = useState(false)


    const classes = useJobsStyles()

    useEffect(() => {
        fetchWithToken(`${apiUrl}/companies/fields`, {
            method: "GET",
            headers: {
                Accept: +"application/json",
                "Content-Type": "application/json"
            },
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token
        })
            .then(response => {
                const body = response.body
                const { fields } = body
                setFields(fields)
            })
            .catch(err => {
                ErrorUtils.errorLog("RequiredField Error.", err.message)
            })
    }, [access_token, apiUrl, companyId, refresh_token, setAccess_token, refresh])

    function handleCloseDialog() {
        setOpenDialog(false)
        setEditMode(false)
        setRefresh(true)
        setRequiredFields(checked)
    }

    function handlePatch() {
        setSaving(true)
        setOpenDialog(true)
        fetchWithToken(`${apiUrl}/companies/${companyId}/requiredfields`, {
            method: "PATCH",
            headers: {
                Accept: +"application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ requiredFields: checked }),
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token
        })
            .then(response => {
                setSaving(false)

            })
            .catch(err => {
                setSaving(false)
                ErrorUtils.errorLog(t("33"), err.message)
            })
    }

    function getWordCode(value) {
        switch (value) {
            case "add":
                return 10000 //addizione
            case "ax":
                return 10004 //asse
            case "bevm":
                return 10006 //valore di posizione
            case "bevp":
                return 10007 //posizione bisello
            case "cyl":
                return 10017 //cilindro
            case "dbl":
                return 10025 //ponte
            case "dia":
                return 10028 //diametro
            case "etyp":
                return 10035 //tipo montaggio
            case "ftyp":
                return 10040 //finitura bordo
            case "hbox":
                return 10043 //dima orizzontale
            case "ipd":
                return 10049 //distanza interpupillare
            case "ltyp":
                return 10070 //tipo lente
            case "ocht":
            case "seght":
                return 10088 //altezza montaggio
            case "polish":
                return 10102 //lucidatura
            case "sph":
                return 10128 //sfero
            case "vbox":
                return 10144 //dima verticale
            case "lmattype":
                return 10300 //materiale lente
            case "_lcoat":
                return 10301 //scivolosità
            case "thkp":
                return 10303 //spessore bordo
            case "cthick":
                return 10304 //spessore centro
            case "gwidth":
                return 10305 //larghezza canalino
            case "gdepth":
                return 10306 //profondità canalino
            case "fpinb":
                return 10307 //smusso alto
            case "pinb":
                return 10308 //smusso alto
            case "fcrv":
                return 10400 //curva montatura
            case "frnt":
                return 10401 //curva montatura
            case "ztilt":
                return 10402 //curva montatura
            case "lind":
                return 10403 //indice di rifrazione
            case "panto":
                return 10418 //angolo pantoscopico
            case "bvd":
                return 10419 //distanza apice corneale
            default:
                return value
        }
    }

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            //Altezza montaggio deve aggiungere anche campo OMA per altezza dal BORDO
            if (value === "ocht") {
                newChecked.push("_seght");
            }
        } else {
            //Altezza montaggio deve rimuovere anche campo OMA per altezza dal BORDO
            if (value === "ocht") {
                const fromEdgeIndex = checked.indexOf("_seght");
                if (fromEdgeIndex !== -1) {
                    newChecked.splice(fromEdgeIndex, 1);
                }
            }
            else {
                newChecked.splice(currentIndex, 1);

            }
        }
        setChecked(newChecked);
    }

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("10226").toUpperCase()}</h4>
            </CardHeader>

            <CardBody>
                {!editMode ? <Box display="flex" justifyContent="flex-end" marginBottom={3}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setEditMode(true)}>
                        {t("10214")}
                    </Button>
                </Box>
                    : <Box display="flex" justifyContent="flex-end">
                        <Box p={1}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setRefresh(true)
                                    setEditMode(false)
                                    setChecked(requiredFields)

                                }}>
                                {t("10010")}
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    handlePatch()
                                }}>
                                {t("10114")}
                            </Button>
                        </Box>
                    </Box>}


                <Box display="flex" justifyContent="flex-end" marginLeft={3}>
                    <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" >
                        {fields ? fields.map(value => {
                            const labelId = `checkbox-list-label-${value}`;
                            return (
                                <Grid item xs={4} key={value}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!editMode}
                                                edge="start"
                                                checked={checked.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={handleToggle(value)}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        }
                                        label={t(getWordCode(value))}
                                    />

                                </Grid>
                            );
                        }) : null}
                    </Grid>
                </Box>

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                    {saving ? <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CircularProgress size={42} style={{ color: "primary" }} />
                        </DialogContentText>
                    </DialogContent> :
                        <Fragment>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t("10220")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>Ok</Button>
                            </DialogActions>
                        </Fragment>
                    }
                </Dialog>
            </CardBody>
        </Card>
    )
}
