import React, { useContext } from "react"
import { RootContext } from "../../RootContext"
import classNames from "classnames"

// @material-ui/core components
import Button from "components/CustomButtons/Button.js"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Clock from "react-live-clock"
import Divider from "@material-ui/core/Divider"
import Grow from "@material-ui/core/Grow"
import Hidden from "@material-ui/core/Hidden"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Poppers from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import Person from "@material-ui/icons/Person"

// pegaso components
import DatesUtils from "components/Tools/DatesUtils"

// css styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js"

const useStyles = makeStyles(styles)

export default function AdminNavbarLinks(props) {
  const classes = useStyles()
  const { t, getUsername, getUtc } = useContext(RootContext)
  const { logout, companyId, getLab, getOptician } = useContext(RootContext)
  const [openProfile, setOpenProfile] = React.useState(null)
  const { internalOpticianId, internalOpticianName } = getLab()
  const hostName = window.location.hostname

  let opticianId = ""
  let opticianName = ""
  const utc = getUtc()
  let utcstring = DatesUtils.GetTZ(utc)

  const optician = getOptician()
  if (optician) {
    opticianId = optician.opticianId
    opticianName = optician.name
  } else {
    opticianId = internalOpticianId
    opticianName = internalOpticianName
  }

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }

  const handleCloseProfile = () => {
    setOpenProfile(null)
  }
  // const handleSettings = () => {
  //   props.history.push(`/admin/user`)
  // };
  const handleCloseProfileLogOut = () => {
    logout()
    props.history.push(`/${companyId}`)
  }

  return (
    <div>
      {// 2020-03-12 If you are working in LOCALHOST or PRE-PRODUCTION you'll see a red message
        hostName.indexOf("localhost") >= 0 ? (
          <strong style={{ color: "red" }}>
            ATTENZIONE: Attualmente stai lavorando nell'ambiente di sviluppo
            LOCALHOST&nbsp;&nbsp;&nbsp;&nbsp;
          </strong>
        ) : hostName.indexOf("pre-mini") >= 0 ? (
          <strong style={{ color: "red" }}>
            ATTENZIONE: Attualmente stai lavorando nell'ambiente di sviluppo
            PRE-PRODUCTION&nbsp;&nbsp;&nbsp;&nbsp;
          </strong>
        ) : null}
      {opticianId + " - " + opticianName + " (" + getUsername() + ") --- "}
      <Clock
        format={"DD/MM/YYYY HH:mm:ss"}
        ticking={true}
        timezone={utcstring}
      />{" "}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}>
          <Person className={classes.icons} />

          <Hidden mdUp implementation="css">
            <p className={classes.linkText}> {t("10105")}</p>
          </Hidden>
        </Button>

        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <MenuItem
                      onClick={handleSettings}
                      className={classes.dropdownItem}
                    >
                      {t('settings')}
                    </MenuItem> */}
                    <Divider light />
                    <MenuItem
                      onClick={handleCloseProfileLogOut}
                      className={classes.dropdownItem}>
                      {t("10072").toUpperCase()}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}
