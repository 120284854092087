//Pannello per la scelta del device
import React, { useContext, useEffect } from "react"
import { RootContext } from "../../RootContext"

//core components
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from '@material-ui/core/TextField';

//import CSS
import { useDeviceStylesText } from "styles/device/deviceCss.js"

export default function SerialDevice(props) {
  const {
    t,
  } = useContext(RootContext)

  const {
    changeDevice,
    serialOption,
    setChangeDevice,
    setSerialOption,
    setOpenPanelSerial,
  } = props
  //Classes utilizzate per CSS
  const classesText = useDeviceStylesText()

  /**
   * 
   * USE EFFECT
   * 
  */
  useEffect(() => {
    if (changeDevice) {
      setOpenPanelSerial(false)
      setChangeDevice(false)
    }
  }, [changeDevice,
    setChangeDevice,
    setOpenPanelSerial,
    setSerialOption])





  //Cambia field
  const handleChange = (name) => (event) => {
    let value = event.target.value.trim()
    value = (value.length === 0 ? 0 : parseInt(value))
    setSerialOption({ ...serialOption, [name]: value })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setSerialOption({ ...serialOption, [name]: event.target.checked })
  }


  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            id={"timeout"}
            label={t("10286")}
            className={classesText.textField}
            value={serialOption.timeout}
            margin="normal"
            onChange={handleChange("timeout")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"handshake"}
            label={t("10287")}
            className={classesText.textField}
            value={serialOption.handshake}
            margin="normal"
            onChange={handleChange("handshake")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"readBufferSize"}
            label={t("10288")}
            className={classesText.textField}
            value={serialOption.readBufferSize}
            margin="normal"
            onChange={handleChange("readBufferSize")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"writeBufferSize"}
            label={t("10289")}
            className={classesText.textField}
            value={serialOption.writeBufferSize}
            margin="normal"
            onChange={handleChange("writeBufferSize")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"readTimeout"}
            label={t("10290")}
            className={classesText.textField}
            value={serialOption.readTimeout}
            margin="normal"
            onChange={handleChange("readTimeout")}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id={"writeTimeout"}
            label={t("10291")}
            className={classesText.textField}
            value={serialOption.writeTimeout}
            margin="normal"
            onChange={handleChange("writeTimeout")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={serialOption.rts}
                onChange={handleChangeChecked("rts")}
                name="rts"
              />
            }
            label={t("10292")}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={serialOption.dtr}
                onChange={handleChangeChecked("dtr")}
                name="dtr"
              />
            }
            label={t("10293")}
          />
        </Grid>


        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={serialOption.discardNull}
                onChange={handleChangeChecked("discardNull")}
                name="discardNull"
              />
            }
            label={t("10294")}
          />
        </Grid>
      </Grid>


    </div>


  )
}