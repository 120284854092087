//Pannello per la scelta del device
import React, { useCallback, useContext, useEffect, useState } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

//pegaso/views/components
import ErrorUtils from "components/Tools/ErrorUtils"
import FilterDevice from "components/Filter/FilterDevice/"
import SettingsSaveButton from "components/Buttons/SettingsSaveButton"
import EmptyTracerButton from "components/Buttons/EmptyTracerButton"
import SettingsTableOkSaved from "views/Settings/SettingsTableOkSaved"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"
import { fetchWithToken, refreshToken } from "components/Fetch/api-fetch"

//import CSS
import { useDeviceEditScreenStyles } from "styles/device/deviceCss.js"


export default function DeviceSettings(props) {
    const {
        t,
        companyId,
        getUsername,
        apiUrl,
        access_token,
        refresh_token,
        setAccess_token,
        publicUrl,
    } = useContext(RootContext)

    //Classes utilizzate per CSS
    const classes = useDeviceEditScreenStyles()


    //Variabili
    const [dialogMsg, setDialogMsg] = useState("")
    const [allBrandsDevices, setAllBrandsDevices] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [openPanelSettings, setOpenPanelSettings] = useState(false)

    const [username] = useState(getUsername())
    const [usrDvc, setUsrDvc] = useState(undefined)

    const [selectedDeviceJSON, setSelectedDeviceJSON] = useState(undefined)

    /*************************************
     *
     * Fetch
     *
     *************************************/

    //Fetch -  Elenco devices
    const fetchDevices = useCallback(() => {
        const url = `${apiUrl}/brands`
        fetchWithToken(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            apiUrl: apiUrl,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then((response) => {
                return response.body
            })
            .then(({ brands }) => {
                brands = brands.filter((elem) => {
                    const { models } = elem
                    let newElem = elem
                    if (models.length > 0) {
                        newElem.models = models.filter((model) => {
                            return model.type === "Tracer" || model.type === "Blocker" ? model : null
                        })
                    }
                    return newElem
                })
                setAllBrandsDevices(brands)
            })
            .catch((err) => {
                ErrorUtils.errorLog("FetchDevices  Error", err)
            })
    }, [apiUrl, refresh_token, access_token, setAccess_token])

    // Fetch - Get User
    const fetchUser = useCallback(() => {
        const url = `${apiUrl}/companies/${companyId}/users/${username}`
        fetchWithToken(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            apiUrl: apiUrl,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then((response) => {
                return response.body
            })
            .then(({ userDevice }) => {
                if (userDevice) {
                    setUsrDvc(userDevice)
                    setSelectedDeviceJSON(userDevice)
                }
                fetchDevices()
            })
            .catch((err) => {
                ErrorUtils.errorLog("FetchDevices  Error", err)
            })
    }, [apiUrl, companyId, fetchDevices, refresh_token, access_token, setAccess_token, username])

    /*************************************
     *
     * Handle
     *
     *************************************/

    //Salvataggio dei dati
    const handleSave = (event) => {
        var device = selectedDeviceJSON
            ? (selectedDeviceJSON.model.modelId === undefined ? UserUtils.getBrandModel(selectedDeviceJSON, allBrandsDevices) : selectedDeviceJSON)
            : undefined
        device.deviceId = 1
        var deviceToBody = device
            ? UserUtils.createJsonDevices(device, false)
            : undefined
        setIsSaving(true)
        const url = `${apiUrl}/companies/${companyId}/users/${username}/device`
        fetchWithToken(url, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userDevice: deviceToBody,
            }),
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then((response) => {
                return response.body
            })
            .then((data) => {
                let msgDetails = StringUtils.generateNewLines(
                    `${t("10296")}\n\nUsername: ${username}\n`
                )
                refreshToken()
                setDialogMsg(msgDetails)
                setOpenDialog(true)
                setIsSaving(false)
            })
            .catch((err) => {
                ErrorUtils.errorLog(t("28"), err)
                setDialogMsg(t("28"))
                setOpenDialog(true)
                setIsSaving(false)
            })
    }

    //Salvataggio dei dati
    const handleSaveRemove = (event) => {
        setIsSaving(true)
        const url = `${apiUrl}/companies/${companyId}/users/${username}/device`
        fetchWithToken(url, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then((response) => {
                return response.body
            })
            .then((data) => {
                let msgDetails = StringUtils.generateNewLines(
                    `${t("10296")}\n\nUsername: ${username}\n`
                )
                refreshToken()
                setDialogMsg(msgDetails)
                setOpenDialog(true)
                setIsSaving(false)
            })
            .catch((err) => {
                ErrorUtils.errorLog(t("28"), err)
                setDialogMsg(t("28"))
                setOpenDialog(true)
                setIsSaving(false)
            })
    }

    //Close
    const handleClose = () => {
        setOpenDialog(false)
    }

    // Campi obbligatori per abilitare il tasto SAVE
    function disableSave() {
        let result =
            !username ||
            !selectedDeviceJSON
        return result
    }

    function disableSaveEmptyTracer() {
        let result =  username !== undefined && selectedDeviceJSON == null
        return result
    }



    //Chiude o apre il pannello contenente la tarratura
    const changeOpenPanelSettings = (event,) => {
        var value = !openPanelSettings
        setOpenPanelSettings(value)
    }

    /*************************************
     *
     * UseEffect
     *
     *************************************/
    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    return (
        <div>
            <Accordion expanded={openPanelSettings} onChange={changeOpenPanelSettings} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10313").toUpperCase()}</u></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {allBrandsDevices.length > 0 ?

                        <Card>
                            <CardBody>
                                <Typography align="center" style={{ width: "100%", alignItems: "center" }}>{t("10405")}</Typography>

                                <a href={publicUrl + '/resources/PegasoDriver.msi'} rel="noopener noreferrer" target="_blank" download='PegasoDriver.msi' >
                                    DOWNLOAD DRIVER: <img src="/resources/images/setup.png" alt="download Pegaso Driver" width="16px" height="16px" />
                                </a>

                                {/*GRID CONTENENTE IL DEVICE DELL'UTENTE*/}
                                <FilterDevice
                                    userDevice={usrDvc}
                                    creation={false}
                                    isHub={false}
                                    selectedDeviceJSON={selectedDeviceJSON}
                                    setSelectedDeviceJSON={setSelectedDeviceJSON}
                                    allBrandsDevices={allBrandsDevices}
                                    hideAdvanced={true}
                                />

                                {/*BOTTONI PER SALVATAGGIO*/}
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="flex-end"
                                    p={1}
                                    className={classes.gridDeviceToolbar}
                                >

                                    <EmptyTracerButton
                                        {...props}
                                        handleSave={handleSaveRemove}
                                        disabled={disableSaveEmptyTracer()}
                                    />

                                    <SettingsSaveButton
                                        {...props}
                                        handleSave={handleSave}
                                        disabled={disableSave()}
                                    />
                                </Box>
                                <SettingsTableOkSaved
                                    {...props}
                                    dialogMsg={dialogMsg}
                                    handleClose={handleClose}
                                    isSaving={isSaving}
                                    openDialog={openDialog}
                                />
                            </CardBody>
                        </Card>
                        :
                        <CircularProgress className={classes.progress} />
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}