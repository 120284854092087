import React from "react"
// Material ui component
import { Circle } from "react-konva"
// Css

//Pegaso component
import Slot from "components/Slot/Slot"



export default function Drills(props) {
  const { isEditShape, isDraggable, openDialog, origin, onDragEnd, scaleX, scaleY, side, shapeClass, shapeJson, startLens, stroke } = props




  return (
    shapeJson
      ? shapeJson.drillingPoints.length > 0
        ? shapeJson.drillingPoints.map((elem) => {
          let keyDrill =
            "drill_point_" + side + "_" + elem.id

          if (elem.isSlot) {
            return (
              <Slot
                colorSlot={stroke}
                isEditShape={isEditShape}
                isDraggable={isDraggable}
                slot={elem}
                key={keyDrill}
                origin={origin}
                openDialog={openDialog}
                shapeClass={shapeClass}
                side={side}
                startLens={startLens}
                scaleX={scaleX}
                scaleY={scaleY}
              />
            )
          }
          else {
            let xValue = side === "right" ? (startLens + elem.xStart * scaleX) : (startLens - elem.xStart * scaleX)
            return (
              <Circle
                draggable={isDraggable}
                key={keyDrill}
                onDragEnd={onDragEnd}
                name={keyDrill}
                radius={(elem.diam * scaleX) / 2}
                stroke={stroke}
                strokeWidth={1}
                x={xValue}
                y={origin.y - elem.yStart * scaleY}
              />
            )
          }
        })
        : null
      : null

  )
}