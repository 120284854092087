import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

// core components
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
// pegaso views/components
import FilterSelect from "components/Filter/FilterSelect"

// CSS
import {
  useJobsToolbarStyles,
  useJobsToolbarSelectStyles,
} from "styles/jobs/jobsToolbarCss"


// Toolbar -> FILTERS
function ToolbarFilters(props) {
  const {
    frameBrandId,
    frameSupplierId,
    selectOptionFrameBrands,
    selectOptionFrameSuppliers,
    handleCancelFetch,
    handleChangeFilter,
    /*lasses,
    filter,
    handleClickResetFilters,
    singleJob,*/
  } = props
  const { t,  } = useContext(RootContext)
  //const filterClass = useJobsToolbarSelectStyles()

  return (
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={6}>
          <FilterSelect
            classes={useJobsToolbarSelectStyles()}
            id="searchFrameSupplierId"
            label={t("10328")}
            defaultValue={frameSupplierId}
            disabled={false}
            handleChange1={handleChangeFilter}
            handleClick1={handleCancelFetch}
            options={selectOptionFrameSuppliers}
            filterField={"frameSupplier"}
          />
        </Grid>

        <Grid item xs={6}>
          <FilterSelect
            classes={useJobsToolbarSelectStyles()}
            id="searchFrameBrandId"
            label={t("10338")}
            defaultValue={frameBrandId}
            disabled={false}
            handleChange1={handleChangeFilter}
            handleClick1={handleCancelFetch}
            options={selectOptionFrameBrands}
            filterField={"frameBrand"}
          />
        </Grid>

      </Grid>
  )
}

// Toolbar -> TOOLBAR
export default function FrameListToolbar(props) {
  const classes = useJobsToolbarStyles()
  return (
    <div>
      <Paper className={classes.paperToolbar}>
        <ToolbarFilters classes={classes} {...props} />
      </Paper>
    </div>
  )
}
