import React, { useState, useContext, useEffect } from "react"
import { RootContext } from "../../RootContext"

// core components
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import enLocale from "date-fns/locale/en-US"
import itLocale from "date-fns/locale/it"
import grLocale from "date-fns/locale/el"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { useTranslation } from "react-i18next"
import { titleCase } from "title-case";

// Pegaso components
import DatesUtils from "components/Tools/DatesUtils"

export default function FilterDatePicker({
  classes,
  defaultValue,
  filterField,
  handleChange,
  handleClick1,
  id,
  label,
  disabled,
}) {
  const { t } = useContext(RootContext)
  const currentLocale = useTranslation().i18n.language
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChangeEvent = (event) => {
    const TargetValue = DatesUtils.formatDateToString(
      event,
      false,
      false,
      false
    )
    if (handleChange) {
      handleChange(filterField, TargetValue)
    }
    setValue(TargetValue)
    if (handleClick1) {
      handleClick1()
    }
  }
  const localeMap = {
    en: enLocale,
    it: itLocale,
    gr: grLocale,
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[currentLocale]}
    >
      <DatePicker
        id={id}
        label={titleCase(label)}
        className={classes.textField}
        value={value}
        onChange={handleChangeEvent}
        format="dd-MM-yyyy"
        orientation="landscape"
        disabled={disabled ? disabled : false}
        okLabel={t("10229")}
        clearLabel={t("10230")}
        cancelLabel={t("10010")}
        emptyLabel={t("10231")}
        invalidLabel={t("10160")}
        todayLabel={t("10232")}
        showTodayButton={true}
        disableFuture={true}
      />
    </MuiPickersUtilsProvider>
  )
}
