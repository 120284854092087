
import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

//core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import DeleteIcon from '@material-ui/icons/Delete';

//import CSS
import { useDeviceEditScreenStyles } from "styles/device/deviceCss.js"

export default function EmptyTracerButton(props) {
  const { t } = useContext(RootContext)
  const { handleSave, disabled, buttonLabel } = props

  //Classes utilizzate per CSS
  const classes = useDeviceEditScreenStyles()

  /*Da Verificare se i due bottoni deveno essere sempre attivi o meno
    e se servono entrambi
    */

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      p={1}
      className={classes.gridDeviceToolbar}
    >
      <Box p={1}>
        <Button
          disabled={disabled}
          variant="outlined"
          color="secondary"
          onClick={handleSave}
        >
          <DeleteIcon />
          {buttonLabel ? buttonLabel : t("10448")}
        </Button>
      </Box>
    </Box>
  )
}